import React from 'react'

import { components } from 'react-select'
import { observer } from 'mobx-react'

import { sort } from 'utils/array'
import { useStores } from 'stores'
import FormGroup from 'common/FormGroup'
import RadioGroup from 'common/Forms/RadioGroup'
import ReactSelect from 'common/Forms/ReactSelect'
import WebformInfoDeliveryInfoAddress from 'components/Webform/WebformInfoDeliveryInfoAddress'
import Checkbox from 'common/Forms/Checkbox'

const CLIENT = 'client'
const OTHER = 'other'

export default observer(({ resource, data, addressType: addressTypeProp, namePrefix = '', lineIndex, disabled }) => {
  const [addressType, setAddressType] = React.useState(addressTypeProp || CLIENT)

  const [isChecked, setIsChecked] = React.useState(true)

  const { clientSites: clientSitesData = [], deliveryInfo } = data
  const { clientAreaStore } = useStores()
  const isClient = addressType === CLIENT
  const isOther = addressType === OTHER
  const { settings = {} } = data

  const clientSites = React.useMemo(() => sort(clientSitesData, 'name'), [clientSitesData])

  const handleChangeAddressType = selected => {
    setAddressType(selected)
  }

  return (
    <React.Fragment>
      <FormGroup
        formGroup
        horizontal
        label={resource.DeliveryAddress}
        inputColClassName="d-flex flex-sm-row flex-column"
      >
        <div className="col-sm-5 col-12">
          <RadioGroup
            disabled={disabled}
            radios={[
              {
                value: CLIENT,
                label: resource.CompanySite,
              },
              {
                value: OTHER,
                label: resource.OtherAddress,
              },
            ]}
            name={`${namePrefix}deliveryInfo.addressType`}
            label={resource.DeliveryAddress}
            defaultValue={addressType}
            onChange={handleChangeAddressType}
          />
        </div>
        <div className="col-sm-7 col-12">
          {isClient && (
            <ReactSelect
              isDisabled={disabled}
              name={`${namePrefix}deliveryInfo.clientAddress`}
              rules={{ required: isClient && resource.Required }}
              defaultValue={deliveryInfo.clientSite}
              label={resource.CompanySite}
              options={clientSites}
              serverSide
              loadData={args =>
                clientAreaStore.getClientSites({
                  search: args.search,
                  size: 50,
                })
              }
              defaultOptions
              optionValueKey="clientSiteId"
              optionLabelKey="name"
              components={{
                Option: ({ data, ...innerProps }) => {
                  const address = data.address
                  return (
                    <components.Option data={data} {...innerProps}>
                      <strong className="d-block">{data.name}</strong>
                      <small className="d-block muted address-mini">{`${address.street} ${address.number} ${
                        address.box
                      }, ${address.postalCode} ${address.city}, ${address.country ? address.country.name : ''}`}</small>
                    </components.Option>
                  )
                },
              }}
            />
          )}
        </div>
      </FormGroup>
      {isOther && (
        <WebformInfoDeliveryInfoAddress
          {...{ resource, deliveryAddress: deliveryInfo.deliveryAddress, isOther, namePrefix, lineIndex, disabled }}
        />
      )}
      {settings.enableWebformService && (
        <FormGroup formGroup horizontal label={resource.Service} inputColClassName="d-flex flex-sm-row flex-column">
          <Checkbox
            name={`${namePrefix}deliveryInfo.isService`}
            horizontal
            checked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
          />
        </FormGroup>
      )}
    </React.Fragment>
  )
})
