import { makeObservable, observable } from 'mobx'

import BaseStore, { defaults } from 'stores/base'

export default class WebshopPricingStore extends BaseStore {
  ItemPricingTier = { ...defaults.CRUD }

  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options

    makeObservable(this, {
      ItemPricingTier: observable,
    })
  }

  fetchPricingTiers(payload) {
    return this.getRequest(
      this.ItemPricingTier,
      () => this.service.fetchPricingTiers(payload),
      newData => {
        if (newData?.items?.length) {
          this.ItemPricingTier.data = newData.items.reduce((dict, item) => {
            dict[item.itemId] = item.pricingTiers
            return dict
          }, {})
        } else {
          this.ItemPricingTier.data = {}
        }
      }
    )
  }

  getItemPricingTiers = itemId => {
    if (this.ItemPricingTier.data) {
      const pricingTiers = this.ItemPricingTier.data[itemId]
      return pricingTiers
    }
    return []
  }

  getItemPricingTier = (itemId, quantity) => {
    if (this.ItemPricingTier.data) {
      const pricingTiers = this.ItemPricingTier.data[itemId]
      if (pricingTiers?.length) {
        let pricingTier
        if (quantity) {
          pricingTier = pricingTiers
            .slice()
            .reverse()
            .find(item => {
              return quantity >= item.quantity
            })
        } else {
          pricingTier = pricingTiers[0]
        }
        if (pricingTier) {
          return {
            ...pricingTier,
            subTotal: quantity ? quantity * pricingTier.amount : 0,
            subTotalVatIncluded: quantity ? quantity * pricingTier.amountVatIncluded : 0,
          }
        }
      }
    }
    return null
  }
}
