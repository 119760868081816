import BaseService from 'services/base'

export default class GlobalMessageService extends BaseService {
  constructor(api, praApi) {
    super(api)
    this.api = api
    this.praApi = praApi

    this.handleOnApiError = this.handleOnApiError.bind(this)
    this.api.onApiError = this.handleOnApiError

    this.handleOnPraApiError = this.handleOnPraApiError.bind(this)
    this.praApi.onApiError = this.handleOnPraApiError
  }

  handleOnApiError(result) {
    this.onApiError && this.onApiError(result)
  }

  handleOnPraApiError(result) {
    this.onPraApiError && this.onPraApiError(result)
  }
}
