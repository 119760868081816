import React from 'react'

import Card from 'common/Card'
import ReactSelect from 'common/Forms/ReactSelect'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({ resource, session, disabled }) => {
  const languages = React.useMemo(
    () => [
      { value: 'EN', label: 'English' },
      { value: 'FR', label: 'Français' },
      { value: 'NL', label: 'Nederlands' },
    ],
    []
  )

  const defaultLanguage = React.useMemo(
    () => languages.find(item => item.value === session.uiLanguageSetting),
    [languages, session.uiLanguageSetting]
  )

  return (
    <Card title={resource.AccountInfo} fieldset>
      <ReadOnlyField
        label={resource.FullName}
        value={`${session.firstName} ${session.lastName}`}
        formGroup
        horizontal
        labelCol={{ xs: 'auto', lg: 3 }}
        inputCol={{ xs: true, lg: 5 }}
        className="fw-bold"
        formClassName="mb-1"
      />
      <ReactSelect
        name="language"
        label={resource.LanguageLabel}
        defaultValue={defaultLanguage}
        options={languages}
        formGroup
        horizontal
        labelCol={{ lg: 3 }}
        inputCol={{ lg: 5 }}
        formClassName="mb-0"
        isDisabled={disabled}
      />
      <ReadOnlyField
        value={resource.LanguageWarning}
        formGroup
        horizontal
        inputCol={{
          lg: { offset: 3, size: 5 },
        }}
        className="fs-12 text-warning"
      />
    </Card>
  )
}
