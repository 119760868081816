import React from 'react'

import { formatDate } from 'utils/formatters'
import { padLeft } from 'utils'
import Button from 'common/Button'
import Card from 'common/Card'
import Modal from 'common/Modal'
import ReactTable from 'common/ReactTable'
import ReadOnlyAttachment from 'common/ReadOnlyAttachment'
import ReadOnlyField from 'common/ReadOnlyField'

import SupplierPanel from 'components/Case/CaseDetail/Source/QuoteFormModal/SupplierPanel'

export default ({ resource, commonResource, quote, onClose }) => {
  const { attachments, supplier, lines = [] } = quote

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'lineIndex',
        headerClassName: 'col-1 text-muted',
        className: 'col-1 text-muted',
        Cell: ({ value }) => padLeft(value, 1),
      },
      {
        Header: resource.Description,
        accessor: 'description',
        headerClassName: 'col-5',
        className: 'col-5',
        Cell: ({ value, row }) => (
          <div>
            <div className="mb-3 word-break-break-word white-space-normal">{value}</div>
            <div className="mb-3 word-break-break-word white-space-normal">{row.extendedDescription}</div>
          </div>
        ),
      },
      {
        Header: resource.DeliveryDate,
        accessor: 'deliveryDate',
        headerClassName: 'col-2',
        className: 'col-2',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: resource.Qty,
        accessor: 'quantity.amount',
        headerClassName: 'col-1 text-end',
        className: 'col-1 text-end',
      },
      {
        Header: resource.Unit,
        accessor: 'quantity.unit',
        headerClassName: 'col-1 text-end',
        className: 'col-1 text-end',
        Cell: ({ value }) => (
          <div className="d-flex justify-content-between">
            <span>{value}</span>
            <span className="text-muted">x</span>
          </div>
        ),
      },
      {
        Header: `${resource.UnitPrice} (€)`,
        accessor: 'unitPrice.value',
        headerClassName: 'col-2 text-end',
        className: 'col-2 text-end',
        Cell: ({ value, row }) => {
          const notApplicable = !!row.notApplicable
          return (
            <div>
              <div className="text-end mb-3">{notApplicable ? 'N/A' : value}</div>
              <label>
                <input type="checkbox" className="me-2 border-0" disabled defaultChecked={notApplicable} />
                <span className="fw-normal">{resource.NotApplicable}</span>
              </label>
            </div>
          )
        },
      },
    ],

    []
  )

  return (
    <Modal
      isOpen
      title={resource.viewQuote}
      size="xl"
      footer={
        <Button type="button" text color="primary" onClick={onClose}>
          {commonResource.Cancel}
        </Button>
      }
      footerClassName="justify-content-start"
      onClose={onClose}
    >
      {quote.id && (
        <React.Fragment>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="d-flex col-lg-6 col-12 pe-lg-5 pe-0 mb-4">
              <Card title={resource.QuoteInformation} outline className="flex-fill">
                <ReadOnlyField
                  label={resource.ExpirationDate}
                  value={formatDate(quote.expirationDate)}
                  formGroup
                  horizontal
                  labelCol={{ md: 4 }}
                  inputCol={{ md: 6 }}
                />
              </Card>
            </div>
            <SupplierPanel resource={resource} supplier={supplier} />
          </div>
          <Card title={resource.QuotationLines} color="primary" outline className="mb-4">
            <ReactTable columns={columns} data={lines} responsive />
          </Card>
          <Card title={resource.RfqDetails} outline>
            <ReadOnlyField
              label={resource.ExtendedDescription}
              value={quote.extendedDescription}
              className="word-wrap-break-word"
              formGroup
              horizontal
            />
            <ReadOnlyAttachment
              label={resource.Attachments}
              value={attachments}
              noAttachment={resource.NoAttachments}
              formGroup
              horizontal
            />
          </Card>
        </React.Fragment>
      )}
    </Modal>
  )
}
