import BaseService from 'services/base'

export default class WebshopCartService extends BaseService {
  constructor(api) {
    const url = 'webshop/cart'
    super(api, url)
    this.api = api
    this.url = url
  }

  addItemToCart(payload) {
    return this.post('item', payload)
  }

  calculateCartQuantity() {
    return this.get('quantity')
  }

  checkout(cartId) {
    return this.post(`${cartId}/checkout`)
  }

  enableMultiAddress(payload) {
    return this.post('item/multi-address', payload)
  }

  copyCart(payload) {
    return this.api.post('webshop/copycart', payload)
  }

  fetchTable(payload) {
    return this.api.post('webshop/carts', payload)
  }

  getLookups(payload) {
    return this.api.get('webshop/lookups', payload)
  }

  getClientSites({ clientId, ...payload }) {
    return this.api.get(`webshop/${clientId}/sites/search`, payload)
  }

  getClientEmployees({ clientId, ...payload }) {
    return this.api.get(`webshop/${clientId}/employees/search`, payload)
  }

  getPunchoutData() {
    return this.api.get('webshop/punchout')
  }

  removeCartItem({ cartId, itemId }) {
    return this.delete(`${cartId}/item/${itemId}`)
  }

  updateCart(payload) {
    return this.post('update', payload)
  }
}
