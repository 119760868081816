import React from 'react'

import ReactSelect from 'common/Forms/ReactSelect'

export default ({
  resource,
  defaultCategory,
  defaultSubCategory,
  categoryRules,
  subCategoryRules,
  categories: categoriesProp,
  subCategories: subCategoriesProp,
  showSubcategory,
  disabled,
}) => {
  const [category, setCategory] = React.useState(defaultCategory)
  const [subCategory, setSubCategory] = React.useState(defaultSubCategory)
  const [categories, setCategories] = React.useState(categoriesProp)
  const [subCategories, setSubCategories] = React.useState(subCategoriesProp || [])
  const [isDisableSubCategory, setIsDisableSubCategory] = React.useState(false)

  React.useEffect(() => {
    if (defaultCategory) {
      handleCategoryChange(defaultCategory)
    }
  }, [defaultCategory])

  const handleCategoryChange = selected => {
    if (selected) {
      const newSubCategories = subCategoriesProp.filter(item => item.categoryNames.includes(selected.name))
      if (newSubCategories && newSubCategories.length) {
        setIsDisableSubCategory(false)
      } else {
        setIsDisableSubCategory(true)
        setSubCategory(null)
      }
      setSubCategories(newSubCategories)
    } else {
      setCategories([...categoriesProp])
      setSubCategories([...subCategoriesProp])
      setIsDisableSubCategory(false)
      setSubCategory(null)
    }
    setCategory(selected)
  }

  const handleSubCategoryChange = selected => {
    if (selected) {
      const newCategories = categoriesProp.filter(
        item => item.subcategories && item.subcategories.includes(selected.name)
      )
      setCategories(newCategories)
    } else {
      setCategories([...categoriesProp])
      setSubCategories([...subCategoriesProp])
    }
    setSubCategory(selected)
  }

  return (
    <React.Fragment>
      <ReactSelect
        name="category"
        rules={categoryRules}
        label={`${resource.Category} *`}
        value={category}
        defaultValue={defaultCategory}
        placeholder={resource.SelectAppropriateCategory}
        options={categories}
        optionValueKey="id"
        optionLabelKey="name"
        formGroup
        horizontal
        isClearable
        isDisabled={disabled}
        onChange={handleCategoryChange}
      />
      {showSubcategory && (
        <ReactSelect
          name="subCategory"
          rules={subCategoryRules}
          value={!isDisableSubCategory ? subCategory : null}
          defaultValue={defaultSubCategory}
          label={resource.SubCategory}
          placeholder={resource.SelectAppropriateSubcategory}
          options={subCategories}
          optionValueKey="id"
          optionLabelKey="name"
          formGroup
          horizontal
          isClearable
          isDisabled={disabled || isDisableSubCategory}
          onChange={handleSubCategoryChange}
        />
      )}
    </React.Fragment>
  )
}
