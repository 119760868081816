import BaseStore from 'stores/base'

class AccountClientStore extends BaseStore {
  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options
  }

  selectClient(clientId) {
    return this.request(this.CRUD, () => this.service.selectClient(clientId))
  }

  selectClientByPraId(praId) {
    return this.request(this.CRUD, () => this.service.selectClientByPraId(praId))
  }
}

export default AccountClientStore
