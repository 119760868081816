import React from 'react'

import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'

import { useStores } from 'stores'
import Button from 'common/Button'
import Input from 'common/Forms/Input'
import Modal from 'common/Modal'

export default observer(({ caseId, subject, isOpen, onClose }) => {
  const { pageResourceStore, clientCaseStore } = useStores()
  const methods = useForm()

  const { handleSubmit } = methods
  const {
    CRUD: { submitting },
  } = clientCaseStore
  const {
    PageResource: { case: resource = {}, common: commonResource = {} },
  } = pageResourceStore

  const handleOnSubmit = async values => {
    await clientCaseStore.updateSubject(caseId, values)
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      useForm={methods}
      title={resource.EditCaseSubject}
      footer={
        <div className="w-100 d-flex">
          <Button type="button" text color="primary" disabled={submitting} className="me-2" onClick={onClose}>
            {commonResource.Cancel}
          </Button>
          <Button type="submit" icon="done" disabled={submitting} loading={submitting} className="ms-auto">
            {commonResource.Save}
          </Button>
        </div>
      }
      disableClose={submitting}
      toggle={onClose}
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <Input
        name="subject"
        rules={{ required: commonResource.Required }}
        placeholder={resource.EnterSubject}
        defaultValue={subject}
        disabled={submitting}
        autoFocus
      />
    </Modal>
  )
})
