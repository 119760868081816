import React from 'react'

import { useLocation } from 'react-router-dom'

import { ROUTES } from 'definitions'

import AttributesPanel from './AttributesPanel'
import CategoriesPanel from './CategoriesPanel'
import PriceFilterPanel from './PriceFilterPanel'
import SearchPanel from './SearchPanel'

export default () => {
  const location = useLocation()
  const isSearchPage = React.useMemo(
    () => location.pathname.toLowerCase().startsWith(ROUTES.WEBSHOP_SEARCH),
    [location.pathname]
  )

  return (
    <div id="webshop-sidebar" className="d-xl-block d-none pe-3">
      <SearchPanel />
      {isSearchPage && <AttributesPanel categoryOnly={isSearchPage} />}
      {!isSearchPage && <CategoriesPanel />}
      <PriceFilterPanel />
      <AttributesPanel excludeCategory />
    </div>
  )
}
