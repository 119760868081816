import React from 'react'

import { useFormState } from 'react-hook-form'

import Icon from 'common/Icon'
import Input from 'common/Forms/Input'

export default ({ resource, lineItem, index }) => {
  const { errors } = useFormState()

  const hasError = !!errors.lines && !!errors.lines[index]
  const lineNumber = lineItem.lineNumber > 0 ? lineItem.lineNumber : index + 1

  return (
    <div className="col-auto fw-bold">
      <Input type="hidden" name={`lines.${index}.lineNumber`} defaultValue={0} />
      <span className="d-lg-none d-flex align-items-center">
        {!hasError && resource.Line} #{lineNumber}
        {hasError && <Icon icon="error" className="ms-2 text-danger" title={resource.LineHasError} />}
      </span>
      <span className="d-lg-inline d-none">
        {hasError && (
          <Icon icon="error" className="text-danger" title={resource.LineHasError} style={{ marginLeft: '-5px' }} />
        )}
        {!hasError && lineNumber}
      </span>
    </div>
  )
}
