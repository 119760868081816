import BaseService from 'services/base'

export default class ConnectionCartService extends BaseService {
  constructor(api) {
    const url = 'connections/carts'
    super(api, url)
    this.api = api
    this.url = url
  }

  getCartQuantity() {
    return this.get('quantity')
  }

  getPunchout() {
    return this.get('punchout')
  }

  checkout(cartId) {
    return this.post(`${cartId}/checkout`)
  }
}
