import React from 'react'

import cx from 'classnames'

import { formatCurrency, formatDate } from 'utils/formatters'
import { range } from 'utils/array'
import Button from 'common/Button'
import ProposalMessage from 'components/Case/CaseList/Proposals/ProposalMessage'
import ProposalShareColleague from 'components/Case/CaseList/Proposals/ProposalShareColleague'
import RejectProposal from 'components/Case/CaseList/Proposals/RejectProposalModal'

import pdfLogo from 'assets/images/icon-pdf.png'

export default ({ session, resource, caseProposal, bundle, isAborted, onPickProposal }) => {
  const [isRejectModalOpen, setIsRejectModalOpen] = React.useState(false)
  const proposals = bundle.proposals || []
  const filler = range(0, 3 - proposals.length)
  const canPick =
    session.pickProposalsWithoutPunchout || (!session.pickProposalsWithoutPunchout && session.hasPunchoutSession)

  const handleRejectSubmit = reason => {
    setIsRejectModalOpen(false)
  }

  return (
    <table className="proposal-table table table-bordered mb-0 responsive-min-width">
      <thead>
        {session.enableProposalShareWithColleague && (
          <ProposalShareColleague
            resource={resource}
            caseProposal={caseProposal}
            proposals={proposals}
            filler={filler}
          />
        )}
        <tr>
          <th className="col-3">&nbsp;</th>
          {proposals.map((proposal, index) => (
            <td key={index} className={cx('col-3 white-space-normal', proposal.isSelected && 'table-success')}>
              <h5 className="d-inline-block mb-0" title={proposal.proposalDocNumber}>
                {index + 1}
              </h5>
              <small className="muted">{proposal.description}</small>
            </td>
          ))}
          {filler.map(item => (
            <td key={item} className="bg-light col-3" />
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{resource.ValidFrom}</th>
          {proposals.map((proposal, index) => (
            <td key={index} className={cx(proposal.isSelected && 'table-success')}>
              {formatDate(proposal.proposalDate, 'YYYY-MM-DD')}
            </td>
          ))}
          {filler.map(item => (
            <td key={item} className="bg-light" />
          ))}
        </tr>
        <tr>
          <th>{resource.ValidUntil}</th>
          {proposals.map((proposal, index) => (
            <td key={index} className={cx(proposal.isSelected && 'table-success')}>
              {formatDate(proposal.expiryDate, 'YYYY-MM-DD')}
            </td>
          ))}
          {filler.map(item => (
            <td key={item} className="bg-light" />
          ))}
        </tr>
        <tr>
          <th>
            {resource.TotalPrice} <small>{resource.VatExclusive}</small>
          </th>
          {proposals.map((proposal, index) => (
            <td key={index} className={cx(proposal.isSelected && 'table-success')}>
              {formatCurrency(proposal.totalVATExclusive.value)}
            </td>
          ))}
          {filler.map(item => (
            <td key={item} className="bg-light" />
          ))}
        </tr>
        <tr>
          <th>{resource.DeliveryDate}</th>
          {proposals.map((proposal, index) => (
            <td key={index} className={cx(proposal.isSelected && 'table-success')}>
              {resource.SeePdfProposal}
            </td>
          ))}
          {filler.map(item => (
            <td key={item} className="bg-light" />
          ))}
        </tr>
        <tr>
          <th>{resource.DownloadProposal}</th>
          {proposals.map((proposal, index) => (
            <td key={index} className={cx(proposal.isSelected && 'table-success')}>
              <a
                id={`file-download-proposal-${proposal.proposalId}`}
                href={bundle.shouldPromptSaveAs ? `${proposal.pdfFileUrl}?PromptForSaveAs=true` : proposal.pdfFileUrl}
                download={`${proposal.proposalDocNumber}.pdf`}
                className="btn btn-small btn-info"
              >
                <img src={pdfLogo} alt={resource.Download} className="me-1" /> {resource.Download}
              </a>
            </td>
          ))}
          {filler.map(item => (
            <td key={item} className="bg-light" />
          ))}
        </tr>
        <tr>
          <th>{resource.Attachments}</th>
          {proposals.map((proposal, index) => (
            <td key={index}>
              <ul className="list-unstyled">
                {proposal.attachments &&
                  proposal.attachments.map((attachment, index) => (
                    <li key={index}>
                      <a
                        href={`${attachment.url}${bundle.shouldPromptSaveAs ? '?PromptForSaveAs=true' : ''}`}
                        download={attachment.fileName}
                      >
                        <span className="material-icons-outlined me-1">attachment</span>
                        {attachment.fileName}
                      </a>
                    </li>
                  ))}
                {(!proposal.attachments || !proposal.attachments.length) && (
                  <li>
                    <em className="text-muted">{resource.NoAttachments}</em>
                  </li>
                )}
              </ul>
            </td>
          ))}
          {filler.map(item => (
            <td key={item} className="bg-light" />
          ))}
        </tr>
        <tr>
          <th>{resource.Remarks}</th>
          {proposals.map((proposal, index) => (
            <td
              key={index}
              className={cx('white-space-break-spaces white-space-normal', proposal.isSelected && 'table-success')}
            >
              {proposal.remark}
            </td>
          ))}
          {filler.map(item => (
            <td key={item} className="bg-light" />
          ))}
        </tr>
        <tr>
          <th />
          {proposals.map((proposal, index) => (
            <td key={index} className="white-space-break-spaces white-space-normal">
              {resource.TermsAndConditionMessage}
            </td>
          ))}
          {filler.map(item => (
            <td key={item} className="bg-light" />
          ))}
        </tr>
        <ProposalMessage proposals={proposals} resource={resource} filler={filler} />

        <RejectProposal
          isOpen={isRejectModalOpen}
          onClose={() => setIsRejectModalOpen(false)}
          onSubmit={handleRejectSubmit}
          namePrefix="rejection"
          resource={resource}
          caseProposals={caseProposal}
          proposal={proposals}
        />

        {canPick &&
          !bundle.bundleRevoked &&
          !bundle.proposalSelected &&
          bundle.isCaseForCurrentUser &&
          !bundle.bundleRejected && (
            <tr>
              <td className="full-cell">
                <Button
                  size="lg"
                  color="danger"
                  icon="thumb_down"
                  disabled={isAborted}
                  onClick={() => setIsRejectModalOpen(true)}
                >
                  {resource.RejectProposal}
                </Button>
              </td>
              {proposals.map((proposal, index) => (
                <td key={index} className="full-cell">
                  <Button
                    size="lg"
                    color="success"
                    icon="thumb_up"
                    disabled={isAborted}
                    onClick={() => {
                      onPickProposal(proposal.proposalId)
                    }}
                  >
                    {resource.PickThisProposal}
                  </Button>
                </td>
              ))}
              {filler.map(item => (
                <td key={item} className="bg-light" />
              ))}
            </tr>
          )}
      </tbody>
    </table>
  )
}
