import React from 'react'

import { observer } from 'mobx-react'
import { useStores } from 'stores'
import LayoutTitle from 'common/LayoutTitle'
import NewsItem from 'components/News/NewsItem'

export default observer(() => {
  const { authStore, pageResourceStore, newsStore } = useStores()
  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { news: resource = {} },
  } = pageResourceStore

  const data = newsStore.CRUD.data || {}

  React.useEffect(() => {
    const payload = {
      clientId: session.clientId,
      clientAbbreviation: session.clientAbbreviation,
      email: session.email,
      userAuthId: session.UserAuthId,
    }
    pageResourceStore.get('news')
    newsStore.getNews(payload)
  }, [newsStore, pageResourceStore, session])

  return (
    <div id="news">
      <LayoutTitle title={resource.News} />

      <div className="news-list">
        {data &&
          data.newsFeedItems &&
          data.newsFeedItems.map((newsItem, index) => {
            return <NewsItem key={index} newsItem={newsItem} resource={resource} />
          })}
      </div>
    </div>
  )
})
