import BaseStore from 'stores/base'

class AccountStore extends BaseStore {
  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options
  }

  forgotPassword(payload) {
    return this.request(this.CRUD, async () => this.service.forgotPassword(payload))
  }

  changePassword(payload) {
    return this.request(this.CRUD, async () => this.service.changePassword(payload))
  }

  updateEmailNotification(clientId, employeeId, payload) {
    return this.request(this.CRUD, async () => this.service.updateEmailNotification(clientId, employeeId, payload))
  }

  updateAccountInfo(payload) {
    return this.request(this.CRUD, async () => this.service.updateAccountInfo(payload))
  }
}

export default AccountStore
