import React from 'react'

import { components } from 'react-select'
import cx from 'classnames'

import { ADDRESS_TYPE } from 'definitions'
import { useStateCallback } from 'hooks/useStateCallback'
import Icon from 'common/Icon'
import RadioGroup from 'common/Forms/RadioGroup'
import ReactSelect from 'common/Forms/ReactSelect'

import CartSummaryDeliveryAddressPopup from './CartSummaryDeliveryAddressPopup'
import CartSummaryDeliveryOtherAddress from './CartSummaryDeliveryOtherAddress'

export default ({ resource, webshopCartStore, clientId, cart, disabled, updateCart }) => {
  const [addressType, setAddressType] = useStateCallback(cart.deliveryInfo?.addressType || ADDRESS_TYPE.CLIENT)
  const [isOpen, setIsOpen] = React.useState()
  // const [otherAddress, setOtherAddress] = React.useState(cart.deliveryInfo?.address)

  const isClient = addressType === ADDRESS_TYPE.CLIENT
  const isOther = addressType === ADDRESS_TYPE.OTHER
  const otherAddressCheckBoxId = `other-address-checkbox-id-${cart.id}`

  const handleChangeAddressType = selected => setAddressType(selected, () => updateCart())

  const toggle = () => setIsOpen(prev => !prev)

  return (
    <React.Fragment>
      <RadioGroup
        radios={[
          {
            value: ADDRESS_TYPE.CLIENT,
            label: resource.ClientSite,
            children: isClient && (
              <ReactSelect
                isDisabled={disabled}
                name="deliveryInfo.clientAddress"
                rules={{ required: isClient && resource.Required }}
                formGroup
                optionValueKey="clientSiteId"
                optionLabelKey="name"
                serverSide
                loadData={args =>
                  webshopCartStore.getClientSites({
                    clientId,
                    search: args.search,
                    size: 50,
                  })
                }
                defaultOptions
                components={{
                  Option: ({ data, ...innerProps }) => {
                    const address = data.address
                    return (
                      <components.Option data={data} {...innerProps}>
                        <strong className="d-block">{data.name}</strong>
                        <small className="d-block muted address-mini">{`${address.street} ${address.number} ${address.box}, ${
                          address.postalCode
                        } ${address.city}, ${address.country ? address.country.name : ''}`}</small>
                      </components.Option>
                    )
                  },
                }}
                onChange={() => updateCart()}
              />
            ),
          },
          {
            id: otherAddressCheckBoxId,
            value: ADDRESS_TYPE.OTHER,
            label: (
              <React.Fragment>
                <span>{resource.OtherAddress}</span>
                {isOther && (
                  <Icon
                    icon="edit_note"
                    className="text-primary position-absolute"
                    style={{ top: 0, right: '-30px', bottom: 0 }}
                    title={resource.EditOtherAddress}
                  />
                )}
              </React.Fragment>
            ),
            labelClassName: 'position-relative',
          },
        ]}
        name="deliveryInfo.addressType"
        rules={{ required: resource.Required }}
        label={<h5 className="webshop-title-color fw-bolder mb-0">{resource.DeliveryAddress}</h5>}
        formGroup
        formClassName={cx(isOther && 'mb-0')}
        disabled={disabled}
        onChange={handleChangeAddressType}
      />
      <CartSummaryDeliveryAddressPopup
        isOpen={isOpen}
        target={otherAddressCheckBoxId}
        isOther={isOther}
        resource={resource}
        disabled={disabled}
        address={cart.deliveryInfo?.address}
        toggle={toggle}
        updateCart={() => updateCart()}
      />
      {isOther && <CartSummaryDeliveryOtherAddress resource={resource} />}
    </React.Fragment>
  )
}
