import React from 'react'

import { Alert } from 'reactstrap'

import ActionsPanel from './ActionsPanel'
import PricingTier from './PricingTier'
import ProductPrice from './ProductPrice'

export default ({ resource, data, setPricingTier, onBeforeChange }) => {
  return (
    <div className="sidebar-panel px-3 webshop-text-color col-md-auto col-12">
      <ProductPrice resource={resource} />
      <ActionsPanel resource={resource} data={data} setPricingTier={setPricingTier} onBeforeChange={onBeforeChange} />
      <Alert color="warning" className="mb-5">
        {resource.ShippingCostWarning}
      </Alert>
      <PricingTier resource={resource} itemId={data.itemId} />
    </div>
  )
}
