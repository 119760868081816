import React from 'react'

import { useWatch } from 'react-hook-form'
import cx from 'classnames'

import { FlexTableCell, FlexTableRow } from 'common/FlexTable'
import { formatCurrency, formatDate, formatNumber } from 'utils/formatters'
import Input from 'common/Forms/Input'
import NumberControl from 'common/Forms/NumberControl'
import ReadOnlyField from 'common/ReadOnlyField'
import WatchField from 'common/Forms/WatchField'

import CartTableTransportTresholds from './CartTableTransportTresholds'

export default ({
  index,
  resource,
  settings,
  categories,
  cart,
  supplierId,
  estimatedDeliveryDate,
  isStripe,
  disabled,
  showSubCategories,
  onMultiAddressCountChange,
}) => {
  const transports = useWatch({ name: 'transports' })

  const transport = React.useMemo(
    () => transports && transports.find(item => item.supplierId === supplierId),
    [transports, supplierId]
  )

  const transportCategoryName = React.useMemo(() => {
    return (transport && categories.find(item => item.code === transport.categoryCode)?.name) || ''
  }, [categories, transport])

  return (
    <React.Fragment>
      {!transport && !!estimatedDeliveryDate && (
        <FlexTableRow className={cx('transport border-0 pb-2', isStripe && 'table-striped-bg')}>
          <FlexTableCell className="justify-content-end col-12 fw-bold fs-16 pe-3">
            <WatchField
              name={`items.${index}.estimatedDeliveryDate`}
              defaultValue={estimatedDeliveryDate}
              render={value => (
                <span>
                  {resource.EstimatedDeliveryDate}: {formatDate(value)}
                </span>
              )}
            />
          </FlexTableCell>
        </FlexTableRow>
      )}
      {!!transport && (
        <React.Fragment>
          <FlexTableRow className={cx('transport border-0 pt-3', isStripe && 'table-striped-bg')}>
            <FlexTableCell className="align-items-start col-xl-5 col-12 fw-bold fs-12 ps-action-space pb-xl-0">
              {resource.Transport}
            </FlexTableCell>
            {cart.isMultiAddress ? (
              <FlexTableCell
                key={`transportItem-${transport.itemReference}`}
                className="d-flex align-items-start col-xl col-12 pb-xl-0 p-xl-2 pe-xl-0 p-1"
              >
                <span className="d-xl-none d-inline-block col-sm-4 col-auto fs-12 me-2">{resource.Quantity}:</span>
                <Input type="hidden" name="isMultiAddress" defaultValue={cart.isMultiAddress} />
                <div className="col-xl-12 col-md-4 col-sm-6 col-8">
                  <NumberControl
                    name={'multiAddressCount'}
                    rules={{ required: resource.Required }}
                    defaultValue={transport.quantity}
                    step={1}
                    min={1}
                    formGroup
                    formClassName="mb-1"
                    disabled={disabled}
                    onChange={newQuantity => onMultiAddressCountChange(newQuantity)}
                  />
                  <div className="fs-12">{resource.MultipleDeliveryAddressCount}</div>
                </div>
              </FlexTableCell>
            ) : (
              <FlexTableCell className="d-flex align-items-start col-xl col-12 fs-12 pb-xl-0">
                <span className="d-xl-none d-inline-block col-sm-4 col-6 fw-bold me-2">{resource.Quantity}:</span>
                <span>{formatNumber(transport.quantity)}</span>
              </FlexTableCell>
            )}
            <FlexTableCell className="d-flex align-items-start justify-content-xl-end col-xl-2 col-12 fs-12 pb-xl-0">
              <span className="d-xl-none d-inline-block col-sm-4 col-6 fw-bold me-2">
                {resource.PricePerPieceExclVAT}:
              </span>
              <span>{formatCurrency(transport.amount)}</span>
            </FlexTableCell>
            <FlexTableCell className="d-flex align-items-start justify-content-xl-end col-xl-2 col-12 fs-12 pb-xl-0">
              <span className="d-xl-none d-inline-block col-sm-4 col-6 fw-bold me-2">{resource.SubTotalExclVAT}:</span>
              <span>{formatCurrency(transport.subTotal)}</span>
            </FlexTableCell>
          </FlexTableRow>
          <FlexTableRow className={cx('transport', isStripe && 'table-striped-bg')}>
            <FlexTableCell className="align-items-start col-xl-5 col-12 d-block ps-action-space pt-xl-0">
              <ReadOnlyField
                value={transportCategoryName}
                label={resource.Category}
                formGroup
                className="form-control"
                labelClassName="mb-1"
              />
              {showSubCategories && (
                <ReadOnlyField
                  value={transport.subCategoryCode}
                  label={resource.SubCategory}
                  formGroup
                  className="form-control"
                  labelClassName="mb-1"
                />
              )}
              {settings.enableGLAccount && (
                <ReadOnlyField
                  value={transport.glAccount?.glAccount || ''}
                  label={resource.GLAccount}
                  formGroup
                  className="form-control"
                  labelClassName="mb-1"
                />
              )}
              <CartTableTransportTresholds resource={resource} transport={transport} />
            </FlexTableCell>
            <FlexTableCell className="align-items-start col pt-xl-0">
              {transport.remarks && (
                <ReadOnlyField
                  value={transport.remarks}
                  label={resource.Remarks}
                  formGroup
                  className="pt-0"
                  formClassName="mb-0"
                  labelClassName="mb-1"
                />
              )}
              {estimatedDeliveryDate && (
                <div className="mt-auto text-end fw-bold col-12 fs-16 pe-3">
                  <WatchField
                    name={`items.${index}.estimatedDeliveryDate`}
                    defaultValue={estimatedDeliveryDate}
                    render={value => (
                      <span>
                        {resource.EstimatedDeliveryDate}: {formatDate(value)}
                      </span>
                    )}
                  />
                </div>
              )}
            </FlexTableCell>
          </FlexTableRow>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
