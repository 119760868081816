import BaseService from 'services/base'

export default class AccountClientService extends BaseService {
  constructor(api) {
    const url = 'account'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchTable(payload) {
    return this.get('clients', payload)
  }

  selectClient(clientId) {
    return this.post(`client/${clientId}/select`, null, { ignoreGlobalMessage: true })
  }

  selectClientByPraId(praId) {
    return this.post(`selectClient/${praId}`)
  }
}
