import React from 'react'

import cx from 'classnames'

import pdfLogo from 'assets/images/icon-pdf.png'

export default ({ fileId, fileName, title, className }) => {
  return (
    <a
      title={title}
      href={`${process.env.REACT_APP_API_URL}/file/${fileId}`}
      download={`${fileName}.pdf`}
      className={cx(className)}
    >
      <img src={pdfLogo} alt={fileName} />
    </a>
  )
}
