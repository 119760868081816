import React from 'react'

import { useFormContext } from 'react-hook-form'
import cx from 'classnames'

import { FlexTableCell } from 'common/FlexTable'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'

const defaultCurrency = {
  code: 'EUR',
  description: 'Euro',
}

export default ({ resource, currencies = [], index, lineItem, settings, isReadOnly, isDisabled }) => {
  const [currency, setCurrency] = React.useState(lineItem.currency || defaultCurrency)
  const { getValues, watch, setValue } = useFormContext()

  const supplierIdWatch = watch('supplierId')

  const enableSelection = React.useMemo(() => {
    return (
      !isReadOnly && settings.enableOtherCurrency && index === 0 && supplierIdWatch.id !== settings.defaultSupplierId
    )
  }, [index, isReadOnly, supplierIdWatch, settings])

  const handleCurrencyChange = selected => {
    setCurrency(selected)
    const lines = getValues('lines')
    if (lines.length > 1) {
      lines.forEach((_, lineIndex) => setValue(`lines.${lineIndex}.currency`, selected))
    }
  }

  return (
    <FlexTableCell
      className={cx('col-6 justify-content-start', {
        'col-lg-1': !settings.enableOtherCurrency,
        'col-lg-2': settings.enableOtherCurrency,
      })}
    >
      {enableSelection && (
        <ReactSelect
          name={`lines.${index}.currency`}
          rules={{ required: resource.Required }}
          label={resource.Currency}
          defaultValue={currency}
          options={currencies}
          optionValueKey="code"
          optionLabelKey="description"
          formGroup
          isDisabled={isDisabled}
          readOnlyField={isReadOnly}
          formClassName="col mb-0"
          labelClassName="d-lg-none"
          onChange={handleCurrencyChange}
        />
      )}
      {!enableSelection && (
        <React.Fragment>
          <Input type="hidden" name={`lines.${index}.currency.code`} defaultValue={currency.code} />
          <Input
            name={`lines.${index}.currency.description`}
            defaultValue={currency.description}
            label={resource.Currency}
            formGroup
            formClassName="col mb-0"
            labelClassName="d-lg-none"
            textOnlyInput
          />
        </React.Fragment>
      )}
    </FlexTableCell>
  )
}
