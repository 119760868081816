import React from 'react'

import { observer } from 'mobx-react'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { useStores } from 'stores'
import ContentLoader from 'common/ContentLoader'
import ProductItem from './ProductItem'

import ProductsEmptyResult from './ProductsEmptyResult'

export default observer(({ filters }) => {
  const { authStore, pageResourceStore, webshopStore } = useStores()
  const [items, setItems] = React.useState([])

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { webshop: resource },
  } = pageResourceStore
  const {
    Table: {
      data: { items: itemsData = [] },
      loading,
    },
    ItemPricing: { data: itemPricingLookup = {}, loading: itemPricingLoading },
  } = webshopStore

  React.useEffect(() => {
    return () => webshopStore.clearProductDetail()
  }, [webshopStore])

  useDeepCompareEffect(() => {
    setItems(itemsData)
  }, [webshopStore, setItems, itemsData])

  useDeepCompareEffect(() => {
    if (itemsData.length && !itemPricingLoading) {
      if (Object.keys(itemPricingLookup).length) {
        const { page, pageSize, priceFrom, priceTo, sort, isFilterByPrice, isSortByPrice } = filters

        let newItems = itemsData

        if (isFilterByPrice) {
          // filter by price
          newItems = newItems.filter(item => {
            const pricing = itemPricingLookup[item.itemId]

            const amount = pricing?.amount || 0
            return priceFrom <= amount && amount <= priceTo
          })
        }
        if (isSortByPrice) {
          // sort by price
          newItems = newItems.sort((item1, item2) => {
            const pricing1 = itemPricingLookup[item1.itemId]
            const pricing2 = itemPricingLookup[item2.itemId]

            if (pricing1?.amount && pricing2) {
              if (sort.desc) {
                return pricing2.amount - pricing1.amount
              } else {
                return pricing1.amount - pricing2.amount
              }
            }
            return 0
          })
        }
        if (isFilterByPrice || isSortByPrice) {
          webshopStore.Table.totalItemCountByPrice = newItems.length
          webshopStore.Table.loading = false

          if (newItems.length > pageSize) {
            const from = page ? (page - 1) * pageSize : 0
            const to = from + pageSize
            newItems = newItems.slice(from, to)
          }

          setItems(newItems)
        }
      }
    }
  }, [webshopStore, setItems, itemsData, itemPricingLookup, itemPricingLoading, filters])

  React.useEffect(() => {
    // For loading webshop item pricing
    if (itemsData?.length) {
      webshopStore.getItemPricing({
        clientId: session.clientId,
        items: itemsData.map(item => ({
          itemId: item.itemId,
          serviceContract: item.serviceContract,
        })),
      })
    }
  }, [webshopStore, itemsData, session.clientId])

  const initialPageLoading = loading && !filters.page
  const showEmptyResult = !loading && !items.length

  return (
    <React.Fragment>
      <div className="position-relative d-flex flex-wrap">
        <ContentLoader
          show={loading}
          className="align-items-start justify-content-start"
          loaderClassName="ms-4 mt-4"
          size="lg"
        />
        {!initialPageLoading &&
          items.map((item, index) => (
            <ProductItem
              key={`${item.itemId}-${index}`}
              resource={resource}
              item={item}
              itemPricingLookup={itemPricingLookup}
              itemPricingLoading={itemPricingLoading}
            />
          ))}
      </div>
      {showEmptyResult && <ProductsEmptyResult resource={resource} subCriteria={webshopStore.SubCriteria} />}
    </React.Fragment>
  )
})
