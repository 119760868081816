import React from 'react'

import { useFormContext } from 'react-hook-form'

import { formatCurrency } from 'utils/formatters'
import Button from 'common/Button'

export default ({ resource, cart, submitting, saving }) => {
  const { watch } = useFormContext()

  const totalWatch = watch('cartTotal')
  const total = totalWatch === undefined ? cart.total : totalWatch

  return (
    <div className="filler mt-5 p-1">
      <div className="d-flex align-items-center justify-content-center mt-4 mb-3">
        <h5 className="webshop-title-color mb-0 fw-bolder">
          {resource.GrandTotal}: {formatCurrency(total || 0)}
        </h5>
      </div>
      <Button
        type="submit"
        color="primary"
        size="lg"
        className="w-100"
        disabled={submitting || saving}
        loading={submitting}
      >
        {resource.Checkout}
      </Button>
    </div>
  )
}
