import React from 'react'

import { Badge, PopoverBody, UncontrolledPopover } from 'reactstrap'
import cx from 'classnames'

import { CASE_TYPE } from 'definitions'
import { formatDate } from 'utils/formatters'
import Icon from 'common/Icon'

export default ({ session, resource, caseItem }) => {
  const getCaseType = type => {
    switch (type) {
      case CASE_TYPE.Connection:
        return { value: CASE_TYPE.Connection.toLowerCase(), label: 'Connections' }
      case CASE_TYPE.BeforeTheFacts:
      case CASE_TYPE.AfterTheFacts:
        return { value: 'po', label: 'PO' }
      case CASE_TYPE.ContractPo:
        return { value: 'contract-po', label: 'CONTRACT PO' }
      case CASE_TYPE.SelfService:
        return { value: 'self-service', label: 'DIY' }
      default:
        return { value: type.toLowerCase(), label: type }
    }
  }

  const caseType = getCaseType(caseItem.type)

  return (
    <div className="d-flex col mb-lg-0 mb-2 flex-sm-row flex-column">
      <div className="col-auto mb-2">
        <Badge color="none" className={cx(`badge-case badge-case-${caseType.value}`)}>
          {caseType.label}
        </Badge>
      </div>
      <div className="col px-sm-3 px-0">
        <small className="text-muted d-flex flex-wrap flex-sm-row flex-column mb-xl-0 mb-2">
          <div className="d-flex align-items-center mb-1 me-2">{caseItem.caseNumber}</div>
          <div className="d-flex align-items-center mb-1 me-2">
            <Icon icon="person" filled className="md-12 me-1 text-muted" />
            <span className="requester-name">{caseItem.requester.employeeName}</span>
          </div>
          <div className="d-flex align-items-center mb-1 me-2">
            <Icon icon="schedule" filled className="md-12 me-1 text-muted" />
            <span title={resource.CreationDate}>{formatDate(caseItem.created, 'dddd, D MMMM YYYY, H:mm')}</span>
          </div>
          {caseItem.deliveryDate && (
            <div className="d-flex align-items-center mb-1 me-2">
              <Icon icon="local_shipping" filled className="md-12 me-1 text-muted" />
              <span title={resource.DeliveryDate}>{formatDate(caseItem.deliveryDate, 'dddd, D MMMM YYYY, H:mm')}</span>
            </div>
          )}
          {session.showCaseOrderSupplier && caseItem.orderSuppliers && (
            <React.Fragment>
              <div id={`case-${caseItem.id}popover`} className="d-flex align-items-center me-2 cursor-pointer">
                <Icon icon="local_shipping" filled className="md-12 me-1 text-muted" />
                <span>
                  {caseItem.orderSuppliers.length > 1 ? resource.MultipleSupplier : caseItem.orderSuppliers[0].name}{' '}
                </span>
              </div>
              <UncontrolledPopover target={`case-${caseItem.id}popover`} trigger="hover" placement="top">
                <PopoverBody>
                  <div className="supplier-popover">
                    {caseItem.orderSuppliers.map((supplier, index) => (
                      <div key={index}>
                        <div className="fw-semibold mb-1">{supplier.name}</div>
                        <div className="d-flex align-items-center mb-1">
                          <Icon icon="phone" className="md-22 me-1" />
                          <span>{supplier.telephoneNumber}</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <Icon icon="email" className="md-22 me-1" />
                          <span>{supplier.emailAddress}</span>
                        </div>
                        <div className="spacer"></div>
                      </div>
                    ))}
                  </div>
                </PopoverBody>
              </UncontrolledPopover>
            </React.Fragment>
          )}
          {caseItem.poReferences.length > 0 && (
            <div className="d-flex align-items-center mb-1 me-2">
              <Icon icon="description" filled className="md-12 me-1 text-muted" />
              <span title={resource.POReference}>
                {caseItem.poReferences.length > 1 ? resource.MultiplePOReference : caseItem.poReferences[0]}
              </span>
            </div>
          )}
        </small>
        <div className="fw-semibold word-break-break-word white-space-normal">{caseItem.subject}</div>
      </div>
    </div>
  )
}
