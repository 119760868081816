import React from 'react'

import { PopoverBody, UncontrolledPopover } from 'reactstrap'
import cx from 'classnames'

import { getRandomUniqueNumber } from 'utils'

export default ({ idPrefix = 'fileRestrictionTooltip', resource, accept, className, maxFileSizeLabel = '10 MB' }) => {
  const popupId = React.useMemo(() => `${idPrefix}-${getRandomUniqueNumber()}`, [idPrefix])

  return (
    <React.Fragment>
      <small
        id={popupId}
        className={cx(
          'file-restriction-tooltip d-inline-flex align-items-center text-primary cursor-pointer',
          className
        )}
      >
        <span className="material-icons-outlined md-20 me-1 text-primary">help</span>
        {resource.UploadRestriction}
      </small>
      <UncontrolledPopover trigger="hover" placement="left" target={popupId}>
        <PopoverBody>
          <div>
            <strong>{resource.MaxFileSize}</strong>
          </div>
          <div>{maxFileSizeLabel}</div>
          <br />
          <div>
            <strong>{resource.AllowedFileTypes}</strong>
          </div>
          <div>{accept && accept.split(',').join(', ')}</div>
        </PopoverBody>
      </UncontrolledPopover>
    </React.Fragment>
  )
}
