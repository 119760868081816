import React from 'react'

import { ADDRESS_TYPE } from 'definitions'
import Card from 'common/Card'
import DatePicker from 'common/Forms/DatePicker'

import ClientRequester from 'components/Sections/ClientRequester'
import DeliveryInfo from 'components/Sections/DeliveryInfo'

export default ({ resource, commonResource, clientId, prTabData, prLine, disabled }) => {
  const deliveryInfo = prLine.deliveryInfo

  return (
    <Card title={resource.DeliveryInformation} fieldset>
      <DatePicker
        name="deliveryInfo.deliveryDate"
        label={resource.PreferredDate}
        defaultValue={deliveryInfo.deliveryDate}
        formGroup
        horizontal
        inputCol={{ md: 5 }}
        disabled={disabled}
      />
      {prTabData.purchaseRequest?.settings?.shouldSelectRequester === true && (
        <ClientRequester
          resource={resource}
          commonResource={commonResource}
          clientId={clientId}
          deliverTo={deliveryInfo.deliverTo}
          defaultRequester={deliveryInfo.requester}
          defaultOtherRequester={deliveryInfo.otherRequester}
          disabled={disabled}
        />
      )}
      <DeliveryInfo
        resource={resource}
        clientSites={prTabData.clientSites}
        addressType={ADDRESS_TYPE.OTHER}
        defaultClientSite={deliveryInfo.clientSite}
        defaultDeliveryAddress={deliveryInfo.deliveryAddress}
        disabled={disabled}
      />
    </Card>
  )
}
