import React from 'react'

import { Badge } from 'reactstrap'
import { components } from 'react-select'

import { useStores } from 'stores'
import ButtonLink from 'common/ButtonLink'
import Card from 'common/Card'
import FileAttachment from 'common/Forms/FileAttachment'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'

import SupplierDetail from './SupplierDetail'

export default ({ resource, commonResource, rfq, isEdit, disabled, onRequestForNewSupplier }) => {
  const { supplierStore } = useStores()
  const [supplier, setSupplier] = React.useState(rfq.supplier)

  return (
    <Card title={resource.RfqDetails} outline>
      <ReactSelect
        placeholder={resource.SearchSupplierPlaceholder}
        serverSide
        loadData={args =>
          supplierStore.fetch('', {
            filter: args.search,
            activeOnly: true,
            withSummary: false,
            isDiy: true,
          })
        }
        loadDataPropertyKey="suppliers"
        optionValueKey="id"
        optionLabelKey="name"
        formGroup
        horizontal
        components={{
          Option: ({ data, ...innerProps }) => {
            if (data.id) {
              const address = data.address || {}
              const tags = data.tags || []
              return (
                <components.Option data={data} {...innerProps}>
                  <strong className="d-block">{data.name}</strong>
                  <small className="d-block">{`${address.street} ${address.number} ${address.box}, ${
                    address.postalCode
                  } ${address.city}, ${address.country ? address.country.name : ''}`}</small>
                  {!!tags.length && (
                    <div className="mt-1">
                      {tags.map((item, index) => (
                        <Badge key={index} color="default" className="py-0 ms-1 mb-1">
                          {item}
                        </Badge>
                      ))}
                    </div>
                  )}
                </components.Option>
              )
            } else {
              return <components.Option data={data} {...innerProps} />
            }
          },
        }}
        emptySearchResultFunction={supplierName => (
          <ButtonLink type="button" onClick={() => onRequestForNewSupplier(supplierName)}>
            {resource.SupplierNotFound}
          </ButtonLink>
        )}
        isDisabled={disabled || rfq.isSent}
        {...(isEdit
          ? {
              name: 'supplier',
              rules: { required: commonResource.Required },
              defaultValue: rfq.supplier,
              label: resource.Supplier,
              onChange: selected => setSupplier(selected),
            }
          : {
              name: 'suppliers',
              rules: {
                required: commonResource.Required,
                validate: selection => !!selection.length || commonResource.Required,
              },
              defaultValue: rfq.suppliers,
              label: resource.Suppliers,
              isMulti: true,
              closeMenuOnSelect: false,
            })}
      />
      {isEdit && supplier && <SupplierDetail supplier={supplier} />}
      <FileAttachment
        name="attachments"
        defaultValue={{ attachments: rfq.attachments }}
        label={resource.Attachments}
        disabled={disabled || rfq.isSent}
        formGroup
        horizontal
      />
      <Input
        name="remarks"
        type="textarea"
        defaultValue={rfq.remarks}
        label={resource.Remarks}
        formGroup
        horizontal
        placeholder={resource.RfqRemarksPlaceholder}
        disabled={disabled || rfq.isSent}
        rows="5"
      />
    </Card>
  )
}
