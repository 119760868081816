import React from 'react'

import { PopoverBody, UncontrolledPopover } from 'reactstrap'

import { validateEmail } from 'utils/validators'
import Card from 'common/Card'
import Icon from 'common/Icon'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'

export default ({ resource, commonResource, data, disabled }) => {
  const options = React.useMemo(() => {
    return [
      { value: 'NotAcceptable', label: resource['Level.NotAcceptable'] },
      { value: 'NotGood', label: resource['Level.NotGood'] },
      { value: 'SuggestionToImprove', label: resource['Level.SuggestionToImprove'] },
      { value: 'Good', label: resource['Level.Good'] },
    ]
  }, [])

  const defaultLevel = React.useMemo(() => {
    return options.find(item => item.value === data.level)
  }, [options, data.level])

  return (
    <Card
      title={
        <div className="d-flex flex-wrap">
          <div className="col-lg-3 col-12">{data.caseNumber}</div>
          <div className="col">{data.caseDescription}</div>
        </div>
      }
      color="primary"
      outline
      className="mb-4"
      bodyClassName="col-lg-10"
    >
      <Input
        name="email"
        rules={{
          required: commonResource.Required,
          validate: value => validateEmail(value, commonResource.Email),
        }}
        defaultValue={data.email}
        label={
          <span className="d-inline-flex align-items-center">
            <span>{resource.Email}</span>
            <Icon id="tooltipEmail" icon="help" className="ms-2 md-18 text-info cursor-pointer" />
          </span>
        }
        formGroup
        horizontal
        labelCol={{ md: 4 }}
        inputCol={{ md: 8 }}
        disabled={disabled}
      />
      <UncontrolledPopover placement="right" target="tooltipEmail" trigger="hover">
        <PopoverBody className="fs-6">{resource['Email.Info']}</PopoverBody>
      </UncontrolledPopover>
      <ReactSelect
        name="level"
        rules={{ required: commonResource.Required }}
        defaultValue={defaultLevel}
        label={
          <span className="d-inline-flex align-items-center">
            <span>{resource.Level}</span>
            <Icon id="tooltipLevel" icon="help" className="ms-2 md-18 text-info cursor-pointer" />
          </span>
        }
        placeholder={resource.LevelPlaceholder}
        formGroup
        horizontal
        labelCol={{ md: 4 }}
        inputCol={{ md: 8 }}
        options={options}
        isDisabled={disabled}
      />
      <UncontrolledPopover placement="auto" target="tooltipLevel" trigger="hover" popperClassName="max-width-700">
        <PopoverBody className="fs-6">
          <div dangerouslySetInnerHTML={{ __html: resource['Level.NotAcceptable.Instruction'] }} className="mb-3" />
          <div dangerouslySetInnerHTML={{ __html: resource['Level.NotGood.Instruction'] }} className="mb-3" />
          <div
            dangerouslySetInnerHTML={{ __html: resource['Level.SuggestionToImprove.Instruction'] }}
            className="mb-3"
          />
          <div dangerouslySetInnerHTML={{ __html: resource['Level.Good.Instruction'] }} className="mb-3" />
        </PopoverBody>
      </UncontrolledPopover>
      <Input
        type="textarea"
        name="remarks"
        rules={{ required: commonResource.Required }}
        defaultValue={data.remarks}
        label={resource.Remarks}
        placeholder={resource['Remarks.Placeholder']}
        formGroup
        horizontal
        labelCol={{ md: 4 }}
        inputCol={{ md: 8 }}
        rows="5"
        disabled={disabled}
      />
    </Card>
  )
}
