import React from 'react'

import { Collapse } from 'reactstrap'

import PraViewApproverInfo from './PraViewApproverInfo'
import PraViewGeneralInfo from './PraViewGeneralInfo'
import PraViewOtherInfo from './PraViewOtherInfo'

export default ({ resource, pra, isOpen }) => {
  return (
    <Collapse isOpen={isOpen}>
      <div className="pra-view border rounded bg-white my-6 p-lg-4 p-2">
        <PraViewGeneralInfo resource={resource} pra={pra} />
        <PraViewOtherInfo resource={resource} pra={pra} />
        <PraViewApproverInfo resource={resource} pra={pra} />
      </div>
    </Collapse>
  )
}
