import React from 'react'

import { sort, unique } from 'utils/array'
import ReactSelect from 'common/Forms/ReactSelect'

import { useStateCallback } from 'hooks/useStateCallback'

// All this lookup logic is only temporary until we implement a proper search category/sub category APIs in BE
export default ({
  index,
  resource,
  settings,
  categories: categoriesProp,
  lineItem,
  defaultSubCategory,
  disabled,
  isReadOnly,
  fetchApprovers,
  onChange,
}) => {
  const [category, setCategory] = useStateCallback(lineItem.category)
  const [subCategory, setSubCategory] = useStateCallback(defaultSubCategory)
  const [categories, setCategories] = React.useState([])
  const [subCategories, setSubCategories] = React.useState([])
  const [isDisableSubCategory, setIsDisableSubCategory] = React.useState(!settings.showSubCategories)

  const originalCategories = React.useMemo(() => {
    const uniqueItems = unique(categoriesProp, 'name')
    const sortedItems = sort(uniqueItems, 'name')
    return sortedItems
  }, [categoriesProp])

  React.useEffect(() => {
    if (lineItem.category) {
      handleCategoryChange(lineItem.category)
      if (lineItem.subCategory) {
        handleSubCategoryChange(lineItem.subCategory)
      } else {
        setCategories([...originalCategories])
      }
    } else {
      handleCategoryChange()
    }
  }, [lineItem.category, lineItem.subCategory])

  const getCategories = selectedSubCategory => {
    let items
    if (selectedSubCategory) {
      items = categoriesProp.filter(item => item.subcategory === selectedSubCategory.name)
    } else {
      items = categoriesProp
    }

    const uniqueItems = unique(items, 'name')
    const sortedItems = sort(uniqueItems, 'name')
    return sortedItems
  }

  const getSubCategories = selectedCategory => {
    const items = categoriesProp
      .filter(item => (!selectedCategory || item.name === selectedCategory.name) && !!item.subcategory)
      .map((item, index) => ({ id: index + 1, name: item.subcategory }))

    const uniqueItems = unique(items, 'name')
    const sortedItems = sort(uniqueItems, 'name')
    return sortedItems
  }

  const handleCategoryChange = selected => {
    if (selected && settings.showSubCategories) {
      const newSubCategories = getSubCategories(selected)
      if (newSubCategories.length) {
        setIsDisableSubCategory(false)
      } else {
        setIsDisableSubCategory(true)
        setSubCategory(null)
      }
      setSubCategories(newSubCategories)
    } else {
      setCategories([...originalCategories])
      setSubCategories(getSubCategories())
      setIsDisableSubCategory(false)
      setSubCategory(null)
    }
    setCategory(selected, () => fetchApprovers())
  }

  const handleSubCategoryChange = selected => {
    if (selected) {
      setCategories(getCategories(selected))
    } else {
      setCategories([...originalCategories])
      setSubCategories(getSubCategories())
    }
    setSubCategory(selected, () => fetchApprovers())
  }

  const requireSubCategory = !isDisableSubCategory && !!category

  return (
    <React.Fragment>
      <ReactSelect
        name={`lines.${index}.category`}
        rules={{ required: resource.Required }}
        label={`${resource.Category}${isReadOnly ? '' : ' *'} `}
        value={category}
        defaultValue={lineItem.category}
        placeholder={resource.SelectCategory}
        options={categories}
        optionValueKey="code"
        optionLabelKey="name"
        formGroup
        horizontal
        isClearable
        isDisabled={disabled}
        readOnlyField={!settings.allowAdministratorOverride && isReadOnly}
        formClassName="col-12 mx-0"
        labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
        inputColClassName="px-lg-3 px-0"
        labelCol={{ lg: 3, xl: 5 }}
        inputCol={{ lg: 7, xl: 5 }}
        onChange={selected => {
          handleCategoryChange(selected)
          onChange(index, 'category', selected)
        }}
      />
      {settings.showSubCategories && (
        <ReactSelect
          name={`lines.${index}.subCategory`}
          rules={{ required: requireSubCategory && resource.Required }}
          value={!isDisableSubCategory ? subCategory : null}
          defaultValue={defaultSubCategory}
          label={`${resource.SubCategory}${isReadOnly || !requireSubCategory ? '' : ' *'} `}
          placeholder={resource.SelectSubCategory}
          options={subCategories}
          optionValueKey="id"
          optionLabelKey="name"
          formGroup
          horizontal
          isClearable
          isDisabled={disabled || isDisableSubCategory}
          readOnlyField={!settings.allowAdministratorOverride && isReadOnly}
          formClassName="col-12 mx-0"
          labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
          inputColClassName="px-lg-3 px-0"
          labelCol={{ lg: 3, xl: 5 }}
          inputCol={{ lg: 7, xl: 5 }}
          onChange={selected => {
            handleSubCategoryChange(selected)
            onChange(index, 'subCategory', selected)
          }}
        />
      )}
    </React.Fragment>
  )
}
