import React from 'react'

import { Badge } from 'reactstrap'
import { Link } from 'react-router-dom'

import { ROUTES } from 'definitions'
import Icon from 'common/Icon'

export default ({ resource, items, onClose }) => {
  if (items.length > 0) {
    return (
      <div className="d-flex flex-column">
        <Badge color="primary" className="d-flex rounded fs-16">
          {resource.Favorites}
          <Badge color="white" className="ms-auto rounded-pill">
            <span className="text-primary">{items.length}</span>
          </Badge>
        </Badge>
        <div className="d-flex flex-wrap px-3 py-1">
          {items.length > 0 ? (
            items.map(item => {
              const url = `${ROUTES.WEBSHOP_PRODUCT}/${item.itemId}`

              return (
                <Link
                  key={item.itemId}
                  to={url}
                  className="product-item d-flex col-12 text-link border-bottom"
                  onClick={onClose}
                >
                  {item.imageUrl ? (
                    <div className="item-image" style={{ backgroundImage: `url('${item.imageUrl}')` }}></div>
                  ) : (
                    <Icon icon="local_offer" className="image-default" />
                  )}
                  <div className="fs-12 fw-bold">{item.name}</div>
                </Link>
              )
            })
          ) : (
            <div className="fw-bold fs-12 ms-1">{resource.NoResults}</div>
          )}
        </div>
      </div>
    )
  }

  return false
}
