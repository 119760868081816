import React from 'react'

import cx from 'classnames'

import FileLink from 'common/FileLink'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({ label, value = [], noAttachment, className, ...rest }) => {
  if (value?.length > 0) {
    return (
      <ReadOnlyField
        label={label}
        value={
          <ul className="list-unstyled mb-0">
            {value.map((item, index) => (
              <li key={index}>
                <FileLink data={item} />
              </li>
            ))}
          </ul>
        }
        className={className}
        {...rest}
      />
    )
  }

  return (
    <ReadOnlyField label={label} value={noAttachment} className={cx('fst-italic text-muted', className)} {...rest} />
  )
}
