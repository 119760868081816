import React from 'react'

import cx from 'classnames'

export const TableList = ({ children }) => <div className="table-list">{children}</div>

export const TableListItem = ({ children }) => <div className="table-list-item col-lg-8 col-12">{children}</div>

export const TableListStatus = ({ children }) => (
  <div className="table-list-status col-lg-4 col-md-6 col-sm-8 col-10">
    <div className="list-group mt-lg-7 mt-0">{children}</div>
  </div>
)

export const TableListStatusItem = ({ children, className }) => (
  <div className={cx('list-group-item d-flex justify-content-between align-items-center', className)}>{children}</div>
)
