import React from 'react'

import { observer } from 'mobx-react'
import {
  // useNavigate, useLocation,
  useSearchParams,
} from 'react-router-dom'

// import { ROUTES } from 'definitions'
import { useDebounce } from 'hooks/useDebounce'
import { useStores } from 'stores'
import Input from 'common/Input'

export default observer(() => {
  const { pageResourceStore, webshopStore } = useStores()
  const debouncedHandleSearch = useDebounce(event => handleSearch(event))
  const [searchParams, setSearchParams] = useSearchParams()
  // const navigate = useNavigate()
  // const location = useLocation()

  const {
    PageResource: { webshop: resource },
  } = pageResourceStore

  const handleSearch = event => {
    // searchParams.set('subcriteria', event.target.value)
    searchParams.delete('page')

    // if (location.pathname.toLowerCase().startsWith(ROUTES.WEBSHOP_CATALOG.toLowerCase())) {
    //   navigate(ROUTES.WEBSHOP_CATALOG)
    // } else if (location.pathname.toLowerCase().startsWith(ROUTES.WEBSHOP_SEARCH.toLowerCase())) {
    //   navigate(ROUTES.WEBSHOP_SEARCH)
    // }
    setSearchParams(searchParams)
    webshopStore.SubCriteria = event.target.value
  }

  return (
    <Input
      id="textboxSubCriteria"
      placeholder={resource.SearchWithinResult}
      className="mb-4"
      onChange={debouncedHandleSearch}
    />
  )
})
