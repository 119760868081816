import React from 'react'

import { useWatch } from 'react-hook-form'

export default ({ name, resource, stock }) => {
  const watchItemHasStockStop = useWatch({ name })
  return (
    <span className="text-danger w-100">{watchItemHasStockStop && resource.ItemStock.replace('{{value}}', stock)}</span>
  )
}
