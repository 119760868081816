import React from 'react'

import { Badge } from 'reactstrap'

import { defaultLongDateFormat, formatDate } from 'utils/formatters'
import Button from 'common/Button'
import Icon from 'common/Icon'
import PdfLink from 'common/PdfLink'

import { TableListStatus, TableListStatusItem } from 'components/Case/CaseDetail/TableList'

export default ({ resource, proposeItem }) => {
  const { status, proposals = [] } = proposeItem
  const proposalPdfs = proposals.map((item, index) => ({
    fileId: item.pdfFileId,
    title: item.documentNumber,
    tooltip: `${resource.DownloadProposal} ${index + 1} ${resource.AsPDF}`,
  }))

  return (
    <TableListStatus>
      {status.bundleSent && (
        <React.Fragment>
          <TableListStatusItem>
            <div className="d-flex align-items-center">
              <Badge color="success">
                <Icon icon="done" className="md-14" />
              </Badge>
              <div className="ms-2">{resource.BundleSent}</div>
              {proposalPdfs.map(item => (
                <PdfLink
                  key={item.fileId}
                  fileId={item.fileId}
                  fileName={item.title}
                  title={item.tooltip}
                  className="ms-2"
                />
              ))}
            </div>
            <small className="text-muted">
              <em>{formatDate(status.dateBundleSent, defaultLongDateFormat)}</em>
            </small>
          </TableListStatusItem>
          <TableListStatusItem>
            {status.proposalSelected && (
              <React.Fragment>
                <div className="d-flex align-items-center">
                  <Badge color="success">
                    <Icon icon="done" className="md-14" />
                  </Badge>
                  <div className="ms-2 me-1">{resource.ProposalSelected}</div>
                </div>
                <small className="text-muted">
                  <em>{formatDate(status.dateProposalSelected, defaultLongDateFormat)}</em>
                </small>
              </React.Fragment>
            )}
            {!status.proposalSelected && (
              <React.Fragment>
                <div className="d-flex align-items-center">
                  <Badge color="danger">
                    <Icon icon="clear" className="md-14" />
                  </Badge>
                  <div className="ms-2 me-1">{resource.ProposalSelected}</div>
                </div>
                {status.bundleRevoked && (
                  <small className="text-muted">
                    <em>{formatDate(status.dateBundleRevoked)}</em>
                  </small>
                )}
              </React.Fragment>
            )}
          </TableListStatusItem>
        </React.Fragment>
      )}
      {!status.bundleSent && (
        <React.Fragment>
          <TableListStatusItem>
            <div className="d-flex align-items-center">
              <Badge color="danger">
                <Icon icon="clear" className="md-14" />
              </Badge>
              <div className="ms-2 me-1">{resource.BundleSent}</div>
            </div>
            <Button color="primary" size="sm" className="text-lowercase">
              {resource.SendNow}
            </Button>
          </TableListStatusItem>
          <TableListStatusItem>
            <div className="d-flex align-items-center">
              <Badge color="danger">
                <Icon icon="clear" className="md-14" />
              </Badge>
              <div className="ms-2 me-1">{resource.ProposalSelected}</div>
            </div>
          </TableListStatusItem>
        </React.Fragment>
      )}
    </TableListStatus>
  )
}
