import { makeObservable, observable } from 'mobx'

import BaseStore, { defaults } from 'stores/base'

export default class WebshopStore extends BaseStore {
  Config = { ...defaults.CRUD, loaded: false }
  Lookups = { ...defaults.CRUD, loaded: false }
  ItemPricing = { ...defaults.CRUD }
  ItemPricingTier = { ...defaults.CRUD }
  SidebarAttributes = { ...defaults.Table }
  SubCriteria = null
  IsFromSearch = false

  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options

    makeObservable(this, {
      Config: observable,
      Lookups: observable,
      ItemPricing: observable,
      ItemPricingTier: observable,
      SidebarAttributes: observable,
      SubCriteria: observable,
      IsFromSearch: observable,
    })

    this.fetchTable = this.fetchTable.bind(this)
  }

  async checkWebshopPermission(payload) {
    const { data, error } = await this.service.checkWebshopPermission(payload)
    const success = data && !error
    return success && data
  }

  async fetchTable({ isByPrice, page, ...payload }) {
    this.Table.error = null
    this.Table.status = null
    this.Table.loading = true
    if (isByPrice && !page) {
      this.Table.data = []
      // this.Table.totalItemCountByPrice = 0
    }

    const { data, error, status } = await this.service.fetchTable(payload)

    const success = data && !error
    if (success) {
      this.Table.data = data
    } else {
      this.Table.status = status
      this.Table.error = error
    }
    if (!isByPrice) {
      this.Table.loading = false
    }
    return success && data
  }

  fetchSidebarAttributes(payload) {
    return this.getRequest(this.SidebarAttributes, () => this.service.fetchTable(payload))
  }

  getProductDetail(payload) {
    this.CRUD.categoryPath = null
    return this.getRequest(this.CRUD, () => this.service.getProductDetail(payload))
  }

  getItemPricing(payload) {
    this.ItemPricing.data = {}
    return this.getRequest(
      this.ItemPricing,
      () => this.service.getItemPricing(payload),
      newData => {
        if (newData?.items?.length) {
          this.ItemPricing.data = newData.items.reduce((dict, item) => {
            dict[item.itemId] = {
              amount: item.amount,
              amountVatIncluded: item.amountVatIncluded,
            }
            return dict
          }, {})
        } else {
          this.ItemPricing.data = {}
        }
      }
    )
  }

  getConfiguration(payload) {
    if (!this.Config.loaded) {
      return this.getRequest(this.Config, () => this.service.getConfiguration(payload))
    }
    return {}
  }

  async searchItems(payload) {
    const { data, error } = await this.service.searchItems(payload)
    const success = data && !error
    return success && data
  }

  clearProductDetail() {
    this.clearTableData()
    this.clearItemPricing()
  }

  clearItemPricing() {
    this.ItemPricing = defaults.CRUD
  }
}
