import React from 'react'

import { Collapse } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'

import { CLIENTS, PUNCHOUT_TYPE, ROUTES } from 'definitions'
import { useAuthManager } from 'hooks/useAuthManager'
import { useStores } from 'stores'
import Card from 'common/Card'
import ContentLoader from 'common/ContentLoader'
import Toaster from 'common/Toaster'

import ProposalTable from 'components/Case/CaseList/Proposals/ProposalTable'
import Ribbon from 'common/Ribbon'

export default React.forwardRef(({ caseId, isAborted, onPunchout }, ref) => {
  const navigate = useNavigate()
  const { authStore, proposalStore, globalMessageStore, pageResourceStore } = useStores()
  const [caseProposal, setCaseProposal] = React.useState()
  const [isOpen, setIsOpen] = React.useState()
  const [loading, setLoading] = React.useState()
  const authManager = useAuthManager()

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { case: resource = {} },
  } = pageResourceStore
  const bundles = caseProposal?.bundles || []

  React.useImperativeHandle(ref, () => ({
    toggle: () => {
      if (!isOpen) {
        getProposal()
      } else {
        setIsOpen(false)
      }
    },
  }))

  const getProposal = async () => {
    setLoading(true)
    const result = await proposalStore.fetchList(caseId)
    setLoading(false)
    if (result) {
      const theBundles = result.caseProposal.bundles || []
      setIsOpen(!!theBundles.length)
      setCaseProposal(result.caseProposal)
    }
  }

  const handlePickProposal = async proposalId => {
    Toaster.confirm({
      title: resource.PickProposal,
      message: resource.SelectProposalConfirmation,
      submitText: resource.Confirm,
      cancelText: resource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const { data } = await proposalStore.pickProposal({ caseId, proposalId })
        if (data) {
          if (session.hasPunchoutSession) {
            if (session.clientAbbreviation === CLIENTS.TLG) {
              completeTlgProposal(proposalId, closeModal)
            } else {
              completePunchoutProposal(proposalId, closeModal)
            }
          } else {
            closeModal()
            completePickProposal(data)
          }
        } else {
          closeModal()
        }
      },
    })
  }

  const completePickProposal = data => {
    setCaseProposal({
      ...caseProposal,
      bundles: data.updatedCase.bundles,
    })

    if (session.punchoutType === PUNCHOUT_TYPE.NonErp) {
      if (data.hasPra) {
        navigate(`${ROUTES.PRA}?key=${data.praKey}`)
      } else {
        globalMessageStore.show({
          title: resource.ProposalSelected,
          message: `${resource.ProposalSelectedSuccessfully} ${data.pickProposalMessage}`,
          color: 'success',
        })
      }
    }
  }

  const completePunchoutProposal = async (proposalId, closeModal) => {
    const { data } = await proposalStore.getPunchoutRequest({
      proposalId,
      punchoutType: session.punchoutType,
    })
    if (data) {
      const result = await authManager.logoutAfterPunchout()
      if (result) {
        onPunchout({ punchoutType: session.punchoutType, data })
      }
    } else {
      closeModal()
    }
  }

  const completeTlgProposal = async (proposalId, closeModal) => {
    const { data } = await proposalStore.sendPunchoutRequest({ proposalId })
    closeModal()
    if (data) {
      globalMessageStore.show({
        title: resource.ProposalSelected,
        message: resource.ProposalSelectedSuccessfully,
        color: 'success',
      })
    }
  }

  return (
    <React.Fragment>
      <ContentLoader show={loading} style={{ top: 0 }} />
      <Collapse isOpen={isOpen} className="collapse-section mt-2">
        {bundles.map((bundle, index) => (
          <Card
            key={index}
            title={
              <div className="d-flex align-items-center mb-0 p-1">
                <span
                  className={cx((bundle.bundleRevoked || bundle.proposalSelected || bundle.bundleRejected) && 'px-10')}
                >
                  {resource.Bundle} {bundle.bundleIndex}
                </span>
                {bundle.bundleRevoked && <Ribbon color="danger">{resource.Revoked}</Ribbon>}
                {bundle.bundleRejected && <Ribbon color="danger">{resource.Rejected}</Ribbon>}
                {bundle.proposalSelected && <Ribbon color="success">{resource.Selected}</Ribbon>}
              </div>
            }
            className={cx('border-0 mb-5', {
              'proposal-bundle-unavailable': bundle.bundleRevoked || bundle.proposalSelected,
            })}
            titleClassName="border border-bottom-0 position-relative"
            bodyClassName="p-0 white-space-nowrap table-responsive"
          >
            <ProposalTable
              resource={resource}
              session={session}
              caseProposal={caseProposal}
              bundle={bundle}
              isAborted={isAborted}
              onPickProposal={handlePickProposal}
            />
          </Card>
        ))}
      </Collapse>
    </React.Fragment>
  )
})
