import React from 'react'

import { observer } from 'mobx-react'

import { useDebounce } from 'hooks/useDebounce'
import { useStores } from 'stores'
import Button from 'common/Button'
import Icon from 'common/Icon'
import Input from 'common/Input'
import Loader from 'common/Loader'
import MessageList from 'common/MessageList'

export default observer(() => {
  const { accountClientStore, pageResourceStore } = useStores()
  const [clientId, setClientId] = React.useState()
  const searchRef = React.useRef()
  const pageRef = React.useRef(0)
  const [data, setData] = React.useState([])

  const {
    CRUD: { submitting, error },
    Table: {
      data: { allClientsCount = 0 },
      loading,
    },
  } = accountClientStore
  const {
    PageResource: { account: resource = {} },
  } = pageResourceStore

  const triggerChange = () => {
    handleSearchChange()
  }

  const handleSearchChange = search => {
    searchRef.current = search
    handleLoadData()
  }

  const handleLoadData = async page => {
    const result = await accountClientStore.fetchTable({ search: searchRef.current, page: page || 0 })

    if (result) {
      if (page > 0) {
        setData(prevData => [...prevData, ...result.clients])
      } else {
        pageRef.current = 0
        setData([...result.clients])
      }
    } else {
      setData([])
    }
  }

  const debounceLoadMoreData = useDebounce(() => {
    pageRef.current += 1
    handleLoadData(pageRef.current)
  })

  const handleSubmit = async clientId => {
    setClientId(clientId)
    const result = await accountClientStore.selectClient(clientId)
    if (result) {
      window.location.reload()
    } else {
      setClientId(null)
    }
  }

  React.useEffect(() => {
    triggerChange()

    const modalEl = document.getElementById('select-company-modal').parentElement

    const checkScroll = () => {
      // Check if there's more clients to load when scrolling more than 70%
      if (accountClientStore.Table.data.hasMorePages) {
        if (modalEl.scrollTop + modalEl.clientHeight >= Math.ceil(0.95 * modalEl.scrollHeight)) {
          debounceLoadMoreData()
        }
      }
    }

    modalEl.addEventListener('scroll', checkScroll, false)
    return () => {
      modalEl.removeEventListener('scroll', checkScroll, false)
    }
  }, [accountClientStore])

  return (
    <React.Fragment>
      <MessageList messages={error && [error]} />
      <Loader show={loading} />
      {!loading && (
        <React.Fragment>
          {allClientsCount > 12 && (
            <Input
              value={searchRef.current}
              startAdornment={<Icon icon="search" />}
              placeholder={resource.SelectCompanySearch}
              onChange={e => handleSearchChange(e.target.value)}
              autoFocus
            />
          )}
          <div className="d-flex flex-wrap clients">
            {data.length > 0 &&
              data.map((item, index) => (
                <Button
                  key={index}
                  type="button"
                  color="default"
                  text
                  disabled={submitting}
                  loading={clientId === item.id && submitting}
                  loadingSize="xl"
                  className="col-xl-3 col-lg-4 col-sm-6 col-xs-12 d-inline-flex flex-column mb-2"
                  onClick={() => handleSubmit(item.id)}
                >
                  <div className="image-wrapper border d-flex align-items-center col">
                    <img src={`${process.env.REACT_APP_API_URL}/file/${item.logoFileId}`} alt={item.name} />
                  </div>
                  <div className="name">{item.name}</div>
                  <small className="vat-number">{item.vatNumber}</small>
                </Button>
              ))}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
})
