import React from 'react'

import { observer } from 'mobx-react'
import { Spinner } from 'reactstrap'
import { useWatch } from 'react-hook-form'

import { formatCurrency } from 'utils/formatters'
import { useStores } from 'stores'

export default observer(({ resource }) => {
  const { webshopPricingStore } = useStores()
  const pricing = useWatch({ name: 'pricing' })

  const {
    ItemPricingTier: { loading },
  } = webshopPricingStore

  return (
    <div className="d-flex align-items-baseline mb-3">
      {loading && <Spinner color="secondary" type="grow" size="sm" className="m-3" />}
      {!loading && !!pricing && (
        <React.Fragment>
          <div className="d-flex align-items-baseline">
            <div className="fs-24 fw-bolder webshop-title-color">
              {pricing.amount ? formatCurrency(pricing.amount) : ''}
            </div>
            <div className="ms-1 fs-11 fw-bold">{resource.ExclVAT}</div>
          </div>
          <div className="ms-auto fs-11">
            {!!pricing.amountVatIncluded && `${formatCurrency(pricing.amountVatIncluded)} ${resource.InclVAT}`}
          </div>
        </React.Fragment>
      )}
    </div>
  )
})
