import React from 'react'

import { formatDate } from 'utils/formatters'
import { padLeft } from 'utils'
import Card from 'common/Card'
import Checkbox from 'common/Checkbox'
import CheckboxForm from 'common/Forms/Checkbox'
import Input from 'common/Forms/Input'
import ReactTable from 'common/ReactTable'

export default ({ resource, rfq, disabled }) => {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'selected',
        width: '40',
        Header: ({ isAllSelected, toggleAllRows }) => {
          return (
            <Checkbox
              type="checkbox"
              checked={isAllSelected}
              onChange={() => toggleAllRows()}
              disabled={disabled || rfq.isSent}
            />
          )
        },
        Cell: ({ index, value, toggleRow }) => {
          return (
            <CheckboxForm
              type="checkbox"
              name={`lines.${index}.selected`}
              checked={value}
              onChange={() => toggleRow()}
              disabled={disabled || rfq.isSent}
            />
          )
        },
      },
      {
        Header: '#',
        accessor: 'lineIndex',
        headerClassName: 'col-1 text-muted',
        className: 'col-1 text-muted',
        Cell: ({ value }) => padLeft(value, 1),
      },
      {
        Header: resource.Description,
        accessor: 'extendedDescription',
        headerClassName: 'col',
        className: 'col',
        Cell: ({ index, row, value }) => (
          <div>
            <Input name={`lines.${index}.lineIndex`} type="hidden" defaultValue={row.lineIndex} />
            <div className="mb-3">{row.description}</div>
            <Input
              name={`lines.${index}.remarks`}
              type="textarea"
              defaultValue={value}
              className="mb-2"
              disabled={disabled || !row.selected || rfq.isSent}
            />
          </div>
        ),
      },
      {
        Header: resource.Delivery,
        accessor: 'deliveryInfo.deliveryDate',
        headerClassName: 'col-2 text-end',
        className: 'col-2 text-end',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: resource.Qty,
        accessor: 'quantity.amount',
        headerClassName: 'col-1 text-end',
        className: 'col-1 text-end',
      },
      {
        Header: resource.Unit,
        accessor: 'quantity.unit',
        headerClassName: 'col-1 text-end',
        className: 'col-1 text-end',
      },
    ],

    []
  )

  return (
    <Card title={resource.PrLines} color="primary" outline className="mb-4">
      <ReactTable columns={columns} data={rfq.lines} enableSelection responsive />
    </Card>
  )
}
