import BaseService from 'services/base'

export default class WebformService extends BaseService {
  constructor(api) {
    const url = 'clientarea/webform'
    super(api, url)
    this.api = api
    this.url = url
  }

  getUserRequester(addUserAsRequesterIfNotExist = false) {
    return this.api.get('client/getuserrequester', { addUserAsRequesterIfNotExist })
  }

  getCopyFromCase(caseId) {
    return this.api.get(`${this.url}/copy/${caseId}`)
  }
}
