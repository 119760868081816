import React from 'react'

import { Alert } from 'reactstrap'
import { useFormContext } from 'react-hook-form'

export default ({ approver, lineIndex }) => {
  const { getValues, watch } = useFormContext()

  const currentApproverEditWatch = watch('currentApproverEdit')
  let comment = approver.comment

  if (currentApproverEditWatch && currentApproverEditWatch.lineIndex === lineIndex) {
    comment = currentApproverEditWatch.comment
  } else if (approver.status === 0 && approver.question && approver.question.pendingQuestion) {
    const answers = getValues('answers') || []
    const answer = answers.find(item => item.lineIndex === approver.lineIndex)
    if (answer) {
      comment = answer.comment
    }
  }

  if (comment) {
    return (
      <Alert color="light" className="mt-1 py-1 px-1 mb-0 col-12">
        {comment}
      </Alert>
    )
  }

  return false
}
