import React from 'react'

import { Alert, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { formatCurrency } from 'utils/formatters'
import { ROUTES, SERVICE_CONTRACT } from 'definitions'
import Card from 'common/Card'

import ButtonFavourite from 'components/Webshop/WebshopFavourites/ButtonFavourite'
import sbcThumnail from 'assets/images/spot_buy_center_thumbnail.png'

export default ({ resource, itemPricingLookup, itemPricingLoading, item, isFavourite }) => {
  const imageUrl = item.largeImageUrl ? item.largeImageUrl : '/assets/images/no-image.png'
  const isClientCatalog = item.serviceContract === SERVICE_CONTRACT.CLIENT_CATALOG

  const itemPrice = React.useMemo(() => {
    return itemPricingLookup[item.itemId] || {}
  }, [item, itemPricingLookup])

  const hasAccess = !!item.name

  return (
    <Link
      to={`${ROUTES.WEBSHOP_PRODUCT}/${item.itemId}`}
      state={{
        previousPathname: window.location.pathname,
        previousSearch: window.location.search,
      }}
      className={cx(
        'product-item-link text-decoration-none webshop-text-color',
        !hasAccess && 'pe-none',
        isFavourite ? 'col-xxl-3 col-lg-4 col-md-6 col-12' : 'col-lg-4 col-md-6 col-12'
      )}
    >
      <Card
        title={
          <React.Fragment>
            {!hasAccess && (
              <Alert color="primary" className="mb-0 p-3 fs-14 rounded-0 rounded-top">
                {resource.ProductAccessDeniedByUserGroup}
              </Alert>
            )}
            {hasAccess && (
              <div className="fw-bold fs-14 px-xxl-5 py-xxl-4 px-3 py-2">
                {itemPricingLoading && <Spinner color="secondary" type="grow" size="sm" />}
                {!itemPricingLoading && !!itemPrice.amount && (
                  <React.Fragment>
                    <div className="mb-1 d-flex flex-wrap align-items-baseline">
                      <span className="fw-bolder fs-20 webshop-title-color">
                        {itemPrice.amount ? formatCurrency(itemPrice.amount) : ''}
                      </span>
                      <span className="ms-1 fs-11 fw-bold"> {resource.ExclVAT}</span>
                    </div>
                    {!!item.name && (
                      <div className="fs-11">
                        <span>{itemPrice.amountVatIncluded ? formatCurrency(itemPrice.amountVatIncluded) : ''}</span>
                        <span className="ms-1">{resource.InclVAT}</span>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            )}
          </React.Fragment>
        }
        footer={
          hasAccess && (
            <React.Fragment>
              <div className="fw-bold fs-16 mb-1 webshop-title-color">{item.name}</div>
              <div className="fs-12">{isClientCatalog ? item.supplierName : resource.SpotBuyCenter}</div>
            </React.Fragment>
          )
        }
        className="d-block h-100 paper paper-1 border-0 p-0"
        titleClassName="webshop-text-color bg-white p-0 border-0"
        bodyClassName={cx('position-relative p-0', hasAccess && 'border-top')}
        footerClassName="px-xxl-5 py-xxl-4 px-3 py-2"
      >
        <ButtonFavourite resource={resource} itemId={item.itemId} favouriteId={item.favouriteId} className="pe-auto" />
        {hasAccess && (
          <React.Fragment>
            {!isClientCatalog && (
              <img className="logo service-contract" src={sbcThumnail} alt={SERVICE_CONTRACT.SPOT_BUY_CENTER_CATALOG} />
            )}
            <div
              className={cx('product-image', item.largeImageUrl && 'product-large-image')}
              style={{ backgroundImage: `url("${imageUrl}")` }}
            />
          </React.Fragment>
        )}
      </Card>
    </Link>
  )
}
