import React from 'react'

import 'react-day-picker/lib/style.css'
import cx from 'classnames'
import DayPicker from 'react-day-picker/DayPicker'

import DayPickerHeader from 'common/DateParts/DayPickerHeader'

export default ({
  selectedDays: selectedDaysProp,
  className,
  onChange,
  years,
  todayButton = 'Today',
  highlightedDate,
  ...props
}) => {
  const [monthDisplay, setMonthDisplay] = React.useState()

  const selectedDays = React.useMemo(() => selectedDaysProp || new Date(), [selectedDaysProp])

  const date = React.useMemo(() => {
    if (selectedDays) {
      if (Array.isArray(selectedDays)) {
        if (selectedDays.length) {
          return selectedDays[0]
        }
        return new Date()
      }
    }
    return selectedDays
  }, [selectedDays])

  React.useEffect(() => setMonthDisplay(date), [date])

  const handleChangeDate = newValue => setMonthDisplay(newValue)

  const handleDayClick = (newValue, modifier) => {
    if (!modifier?.disabled) {
      onChange && onChange(newValue)
    }
  }

  const handleTodayClick = day => handleDayClick(day)

  return (
    <div>
      <DayPicker
        selectedDays={selectedDays || new Date()}
        todayButton={todayButton}
        showOutsideDays
        captionElement={headerProps => <DayPickerHeader {...headerProps} years={years} onChange={handleChangeDate} />}
        month={monthDisplay}
        className={cx(className)}
        onDayClick={handleDayClick}
        onTodayButtonClick={handleTodayClick}
        modifiers={{ ...(selectedDaysProp ? {} : { highlighted: highlightedDate }) }}
        {...props}
      />
    </div>
  )
}
