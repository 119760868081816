import React from 'react'

import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap'
import cx from 'classnames'

export default ({
  children,
  title,
  actions,
  footer,
  className,
  titleClassName,
  bodyClassName,
  footerClassName,
  actionClassName,
  outline,
  fieldset,
  color,
  titleLeft,
  titleCenter,
  ...props
}) => {
  const TitleTag = fieldset ? 'h4' : 'h5'

  return (
    <Card
      className={cx(className, outline && 'card-outline', fieldset && 'fieldset')}
      outline={outline}
      color={color}
      {...props}
    >
      {title && (
        <CardHeader className={cx(titleClassName, !fieldset && color && `alert alert-${color} px-4 py-2`)}>
          <TitleTag className="card-header-title">{title}</TitleTag>
          {actions && <div className={cx('card-header-actions', actionClassName)}>{actions}</div>}
        </CardHeader>
      )}
      <CardBody className={cx(bodyClassName)}>{children}</CardBody>
      {footer && <CardFooter className={cx(footerClassName)}>{footer}</CardFooter>}
    </Card>
  )
}
