import React from 'react'

import cx from 'classnames'

import { useDebounce } from 'hooks/useDebounce'
import { useStores } from 'stores'
import ButtonIcon from 'common/ButtonIcon'

export default ({ resource, itemId, favouriteId: favouriteIdProp, className }) => {
  const { authStore, webshopFavouriteStore } = useStores()
  const [favouriteId, setFavouriteId] = React.useState()
  const debounceToggle = useDebounce(() => toggle())

  const {
    AppSession: { session },
  } = authStore
  const {
    CRUD: { submitting },
  } = webshopFavouriteStore
  const isFavourite = !!favouriteId

  React.useEffect(() => setFavouriteId(favouriteIdProp), [favouriteIdProp])

  const toggle = async () => {
    if (!favouriteId) {
      const result = await webshopFavouriteStore.post(null, { itemId })
      if (result?.success) {
        setFavouriteId(result.favouriteId)
        loadHeaderFavourites()
      }
    } else if (favouriteId) {
      const result = await webshopFavouriteStore.delete(favouriteId)
      if (result?.success) {
        setFavouriteId(null)
        loadHeaderFavourites()
      }
    }
  }

  const loadHeaderFavourites = () => {
    webshopFavouriteStore.fetchHeaderFavourites({
      topRows: 5,
      languageCode: session.uiLanguageSetting.toLowerCase(),
    })
  }

  return (
    <ButtonIcon
      color="gold"
      icon={isFavourite ? 'favorite' : 'favorite_border'}
      title={`${isFavourite ? resource.RemoveFavorites : resource.AddFavorites}`}
      className={cx('p-1 logo favourite cursor-pointer', className)}
      iconClassName={cx('md-28', isFavourite && 'text-gold')}
      disabled={submitting}
      onClick={event => {
        event.stopPropagation()
        event.preventDefault()
        debounceToggle()
      }}
    />
  )
}
