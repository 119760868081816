import React from 'react'

import { observer } from 'mobx-react'
import { useStores } from 'stores'
import CompanyList from 'components/Core/SelectCompanyModal/CompanyList'
import Modal from 'common/Modal'

export default observer(({ isOpen, toggle, hideClose }) => {
  const { accountClientStore, pageResourceStore } = useStores()

  const {
    PageResource: { account: resource = {} },
  } = pageResourceStore

  const {
    CRUD: { submitting },
  } = accountClientStore

  return (
    <Modal
      id="select-company-modal"
      isOpen={isOpen}
      title={resource.SelectCompanyMessage}
      size="xl"
      hideClose={hideClose}
      disableClose={submitting}
      toggle={toggle}
    >
      {isOpen && <CompanyList />}
    </Modal>
  )
})
