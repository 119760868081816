import React from 'react'

import { DELIVER_TO } from 'definitions'
import { useStateCallback } from 'hooks/useStateCallback'
import Input from 'common/Forms/Input'
import RadioGroup from 'common/Forms/RadioGroup'
import ReactSelect from 'common/Forms/ReactSelect'

export default ({ resource, clientRequesterStore, clientId, deliverTo: deliveryToProp = {}, disabled, updateCart }) => {
  const [deliverTo, setDeliverTo] = useStateCallback(deliveryToProp?.type || DELIVER_TO.EMPLOYEE)

  const isEmployee = deliverTo === DELIVER_TO.EMPLOYEE
  const isOther = deliverTo === DELIVER_TO.OTHER

  const handleChangeDeliverTo = selected => {
    setDeliverTo(selected, () => updateCart())
  }

  return (
    <React.Fragment>
      <RadioGroup
        rules={{ required: resource.Required }}
        radios={[
          {
            value: DELIVER_TO.EMPLOYEE,
            label: resource.CompanyEmployee,
            children: isEmployee && (
              <ReactSelect
                name="deliveryInfo.deliverTo.clientRequester"
                rules={{ required: isEmployee && resource.Required }}
                defaultValue={deliveryToProp.clientRequester}
                isDisabled={disabled}
                serverSide
                loadData={args =>
                  clientRequesterStore.fetch(`${clientId}/requester`, {
                    displayName: args.search,
                    addUserAsRequesterIfNotExist: false,
                  })
                }
                loadDataPropertyKey="requesters"
                optionValueKey="key"
                optionLabelKey="value"
                onChange={() => updateCart()}
              />            
            ),
          },
          {
            value: DELIVER_TO.OTHER,
            label: resource.OtherPerson,
          },
        ]}
        name="deliveryInfo.deliverTo.type"
        label={<h5 className="webshop-title-color fw-bolder mb-0">{resource.DeliverTo}</h5>}
        formGroup
        disabled={disabled}
        onChange={handleChangeDeliverTo}
      />
      {isOther && (
        <Input
          name="deliveryInfo.deliverTo.personName"
          rules={{ required: isOther && resource.Required }}
          disabled={disabled}
          onChange={() => updateCart()}
        />
      )}
    </React.Fragment>
  )
}
