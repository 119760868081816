import React from 'react'

import { sort, sortByBoolean, unique } from 'utils/array'
import ReactSelect from 'common/Forms/ReactSelect'
import ReactSelectCategory from './ReactSelectCategory'

// All this lookup logic is only temporary until we implement a proper search category/sub category APIs in BE
export default ({ index, resource, showSubCategories, categories: categoriesProp, field, disabled, updateCart }) => {
  const [category, setCategory] = React.useState(field.category)
  const [subCategory, setSubCategory] = React.useState(field.subCategory)
  const [categories, setCategories] = React.useState([])
  const [subCategories, setSubCategories] = React.useState([])
  const [isDisableSubCategory, setIsDisableSubCategory] = React.useState(showSubCategories)

  const applySuggestions = options => {
    const suggested = options.map(item => ({
      ...item,
      suggested: field.suggestedCategories.includes(item.code),
    }))
    return sortByBoolean(suggested, 'suggested')
  }

  const originalCategories = React.useMemo(() => {
    if (categoriesProp) {
      const uniqueItems = unique(categoriesProp, 'name')

      if (field.suggestedCategories?.length) {
        return applySuggestions(uniqueItems)
      } else {
        return uniqueItems
      }
    }
    return []
  }, [field.suggestedCategories, categoriesProp])

  React.useEffect(() => {
    if (originalCategories?.length) {
      handleCategoryChange(field.category)
      handleSubCategoryChange(field.subCategory)
    }
  }, [originalCategories, field.category, field.subCategory])

  const getCategories = selectedSubCategory => {
    let items
    if (selectedSubCategory) {
      items = categoriesProp.filter(item => item.subcategory === selectedSubCategory.name)
    } else {
      items = categoriesProp
    }

    const uniqueItems = unique(items, 'name')
    if (field.suggestedCategories?.length) {
      return applySuggestions(uniqueItems)
    } else {
      return uniqueItems
    }
  }

  const getSubCategories = selectedCategory => {
    const items = categoriesProp
      .filter(item => (!selectedCategory || item.name === selectedCategory.name) && !!item.subcategory)
      .map((item, index) => ({ id: index + 1, name: item.subcategory }))

    const uniqueItems = unique(items, 'name')
    const sortedItems = sort(uniqueItems, 'name')
    return sortedItems
  }

  const handleCategoryChange = selected => {
    if (selected) {
      const newSubCategories = getSubCategories(selected)
      if (newSubCategories.length) {
        setIsDisableSubCategory(false)
      } else {
        setIsDisableSubCategory(true)
        setSubCategory(null)
      }
      setSubCategories(newSubCategories)
    } else {
      setCategories([...originalCategories])
      setSubCategories(getSubCategories())
      setIsDisableSubCategory(false)
      setSubCategory(null)
    }
    setCategory(selected)
  }

  const handleSubCategoryChange = selected => {
    if (selected) {
      setCategories(getCategories(selected))
    } else {
      setCategories([...originalCategories])
      setSubCategories(getSubCategories())
    }
    setSubCategory(selected)
  }

  const requireSubCategory = !isDisableSubCategory && !!category

  return (
    <React.Fragment>
      <ReactSelectCategory
        resource={resource}
        hasSuggestedCategories={!!field.suggestedCategories?.length}
        name={`items.${index}.category`}
        rules={{ required: !category && resource.Required }}
        value={category}
        label={resource.Category}
        placeholder={resource.SelectCategory}
        options={categories}
        optionValueKey="code"
        optionLabelKey="name"
        isClearable
        isDisabled={disabled}
        formGroup
        formClassName="w-100"
        labelClassName="d-xl-none mb-1"
        onChange={selected => {
          handleCategoryChange(selected)
          updateCart()
        }}
      />
      {showSubCategories && (
        <ReactSelect
          name={`items.${index}.subCategory`}
          rules={{ required: requireSubCategory && resource.Required }}
          value={!isDisableSubCategory ? subCategory : null}
          label={resource.SubCategory}
          placeholder={resource.SelectSubCategory}
          options={subCategories}
          optionValueKey="id"
          optionLabelKey="name"
          isClearable
          isDisabled={disabled || isDisableSubCategory}
          formGroup
          formClassName="w-100"
          labelClassName="d-xl-none mb-1"
          onChange={selected => {
            handleSubCategoryChange(selected)
            updateCart()
          }}
        />
      )}
    </React.Fragment>
  )
}
