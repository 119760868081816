import React from 'react'

import { Collapse } from 'reactstrap'
import cx from 'classnames'

import ButtonIcon from './ButtonIcon'
import Card from 'common/Card'

export default ({ defaultIsOpen, actions, collapseClassName, className, children, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(defaultIsOpen)

  const toggle = () => setIsOpen(prev => !prev)

  return (
    <Card
      {...props}
      actions={
        <React.Fragment>
          {actions}
          <ButtonIcon icon={isOpen ? 'expand_less' : 'expand_more'} className="p-1" onClick={toggle} />
        </React.Fragment>
      }
      className={cx('card-collapse', className)}
    >
      <Collapse isOpen={isOpen} className={cx(collapseClassName)}>
        {children}
      </Collapse>
    </Card>
  )
}
