import BaseService from 'services/base'

export default class WebshopPricingService extends BaseService {
  constructor(api) {
    const url = 'webshop/item/pricing'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchPricingTiers(payload) {
    return this.post('tier', payload)
  }
}
