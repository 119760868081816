import React from 'react'

import { Col, FormGroup, Label } from 'reactstrap'
import cx from 'classnames'

export default ({
  children,
  label,
  formGroup,
  horizontal,
  check,
  className,
  labelClassName,
  inputColClassName,
  labelCol = { md: 3 },
  inputCol = { md: 9 },
  hideLabel,
  title,
  hasError,
}) => {
  if (!formGroup) return children

  const InputCol = horizontal ? Col : React.Fragment

  return (
    <FormGroup row={horizontal} check={check} title={title} className={cx('form-group', className)}>
      {label && !hideLabel && (
        <Label {...(horizontal ? labelCol : {})} className={cx(labelClassName, hasError && 'text-danger')}>
          {label}
        </Label>
      )}
      <InputCol {...(horizontal ? { ...inputCol, className: cx(inputColClassName) } : {})}>{children}</InputCol>
    </FormGroup>
  )
}
