import React from 'react'

import { formatCurrency } from 'utils/formatters'
import { padLeft } from 'utils'
import Button from 'common/Button'
import Card from 'common/Card'
import ReactTable from 'common/ReactTable'
import Ribbon from 'common/Ribbon'

import { TableListItem } from 'components/Case/CaseDetail/TableList'

export default ({ resource, orderItem, onViewOrder }) => {
  const { order, supplier, totals } = orderItem

  const columns = React.useMemo(
    () => [
      {
        Header: 'PO #',
        accessor: 'poIndex',
        headerClassName: 'col-1 text-muted',
        className: 'col-1',
        Cell: ({ value, row }) =>
          value && (
            <span>
              {padLeft(value, 1)} {padLeft(row.poLineIndex, 1)}
            </span>
          ),
      },
      {
        Header: resource.Description,
        accessor: 'description',
        headerClassName: 'col',
        className: 'col',
        footerClassName: 'col text-end fw-bold',
        Cell: ({ value, row }) =>
          row.isCreditNotes ? (
            <em className="text-muted">{resource.CreditNotes}</em>
          ) : (
            <span title={value} className="word-break-break-word white-space-normal">
              {value}
            </span>
          ),
        Footer: () => `${resource.Total}:`,
      },
      {
        Header: resource.PoAmount,
        accessor: 'poLinePrice.value',
        headerClassName: 'col-2 text-end',
        className: 'col-2 border-left text-end border-start',
        footerClassName: 'col-2 border-left text-end fw-bold border-start',
        Cell: ({ value }) => <span>{value ? formatCurrency(value) : '-'}</span>,
        Footer: () => formatCurrency(totals.poTotal.value),
      },
      {
        Header: resource.OrderAmount,
        accessor: 'orderLinePrice.value',
        headerClassName: 'col-2 text-end',
        className: 'col-2 border-left text-end border-start',
        footerClassName: 'col-2 border-left text-end fw-bold border-start',
        Cell: ({ value }) => <span>{value ? formatCurrency(value) : '-'}</span>,
        Footer: () => formatCurrency(totals.orderTotal.value),
      },
      {
        Header: resource.PiAmount,
        accessor: 'piLinesTotal.value',
        headerClassName: 'col-2 text-end',
        className: 'col-2 border-left text-end border-start',
        footerClassName: 'col-2 border-left text-end fw-bold card-cap-bg border-start',
        Cell: ({ value, row }) =>
          row.isCreditNotes ? (
            <span>{totals.pcnTotal ? formatCurrency(totals.pcnTotal.value) : '-'}</span>
          ) : (
            <span>{value ? formatCurrency(value) : '-'}</span>
          ),
        Footer: () => (
          <span className="text-end fw-bold ">{totals.piTotal ? formatCurrency(totals.piTotal.value) : '-'}</span>
        ),
      },
    ],

    []
  )

  return (
    <TableListItem>
      <Card
        outline
        title={
          <div className="mb-lg-0 mb-1 white-space-break-spaces text-lg-start text-center">
            <strong>{`${resource.Order} ${padLeft(order.documentIndex, 1)}`}</strong> - {resource.Supplier}:{' '}
            {supplier.name}
          </div>
        }
        className="position-relative"
        titleClassName="white-space-nowrap flex-lg-row flex-column"
        actions={
          <Button
            color={order.isDummyOrder ? 'warning' : 'primary'}
            text
            size="sm"
            icon="visibility"
            onClick={() => onViewOrder({ order, supplier })}
          >
            {order.isDummyOrder && resource.ViewSimulatedOrder}
            {order.isRequestForPI && resource.ViewInvoiceRequest}
            {!order.isDummyOrder && !order.isRequestForPI && resource.ViewOrder}
          </Button>
        }
      >
        {!!order.cancelReason && (
          <Ribbon color="danger" right={false}>
            {resource.Cancelled}
          </Ribbon>
        )}
        <ReactTable columns={columns} data={order.lines} showFooter responsive />
      </Card>
    </TableListItem>
  )
}
