import BaseService from 'services/base'

export default class ClientService extends BaseService {
  constructor(api) {
    const url = 'client'
    super(api, url)
    this.api = api
    this.url = url
  }
}
