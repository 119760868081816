import React from 'react'

export default () => {
  return (
    <div id="404">
      <h3>Page not found</h3>
      <p>The page you requested could not be found.</p>
    </div>
  )
}
