import React from 'react'

import { components } from 'react-select'

import { isSsoSaml } from 'utils/auth'
import { LOGIN_PROVIDER } from 'definitions'
import { useStores } from 'stores'
import { validateEmail } from 'utils/validators'
import Input from 'common/Forms/Input'
import NormalInput from 'common/Input'
import ReactSelect from 'common/Forms/ReactSelect'

export default ({ resource, client, session, punchoutSession, disabled }) => {
  const { clientAreaStore } = useStores()

  const sites = React.useMemo(() => {
    return (
      client.sites?.map(item => {
        const address = item.address || {}
        return {
          value: item.clientSiteId,
          label: item.name,
          address: `${address.street} ${address.number} ${address.box} ${address.postalCode} ${address.city} ${address.country?.name}`,
        }
      }) || []
    )
  }, [client])

  const defaultSite = React.useMemo(() => {
    if (punchoutSession.siteId) {
      return sites.find(item => item.value === punchoutSession.siteId)
    }
    return null
  }, [sites, punchoutSession])

  const isSsoSamlLogin = isSsoSaml(session.loginProvider)

  return (
    <div className="py-2 px-5">
      <Input
        name="email"
        rules={{
          required: resource.Required,
          validate: value => validateEmail(value, resource.Email),
        }}
        defaultValue={punchoutSession.email}
        label={`${resource.EmailAddress} *`}
        placeholder={resource.EmailAddressSample}
        formGroup
        formClassName="mb-2"
        readOnly={isSsoSamlLogin || disabled || punchoutSession.disableUnknownUserPopupEmail}
      />
      <Input
        name="firstName"
        rules={{ required: resource.Required }}
        label={`${resource.FirstName} *`}
        defaultValue={punchoutSession.firstName}
        formGroup
        formClassName="mb-2"
        readOnly={isSsoSamlLogin || disabled}
      />
      <Input
        name="lastName"
        rules={{ required: resource.Required }}
        defaultValue={punchoutSession.lastName}
        label={`${resource.LastName} *`}
        formGroup
        formClassName="mb-2"
        readOnly={isSsoSamlLogin || disabled}
      />
      <ReactSelect
        name="site"
        rules={{ required: resource.Required }}
        label={`${resource.Site} *`}
        placeholder={resource.ChooseACompanySite}
        defaultValue={defaultSite}
        options={sites}
        serverSide
        loadData={args =>
          clientAreaStore.getClientSites({
            search: args.search,
            size: 50,
          })
        }
        defaultOptions
        optionValueKey="clientSiteId"
        optionLabelKey="name"
        formGroup
        formClassName="mb-2"
        isDisabled={disabled}
        components={{
          Option: ({ data, ...innerProps }) => {
            const address = data.address
            return (
              <components.Option data={data} {...innerProps}>
                <strong className="d-block">{data.name}</strong>
                <small className="d-block muted address-mini">{`${address.street} ${address.number} ${
                  address.box
                }, ${address.postalCode} ${address.city}, ${address.country ? address.country.name : ''}`}</small>
              </components.Option>
            )
          },
        }}
      />
      <Input
        name="telephone"
        label={resource.PhoneNumber}
        defaultValue={punchoutSession.phoneNumber}
        placeholder={resource.PhoneNumberSample}
        formGroup
        formClassName="mb-2"
        readOnly={isSsoSamlLogin || disabled}
      />
      {punchoutSession.costCenter && (
        <React.Fragment>
          <Input
            type="hidden"
            name="costCenterId"
            label={resource.CostCenter}
            defaultValue={punchoutSession.costCenter.id}
          />
          <NormalInput
            name="costCenter"
            label={resource.CostCenter}
            defaultValue={punchoutSession.costCenter.costCenter}
            formGroup
            formClassName="mb-2"
            readOnly
          />
        </React.Fragment>
      )}
      {session.loginProvider === LOGIN_PROVIDER.BELFIUS && (
        <Input type="hidden" name="uiLanguageSetting" defaultValue={session.uiLanguageSetting} />
      )}
      {session.loginProvider === LOGIN_PROVIDER.GOED && (
        <React.Fragment>
          <NormalInput
            label={resource.UserGroup}
            defaultValue={punchoutSession.userGroupCode}
            formGroup
            formClassName="mb-2"
            readOnly
          />
          <NormalInput
            label={resource.NPlusOne}
            defaultValue={punchoutSession.nPlusOneName}
            formGroup
            formClassName="mb-2"
            readOnly
          />
          <Input type="hidden" name="userGroup" defaultValue={punchoutSession.UserGroupId} />
          <Input type="hidden" name="nplusOneId" defaultValue={punchoutSession.nplusOneId} />
          <Input type="hidden" name="uiLanguageSetting" defaultValue={session.uiLanguageSetting} />
        </React.Fragment>
      )}
    </div>
  )
}
