import React from 'react'

import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'

import { PRA_APPROVER_STATUS, PRA_STATUS } from 'definitions'
import Button from 'common/Button'
import Input from 'common/Forms/Input'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'

export default observer(
  ({ praStore, action, data: pra, loggedinEmployeeId, resource, onSubmit, onRelaunch, onClose }) => {
    const methods = useForm({
      shouldFocusError: true,
    })

    const { handleSubmit } = methods
    const {
      CRUD: { submitting, error },
    } = praStore

    const title = React.useMemo(() => {
      switch (action) {
        case 'abort':
          return resource.Abort
        case 'relaunch':
          return resource.Relaunch
        default:
          return ''
      }
    }, [resource, action])

    const handleOnSubmit = async values => {
      let status
      let nextState

      switch (action) {
        case 'abort':
          nextState = PRA_STATUS.ABORTED.id
          status = PRA_APPROVER_STATUS.ABORTED
          break
        case 'relaunch':
          status = PRA_APPROVER_STATUS.APPROVED
          nextState = PRA_STATUS.UNSTARTED.id
          break
        default:
          return
      }

      const approverIndex = pra.approvers.findIndex(
        item => item.id === loggedinEmployeeId && item.status === PRA_APPROVER_STATUS.UNSTARTED
      )
      const result = await praStore.updatePra({
        pra,
        loggedinEmployeeId,
        nextState,
        currentApproverEdit: {
          lineIndex: approverIndex >= 0 ? approverIndex : 0,
          status,
          comment: values.comment,
        },
      })

      if (result) {
        onClose()
        if (action === 'relaunch') {
          onRelaunch()
        } else {
          onSubmit()
        }
      }
    }

    React.useEffect(() => {
      praStore.CRUD.error = null
      return () => {
        praStore.CRUD.error = null
      }
    }, [praStore])

    return (
      <Modal
        isOpen
        useForm={methods}
        title={title}
        disableClose={submitting}
        centered
        footer={
          <React.Fragment>
            <Button type="button" text onClick={onClose}>
              {resource.Cancel}
            </Button>
            <Button type="submit" disabled={submitting} loading={submitting}>
              {resource.Done}
            </Button>
          </React.Fragment>
        }
        footerClassName="d-flex justify-content-between"
        onSubmit={handleSubmit(handleOnSubmit)}
        onClose={onClose}
      >
        <MessageList messages={error && [error]} />
        <Input
          name="comment"
          rules={{ required: resource.Required }}
          label={resource.AddComment}
          disabled={submitting}
          formGroup
          autoFocus
        />
      </Modal>
    )
  }
)
