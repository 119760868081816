import React from 'react'

import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'

import { useStores } from 'stores'
import MessageList from 'common/MessageList'

export default observer(() => {
  const { globalMessageStore, pageResourceStore } = useStores()
  const location = useLocation()

  const showGlobalError = error =>
    globalMessageStore.showMessages({
      title: pageResourceStore.PageResource.common?.DefaultErrorTitle || "Oh snap! That's an error!",
      messages: [error],
      color: 'danger',
    })

  React.useEffect(() => {
    // Global api error handler
    globalMessageStore.onApiError = result => result && showGlobalError(result.error)
    globalMessageStore.onPraApiError = result => result && showGlobalError(result.error)
  }, [globalMessageStore, pageResourceStore])

  React.useEffect(() => {
    globalMessageStore.close()
  }, [location.pathname, location.search, globalMessageStore])

  return <MessageList {...globalMessageStore.Info} />
})
