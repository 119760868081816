import React from 'react'

import Card from 'common/Card'
import FileAttachment from 'common/Forms/FileAttachment'
import Input from 'common/Forms/Input'

export default ({ resource, quote, disabled }) => (
  <Card title={resource.RfqDetails} outline>
    <Input
      name="remarks"
      type="textarea"
      defaultValue={quote.remarks}
      label={resource.Remarks}
      formGroup
      horizontal
      disabled={disabled}
      rows="5"
    />
    <FileAttachment
      name="attachments"
      defaultValue={{ attachments: quote.attachments }}
      label={resource.Attachments}
      disabled={disabled}
      formGroup
      horizontal
    />
  </Card>
)
