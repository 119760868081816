import BaseService from 'services/base'

export default class SourceService extends BaseService {
  constructor(api) {
    const url = 'clientcase'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchTable(caseId) {
    return this.api.get(`${this.url}/${caseId}/source`)
  }

  getById = (caseId, id) => {
    return this.api.get(`${this.url}/${caseId}/rfq/${id}`, null, { ignoreGlobalMessage: true })
  }

  post = (caseId, payload) => {
    return this.api.post(`${this.url}/${caseId}/rfq`, payload, { ignoreGlobalMessage: true })
  }

  patch = (caseId, payload) => {
    const { id } = payload
    return this.api.put(`${this.url}/${caseId}/rfq/${id}`, payload, { ignoreGlobalMessage: true })
  }

  delete = payload => {
    const { caseId, id } = payload
    return this.api.delete(`${this.url}/${caseId}/rfq/${id}`)
  }

  sendRfq = payload => {
    const { caseId, id } = payload
    return this.api.put(`${this.url}/${caseId}/rfq/${id}/send`)
  }
}
