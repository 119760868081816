import React from 'react'

import { Col } from 'reactstrap'
import { observer } from 'mobx-react'
import { useFormState } from 'react-hook-form'
import { useStores } from 'stores'
import FormGroup from 'common/FormGroup'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'

export default observer(({ resource, deliveryAddress, isOther, namePrefix = '', lineIndex, disabled }) => {
  const { globalDataStore } = useStores()
  const { errors } = useFormState()

  let addressHasError
  let locationHasError

  if (errors) {
    if (lineIndex >= 0 && errors?.webformLines?.length > 0) {
      addressHasError =
        errors?.webformLines[lineIndex]?.deliveryInfo?.deliveryAddress?.street ||
        errors?.webformLines[lineIndex]?.deliveryInfo?.deliveryAddress?.number
      locationHasError =
        errors?.webformLines[lineIndex]?.deliveryInfo?.deliveryAddress?.postalCode ||
        errors?.webformLines[lineIndex]?.deliveryInfo?.deliveryAddress?.city
    } else {
      addressHasError = errors?.deliveryInfo?.deliveryAddress?.street || errors?.deliveryInfo?.deliveryAddress?.number
      locationHasError =
        errors?.deliveryInfo?.deliveryAddress?.postalCode || errors?.deliveryInfo?.deliveryAddress?.city
    }
  }

  return (
    <React.Fragment>
      <Input
        readOnly={disabled}
        name={`${namePrefix}deliveryInfo.deliveryAddress.building`}
        defaultValue={deliveryAddress?.building}
        label={resource.Building}
        placeholder={resource.phBuilding}
        formGroup
        horizontal
      />
      <FormGroup
        label={`${resource.Address} *`}
        formGroup
        horizontal
        hasError={addressHasError}
        inputColClassName="d-flex"
      >
        <Col md="6" className="pe-3">
          <Input
            readOnly={disabled}
            name={`${namePrefix}deliveryInfo.deliveryAddress.street`}
            rules={{ required: isOther && resource.Required }}
            defaultValue={deliveryAddress?.street}
            placeholder={resource.phStreet}
          />
        </Col>
        <Col md="3" className="px-3">
          <Input
            readOnly={disabled}
            name={`${namePrefix}deliveryInfo.deliveryAddress.number`}
            rules={{ required: isOther && resource.Required }}
            defaultValue={deliveryAddress?.number}
            placeholder={resource.phNr}
          />
        </Col>
        <Col md="3" className="ps-3">
          <Input
            readOnly={disabled}
            name={`${namePrefix}deliveryInfo.deliveryAddress.box`}
            defaultValue={deliveryAddress?.box}
            placeholder={resource.phBox}
          />
        </Col>
      </FormGroup>
      <FormGroup
        formGroup
        horizontal
        label={`${resource.Location} *`}
        hasError={locationHasError}
        inputColClassName="d-flex"
      >
        <Col md="4" className="pe-3">
          <Input
            readOnly={disabled}
            name={`${namePrefix}deliveryInfo.deliveryAddress.postalCode`}
            rules={{ required: isOther && resource.Required }}
            defaultValue={deliveryAddress?.postalCode}
            placeholder={resource.phZipCode}
          />
        </Col>
        <Col md="8" className="ps-3">
          <Input
            readOnly={disabled}
            name={`${namePrefix}deliveryInfo.deliveryAddress.city`}
            rules={{ required: isOther && resource.Required }}
            defaultValue={deliveryAddress?.city}
            placeholder={resource.phCity}
          />
        </Col>
      </FormGroup>
      <ReactSelect
        isDisabled={disabled}
        name={`${namePrefix}deliveryInfo.deliveryAddress.country`}
        rules={{ required: isOther && resource.Required }}
        defaultValue={deliveryAddress?.country}
        label={resource.Country}
        formGroup
        horizontal
        serverSide
        loadData={globalDataStore.searchCountries}
        defaultOptions={globalDataStore.getDefaultCountries()}
        optionValueKey="code"
        optionLabelKey="name"
      />
    </React.Fragment>
  )
})
