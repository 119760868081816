import React from 'react'

import { Link } from 'react-router-dom'

import { ROUTES } from 'definitions'
import { titlelize } from 'utils'

import Card from 'common/Card'

export default ({ resource, category }) => {
  return (
    <Link
      to={`${ROUTES.WEBSHOP_CATALOG}/${category.categoryPath}`}
      className="category-item-link col-xl-4 col-md-6 col-12 text-decoration-none"
    >
      <Card
        title={<span className="fw-bold fs-14">{titlelize(category.name)}</span>}
        className="paper paper-1 border-0 p-0"
        titleClassName="webshop-text-color bg-white py-4 px-5 border-0"
        bodyClassName="p-0 position-relative"
      >
        <div className="category-image-panel">
          <div className="category-image" style={{ backgroundImage: `url(${encodeURI(category.largeImageUrl)})` }} />
        </div>
        <div className="category-description-panel">
          <div className="p-5">
            {!category.description ? (
              <div>{resource.NoDescription}</div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: category.description }}></div>
            )}
          </div>
        </div>
      </Card>
    </Link>
  )
}
