import React from 'react'

import cx from 'classnames'

import { FlexTableCell, FlexTableRow } from 'common/FlexTable'
import { formatDate, formatNumber, praLongDateFormat } from 'utils/formatters'

export default ({ resource, item }) => {
  return (
    <FlexTableRow
      className={cx({
        'alert-success': item.quantityReceived > 0,
        'alert-danger': item.quantityReceived < 0,
      })}
    >
      <FlexTableCell className="col-line-index col-auto" />
      <FlexTableCell className="col-2-half ">{formatDate(item.timestamp)}</FlexTableCell>
      <FlexTableCell className="col justify-content-end">{formatNumber(item.quantityReceived)}</FlexTableCell>
      <FlexTableCell className="col-1-half justify-content-end">{formatNumber(item.price, 2)}</FlexTableCell>
      <FlexTableCell className="col-1-half justify-content-end">{formatNumber(item.totalPrice, 2)}</FlexTableCell>
      <FlexTableCell className="col-4 flex-wrap">
        <span className="fs-12 word-break-break-word white-space-normal">{item.comment}</span>
        <span
          className={cx('ms-auto fst-italic fw-bold fs-12', {
            'text-success': item.quantityReceived > 0,
            'text-danger': item.quantityReceived < 0,
          })}
        >{`${resource.On} ${formatDate(item.commentTimestamp, praLongDateFormat)}, ${resource.By} ${
          item.receiverName
        }`}</span>
      </FlexTableCell>
    </FlexTableRow>
  )
}
