import React from 'react'

import { Badge } from 'reactstrap'
import { components } from 'react-select'

import Icon from 'common/Icon'
import ReactSelect from 'common/Forms/ReactSelect'

export default ({ resource, hasSuggestedCategories, options: optionsProp, ...props }) => {
  const [showSuggestionsOnly, setShowSuggestionsOnly] = React.useState(false)
  const [enableSuggestions, setEnableSuggestions] = React.useState(false)

  const options = React.useMemo(() => {
    if (showSuggestionsOnly) {
      return optionsProp.filter(item => item.suggested)
    }
    return optionsProp
  }, [optionsProp, showSuggestionsOnly])

  React.useEffect(() => {
    if (hasSuggestedCategories && optionsProp.some(item => item.suggested)) {
      setShowSuggestionsOnly(true)
      setEnableSuggestions(true)
    } else {
      setShowSuggestionsOnly(false)
      setEnableSuggestions(false)
    }
  }, [optionsProp, hasSuggestedCategories])

  const toggle = () => setShowSuggestionsOnly(prev => !prev)

  return (
    <ReactSelect
      {...props}
      options={options}
      {...(enableSuggestions
        ? {
            components: {
              Menu: innerProps => (
                <components.Menu {...innerProps}>
                  {innerProps.children}
                  <div className="p-1">
                    <button
                      className="alert alert-primary d-flex align-items-center justify-content-between mb-0 py-1 px-2 w-100"
                      onClick={toggle}
                    >
                      {showSuggestionsOnly && (
                        <span>{resource.ShowOtherCategories || 'Click here to see more categories'}</span>
                      )}
                      {!showSuggestionsOnly && <span>{resource.ShowLessCategories || 'Show less categories'}</span>}
                      <Icon icon={showSuggestionsOnly ? 'expand_more' : 'expand_less'} className="text-primary" />
                    </button>
                  </div>
                </components.Menu>
              ),
              Option: ({ data, ...innerProps }) => (
                <components.Option data={data} {...innerProps}>
                  {data.suggested ? (
                    <span className="d-flex align-items-center">
                      <span className="word-break-break-word white-space-normal">{data.name}</span>
                      <Badge color="primary" className="ms-auto fs-11 rounded-pill py-1 px-2">
                        {resource.Suggestion}
                      </Badge>
                    </span>
                  ) : (
                    data.name
                  )}
                </components.Option>
              ),
            },
          }
        : {})}
    />
  )
}
