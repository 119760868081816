import React from 'react'

import { observer } from 'mobx-react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

import { jsonToQueryString } from 'utils/formatters'
import { LOGIN_PROVIDER, ROUTES } from 'definitions'
import { useAuthManager } from 'hooks/useAuthManager'
import { useStores } from 'stores'
import Loader from 'common/Loader'

const AuthWrapper = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { authStore } = useStores()
  const authManager = useAuthManager()
  const [showApp, setShowApp] = React.useState(false)

  React.useEffect(() => {
    authStore.onSessionExpired = () => {
      authManager.logout(true)
    }

    authStore.onRedirectToLoginProvider = (loginProvider, message) => {
      const queryStringParam = message ? jsonToQueryString({ message }) : ''
      navigate(`/${loginProvider}/login${queryStringParam}`)
    }

    const initialize = async () => {
      try {
        setShowApp(false)
        let success = false
        let {
          logout: logoutSearchParam,
          loginProvider: loginProviderParam,
          ...searchParams
        } = queryString.parse(location.search)

        if (!logoutSearchParam) {
          success = await authManager.getAuthSession()
        }

        if (success) {
          if (
            [ROUTES.LOGIN, ROUTES.LOGIN_BELFIUS, ROUTES.LOGIN_GOED, ROUTES.LOGIN_INTERPARKING].includes(
              location.pathname
            )
          ) {
            navigate(ROUTES.DEFAULT, { replace: true })
          }
        } else {
          if (authStore.AppSession.status === 401) {
            authStore.AppSession.error = null
          }

          // check if there is an intended login provider
          let pathname = location.pathname
          let loginSearchParams = { ...searchParams }
          switch (loginProviderParam) {
            case LOGIN_PROVIDER.BELFIUS:
              pathname = ROUTES.LOGIN_BELFIUS
              loginSearchParams = { autoLogin: true }
              searchParams = searchParams || {}
              break
            case LOGIN_PROVIDER.GOED:
              pathname = ROUTES.LOGIN_GOED
              loginSearchParams = { autoLogin: true }
              searchParams = searchParams || {}
              break
            case LOGIN_PROVIDER.INTERPARKING:
              pathname = ROUTES.LOGIN_INTERPARKING
              loginSearchParams = { autoLogin: true }
              searchParams = searchParams || {}
              break
            default:
              break
          }

          // Redirect to login page
          switch (pathname) {
            case ROUTES.LOGIN_BELFIUS:
              authManager.redirectToLogin({ provider: LOGIN_PROVIDER.BELFIUS, loginSearchParams, searchParams })
              break
            case ROUTES.LOGIN_GOED:
              authManager.redirectToLogin({ provider: LOGIN_PROVIDER.GOED, loginSearchParams, searchParams })
              break
            case ROUTES.LOGIN_INTERPARKING:
              authManager.redirectToLogin({ provider: LOGIN_PROVIDER.INTERPARKING, loginSearchParams, searchParams })
              break
            default:
              authManager.redirectToLogin()
              break
          }
        }
      } finally {
        setShowApp(true)
      }
    }

    initialize()
  }, [])

  return (
    <React.Fragment>
      {<Loader show={!showApp} text="Loading..." />}
      {showApp && <Outlet />}
    </React.Fragment>
  )
}

export default observer(AuthWrapper)
