import React from 'react'

import { Outlet } from 'react-router-dom'

import WebshopSidebar from 'components/Webshop/WebshopSidebar'

export default () => {
  return (
    <div className="d-flex">
      <WebshopSidebar />
      <Outlet />
    </div>
  )
}
