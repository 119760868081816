import React from 'react'

import { DropdownItem } from 'reactstrap'
import cx from 'classnames'

import { range } from 'utils/array'
import Button from './Button'
import Dropdown from 'common/Dropdown'
import Input from 'common/Input'

export default ({ resource, currentPage, pageSize, totalItems, className, onJumpToPage }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [disableButton, setDisableButton] = React.useState(true)
  const inputPageRef = React.useRef()
  const totalPageCountRef = React.useRef()

  const pageCount = React.useMemo(() => Math.ceil(totalItems / pageSize), [totalItems, pageSize])

  const filteredPages = React.useMemo(() => {
    const pages = range(1, pageCount)

    totalPageCountRef.current = pages.length

    if (pages.length > 5) {
      if (currentPage > 2) {
        const lastPage = currentPage + 3

        if (lastPage > pages.length) {
          return pages.slice(pages.length - 5, pages.length)
        } else {
          return pages.slice(currentPage - 2, lastPage)
        }
      } else {
        return pages.slice(0, 5)
      }
    }
    return pages
  }, [currentPage, pageCount])

  const toggle = () => setIsOpen(prev => !prev)

  const jumpToPage = page => {
    onJumpToPage && onJumpToPage(page)
  }

  const handleInputChange = event => {
    inputPageRef.current = event.target.value
    setDisableButton(!event.target.value)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleJumpToPage()
    }
  }

  const handleJumpToPage = () => {
    const value = inputPageRef.current
    const pageToJump = value ? parseInt(value) : 1

    if (pageToJump > totalPageCountRef.current) {
      jumpToPage(totalPageCountRef.current)
    } else if (pageToJump < 1) {
      jumpToPage(1)
    } else {
      jumpToPage(pageToJump)
    }
    setIsOpen(false)
  }

  const disableFirstPage = currentPage === 1
  const disablePreviousPage = currentPage === 1
  const disableNextPage = currentPage === totalPageCountRef.current
  const disableLastPage = currentPage === totalPageCountRef.current

  if (pageCount <= 1 || !filteredPages.length) return false

  return (
    <ul className={cx('pagination-list-group list-group list-group-horizontal list-unstyled', className)}>
      <li className={cx('list-group-item', disableFirstPage && 'disabled')}>
        <button type="button" disabled={disableFirstPage} className="button-page" onClick={() => jumpToPage(1)}>
          &laquo;
        </button>
      </li>
      <li className={cx('list-group-item', disablePreviousPage && 'disabled')}>
        <button
          type="button"
          disabled={disableFirstPage}
          className="button-page"
          onClick={() => jumpToPage(currentPage - 1)}
        >
          &#60;
        </button>
      </li>
      {filteredPages.map((page, index) => (
        <li key={index} className="list-group-item">
          <button
            type="button"
            className={cx('button-page', currentPage === page && 'active')}
            disabled={currentPage === page}
            onClick={() => jumpToPage(page)}
          >
            {page}
          </button>
        </li>
      ))}
      {pageCount > 5 && (
        <li className="list-group-item">
          <Dropdown
            direction="end"
            isOpen={isOpen}
            toggle={toggle}
            toggleContent="..."
            toggleButtonOptions={{
              outline: true,
              size: 'sm',
              className: 'button-page',
            }}
          >
            <DropdownItem text>
              <div className="mb-2">{resource?.JumpToPage || 'Jump to page'}</div>
              <div className="d-flex align-items-center">
                <Input
                  type="number"
                  placeholder={resource?.EnterPage || 'Enter page'}
                  max={pageCount}
                  min="1"
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <Button
                  type="button"
                  color="primary"
                  disabled={disableButton}
                  className="ms-2"
                  onClick={handleJumpToPage}
                >
                  {resource?.Go || 'Go'}
                </Button>
              </div>
            </DropdownItem>
          </Dropdown>
        </li>
      )}
      <li className={cx('list-group-item', disableNextPage && 'disabled')}>
        <button
          type="button"
          disabled={disableNextPage}
          className="button-page"
          onClick={() => jumpToPage(currentPage + 1)}
        >
          <span aria-hidden="true">&#62;</span>
        </button>
      </li>
      <li className={cx('list-group-item', disableLastPage && 'disabled')}>
        <button
          type="button"
          disabled={disableLastPage}
          className="button-page"
          onClick={() => jumpToPage(totalPageCountRef.current)}
        >
          &raquo;
        </button>
      </li>
    </ul>
  )
}
