import React from 'react'

import moment from 'moment'

import { FlexTable, FlexTableCell, FlexTableRow } from 'common/FlexTable'
import { formatString } from 'utils/formatters'
import { padLeft } from 'utils'
import { validateMinimum } from 'utils/validators'
import Card from 'common/Card'
import Checkbox from 'common/Forms/Checkbox'
import DatePicker from 'common/Forms/DatePicker'
import Input from 'common/Forms/Input'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({ resource, commonResource, quote, disabled }) => {
  const startingDate = React.useMemo(() => moment().add(1, 'd').toDate(), [])

  return (
    <Card title={resource.QuotationLines} color="primary" outline className="mb-4">
      <FlexTable
        columns={[
          { className: 'col-index text-muted', Header: '#' },
          { className: 'col', Header: resource.Description },
          { className: 'col-2', Header: resource.DeliveryDate },
          { className: 'col-1 justify-content-end', Header: resource.Qty },
          { className: 'col-2', Header: resource.Unit },
          { className: 'col-2 justify-content-end', Header: `${resource.UnitPrice} (€)` },
        ]}
      >
        {quote.lines.map((row, index) => {
          return (
            <FlexTableRow key={index}>
              <FlexTableCell className="col-index col-lg-auto col text-muted align-items-start pt-lg-4">
                <span className="d-lg-none d-flex">#</span>
                {padLeft(row.lineIndex, 1)}
                <Input
                  type="hidden"
                  name={`lines.${index}.lineIndex`}
                  defaultValue={row.lineIndex}
                  disabled={disabled}
                />
              </FlexTableCell>
              <FlexTableCell className="col-lg col-12 flex-wrap">
                <Input
                  name={`lines.${index}.description`}
                  rules={{ required: commonResource.Required }}
                  defaultValue={row.description}
                  label={resource.Description}
                  formGroup
                  formClassName="col-12 mb-2"
                  labelClassName="d-lg-none"
                  disabled={disabled}
                />
                <Input
                  type="textarea"
                  name={`lines.${index}.extendedDescription`}
                  defaultValue={row.extendedDescription}
                  label={resource.ExtendedDescription}
                  placeholder={resource.QuoteRemarksPlaceholder}
                  formGroup
                  formClassName="col-12 mb-0"
                  labelClassName="d-lg-none"
                  disabled={disabled}
                />
              </FlexTableCell>
              <FlexTableCell className="col-lg-2 col-12 align-items-start">
                <DatePicker
                  name={`lines.${index}.deliveryDate`}
                  defaultValue={row.deliveryDate}
                  label={resource.DeliveryDate}
                  disabledDays={[
                    {
                      before: startingDate,
                    },
                  ]}
                  formGroup
                  formClassName="col-12 mb-0"
                  labelClassName="d-lg-none"
                  disabled={disabled}
                />
              </FlexTableCell>
              <FlexTableCell className="col-lg-1 col-2 align-items-start pt-lg-4">
                <ReadOnlyField
                  value={row.quantity.amount}
                  label={resource.Qty}
                  formGroup
                  className="text-lg-end"
                  formClassName="col-12 mb-0"
                  labelClassName="d-lg-none"
                />
              </FlexTableCell>
              <FlexTableCell className="col-lg-2 col-4 align-items-start pt-lg-4">
                <ReadOnlyField
                  value={
                    <div className="d-flex justify-content-between">
                      <span>{row.quantity.unit}</span>
                      <span className="text-muted">x</span>
                    </div>
                  }
                  label={resource.Unit}
                  formGroup
                  formClassName="col-12 mb-0"
                  labelClassName="d-lg-none"
                />
              </FlexTableCell>
              <FlexTableCell className="col-lg-2 col-6 align-items-start">
                <UnitPrice
                  index={index}
                  row={row}
                  resource={resource}
                  commonResource={commonResource}
                  disabled={disabled}
                />
              </FlexTableCell>
            </FlexTableRow>
          )
        })}
      </FlexTable>
    </Card>
  )
}

const UnitPrice = ({ index, row, resource, commonResource, disabled }) => {
  const [notApplicable, setNotApplicable] = React.useState(row.notApplicable)

  return (
    <div className="col justify-content-lg-end">
      {!notApplicable && (
        <Input
          type="number"
          name={`lines.${index}.unitPrice.value`}
          rules={{
            required: commonResource.Number,
            validate: value => {
              return validateMinimum(value, 1, formatString(commonResource.MinValue, 1))
            },
          }}
          label={resource.UnitPrice}
          formGroup
          formClassName="col-12 mb-2"
          labelClassName="d-lg-none"
          disabled={disabled}
          defaultValue={row.UnitPrice}
          min="0"
        />
      )}
      {notApplicable && (
        <ReadOnlyField
          value="N/A"
          label={resource.UnitPrice}
          formGroup
          className="pt-lg-2 text-lg-end"
          formClassName="col-12 mb-3"
          labelClassName="d-lg-none"
        />
      )}
      <Checkbox
        name={`lines.${index}.notApplicable`}
        defaultChecked={notApplicable}
        label={resource.NotApplicable}
        formGroup
        formClassName="col-12 mb-2"
        labelClassName="d-lg-none fst-italic"
        disabled={disabled}
        onChange={event => setNotApplicable(event.target.checked)}
      />
    </div>
  )
}
