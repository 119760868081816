import React from 'react'

import { useStores } from 'stores'
import FormGroup from 'common/FormGroup'
import Icon from 'common/Icon'

export default ({ supplier, formGroup = true }) => {
  const { globalDataStore } = useStores()
  const [countryName, setCountryName] = React.useState()

  React.useEffect(() => {
    const fetchCountries = async () => {
      if (supplier) {
        const { address = {} } = supplier
        const theCountrName = await globalDataStore.getCountryName(address.country ? address.country.code : '')
        setCountryName(theCountrName)
      }
    }
    fetchCountries()
  }, [globalDataStore, supplier])

  const { address = {}, tags } = supplier

  return (
    <FormGroup formGroup={formGroup} horizontal inputColClassName="offset-md-3">
      <div>
        <strong>{supplier.name}</strong>
      </div>
      <dl className="dl-horizontal dl-icons mb-0">
        <dd className="d-flex align-items-center">
          <Icon icon="place" className="md-16 align-self-start pt-1 me-3" />
          {address.street} {address.number} {address.box}
          <br />
          {address.postalCode} {address.city}
          <br />
          {countryName}
          <br />
        </dd>
        <dd className="d-flex align-items-center">
          <Icon icon="phone" className="md-16 me-3" />{' '}
          {supplier.phone ? supplier.phone : <em className="text-muted">/</em>}
        </dd>
        <dd className="d-flex align-items-center">
          <Icon icon="email" className="md-16 me-3" />{' '}
          {supplier.email ? supplier.email : <em className="text-muted">/</em>}
        </dd>
        {tags && !!tags.length && (
          <React.Fragment>
            <dt>
              <Icon icon="local_offer" />
            </dt>
            <dd>{tags.join(', ')}</dd>
          </React.Fragment>
        )}
      </dl>
    </FormGroup>
  )
}
