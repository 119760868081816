import React from 'react'

import { Link, useNavigate } from 'react-router-dom'
import cx from 'classnames'

import { useDebounce } from 'hooks/useDebounce'

export default ({ children, to, disabled, delay, className, ...props }) => {
  const navigate = useNavigate()
  const gotoDebounced = useDebounce(() => goto(), delay)

  const goto = () => navigate(to)

  return (
    <Link
      to={to}
      tabIndex="-1"
      className={cx('shadow-none', className, { 'pointer-events-none': disabled })}
      onClick={event => {
        event.preventDefault()
        gotoDebounced()
      }}
      {...props}
    >
      {children}
    </Link>
  )
}
