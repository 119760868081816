import React from 'react'

import { ListGroup } from 'reactstrap'
import { observer } from 'mobx-react'

import { useDebounce } from 'hooks/useDebounce'
import { useStores } from 'stores'
import ContentLoader from 'common/ContentLoader'
import LayoutTitle from 'common/LayoutTitle'
import Loader from 'common/Loader'

import PraActions from './PraActions'
import PraDetail from '../PraDetail'
import PraFilters from './PraFilters'
import PraItem from './PraItem'

export default observer(() => {
  const { authStore, praStore, pageResourceStore } = useStores()
  const filtersRef = React.useRef({})
  const pageRef = React.useRef(0)
  const praDetailRef = React.useRef()
  const [data, setData] = React.useState([])
  const debounceLoadMoreData = useDebounce(() => {
    pageRef.current += 1
    handleLoadData(pageRef.current)
  })

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { loading: pageResourceLoading, pra: resource = {}, common: commonResource = {} },
  } = pageResourceStore
  const {
    Table: { loading },
  } = praStore
  const pageLoading = loading || pageResourceLoading

  React.useEffect(() => {
    pageResourceStore.get('pra')
  }, [pageResourceStore])

  React.useEffect(() => {
    const checkScroll = () => {
      // Check if there's more products to load when scrolling more than 70%
      if (praStore.Table.hasMorePages) {
        const wrapper = window.document.documentElement
        if (wrapper.scrollTop + wrapper.clientHeight >= Math.ceil(0.95 * wrapper.scrollHeight)) {
          debounceLoadMoreData()
        }
      }
    }
    window.document.addEventListener('scroll', checkScroll, false)
    return () => {
      window.document.removeEventListener('scroll', checkScroll, false)
    }
  }, [praStore])

  const handleFilterChange = values => {
    filtersRef.current = values
    handleLoadData()
  }

  const handleLoadData = async page => {
    const { approver, status, ...rest } = filtersRef.current
    const result = await praStore.fetchTable({
      ...rest,
      approver: null,
      ...(status ? { status: status.value } : {}),
      clientId: session.clientId,
      hasAdministratorRole: session.hasAdministratorRole,
      loggedinEmployeeId: session.loggedinEmployeeId,
      page: page || 0,
    })

    if (result) {
      if (page > 0) {
        setData(prevData => [...prevData, ...result.pras])
      } else {
        pageRef.current = 0
        setData([...result.pras])
      }
    } else {
      setData([])
    }
  }

  const handleCreatPra = () => {
    praDetailRef.current.createPra()
  }

  const handleEditPra = (pra, options) => {
    praDetailRef.current.editPra(pra, options)
  }

  const handleCopyPra = pra => {
    praDetailRef.current.copyPra(pra)
  }

  const handleOnReloadPraList = () => {
    handleLoadData()
  }

  return (
    <div id="pra-list">
      <LayoutTitle
        title={resource.Approvals}
        actions={
          <PraActions resource={resource} onCreatePra={handleCreatPra} disableCreatePra={session.disableCreatePra} />
        }
      />
      <PraFilters session={session} resource={resource} onChange={handleFilterChange} />
      {!pageLoading && !data.length && <p className="fs-italic fs-5 m-2">{resource.NoPrasFound}</p>}
      <div className="position-relative">
        <ContentLoader
          show={pageLoading && pageRef.current === 0}
          size="lg"
          className="p-3 justify-content-center align-items-start"
        />
        <ListGroup className="list-hover">
          {data.map(praItem => {
            return (
              <PraItem
                key={praItem.id}
                praStore={praStore}
                resource={resource}
                praItem={praItem}
                onReload={handleLoadData}
                onEditPra={handleEditPra}
                onCopyPra={handleCopyPra}
              />
            )
          })}
        </ListGroup>
        <Loader
          show={pageLoading && pageRef.current > 0}
          text={commonResource.Loading}
          className="justify-content-center my-4"
        />
      </div>
      <PraDetail ref={praDetailRef} onReloadPraList={handleOnReloadPraList} />
    </div>
  )
})
