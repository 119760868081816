import React from 'react'

import { Container } from 'reactstrap'
import { Outlet } from 'react-router-dom'

import GlobalMessage from 'components/Core/GlobalMessage'
import logoImage from 'assets/images/spot-buy-logo-120.jpg'
import Paper from 'common/Paper'

export default () => {
  return (
    <React.Fragment>
      <header className="py-1 text-center paper paper-1 public">
        <div className="brand">
          <img src={logoImage} alt="Oazo logo" />
        </div>
      </header>
      <Container>
        <Paper elevation="1">
          <GlobalMessage />
          <Outlet />
        </Paper>
      </Container>
    </React.Fragment>
  )
}
