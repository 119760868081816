import React from 'react'

import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import moment from 'moment'

import { formatDate } from 'utils/formatters'
import { useStores } from 'stores'
import Button from 'common/Button'
import DatePicker from 'common/Forms/DatePicker'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'
import ReadOnlyField from 'common/ReadOnlyField'

export default observer(({ resource, report, onClose }) => {
  const { authStore, reportStore } = useStores()
  const [loading, setLoading] = React.useState()
  const [error, setError] = React.useState()
  const downloadLinkRef = React.useRef()
  const methods = useForm()

  const { handleSubmit } = methods
  const session = authStore.getSession()
  const isPO = report === 'po'

  const downloadFileName = React.useMemo(() => {
    const fileType = session.fileTypeConfig || 'xlsx'
    const fileName = report === 'po' ? resource.PurchaseOrderReportFileName : resource.ApprovalAuditTrailFileName
    return `${fileName}.${fileType}`
  }, [resource, report, session])

  const handleOnSubmit = async values => {
    try {
      setLoading(true)
      setError(null)

      let url

      const startDate = formatDate(values.startDate)
      const endDate = formatDate(values.endDate)
      const payload = {
        ClientId: session.clientId,
        LoggedInId: authStore.getLoginEmployeeId(),
        DateFrom: startDate,
        DateTo: endDate,
      }

      switch (report) {
        case 'po':
          url = 'cases/praorderline'
          break
        default:
          url = 'praaudittrail'
          break
      }

      const { data, error } = await reportStore.downloadBlob(url, payload, { ignoreGlobalMessage: true })
      if (data && !error) {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        downloadLinkRef.current.href = window.URL.createObjectURL(blob)
        downloadLinkRef.current.click()
        onClose()
      } else {
        setError(error)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      isOpen
      useForm={methods}
      title={isPO ? resource.DownloadPurchaseOrderReport : resource.DownloadApprovalAuditTrail}
      disableCancel={loading}
      footer={
        <div className="w-100 d-flex">
          <Button text disabled={loading} className="me-2 px-4" onClick={onClose}>
            {resource.Cancel}
          </Button>
          <Button type="submit" color="primary" disabled={loading} loading={loading} className="ms-auto">
            {resource.Download}
          </Button>
        </div>
      }
      onClose={onClose}
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      {loading && (
        <MessageList title={resource.Downloading} messages={[resource.DownloadingMessage]} color="primary" hideClose />
      )}
      <MessageList messages={error && [error]} />
      <DatePicker
        name="startDate"
        label={resource.StartDate}
        defaultValue={moment().subtract(1, 'month').toDate()}
        formGroup
        horizontal
      />
      <DatePicker name="endDate" label={resource.EndDate} defaultValue={moment().toDate()} formGroup horizontal />
      <ReadOnlyField
        value={resource.DateRangeInfo}
        formGroup
        horizontal
        inputCol={{
          md: { offset: 3, size: 9 },
        }}
        formClassName="mb-0"
      />
      <a href="#" ref={downloadLinkRef} download={downloadFileName} className="d-none">
        download
      </a>
    </Modal>
  )
})
