import React from 'react'

import cx from 'classnames'

import { formatCurrency } from 'utils/formatters'
import { padLeft } from 'utils'
import Button from 'common/Button'
import Card from 'common/Card'
import Ribbon from 'common/Ribbon'

import { TableListItem } from 'components/Case/CaseDetail/TableList'

export default ({ resource, proposeItem, onViewDetail }) => {
  const { status, aggregatedLines = [], proposals = [], totals } = proposeItem

  return (
    <TableListItem className="position-relative">
      <Card
        outline
        title={
          <strong>
            {resource.ProposalBundle} #{padLeft(proposeItem.bundleIndex, 1)}
          </strong>
        }
        className="position-relative"
        titleClassName="white-space-nowrap flex-lg-row flex-column"
        actions={
          <React.Fragment>
            {status.bundleSent && (
              <Button color="primary" text size="sm" icon="visibility" onClick={() => onViewDetail(proposeItem)}>
                {resource.ViewProposals}
              </Button>
            )}
            {!status.bundleSent && (
              <Button color="primary" text size="sm" icon="edit">
                {resource.EditRfqDraft}
              </Button>
            )}
          </React.Fragment>
        }
      >
        {status.bundleRevoked && <Ribbon color="danger">{resource.Revoked}</Ribbon>}
        <div className="react-table-container responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="col-1 text-muted">#</th>
                <th className="col-4">{resource.Description}</th>
                <th className="col-1 border-start text-end">{resource.Qty}.</th>
                <th className="col-1">&nbsp;</th>
                {proposals.map((_, proposalIndex) => (
                  <th key={proposalIndex} className="col-2">
                    {resource.Proposal} {proposalIndex + 1}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {aggregatedLines.map((aggregated, aggregatedIndex) => {
                const { proposedPrices = [], quantity } = aggregated
                return (
                  <tr key={aggregatedIndex}>
                    <td className="text-muted">{padLeft(aggregated.prLineIndex, 2)}</td>
                    <td>
                      <span title={aggregated.description} className="word-break-break-word white-space-normal">
                        {aggregated.description}
                      </span>
                    </td>
                    <td className="border-start text-end">{quantity.amount}</td>
                    <td className="text-muted">&times;</td>
                    {proposedPrices.map((proposedPrice, proposedPriceIndex) => (
                      <td key={proposedPriceIndex} className={cx(proposedPrice.isPicked && 'text-success')}>
                        {!proposedPrice.isPlaceHolder ? formatCurrency(proposedPrice.clientPrice.value) : ''}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="2"></th>
                <th className="text-end border-start">{resource.Total}</th>
                <th className="text-muted">=</th>
                {totals.proposedTotalPrices.map((proposedTotal, proposedTotalIndex) => (
                  <th key={proposedTotalIndex} className={cx(proposedTotal.isPicked && 'text-success')}>
                    {formatCurrency(proposedTotal.totalPrice.value)}
                  </th>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </Card>
    </TableListItem>
  )
}
