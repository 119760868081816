import moment from 'moment'

import { formatDate } from 'utils/formatters'
import BaseStore from 'stores/base'

export default class GoodsReceivedStore extends BaseStore {
  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options
  }

  prepareLineItems(data) {
    return data.items?.map(lineItem => {
      const { goodsReceived } = lineItem
      const lastTimestamp = moment(goodsReceived.lastTimestamp, 'YYYY-MM-DD')

      return {
        ...lineItem,
        goodsReceived: {
          ...goodsReceived,
          lastTimestamp: lastTimestamp.isValid() ? lastTimestamp.toDate() : null,
          items: goodsReceived.items?.map(item => {
            const timestamp = moment(item.timestamp, 'YYYY-MM-DD')
            const commentTimestamp = moment(item.commentTimestamp, 'YYYY-MM-DD')

            return {
              ...item,
              timestamp: timestamp.isValid() ? timestamp.toDate() : null,
              commentTimestamp: commentTimestamp.isValid() ? commentTimestamp.toDate() : null,
            }
          }),
        },
      }
    })
  }

  async submit({ praId, userId, sessionId, values }) {
    const { items: lineItems } = values
    const payload = {
      praId,
      userId,
      goodsReceived: lineItems.map(lineItem => {
        const { goodsReceived } = lineItem
        const items = goodsReceived.items || []

        return {
          lineIndex: lineItem.lineIndex,
          totalQuantityReceived: goodsReceived.totalQuantityReceived,
          price: lineItem.price,
          totalPrice: goodsReceived.totalPrice,
          items: items.map(item => {
            return {
              description: lineItem.description,
              received: true,
              quantityReceived: item.quantityReceived,
              price: item.price,
              totalPrice: item.totalPrice,
              timestamp: formatDate(item.timestamp),
              receiverId: sessionId,
              comment: item.comment,
              commentTimestamp: formatDate(new Date()),
            }
          }),
        }
      }),
    }

    const result = await this.post(null, payload, { ignoreGlobalMessage: true })
    if (result) {
      return payload
    } else {
      return false
    }
  }
}
