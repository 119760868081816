import React from 'react'

import { LOGOUT_REQUEST } from 'definitions'

export default () => {
  const handleStorageTransfer = React.useCallback(event => {
    if (!event.newValue) return

    // Check if there's a request to logout current browser tab
    if (event.key === LOGOUT_REQUEST) {
      window.location.reload()
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener('storage', handleStorageTransfer)
    return () => {
      window.removeEventListener('storage', handleStorageTransfer)
    }
  }, [handleStorageTransfer])

  return false
}
