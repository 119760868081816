import React from 'react'

import { Button } from 'reactstrap'
import cx from 'classnames'

import Icon from 'common/Icon'
import Loader from 'common/Loader'

export default ({
  children,
  type = 'button',
  disabled,
  className,
  loading,
  loadingText,
  loadingSize = 'sm',
  color = 'primary',
  size,
  text,
  icon,
  iconEnd,
  iconFilled,
  iconClassName,
  onClick,
  ...props
}) => {
  return (
    <Button
      type={type}
      size={size}
      disabled={disabled}
      {...(color && !text ? { color } : { color: '' })}
      {...(!disabled ? { onClick } : {})}
      className={cx(className, text && `btn-text-${color}`, {
        loading: !!loading,
        'with-icon': !!icon || !!iconEnd,
        'btn-text': !!text,
      })}
      {...props}
    >
      {loading && (
        <Loader show color="white" size={size === 'lg' ? 'lg' : loadingSize} text={loadingText} className="p-0" />
      )}
      {icon && (
        <Icon
          icon={icon}
          filled={iconFilled}
          className={cx('btn-material-icon', iconClassName, !!children && 'has-children')}
        />
      )}
      {children}
      {iconEnd && (
        <Icon icon={iconEnd} filled={iconFilled} className={cx('btn-material-icon icon-end', iconClassName)} />
      )}
    </Button>
  )
}
