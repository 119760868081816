import React from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { useStores } from 'stores'
import Loader from 'common/Loader'
import MessageList from 'common/MessageList'

import ProductInfoPanel from './ProductInfoPanel'
import SidebarPanel from './SidebarPanel'

export default observer(() => {
  const { authStore, globalMessageStore, pageResourceStore, webshopCartStore, webshopPricingStore, webshopStore } =
    useStores()
  const params = useParams()
  const methods = useForm()

  const itemId = params.id
  const { handleSubmit, setValue } = methods

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { webshop: resource },
  } = pageResourceStore
  const {
    CRUD: {
      data: { item: data, success },
      loading,
    },
  } = webshopStore

  React.useEffect(() => {
    if (itemId) {
      const getItem = async () => {
        const result = await webshopStore.getProductDetail({
          itemId,
          languageCode: session.uiLanguageSetting,
        })
        if (result.success) {
          setProductCategoryPath(result.item)
          setValue('quantity', result.item.minimumOrderQty)
        } else if (result?.success === false && !result.item) {
          globalMessageStore.show({
            title: resource.ProductAccessDeniedByUserGroupTitle,
            message: resource.ProductAccessDeniedByUserGroup,
            color: 'info',
          })
        }
      }
      getItem()
    }
  }, [webshopStore, globalMessageStore, resource, session.uiLanguageSetting, itemId])

  React.useEffect(() => {
    if (data?.itemId) {
      const getItemPricing = async () => {
        const result = await webshopPricingStore.fetchPricingTiers({
          clientId: session.clientId,
          items: [
            {
              itemId: data.itemId,
              serviceContract: data.serviceContract,
            },
          ],
        })
        if (result?.success) {
          setPricingTier(data.minimumOrderQty)
        }
      }
      getItemPricing()
    }
  }, [webshopPricingStore, data, session.clientId, setValue])

  const setPricingTier = newQuantity => {
    const pricing = webshopPricingStore.getItemPricingTier(data.itemId, newQuantity)
    if (pricing) {
      setValue('pricing', pricing)
    }
  }

  const handleOnBeforeChange = (quantity, stock) => {
    const name = `item.itemStockStop`
    if (quantity) {
      const hasStockStop = stock !== null && quantity > stock
      setValue(name, hasStockStop)
    } else {
      setValue(name, false)
    }
    return true
  }

  const setProductCategoryPath = item => {
    const categories = item?.categories
    if (categories?.length) {
      // get the longest category path
      const categoryPath =
        categories &&
        categories.sort((cat1, cat2) => {
          if (cat1.categoryPath < cat2.categoryPath) {
            return -1
          }
          if (cat1.categoryPath > cat2.categoryPath) {
            return 1
          }
          return 0
        })[categories.length - 1].categoryPath
      webshopStore.CRUD.categoryPath = categoryPath
    }
  }

  const setRemarksValue = fields => {
    let remarks

    fields && Object.keys(fields).forEach(key => {
        const field = fields[key]
        let fieldValue

        if (field?.input !== undefined) {
          // Get the input value of checkbox if available
          fieldValue = field.input ? `${key.toUpperCase()}? Yes: ${field?.input}` : ''
        } else if (field?.value) {
          // Get the selected value of dropdown if available
          fieldValue = `${field.name.toUpperCase()}? ${field?.value}`
        } else {
          fieldValue = field || ''
        }
        remarks = remarks ? (fieldValue ? `${remarks} - ${fieldValue}` : remarks) : fieldValue
      })

    return remarks
  }

  const handleSelectedCustomField = (name, value) => {
    setValue(name, value)
    return true
  }

  const handleOnSubmit = async values => {
    const payload = {
      item: data,
      pricing: values.pricing,
      quantity: values.quantity,
      remarks: setRemarksValue(values['customized fields']),
    }
    const result = await webshopCartStore.addItemToCart(payload)
    if (result) {
      webshopCartStore.calculateCartQuantity()
    }
  }

  return (
    <div id="webshop-product-detail" className="col">
      <Loader show={loading} />
      {success && !data?.name && (
        <MessageList messages={[resource.ProductAccessDeniedByUserGroup]} color="primary" hideClose />
      )}

      {!!data && !loading && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleOnSubmit)} className="d-flex flex-wrap col webshop-text-color">
            <ProductInfoPanel resource={resource} data={data} handleSelectedCustomField={handleSelectedCustomField} />
            <SidebarPanel
              resource={resource}
              data={data}
              setPricingTier={setPricingTier}
              onBeforeChange={handleOnBeforeChange}
            />
          </form>
        </FormProvider>
      )}
    </div>
  )
})
