import BaseService from 'services/base'

export default class ClientSupplierService extends BaseService {
  constructor(api) {
    const url = 'clientArea/supplier'
    super(api, url)
    this.api = api
    this.url = url
  }

  importClientSupplier = payload => {
    return this.post('import', payload)
  }
}
