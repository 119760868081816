import BaseService from 'services/base'

export default class NewsService extends BaseService {
  constructor(api) {
    const url = 'newsfeeds'
    super(api, url)
    this.api = api
    this.url = url
  }

  getNews(payload) {
    return this.get(`${payload.clientId}/${payload.clientAbbreviation}/${payload.email}/${payload.userAuthId}`)
  }
}
