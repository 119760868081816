import React from 'react'

import { ListGroupItem } from 'reactstrap'
import { observer } from 'mobx-react'

import PraItemActiveActions from './PraItemActions'
import PraItemInfo from './PraItemInfo'
import PraViewPanel from '../PraViewPanel'

export default observer(({ praStore, resource, praItem, onCopyPra, onEditPra }) => {
  const [isOpen, setIsOpen] = React.useState()

  const toggle = () => setIsOpen(prev => !prev)

  const handleCopyPra = async event => {
    event.stopPropagation()
    onCopyPra({ ...praItem })
  }

  const handleEditPra = event => {
    event.stopPropagation()
    onEditPra(praItem)
  }

  const handleGoodsReceived = event => {
    event.stopPropagation()
    onEditPra(praItem, { showGoodsReceived: true })
  }

  return (
    <ListGroupItem className="pra-list-item col px-lg-3 px-0 py-2 border-0 border-bottom">
      <div className="d-flex flex-wrap col flex-xl-row flex-column" onClick={toggle}>
        <PraItemInfo resource={resource} praItem={praItem} />
        <PraItemActiveActions
          praStore={praStore}
          resource={resource}
          praItem={praItem}
          onCopyPra={handleCopyPra}
          onEditPra={handleEditPra}
          onGoodsReceived={handleGoodsReceived}
        />
      </div>
      <PraViewPanel resource={resource} pra={praItem} isOpen={isOpen} />
    </ListGroupItem>
  )
})
