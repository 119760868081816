import React from 'react'

import { SERVICE_CONTRACT, SPOT_BUY_CENTER } from 'definitions'
import { useStores } from 'stores'
import ReactTable from 'common/ReactTable'

export default ({ resource, data }) => {
  const { authStore } = useStores()

  const { specifications } = data
  const {
    AppSession: { session },
  } = authStore

  const getAttributeValue = (attribute, attributeValue) => {
    if (Array.isArray(attributeValue)) {
      return attributeValue
        .map(item => {
          if (typeof attributeValue === 'object') {
            return Object.keys(item)
              .map(key => item[key])
              .join(', ')
          } else {
            return item
          }
        })
        .join('\n')
    } else if (typeof attributeValue === 'object') {
      const newValue = Object.keys(attributeValue)
        .map(key => attributeValue[key])
        .join('\n')

      return newValue
    } else {
      if (attribute.key.toLowerCase() === 'servicecontract') {
        if (attributeValue === SERVICE_CONTRACT.CLIENT_CATALOG) {
          attributeValue = session.clientName
        } else {
          attributeValue = SPOT_BUY_CENTER
        }
      } else if (attributeValue.toLowerCase() === 'true') {
        attributeValue = 'Yes'
      } else if (attributeValue.toLowerCase() === 'false') {
        attributeValue = 'No'
      }

      return attributeValue
    }
  }

  return (
    <div className="pe-2">
      <div className="fs-18 fw-bolder webshop-title mb-5">{resource.ProductSpecifications}</div>
      {specifications &&
        specifications.map((spec, index) => (
          <ReactTable
            key={index}
            data={spec.attributes}
            className="fs-12"
            columns={React.useMemo(
              () => [
                {
                  Header: spec.title,
                  accessor: 'label',
                  width: 110,
                },
                {
                  accessor: 'value',
                  className: 'white-space-pre-line',
                  Cell: ({ row, value }) => {
                    const theValue = value.includes('{') ? JSON.parse(value) : value
                    return getAttributeValue(row, theValue)
                  },
                },
              ],
              [spec.title]
            )}
          />
        ))}
    </div>
  )
}
