import React from 'react'

import { formatCurrency } from 'utils/formatters'
import { padLeft } from 'utils'
import Button from 'common/Button'
import ButtonLink from 'common/ButtonLink'
import Icon from 'common/Icon'
import ReactTable from 'common/ReactTable'

export default ({ resource, purchaseOrder, onViewDetail }) => {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'lineIndex',
        headerClassName: 'text-muted',
        className: 'text-muted',
        width: 50,
        Header: '#',
        Cell: ({ row, value }) => <ButtonLink onClick={() => onViewDetail(row)}>{padLeft(value, 1)}</ButtonLink>,
      },
      {
        accessor: 'description',
        headerClassName: 'col',
        className: 'col',
        Header: resource.Description,
        Cell: ({ value, row }) => (
          <ButtonLink
            title={value}
            className="word-break-break-word white-space-normal"
            onClick={() => onViewDetail(row)}
          >
            {value}
          </ButtonLink>
        ),
      },
      {
        accessor: 'quantity.amount',
        headerClassName: 'col-1 text-end',
        className: 'col-1 text-end',
        Header: resource.Qty,
      },
      {
        headerClassName: 'col-half',
        className: 'col-half',
        Cell: () => <Icon icon="clear" className="pt-2 md-16" />,
      },
      {
        accessor: 'clientPrice.value',
        headerClassName: 'col-1-half',
        className: 'col-1-half',
        Header: resource.ClientPrice,
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        accessor: 'subTotalVatExcl.value',
        headerClassName: 'col-1-half',
        className: 'col-1-half',
        Header: resource.TotalPrice,
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        headerClassName: 'col-2 border-start',
        className: 'col-2 border-start',
        Cell: ({ row }) => (
          <Button
            icon="visibility"
            title={resource.ViewLine}
            color="default"
            size="sm"
            className="me-1"
            onClick={() => onViewDetail(row)}
          >
            {resource.View}
          </Button>
        ),
      },
    ],

    []
  )

  return <ReactTable data={purchaseOrder.lines} columns={columns} responsive />
}
