import React from 'react'

import queryString from 'query-string'

import { PRA_PREPARE_TYPE, ROUTES } from 'definitions'
import { replaceUrl } from 'utils'
import { useStateCallback } from 'hooks/useStateCallback'
import { useStores } from 'stores'
import PraFormModal from './PraFormModal'

export default React.forwardRef(({ onReloadPraList }, ref) => {
  const { authStore, praStore } = useStores()
  const [praModalOptions, setPraModalOptions] = useStateCallback({})

  const {
    AppSession: { session },
  } = authStore

  React.useImperativeHandle(ref, () => ({
    createPra: () => showPra(true, null, 'create'),
    editPra: (pra, options) => showPra(true, pra, 'edit', options),
    copyPra: pra => showPra(true, pra, 'copy'),
  }))

  React.useEffect(() => {
    if (window.location.search) {
      const searchParams = queryString.parse(window.location.search)
      const encryptedKey = searchParams.pr
      const key = searchParams.key

      if (encryptedKey) {
        // load PRA by encryptedKey
        showPraByEncryptedKey(encryptedKey)
      } else if (key) {
        // load PRA by key from pick proposal
        showPraByFromPickProposal(key)
      }
    }
  }, [window.location.search])

  const showPra = (isOpen, pra, action, options) => {
    if (isOpen) preparePra(pra, action)
    setPraModalOptions({
      isOpen,
      isEdit: action === 'edit',
      isCopy: action === 'copy',
      options,
    })
  }

  const showPraByEncryptedKey = encryptedKey => {
    const loggedinEmployeeId = authStore.getLoginEmployeeId()
    showPra(
      true,
      {
        encryptedKey,
        creatorId: loggedinEmployeeId,
        requesterId: loggedinEmployeeId,
      },
      'edit'
    )
  }

  const showPraByFromPickProposal = async key => {
    const clientId = `clients/${session.clientId}`
    const loggedinEmployeeId = authStore.getLoginEmployeeId()

    setPraModalOptions({ isOpen: true, isEdit: true })
    const result = await praStore.createByKey({
      clientId,
      creatorId: session.loggedinEmployeeId,
      loggedinEmployeeId,
      requestKey: key,
      requestType: PRA_PREPARE_TYPE.PENDING_FOR_CREATE,
    })
    if (result) {
      replaceUrl(ROUTES.PRA)
      preparePra(
        {
          id: result.id,
          creatorId: loggedinEmployeeId,
          requestKey: key,
        },
        'edit'
      )
    }
  }

  const preparePra = (pra, action) => {
    const clientId = `clients/${session.clientId}`
    const loggedinEmployeeId = authStore.getLoginEmployeeId()

    praStore.CRUD.loading = true
    switch (action) {
      case 'edit':
      case 'copy':
        praStore.preparePra({
          clientId,
          creatorId: `ClientEmployees/${pra.creatorId}`,
          hasAdministratorRole: session.hasAdministratorRole,
          loggedinEmployeeId,
          ...(pra.id ? { praId: pra.id } : {}),
          ...(pra.encryptedKey ? { encryptedKey: pra.encryptedKey } : {}),
          ...(pra.requestKey ? { requestKey: pra.requestKey } : {}),
          requestType: action === 'copy' ? PRA_PREPARE_TYPE.COPY_PRA : PRA_PREPARE_TYPE.EDIT_PRA,
          requesterId: pra.requesterId,
        })
        break
      default:
        praStore.preparePra({
          clientId,
          creatorId: session.loggedinEmployeeId,
          hasAdministratorRole: session.hasAdministratorRole,
          loggedinEmployeeId,
          requestType: PRA_PREPARE_TYPE.CREATE,
        })
        break
    }
  }

  const handleRelaunchedPra = pra => {
    onReloadPraList()
    setPraModalOptions({}, () => handleReloadPra(pra))
  }

  const handleReloadPra = pra => {
    // reload PRA
    showPra(
      true,
      {
        id: pra.id,
        creatorId: pra.creator.id,
        requesterId: pra.requester.id,
      },
      'edit'
    )
  }

  return (
    <React.Fragment>
      {praModalOptions.isOpen && (
        <PraFormModal
          isEdit={praModalOptions.isEdit}
          isCopy={praModalOptions.isCopy}
          options={praModalOptions.options}
          onClose={() => showPra()}
          onReloadPraList={onReloadPraList}
          onRelaunchedPra={handleRelaunchedPra}
          onReloadPra={handleReloadPra}
        />
      )}
    </React.Fragment>
  )
})
