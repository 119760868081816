import React from 'react'

export const useStateCallback = initialState => {
  const [state, setState] = React.useState(initialState)
  const callbackRef = React.useRef(null)

  const setStateCallbackRef = React.useRef((newState, callback) => {
    callbackRef.current = callback
    setState(newState)
  })

  React.useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state)
      callbackRef.current = null
    }
  }, [state])

  return [state, setStateCallbackRef.current]
}
