import React from 'react'

import cx from 'classnames'

import { formatCurrency, formatDate } from 'utils/formatters'
import { padLeft } from 'utils'
import Card from 'common/Card'
import Checkbox from 'common/Checkbox'
import Modal from 'common/Modal'
import ReactTable from 'common/ReactTable'
import ReadOnlyAttachment from 'common/ReadOnlyAttachment'
import ReadOnlyField from 'common/ReadOnlyField'
import Ribbon from 'common/Ribbon'

export default ({ resource, commonResource, data, onClose }) => {
  const { status, proposals = [] } = data

  const columns = React.useMemo(
    () => [
      {
        width: 50,
        Cell: ({ value }) => <Checkbox defaultChecked disabled />,
      },
      {
        Header: '#',
        accessor: 'prLineIndex',
        headerClassName: 'text-muted',
        className: 'text-muted',
        width: 50,
        Cell: ({ value }) => padLeft(value, 1),
      },
      {
        Header: resource.Description,
        accessor: 'description',
        headerClassName: 'col',
        className: 'col',
        Cell: ({ value, row }) => (
          <div>
            <div className={cx('word-break-break-word white-space-normal', !!row.extendedDescription && 'mb-3')}>
              {value}
            </div>
            {!!row.extendedDescription && (
              <div className="mb-3 word-break-break-word white-space-normal">{row.extendedDescription}</div>
            )}
          </div>
        ),
      },
      {
        Header: resource.ClientPrice,
        accessor: 'clientPrice.value',
        headerClassName: 'col-2 text-end',
        className: 'col-2 text-end',
        Cell: ({ value }) => (value ? formatCurrency(value) : '-'),
      },
      {
        Header: resource.DeliveryDate,
        accessor: 'deliveryDate',
        headerClassName: 'col-2 text-end',
        className: 'col-2 text-end',
        Cell: ({ value }) => formatDate(value),
      },
    ],
    [resource]
  )

  return (
    <Modal isOpen title={resource.ViewProposalBundle} size="xl" onClose={onClose}>
      {proposals.map((proposal, proposalIndex) => {
        const attachments = proposal.attachments || []
        return (
          <Card
            key={proposalIndex}
            outline
            className="position-relative mb-4"
            bodyClassName="px-0"
            title={
              <span className="d-flex align-items-center">
                <Checkbox type="checkbox" defaultChecked disabled />
                <span className="ms-2 me-6">
                  {resource.Proposal} {proposalIndex + 1}
                </span>
                <small className="col border rounded px-3 py-1">{proposal.description}</small>
              </span>
            }
            footer={
              <React.Fragment>
                <ReadOnlyAttachment
                  label={resource.Attachments}
                  value={attachments}
                  noAttachment={resource.NoAttachments}
                  formGroup
                  horizontal
                />
                <ReadOnlyField label={resource.Remarks} formGroup horizontal />
              </React.Fragment>
            }
          >
            {status.bundleSent && proposal.isPicked && <Ribbon color="success">{resource.Selected}</Ribbon>}
            {status.bundleSent && <ReactTable columns={columns} data={proposal.lines} responsive />}
          </Card>
        )
      })}
    </Modal>
  )
}
