import { makeObservable, observable } from 'mobx'

import BaseStore, { defaults } from 'stores/base'

export default class WebshopFavouriteStore extends BaseStore {
  HeaderFavourites = { ...defaults.Table, data: [] }
  Table = { ...defaults.Table, data: [] }

  constructor(service) {
    super(service)
    this.service = service

    makeObservable(this, {
      HeaderFavourites: observable,
    })
  }

  fetchHeaderFavourites(payload) {
    return this.getRequest(
      this.HeaderFavourites,
      () => this.service.fetchTable(payload),
      newData => {
        if (newData?.success) {
          this.HeaderFavourites.data = newData.items
        }
      }
    )
  }

  fetchTable(payload) {
    return this.getRequest(
      this.Table,
      () => this.service.fetchTable(payload),
      newData => {
        if (newData?.success) {
          this.Table.data = newData.items
        }
      }
    )
  }
}
