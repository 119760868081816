import { makeObservable, observable } from 'mobx'

import BaseStore, { defaults } from 'stores/base'

export default class WebshopCategoryStore extends BaseStore {
  CategoryTree = { ...defaults.CRUD, loaded: false }
  TopLevelCategory = null
  TopLevelCategories = { ...defaults.CRUD, loaded: false }

  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options

    makeObservable(this, {
      CategoryTree: observable,
      TopLevelCategory: observable,
      TopLevelCategories: observable,
    })
  }

  fetchTable(payload) {
    return this.getRequest(this.Table, () => this.service.fetchTable(payload))
  }

  fetchCategoryTree(payload) {
    return this.getRequest(this.CategoryTree, () => this.service.fetchCategoryTree(payload))
  }

  fetchTopLevelCategories(languageCode) {
    return this.getRequest(this.TopLevelCategories, () =>
      this.service.fetchCategoryTree({
        languageCode,
        topLevel: true,
      })
    )
  }

  async searchCategories(payload) {
    const { data, error } = await this.service.searchCategories(payload)
    const success = data && !error
    return success && data
  }
}
