import React from 'react'

export default ({ newsItem, resource }) => {
  return (
    <div className="news-list-item">
      <div className="news-list-item-img">
        <div className="news-item-img-container">
          <img src={newsItem.feedImage} alt="Spot Buy Center" className="news-item-img"></img>
        </div>
      </div>
      <div className="news-list-item-body">
        <h6 className="news-list-item-body-title">
          <strong>{newsItem.title}</strong>
        </h6>
        <div className="news-list-item-body-text-container">
          <p className="news-list-item-body-text">{newsItem.description}</p>
          <div className="news-list-item-body-button-container">
            <a href={newsItem.link} target="_blank" className="news-list-item-body-button primary" rel="noreferrer">
              {resource.ReadMore}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
