import BaseService from 'services/base'

export default class PurchaseOrderService extends BaseService {
  constructor(api) {
    const url = 'clientcase'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchTable = caseId => {
    return this.api.get(`${this.url}/${caseId}/po`)
  }
}
