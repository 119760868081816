import React from 'react'

import { CardFooter, Collapse } from 'reactstrap'
import { observer } from 'mobx-react'

import { formatNumber } from 'utils/formatters'
import { useStores } from 'stores'
import Button from 'common/Button'
import ButtonIcon from 'common/ButtonIcon'
import Card from 'common/Card'
import WebformProductLineInfo from 'components/Webform/WebformProductLineInfo'
import WebformProductLineOverrideDelivery from 'components/Webform/WebformProductLineOverrideDelivery'

export default observer(({ resource, lineItem, index, isOpen, onExit, onSave, onEdit, onCopy }) => {
  const { authStore, webformStore } = useStores()
  const [isEdit, setIsEdit] = React.useState(lineItem.isEdit)

  const {
    CRUD: { data, submitting },
  } = webformStore

  const handleCancel = () => onExit(!isEdit, index)

  const handleRemoveLine = () => onExit(true, index, isEdit)

  const handleSave = async () => {
    const result = await onSave(index)
    result && !isEdit && setIsEdit(true)
  }

  const handleEdit = () => onEdit(index)

  const handleCopy = () => onCopy(index)

  return (
    <Card
      className="mb-2"
      title={
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span className="me-4">#{index + 1}</span>
            <strong>
              {isEdit
                ? `${formatNumber(lineItem.quantity?.amount || 0)} x ${lineItem.articleDescription}`
                : resource.NewLine}
            </strong>
          </div>
          <div>
            {isEdit && !isOpen && (
              <ButtonIcon
                icon="content_copy"
                title={resource.CopyLine}
                className="me-1"
                iconClassName="md-18"
                disabled={submitting}
                onClick={handleCopy}
              />
            )}
            {isEdit && (
              <ButtonIcon
                icon="edit"
                title={resource.EditLine}
                className="me-1"
                iconClassName="md-18"
                disabled={submitting}
                onClick={handleEdit}
              />
            )}
            <ButtonIcon
              icon="clear"
              title={resource.RemoveLine}
              iconClassName="md-18"
              disabled={submitting}
              onClick={handleRemoveLine}
            />
          </div>
        </div>
      }
      bodyClassName="p-0"
    >
      <Collapse isOpen={isOpen}>
        <div className="col-lg-10 p-3">
          <WebformProductLineInfo {...{ authStore, resource, lineItem, index, data, isOpen }} />
          <WebformProductLineOverrideDelivery {...{ webformStore, resource, lineItem, index }} />
        </div>
        <CardFooter>
          <div className="d-flex justify-content-between py-1">
            <Button type="Button" className="me-4" onClick={handleCancel} text>
              {resource.Cancel}
            </Button>
            <Button type="button" icon="add" onClick={handleSave}>
              {resource.Save}
            </Button>
          </div>
        </CardFooter>
      </Collapse>
    </Card>
  )
})
