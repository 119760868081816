import React from 'react'

import AsyncSelect from 'react-select/async'
import cx from 'classnames'
import Select from 'react-select'

export default ({
  inputRef,
  placeholder,
  className,
  serverSide,
  optionValueKey,
  optionLabelKey,
  closeMenuOnSelect = true,
  ...rest
}) => {
  const SelectComponent = serverSide ? AsyncSelect : Select

  const getOptionValue = item => (optionValueKey ? item[optionValueKey] : item.value)
  const getOptionLabel = item => (optionLabelKey ? item[optionLabelKey] : item.label)

  return (
    <SelectComponent
      inputRef={inputRef}
      className={cx('react-select', className)}
      classNamePrefix="react-select"
      placeholder={placeholder || ' '}
      {...(optionValueKey ? { getOptionValue } : {})}
      {...(optionLabelKey ? { getOptionLabel } : {})}
      closeMenuOnSelect={closeMenuOnSelect}
      {...rest}
    />
  )
}
