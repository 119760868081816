import React from 'react'

export default ({ data }) => {
  const formRef = React.useRef()
  const { catalogInfo } = data

  React.useEffect(() => {
    formRef.current.submit()
  }, [])

  const renderClientGa = (item, newIndex) => {
    return (
      <React.Fragment>
        <input name={`NEW_ITEM-LINE[${newIndex}]`} />
        <input name={`NEW_ITEM-DESCRIPTION[${newIndex}]`} value={item.description} />
        <input name={`NEW_ITEM-LONGTEXT[${newIndex}]`} value={item.longText} />
        <input name={`NEW_ITEM-QUANTITY[${newIndex}]`} value={item.quantity} />
        <input name={`NEW_ITEM-UNIT[${newIndex}]`} value={item.unit} />
        <input name={`NEW_ITEM-PRICE[${newIndex}]`} value={item.price} />
        <input name={`NEW_ITEM-PRICEUNIT[${newIndex}]`} value={1} />
        <input name={`NEW_ITEM-CURRENCY[${newIndex}]`} value={item.currency} />
        <input name={`NEW_ITEM-LEADTIME[${newIndex}]`} value={item.leadTime} />
        <input name={`NEW_ITEM-VENDORMAT[${newIndex}]`} value={item.vendorMat} />
        <input name={`NEW_ITEM-MATGROUP[${newIndex}]`} value={item.matGroup} />
      </React.Fragment>
    )
  }

  return (
    <form ref={formRef} method="POST" action={catalogInfo.hookURL} className="collapse">
      <input name="OCI_VERSION" value="4.0" />
      <input name="HOOK_URL" value={catalogInfo.hookURL} />
      {catalogInfo.lines.map((item, index) => {
        const newIndex = index + 1

        if (catalogInfo.isClientGalapagos) {
          return renderClientGa(item, newIndex)
        }

        return (
          <React.Fragment key={newIndex}>
            <input name={`NEW_ITEM-VENDORMAT[${newIndex}]`} value={item.vendorMat} />
            <input name={`NEW_ITEM-PRICEUNIT[${newIndex}]`} value="1" />
            <input name={`NEW_ITEM-CURRENCY[${newIndex}]`} value={item.currency} />
            <input name={`NEW_ITEM-LEADTIME[${newIndex}]`} value={item.leadTime} />
            <input name={`NEW_ITEM-PRICE[${newIndex}]`} value={item.price} />
            <input name={`NEW_ITEM-QUANTITY[${newIndex}]`} value={item.quantity} />
            {!catalogInfo.isClientVrt && <input name={`NEW_ITEM-MATGROUP[${newIndex}]`} value={item.matGroup} />}
            {!catalogInfo.isClientElia && (
              <React.Fragment>
                <input name={`NEW_ITEM-UNIT[${newIndex}]`} value="PCE" />
                <input name={`NEW_ITEM-EXT_PRODUCT_ID[${newIndex}]`} value={item.extProductId} />
                <input name={`NEW_ITEM-ATTACHMENT[${newIndex}]`} value={item.attachment} />
              </React.Fragment>
            )}
            {catalogInfo.isClientEan && <input name={`NEW_ITEM-ZZ_LONGTEXT[${newIndex}]`} value={item.longText} />}
            {!catalogInfo.isClientEan && !catalogInfo.isClientElia && (
              <input name={`NEW_ITEM-LONGTEXT_1:132[${newIndex}]`} value={item.longText} />
            )}
            <input name={`NEW_ITEM-DESCRIPTION[${newIndex}]`} value={item.description} />
            {catalogInfo.isClientEan && (
              <React.Fragment>
                <input name={`NEW_ITEM-ZZ_SiteCode[${newIndex}]`} value={item.siteCode} />
                <input name={`NEW_ITEM-ZZ_AddressStreet[${newIndex}]`} value={item.addressStreet} />
                <input name={`NEW_ITEM-ZZ_AddressNumber[${newIndex}]`} value={item.addressNumber} />
                <input name={`NEW_ITEM-ZZ_AddressBox[${newIndex}]`} value={item.addressBox} />
                <input name={`NEW_ITEM-ZZ_AddressPostalCode[${newIndex}]`} value={item.addressPostalCode} />
                <input name={`NEW_ITEM-ZZ_AddressCity[${newIndex}]`} value={item.addressCity} />
                <input name={`NEW_ITEM-ZZ_AddressCountryName[${newIndex}]`} value={item.addressCountryName} />
              </React.Fragment>
            )}
            {catalogInfo.isClientVrt && (
              <React.Fragment>
                <input name={`NEW_ITEM-NEW_ITEM-MATNR[${newIndex}]`} value={item.matNr} />
                <input name={`NEW_ITEM-CUST_FIELD1[${newIndex}]`} value={item.proposalDetailsURL} />
                <input name={`NEW_ITEM-CUST_FIELD2[${newIndex}]`} value={item.deliveryAddress} />
              </React.Fragment>
            )}
            {catalogInfo.isClientElia && (
              <React.Fragment>
                <input name={`NEW_ITEM-SERVICE[${newIndex}]`} value={item.service} />
                <input name={`NEW_ITEM-UNIT[${newIndex}]`} value={item.unitOverride} />
                <input name={`NEW_ITEM-URL[${newIndex}]`} value={item.proposalDetailsURL} />
                <input name={`NEW_ITEM-COMPANY_CODE[${newIndex}]`} value={item.companyCode} />
                <input name={`NEW_ITEM-LONGTEXT[${newIndex}]`} value={item.longText} />

                <input name={`NEW_ITEM-CITY1[${newIndex}]`} value={item.addressCity} />
                <input name={`NEW_ITEM-POST_CODE1[${newIndex}]`} value={item.addressPostalCode} />
                <input name={`NEW_ITEM-STREET[${newIndex}]`} value={item.addressStreet} />
                <input name={`NEW_ITEM-HOUSE_NUM1[${newIndex}]`} value={item.addressNumber} />
                <input name={`NEW_ITEM-COUNTRY[${newIndex}]`} value={item.addressCountryIsoCode} />

                <input name={`NEW_ITEM-ADDRNUMBER[${newIndex}]`} value={item.siteCode} />
                <input name={`NEW_ITEM-ATTACHMENT[${newIndex}]`} value={item.attachmentUrl} />
                <input name={`NEW_ITEM-ATTACHMENT_TITLE[${newIndex}]`} value={item.attachmentTitle} />
              </React.Fragment>
            )}
            {catalogInfo.isClientNip && (
              <React.Fragment>
                <input name={`NEW_ITEM-VENDOR[${newIndex}]`} value={item.vendor} />
              </React.Fragment>
            )}
             {catalogInfo.isClientIss && (
              <React.Fragment>
                <input name={`NEW_ITEM-CUST_FIELD3[${newIndex}]`} value={item.companyCode} />
                <input name={`NEW_ITEM-CUST_FIELD4[${newIndex}]`} value={item.projectCode} />
              </React.Fragment>
            )}
          </React.Fragment>
        )
      })}
      <button type="submit" />
    </form>
  )
}
