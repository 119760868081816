import React from 'react'

import { Spinner } from 'reactstrap'
import { useFormContext } from 'react-hook-form'

import { FlexTableCell } from 'common/FlexTable'
import { formatCurrency } from 'utils/formatters'
import { useStores } from 'stores'

export default ({ resource, index }) => {
  const { webshopPricingStore } = useStores()
  const { getValues } = useFormContext()

  const {
    ItemPricingTier: { loading: pricingTierLoading },
  } = webshopPricingStore

  const lineItem = React.useMemo(() => getValues(`items.${index}`), [getValues, index])

  return (
    <React.Fragment>
      {pricingTierLoading && (
        <FlexTableCell className="d-flex align-items-start justify-content-xl-end col-xl-4 col-12">
          <Spinner color="secondary" type="grow" size="sm" className="m-3" />
        </FlexTableCell>
      )}
      {!pricingTierLoading && (
        <React.Fragment>
          <FlexTableCell className="d-flex align-items-start justify-content-xl-end col-xl-2 col-12 fs-12">
            <span className="d-xl-none d-inline-block col-sm-4 col-6 fw-bold me-2">
              {resource.PricePerPieceExclVAT}:
            </span>
            {lineItem && <span>{formatCurrency(lineItem.amount)}</span>}
          </FlexTableCell>
          <FlexTableCell className="d-flex align-items-start justify-content-xl-end col-xl-2 col-12 fs-12">
            <span className="d-xl-none d-inline-block col-sm-4 col-6 fw-bold me-2">{resource.SubTotalExclVAT}:</span>
            {lineItem && <span>{formatCurrency(lineItem.subTotal)}</span>}
          </FlexTableCell>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
