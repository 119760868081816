import React from 'react'

import { Alert } from 'reactstrap'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { FlexTable, FlexTableCell, FlexTableRow } from 'common/FlexTable'
import { groupBy, sort } from 'utils/array'
import { ROUTES } from 'definitions'
import { validateSpecialCharacters } from 'utils/validators'
import ButtonIcon from 'common/ButtonIcon'
import Checkbox from 'common/Checkbox'
import Icon from 'common/Icon'
import Input from 'common/Forms/Input'
import NumberControl from 'common/Forms/NumberControl'
import ReactSelect from 'common/Forms/ReactSelect'

import ButtonFavourite from 'components/Webshop/WebshopFavourites/ButtonFavourite'
import CartHasSupplierDelivery from './CartHasSupplierDelivery'
import CartTableCategory from './CartTableCategory'
import CartTableItemPrice from './CartTableItemPrice'
import CartTableTransport from './CartTableTransport'
import ItemHasStockStop from '../../ItemHasStockStop'

export default ({
  resource,
  session,
  settings,
  cart,
  fields,
  categories,
  glAccounts,
  disabled,
  updateCart,
  onQuantityChange,
  onMultiAddressCountChange,
  onDeleteCartItem,
  onEnableMultiAddress,
  onBeforeChange,
}) => {
  const supplierGroup = React.useMemo(() => {
    const fieldsIndexMapped = fields.map((item, index) => ({ ...item, index }))
    const grouped = groupBy(fieldsIndexMapped, 'supplierId')
    const mapped = Object.values(grouped).map(items => {
      const { supplierId, supplierName } = items[0]
      return {
        supplierId,
        supplierName,
        items: sort(items, 'itemName'),
      }
    })
    const sorted = sort(mapped, 'supplierName')

    return sorted
  }, [fields])

  return (
    <FlexTable
      className="cart-table pe-lg-2 col-lg col-12 mb-xl-0 mb-5"
      headerClassName="fs-12 fw-normal border-top"
      breakpoint="xl"
      columns={[
        { className: 'col-5 ps-action-space align-items-end', Header: resource.Product },
        { className: 'col align-items-end', Header: resource.Quantity },
        { className: 'col-2 justify-content-end align-items-end text-end', Header: resource.PricePerPieceExclVAT },
        { className: 'col-2 justify-content-end align-items-end text-end', Header: resource.SubTotalExclVAT },
      ]}
    >
      {supplierGroup.map((supplier, supplierIndex) => {
        const isStripe = !(supplierIndex % 2)

        return supplier.items.map((field, supplierItemIndex) => {
          const index = field.index // must follow the indexes generated by useFieldArray
          const hasAccess = !!field.itemName
          const isLastSupplierItemIndex = supplierItemIndex === supplier.items.length - 1

          return (
            <React.Fragment key={field.id}>
              <FlexTableRow className={cx('border-0 pt-3', isStripe && 'table-striped-bg')}>
                <FlexTableCell className="col-xl-5 col-12 pb-xl-0 ps-xl-0">
                  <div className="d-flex">
                    <div className="d-flex flex-wrap flex-column pe-2">
                      <ButtonFavourite resource={resource} itemId={field.itemId} favouriteId={field.favouriteId} />
                      <ButtonIcon
                        icon="delete"
                        color="primary"
                        title={resource.RemoveFromCart}
                        iconClassName="md-22"
                        disabled={disabled}
                        onClick={() =>
                          onDeleteCartItem({
                            itemIndex: index,
                            itemId: field.itemId,
                            calculateDeliveryDate: !!field?.estimatedLeadTime,
                          })
                        }
                      />
                    </div>
                    {!hasAccess && (
                      <Alert color="primary" className="p-3">
                        {resource.ProductAccessDeniedByUserGroup}
                      </Alert>
                    )}
                    {hasAccess && (
                      <div className="d-flex flex-wrap align-items-start">
                        <Input type="hidden" name={`items.${index}.itemIndex`} />
                        <div
                          className={cx('product-image col-auto mb-1', !field.imageUrl && 'no-image')}
                          style={field.imageUrl ? { backgroundImage: `url('${field.imageUrl}')` } : {}}
                        >
                          {!field.imageUrl && <Icon icon="image" />}
                        </div>
                        <div className={cx('col', !!field.imageUrl && 'ms-3')}>
                          <Link
                            to={`${ROUTES.WEBSHOP_PRODUCT}/${field.itemId}`}
                            className="fw-bold fs-12 webshop-text-color"
                          >
                            {field.itemName}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </FlexTableCell>
                {hasAccess && (
                  <React.Fragment>
                    <FlexTableCell className="d-flex align-items-start col-xl col-12 pb-xl-0 flex-wrap">
                      <span className="d-xl-none d-inline-block col-sm-4 col-auto fw-bold me-2 fs-12">
                        {resource.Quantity}:
                      </span>
                      <NumberControl
                        name={`items.${index}.quantity`}
                        rules={{ required: resource.Required }}
                        defaultValue={field.quantity}
                        step={field.quantityStep || 1}
                        min={field.minimumOrderQty}
                        max={field.stock}
                        formGroup
                        formClassName="col-xl col-md-4 col-sm-6 col-8 mb-0"
                        disabled={disabled}
                        onChange={newQuantity => onQuantityChange(index, field, newQuantity)}
                        onBeforeChange={newQuantity => onBeforeChange(index, newQuantity, field.stock)}
                      />
                      <ItemHasStockStop name={`items.${index}.itemStockStop`} resource={resource} stock={field.stock} />
                    </FlexTableCell>
                    <CartTableItemPrice resource={resource} index={index} />
                  </React.Fragment>
                )}
              </FlexTableRow>
              {hasAccess && (
                <React.Fragment>
                  <FlexTableRow className={cx(isStripe && 'table-striped-bg align-items-start')}>
                    <FlexTableCell className="col-xl-5 col-12 pt-xl-0 pb-xl-2 pb-0 ps-action-space flex-wrap">
                      <CartTableCategory
                        index={index}
                        resource={resource}
                        showSubCategories={session.shouldSelectSubcategory}
                        categories={categories}
                        field={field}
                        disabled={disabled}
                        updateCart={updateCart}
                      />
                      {session.enableGLAccount && (
                        <ReactSelect
                          name={`items.${index}.glAccount`}
                          rules={{ required: resource.Required }}
                          label={resource.GLAccount}
                          placeholder={resource.SelectGLAccount}
                          options={glAccounts}
                          optionValueKey="id"
                          optionLabelKey="glAccount"
                          isClearable
                          isDisabled={disabled}
                          formGroup
                          formClassName="w-100"
                          labelClassName="d-xl-none mb-1"
                          onChange={() => updateCart()}
                        />
                      )}
                      <CartHasSupplierDelivery index={index} resource={resource} />
                    </FlexTableCell>
                    <FlexTableCell className="col pt-0 align-items-start flex-column">
                      <Input
                        name={`items.${index}.remarks`}
                        rules={{
                          specialCharacters: value => {
                            return (
                              settings.allowSpecialCharacters ||
                              validateSpecialCharacters(value, resource.ValidateCartItemRemarks)
                            )
                          },
                        }}
                        type="textarea"
                        rows="3"
                        disabled={disabled}
                        formGroup
                        label={resource.Remarks}
                        placeholder={resource.Remarks}
                        formClassName="col-12"
                        labelClassName="d-xl-none"
                        onChange={() => updateCart()}
                      />
                      {settings.allowMultiAddress && (
                        <Checkbox
                          defaultChecked={cart.isMultiAddress}
                          label={resource.MultipleDeliveryAddressLineItem}
                          formGroup
                          onClick={event => onEnableMultiAddress(index, field.itemId, event.target.checked)}
                        />
                      )}
                    </FlexTableCell>
                  </FlexTableRow>
                  {isLastSupplierItemIndex && (
                    <CartTableTransport
                      index={index}
                      resource={resource}
                      settings={settings}
                      categories={categories}
                      cart={cart}
                      supplierId={supplier.supplierId}
                      estimatedDeliveryDate={field.estimatedDeliveryDate}
                      isStripe={isStripe}
                      disabled={disabled}
                      showSubCategories={session.shouldSelectSubcategory}
                      onMultiAddressCountChange={onMultiAddressCountChange}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )
        })
      })}
    </FlexTable>
  )
}
