import React from 'react'

export default ({ data = {} }) => {
  const formRef = React.useRef()
  const { punchoutURL, cxml } = data

  React.useEffect(() => {
    formRef.current.submit()
  }, [])

  return (
    <form ref={formRef} method="POST" action={punchoutURL} className="collapse">
      <input name="cxml-base64" value={cxml} />
      <button type="submit" />
    </form>
  )
}
