import axios from 'axios'

import { IS_AUTHORIZED, OFFLINE_MESSAGE, ROUTES } from 'definitions'
import { jsonToQueryString } from 'utils/formatters'

export default class Api {
  constructor(options = {}) {
    this.options = options
    axios.defaults.withCredentials = true
    this.Instance = axios.create({
      baseURL: options.apiUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    this.Instance.interceptors.response.use(this.responseParser, this.errorParser)
  }

  setHeaders() {}

  get(url, payload = {}, config) {
    this.setHeaders()
    return this.Instance.get(
      `${url}${jsonToQueryString({
        ...payload,
      })}`,
      config
    )
  }

  post(url, payload, config) {
    this.setHeaders()
    return this.Instance.post(`${url}`, payload || {}, config)
  }

  put(url, payload, config) {
    this.setHeaders()
    return this.Instance.put(`${url}`, payload || {}, config)
  }

  patch(url, payload, config) {
    this.setHeaders()
    return this.Instance.patch(`${url}`, payload || {}, config)
  }

  delete(url, config) {
    this.setHeaders()
    return this.Instance.delete(`${url}`, config)
  }

  responseParser = response => {
    return {
      data: response.data,
      error: null,
    }
  }

  errorParser = err => {
    const defaultErrorMessage = 'Your request cannot be completed as of now'
    let ignoreGlobalMessage
    let result

    if (err) {
      const response = err.response
      const responseStatus = response?.data?.responseStatus
      const errorMessage = responseStatus?.message || response?.statusText || defaultErrorMessage
      ignoreGlobalMessage = response?.config?.ignoreGlobalMessage || false

      if (response) {
        // eslint-disable-next-line default-case
        switch (response.status) {
          case 401: {
            const isAuthorized = sessionStorage.getItem(IS_AUTHORIZED)
            sessionStorage.removeItem(IS_AUTHORIZED)
            if (isAuthorized) {
              this.onSessionExpired && this.onSessionExpired()
              return Promise.reject(err)
            }
            break
          }
          case 403: {
            const loginProvider = responseStatus?.meta?.loginProvider
            if (loginProvider && this.onRedirectToLoginProvider) {
              this.onRedirectToLoginProvider(loginProvider, errorMessage)
              return Promise.reject(err)
            }
            break
          }
          case 502:
          case 504:
            result = { data: {}, error: 'Unable to connect to server. Please try again later.' }
            break
          case 503:
            sessionStorage.setItem(OFFLINE_MESSAGE, errorMessage)
            window.location = ROUTES.OFFLINE
            return Promise.reject(err)
        }
      } else {
        if (!navigator.onLine) {
          result = { data: {}, error: 'Your are currently offline. Please connect to the internet.' }
        }
      }

      if (!result) {
        result = { data: {}, error: errorMessage, status: err?.response?.status }
      }
    } else {
      result = { data: {}, error: defaultErrorMessage }
    }

    if (!ignoreGlobalMessage && this.onApiError) {
      this.onApiError(result)
    }
    return result
  }
}
