import React from 'react'

import { Badge } from 'reactstrap'

import { formatCurrency, formatDate } from 'utils/formatters'
import { padLeft } from 'utils'
import Button from 'common/Button'
import Card from 'common/Card'
import Modal from 'common/Modal'
import ReadOnlyAttachment from 'common/ReadOnlyAttachment'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({ resource, commonResource, caseNumber, data, countries, onClose }) => {
  const attachments = data.attachments || []
  const delivery = data.delivery

  const country =
    delivery && !delivery.isMultiAddress && countries
      ? countries.find(item => item.code === delivery.deliveryAddress.country.code)
      : null

  return (
    <Modal
      isOpen
      size="xl"
      title={`Case ${caseNumber} - line ${padLeft(data.lineIndex, 1)}`}
      actions={
        <Badge color="primary" className="me-5">
          {resource.POReference}: {data.externalPoReference}
        </Badge>
      }
      footer={
        <Button type="button" text color="primary" onClick={onClose}>
          {commonResource.Cancel}
        </Button>
      }
      footerClassName="justify-content-start"
      onClose={onClose}
    >
      <Card title={resource.ProductInformation} fieldset>
        <ReadOnlyField label={resource.ProductDescription} value={data.description} formGroup horizontal />
        <ReadOnlyField
          label={resource.QuantityUnit}
          value={`${data.quantity.amount} ${data.quantity.unit}`}
          formGroup
          horizontal
        />
        <ReadOnlyField
          label={resource.SupplierPrice}
          value={data.supplier ? formatCurrency(data.supplier.value) : '-'}
          formGroup
          horizontal
        />
        <ReadOnlyField
          label={resource.TotalPrice}
          value={data.totalPrice ? formatCurrency(data.totalPrice.value) : '-'}
          formGroup
          horizontal
        />
        <ReadOnlyField label={resource.Supplier} value={data.supplier && data.supplier.name} formGroup horizontal />
        <ReadOnlyField
          label={resource.ExtendedDescription}
          value={data.extendedDescription}
          className="word-wrap-break-word"
          formGroup
          horizontal
        />
        <ReadOnlyField
          label={resource.Remarks}
          value={data.remarks}
          className="word-wrap-break-word"
          formGroup
          horizontal
        />
        <ReadOnlyAttachment
          label={resource.Attachments}
          value={attachments}
          noAttachment={resource.NoAttachments}
          formGroup
          horizontal
        />
      </Card>

      {delivery && (
        <Card title={resource.DeliveryInformation} fieldset>
          {delivery.isMultiAddress && (
            <React.Fragment>
              <ReadOnlyField
                label={resource.PreferredDate}
                value={formatDate(delivery.deliveryDate)}
                formGroup
                horizontal
              />
              {delivery.requester && (
                <ReadOnlyField label={resource.DeliverTo} value={delivery.requester.displayname} formGroup horizontal />
              )}
              <ReadOnlyField label={resource.Address} value={resource.MultipleDeliveryAddress} formGroup horizontal />
              <ReadOnlyField label={resource.Location} formGroup horizontal />
              <ReadOnlyField label={resource.Country} formGroup horizontal />
            </React.Fragment>
          )}
          {!delivery.isMultiAddress && (
            <React.Fragment>
              <ReadOnlyField
                label={resource.PreferredDate}
                value={formatDate(delivery.deliveryDate)}
                formGroup
                horizontal
              />
              {delivery.requester && (
                <ReadOnlyField label={resource.DeliverTo} value={delivery.requester.displayname} formGroup horizontal />
              )}
              <ReadOnlyField
                label={resource.Address}
                value={`${delivery.deliveryAddress.street}
                    ${delivery.deliveryAddress.number} ${delivery.deliveryAddress.box}`}
                className="word-wrap-break-word"
                formGroup
                horizontal
              />
              <ReadOnlyField
                label={resource.Location}
                value={`${delivery.deliveryAddress.postalCode}
                    ${delivery.deliveryAddress.city}`}
                formGroup
                horizontal
              />
              <ReadOnlyField
                label={resource.Country}
                value={country ? country.name : delivery.deliveryAddress.country.code}
                formGroup
                horizontal
              />
            </React.Fragment>
          )}
        </Card>
      )}
    </Modal>
  )
}
