import React from 'react'

import { useWatch } from 'react-hook-form'
import Input from 'common/Forms/Input'

export default ({ name, resource, isRequiredCustomField }) => {
  const watchProductTextAreaCustomField = useWatch({ name })
  return watchProductTextAreaCustomField ? (
    <Input name={`${name}.input`} type="textarea" rules={{ required: isRequiredCustomField && resource.Required }} />
  ) : null
}
