import moment from 'moment'

import { ADDRESS_TYPE, BE_COUNTRY, DELIVER_TO } from 'definitions'
import { formatDate } from 'utils/formatters'
import BaseStore from 'stores/base'

export default class PurchaseRequestStore extends BaseStore {
  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options
  }

  preparePrLine({ prLine, prTabData, action, sessionClientSiteId }) {
    const defaultClientSite = prTabData.clientSites.find(item => item.clientSiteId === sessionClientSiteId)

    if (['edit', 'copy'].includes(action)) {
      const { attachments, category, deliveryInfo = {} } = prLine
      const { requester } = deliveryInfo
      const subCategory = prTabData.subcategories.find(item => item.name === category.subcategory)
      const isDeliveryToEmployee = !!requester && !!requester.requesterId

      return {
        ...prLine,
        attachments: action === 'edit' ? attachments : [],
        category: {
          id: category.id,
          name: category.name,
        },
        subCategory: subCategory
          ? {
              id: subCategory.id,
              name: subCategory.name,
            }
          : null,
        deliveryInfo: {
          ...deliveryInfo,
          clientSite: defaultClientSite,
          deliveryDate: moment(deliveryInfo.deliveryDate).toDate(),
          deliverTo: isDeliveryToEmployee ? 'employee' : 'other',
          requester: requester.requesterId
            ? {
                key: requester.requesterId,
                value: requester.displayname,
              }
            : null,
          otherRequester: requester.displayname,
        },
      }
    } else {
      return {
        deliveryInfo: {
          deliveryDate: moment().add(1, 'days').toDate(),
          clientSite: defaultClientSite,
          deliverTo: 'other',
          deliveryAddress: {
            country: BE_COUNTRY,
          },
        },
      }
    }
  }

  submitPrLine({ caseId, values, settings, isEdit }) {
    const { attachments, category, subCategory, glAccount, deliveryInfo, quantity, ...rest } = values
    const { requester } = deliveryInfo
    const isDeliveryToEmployee = deliveryInfo.deliverTo === DELIVER_TO.EMPLOYEE
    const deliveryAddress =
      deliveryInfo.addressType === ADDRESS_TYPE.CLIENT
        ? deliveryInfo.clientAddress.address
        : deliveryInfo.deliveryAddress

    const payload = {
      category: category.name,
      ...(settings.shouldSelectSubcategory && subCategory ? { subcategory: subCategory.name } : {}),
      ...(settings.enableGLAccount ? { glAccount } : {}),
      quantity: {
        amount: parseFloat(quantity.amount),
        unit: quantity.unit,
      },
      attachmentFileIds: attachments?.added?.map(item => item.fileId) || [],
      deliveryInfo: {
        deliveryDate: formatDate(deliveryInfo.deliveryDate),
        deliveryAddress,
        requester: isDeliveryToEmployee
          ? {
              requesterId: requester.key,
              displayname: requester.value,
            }
          : {
              displayname: deliveryInfo.otherRequester,
            },
      },
      ...rest,
    }

    return this.request(this.CRUD, () => {
      const action = isEdit ? this.service.updatePrLine : this.service.createPrLine
      return action(caseId, payload, { ignoreGlobalMessage: true })
    })
  }

  async deletePrLine(payload) {
    return await this.request(this.CRUD, () => this.service.deletePrLine(payload))
  }
}
