import React from 'react'

import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'

import { useStores } from 'stores'
import CheckPunchoutSession from 'components/Core/CheckPunchoutSession'
import WebshopHeader from 'components/Webshop/WebshopHeader'

export default observer(() => {
  const { authStore, globalMessageStore, webshopStore, pageResourceStore } = useStores()
  const [allowWebshop, setAllowWebshop] = React.useState(false)

  const {
    AppSession: { session },
  } = authStore
  const {
    Config: { loading: configLoading },
  } = webshopStore
  const {
    PageResource: { webshop: resource, loading: pageResourceLoading },
  } = pageResourceStore

  React.useEffect(() => {
    pageResourceStore.get('webshop', 'oazowebshop')
  }, [pageResourceStore])

  React.useEffect(() => {
    if (session.userGroupCode) {
      webshopStore.getConfiguration({
        languageCode: session.uiLanguageSetting.toLowerCase(),
        includeCategory: true,
      })
    }
  }, [webshopStore, session])

  React.useEffect(() => {
    if (resource) {
      if (session.userGroupCode) {
        const checkPermission = async () => {
          const result = await webshopStore.checkWebshopPermission({
            languageCode: session.uiLanguageSetting.toLowerCase(),
          })
          if (result?.success) {
            setAllowWebshop(true)
          } else {
            globalMessageStore.show({
              title: resource.AccessDeniedByUserGroupTitle,
              message: resource.AccessDeniedByUserGroup,
              color: 'info',
            })
          }
        }
        checkPermission()
      } else {
        globalMessageStore.show({
          title: resource.EmptyUserGroupTitle,
          message: resource.EmptyUserGroup,
          color: 'info',
        })
      }
    }
  }, [globalMessageStore, webshopStore, session, resource])

  const loading = configLoading || pageResourceLoading
  const showWebshop = !loading && !!resource && !!session.userGroupCode && allowWebshop

  return (
    showWebshop && (
      <React.Fragment>
        <CheckPunchoutSession
          message={
            <span>
              <strong>{resource.Warning}</strong> {resource.PunchoutExpireDisableCheckout}
            </span>
          }
        />
        <WebshopHeader />
        <Outlet />
      </React.Fragment>
    )
  )
})
