import React from 'react'

import { observer } from 'mobx-react'
import { useFormContext, useFormState } from 'react-hook-form'

import { PRA_ACTION, PRA_STATUS } from 'definitions'
import Button from 'common/Button'
import MessageList from 'common/MessageList'

export default observer(({ praStore, resource, disabled, isCreatorOrAdmin, onClose, onPraAction, onSubmit }) => {
  const submitButtonRef = React.useRef()
  const [submitType, setSubmitType] = React.useState()
  const { getValues, setValue, watch } = useFormContext()
  const { errors } = useFormState()

  const actionWatch = watch('action')

  const {
    CRUD: { data = {}, settings = {}, submitting },
    Approvers: { loading: praLoading },
  } = praStore

  const hasErrors = Object.keys(errors).length > 0
  const showRelaunchButton = data.status === PRA_STATUS.REJECTED.id || data.status === PRA_STATUS.ABORTED.id
  const showSaveButtons = data.status === PRA_STATUS.NONE.id || data.status === PRA_STATUS.UNSTARTED.id
  const showAbortButton =
    data.id > 0 &&
    (data.status === PRA_STATUS.UNSTARTED.id || data.status === PRA_STATUS.PENDING.id) &&
    isCreatorOrAdmin
  const showApproveButton = actionWatch === 'approve'
  const showRejectButton = actionWatch === 'reject'
  const showAskQuestionButton = actionWatch === 'question'
  const showAnswerButton = actionWatch === 'answer'
  const showReassignButton = actionWatch === 'reassign'

  const showActionButtons = React.useMemo(() => {
    if (!data.archive) {
      if (isCreatorOrAdmin) {
        return true
      } else {
        return !data.actions.some(item => item.name === 'view')
      }
    }
    return false
  }, [data.archive, data.actions, isCreatorOrAdmin])

  const handleSubmitAction = action => {
    switch (action) {
      case 'save':
        setValue('action', PRA_ACTION.SAVE)
        break
      case 'launch':
        setValue('action', PRA_ACTION.APPROVE_AND_LAUNCH)
        break
      default:
        setValue('action', action)
        break
    }
    setSubmitType(action)

    if (settings.allowAdministratorOverride) {
      switch (action) {
        case 'reassign':
        case 'answer':
        case 'question':
        case 'reject':
        case 'archive':
          onSubmit(getValues())
          break
        default:
          submitButtonRef.current.click()
          break
      }
    } else {
      switch (action) {
        case 'archive':
          onSubmit(getValues())
          break
        default:
          submitButtonRef.current.click()
          break
      }
    }
  }

  const handleAbortPra = () => {
    setSubmitType(null)
    onPraAction('abort')
  }

  const handleRelaunchPra = () => {
    setSubmitType(null)
    onPraAction('relaunch')
  }

  return (
    <div className="d-flex col flex-column">
      <MessageList
        messages={hasErrors && [resource.CorrectErrors]}
        className="col-lg-auto col-12 p-3 mb-lg-2 mb-4 ms-lg-auto"
        hideClose
      />
      <button ref={submitButtonRef} type="submit" className="d-none" disabled={disabled || praLoading} />
      <div className="d-flex flex-lg-row flex-column-reverse col">
        <div className="d-lg-block d-flex flex-lg-row flex-column-reverse mt-lg-0 mt-8">
          <Button type="button" text disabled={disabled || praLoading} onClick={onClose}>
            {resource.Cancel}
          </Button>
          {showActionButtons && showAbortButton && (
            <Button
              type="button"
              color="danger"
              disabled={disabled || praLoading}
              className="ms-lg-4 ms-0 mb-lg-0 mb-4"
              onClick={handleAbortPra}
            >
              {resource.Abort}
            </Button>
          )}
        </div>
        <div className="d-lg-block d-flex flex-lg-row flex-column ms-lg-auto">
          {showActionButtons && (
            <React.Fragment>
              {showRelaunchButton && (
                <React.Fragment>
                  <Button
                    type="button"
                    color="warning"
                    disabled={disabled || praLoading}
                    className="ms-lg-4 ms-0 mb-lg-0 mb-4"
                    onClick={handleRelaunchPra}
                  >
                    {resource.Relaunch}
                  </Button>
                  <Button
                    type="button"
                    color="success"
                    icon="done"
                    disabled={disabled || praLoading}
                    loading={submitType === 'archive' && submitting}
                    className="ms-lg-4 ms-0 mb-lg-0 mb-4"
                    onClick={() => handleSubmitAction('archive')}
                  >
                    {resource.Archive}
                  </Button>
                </React.Fragment>
              )}
              {showSaveButtons && (
                <React.Fragment>
                  <Button
                    type="button"
                    disabled={disabled || praLoading}
                    loading={submitType === 'save' && submitting}
                    className="ms-lg-4 ms-0 mb-lg-0 mb-4"
                    onClick={() => handleSubmitAction('save')}
                  >
                    {resource.OnSaveAndClose}
                  </Button>
                  <Button
                    type="button"
                    icon="done"
                    color="success"
                    disabled={disabled || praLoading}
                    loading={submitType === 'launch' && submitting}
                    className="ms-lg-4 ms-0 mb-lg-0 mb-4"
                    onClick={() => handleSubmitAction('launch')}
                  >
                    {resource.SaveLaunchApproval}
                  </Button>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {showApproveButton && (
            <Button
              type="button"
              icon="done"
              color="success"
              disabled={disabled || praLoading}
              loading={submitType === 'approve' && submitting}
              className="ms-lg-4 ms-0 mb-lg-0 mb-4"
              onClick={() => handleSubmitAction('approve')}
            >
              {resource.Approve}
            </Button>
          )}
          {showRejectButton && (
            <Button
              type="button"
              icon="done"
              color="danger"
              disabled={disabled || praLoading}
              loading={submitType === 'reject' && submitting}
              className="ms-lg-4 ms-0 mb-lg-0 mb-4"
              onClick={() => handleSubmitAction('reject')}
            >
              {resource.Reject}
            </Button>
          )}
          {showAskQuestionButton && (
            <Button
              type="button"
              icon="done"
              color="success"
              disabled={disabled || praLoading}
              loading={submitType === 'question' && submitting}
              className="ms-lg-4 ms-0 mb-lg-0 mb-4"
              onClick={() => handleSubmitAction('question')}
            >
              {resource.AskQuestion}
            </Button>
          )}
          {showAnswerButton && (
            <Button
              type="button"
              icon="done"
              color="success"
              disabled={disabled || praLoading}
              loading={submitType === 'answer' && submitting}
              className="ms-lg-4 ms-0 mb-lg-0 mb-4"
              onClick={() => handleSubmitAction('answer')}
            >
              {resource.Answer}
            </Button>
          )}
          {showReassignButton && (
            <Button
              type="button"
              icon="done"
              color="success"
              disabled={disabled || praLoading}
              loading={submitType === 'reassign' && submitting}
              className="ms-lg-4 ms-0 mb-lg-0 mb-4"
              onClick={() => handleSubmitAction('reassign')}
            >
              {resource.Reassign}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
})
