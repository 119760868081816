import BaseService from 'services/base'

export default class ConnectionService extends BaseService {
  constructor(api) {
    const url = 'connections'
    super(api, url)
    this.api = api
    this.url = url
  }

  connect(id) {
    return this.post(`${id}/connect`)
  }
}
