import React from 'react'

import { observer } from 'mobx-react'
import { useStores } from 'stores'

import CardExport from 'components/Suppliers/CardExport'
import CardImport from 'components/Suppliers/CardImport'
import ImportErrorMessage from 'components/Suppliers/ImportErrorMessage'
import LayoutTitle from 'common/LayoutTitle'
import Toaster from 'common/Toaster'

export default observer(() => {
  const { authStore, pageResourceStore, clientSupplierStore, globalMessageStore } = useStores()

  const {
    AppSession: { session },
  } = authStore

  const {
    PageResource: { clientsuppliers: resource = {}, common: commonResource = {} },
  } = pageResourceStore

  React.useEffect(() => {
    pageResourceStore.get('clientsuppliers')
  }, [pageResourceStore])

  const handleImportFile = params => {
    Toaster.confirm({
      title: resource.ImportSupplier,
      message: resource.ImportSupplierQuestion,
      submitText: commonResource.Confirm,
      cancelText: commonResource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const payload = {
          fileId: params.fileId,
          clientId: session.clientId,
        }

        const response = await clientSupplierStore.importClientSupplier(payload)

        params.resetUploadCallback()
        closeModal()

        if (response.hasErrors) {
          globalMessageStore.show({
            title: resource.NoSuppliersImported,
            message: <ImportErrorMessage data={response} resource={resource} />,
            color: 'danger',
          })
        } else {
          globalMessageStore.show({
            title: resource.SuppliersImported,
            message: `${resource.SuppliersImported}: ${response.succesfullyImported}`,
            color: 'success',
          })
        }
      },
    })
  }

  return (
    <div id="supplier">
      {!pageResourceStore.PageResource.loading && (
        <div>
          <LayoutTitle title={resource.ManageSuppliers} />
          <div className="d-flex flex-lg-row flex-column-reverse mb-4">
            <div className="col-6 px-4">
              <CardImport
                resource={resource}
                importFileCallback={handleImportFile}
                disabled={clientSupplierStore.CRUD.submitting}
              />
            </div>
            <div className="col-6 px-4">
              <CardExport
                exportUrl={`/api/clientArea/supplier/export?clientId=${session.clientId}`}
                resource={resource}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
})
