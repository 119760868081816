import BaseService from 'services/base'

export default class ClientAreaService extends BaseService {
  constructor(api) {
    const url = 'clientArea/client'
    super(api, url)
    this.api = api
    this.url = url
  }

  getClientSites({ ...payload }) {
    return this.api.get('clientarea/sites/search', payload)
  }
}
