import React from 'react'

import { formatCurrency } from 'utils/formatters'
import { padLeft } from 'utils'
import Button from 'common/Button'
import Card from 'common/Card'
import ReactTable from 'common/ReactTable'

import { TableListItem } from 'components/Case/CaseDetail/TableList'

export default ({
  resource,
  quotation,
  onViewQuote,
  onEditQuote,
  onViewRfq,
  onEditRfq,
  onDeleteRfq,
  onDeleteQuote,
}) => {
  const { aggregatedLines, rfq, supplier, quote, status } = quotation

  const tableData = React.useMemo(() => aggregatedLines || [], [aggregatedLines])

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'prLineIndex',
        headerClassName: 'text-muted',
        className: '',
        width: 60,
        Cell: ({ value }) => padLeft(value, 1),
      },
      {
        Header: resource.Description,
        accessor: 'description',
        headerClassName: 'col',
        footerClassName: 'col',
        className: 'col',
        Cell: ({ value, row }) => (
          <div>
            <div title={value} className="word-break-break-word white-space-normal">
              {value}
            </div>
            {row.extendedDescription && (
              <div title={row.extendedDescription} className="word-break-break-word white-space-normal">
                {row.extendedDescription}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: resource.Qty,
        accessor: 'quantity.amount',
        headerClassName: 'col-1',
        footerClassName: 'col-1',
        className: 'col-1 border-start border-end',
        Cell: ({ value }) => (
          <div className="d-flex justify-content-between">
            <span>{value}</span>
            <span className="text-muted">x</span>
          </div>
        ),
      },
      {
        Header: resource.UnitPrice,
        accessor: 'unitPrice.value',
        headerClassName: 'col-2',
        footerClassName: 'col-2',
        className: 'col-2 border-end',
        Cell: ({ value }) => (
          <div className="d-flex justify-content-between">
            <span>{value ? formatCurrency(value) : '-'}</span>
            <span className="text-muted">=</span>
          </div>
        ),
        Footer: () => (
          <div className="text-end">
            <span className="fw-bold me-3">{resource.Total}</span>
            <span className="text-muted">=</span>
          </div>
        ),
      },
      {
        Header: resource.Subtotal,
        accessor: 'quoteLineSubTotal.value',
        headerClassName: 'col-2',
        footerClassName: 'active col-2',
        className: 'col-2',
        Cell: ({ value }) => (value ? formatCurrency(value) : '-'),
        Footer: ({ data }) => {
          const total = React.useMemo(
            () => data.reduce((sum, item) => (item.quoteLineSubTotal ? item.quoteLineSubTotal.value : 0) + sum, 0),
            [data]
          )
          return total ? (
            <span className="fw-bold">{formatCurrency(total)}</span>
          ) : (
            <span className="text-muted">-</span>
          )
        },
      },
    ],

    []
  )

  return (
    <TableListItem>
      <Card
        outline
        title={
          <div className="mb-lg-0 mb-1 white-space-break-spaces text-lg-start text-center">
            <strong>{`${resource.Quotation} ${padLeft(rfq.documentIndex, 1)}`}</strong> - {resource.Supplier}:{' '}
            {supplier.name}
          </div>
        }
        titleClassName="white-space-nowrap flex-lg-row flex-column"
        actionClassName="d-lg-block d-inline-flex flex-wrap"
        actions={
          <React.Fragment>
            {quote && status.quoteSent && (
              <Button
                color="primary"
                text
                size="sm"
                icon="visibility"
                onClick={() => onViewQuote({ ...quote, supplier })}
              >
                {resource.ViewQuote}
              </Button>
            )}
            {quote && !status.quoteSent && (
              <React.Fragment>
                <Button color="primary" text size="sm" icon="edit" onClick={() => onEditQuote(quote)}>
                  {resource.EditQuote}
                </Button>
                <Button color="danger" text size="sm" icon="clear" onClick={() => onDeleteQuote(quote)}>
                  {resource.DeleteQuote}
                </Button>
              </React.Fragment>
            )}
            {status.rfqSent && (
              <Button
                color={rfq.isDummy ? 'warning' : 'primary'}
                text
                size="sm"
                icon="visibility"
                onClick={() => onViewRfq(rfq, supplier)}
              >
                {rfq.isDummy ? resource.ViewDirectOrder : resource.ViewRfq}
              </Button>
            )}
            {!status.rfqSent && (
              <React.Fragment>
                <Button
                  color={rfq.isDummy ? 'warning' : 'primary'}
                  text
                  size="sm"
                  icon="visibility"
                  onClick={() => onEditRfq({ id: rfq.id })}
                >
                  {rfq.isDummy ? resource.EditDirectOrder : resource.EditRfq}
                </Button>
                <Button color="danger" text size="sm" icon="clear" onClick={() => onDeleteRfq(rfq)}>
                  {resource.DeleteRfq}
                </Button>
              </React.Fragment>
            )}
          </React.Fragment>
        }
      >
        <ReactTable columns={columns} data={tableData} showFooter responsive />
      </Card>
    </TableListItem>
  )
}
