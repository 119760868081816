import React from 'react'

import { useStores } from 'stores'
import WebshopProductList from 'components/Webshop/WebshopProductList'

export default () => {
  const { webshopStore } = useStores()

  React.useEffect(() => {
    webshopStore.IsFromSearch = true
  }, [webshopStore])

  return (
    <div id="webshop" className="col position-relative">
      <WebshopProductList />
    </div>
  )
}
