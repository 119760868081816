import React from 'react'

import FileAttachment from 'common/Forms/FileAttachment'

export default ({ resource, multiAddressAttachment, onChange }) => {
  const [hasAttachment, setHasAttachment] = React.useState(!!multiAddressAttachment?.attachments?.length)

  return (
    <div className="multiple-address mb-3">
      <h5 className="webshop-title-color fw-bolder mb-1">{resource.MultipleDeliveryAddress}</h5>
      <p className="mb-1">{resource.MultipleDeliveryAddressInstruction}</p>
      <FileAttachment
        name="multiAddressAttachment"
        rules={{
          validate: newValue => {
            if (!newValue.added?.length) {
              const notYetAdded = !newValue.attachments?.length
              const deleted = !!newValue.deleted?.length

              if (notYetAdded || deleted) {
                return resource.MultiAddressRequiredAttachment
              }
            }
            return true
          },
        }}
        defaultValue={multiAddressAttachment}
        buttonUploadLabel={hasAttachment ? resource.ReplaceAttachment : resource.UploadAttachment}
        hideNoAttachments
        singleAttachmentOnly
        promptDeleteAttachment
        formGroup
        className="flex-column-reverse align-items-start"
        formClassName="file-attachment mb-0"
        onChange={newValue => {
          setHasAttachment(!!newValue.added?.length)
          onChange()
        }}
      />
    </div>
  )
}
