import React from 'react'

import { Alert } from 'reactstrap'
import { observer } from 'mobx-react'
import { useFormContext } from 'react-hook-form'

import WebformProductLine from 'components/Webform/WebformProductLine'

export default observer(({ resource, fields, isOpenLineIndex, onExit, onSave, onEdit, onCopy }) => {
  const { getValues } = useFormContext()

  return (
    <div className="mb-4">
      {!fields.length && (
        <Alert color="secondary" className="text-center body-bg">
          {resource.AddProductLineMessage}
        </Alert>
      )}
      {fields.map((item, index) => {
        const lineItem = getValues(`webformLines.${index}`)
        if (!lineItem) return null

        return (
          <WebformProductLine
            key={item.id}
            resource={resource}
            lineItem={lineItem}
            index={index}
            isOpen={isOpenLineIndex === index}
            onExit={onExit}
            onSave={onSave}
            onEdit={onEdit}
            onCopy={onCopy}
          />
        )
      })}
    </div>
  )
})
