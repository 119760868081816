import React from 'react'

import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'

import { useAuthManager } from 'hooks/useAuthManager'
import { useStores } from 'stores'
import ContentLoader from 'common/ContentLoader'
import Icon from 'common/Icon'
import Punchout from 'components/Punchout'

import Cart from './Cart'

export default observer(() => {
  const { authStore, globalMessageStore, pageResourceStore, webshopCartStore, webshopPricingStore } = useStores()
  const [punchoutOptions, setPunchoutOptions] = React.useState({})
  const authManager = useAuthManager()
  const location = useLocation()

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { webshop: resource },
  } = pageResourceStore
  const {
    Table: { data, loading: tableLoading },
    Lookups: { loading: lookupsLoading },
  } = webshopCartStore

  React.useEffect(() => {
    webshopCartStore.getLookups({ clientId: session.clientId })
    loadWebshopCarts()
  }, [session, webshopCartStore, location.key])

  React.useEffect(() => {
    if (data?.length) {
      const items = {}

      data.forEach(cart => {
        cart.items.forEach(item => {
          items[`${cart.serviceContract}-${item.itemId}`] = {
            itemId: item.itemId,
            serviceContract: cart.serviceContract,
          }
        })
      })

      webshopPricingStore.fetchPricingTiers({
        clientId: session.clientId,
        items: Object.keys(items).map(key => items[key]),
      })
    }
  }, [webshopPricingStore, data, session.clientId])

  const loadWebshopCarts = () => {
    webshopCartStore.fetchTable({
      clientId: session.clientId,
      languageCode: session.uiLanguageSetting.toLowerCase(),
    })
    webshopCartStore.calculateCartQuantity()
  }

  const handlePunchout = async payload => {
    await authManager.logoutAfterPunchout()
    setPunchoutOptions({ isShow: !!payload, ...payload })
  }

  const loading = tableLoading || lookupsLoading

  return (
    <div key={location.key} id="webshop-cart">
      <div className="position-relative">
        <ContentLoader show={loading} className="align-items-start pt-6" />
        {!loading && !data.length && (
          <div className="d-flex align-items-center p-3">
            <div className="bg-icon p-3 me-2">
              <Icon icon="shopping_cart" filled className="text-white md-48" />
            </div>
            <span className="fs-18 fw-semibold">{resource.CartEmpty}</span>
          </div>
        )}
        {data.map(cart => (
          <Cart
            key={cart.id}
            webshopCartStore={webshopCartStore}
            globalMessageStore={globalMessageStore}
            cart={cart}
            resource={resource}
            reloadCart={loadWebshopCarts}
            onPunchout={handlePunchout}
          />
        ))}
      </div>
      {punchoutOptions.isShow && (
        <Punchout isShow punchoutType={punchoutOptions.punchoutType} data={punchoutOptions.punchoutData} />
      )}
    </div>
  )
})
