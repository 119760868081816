import React from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useSearchParams } from 'react-router-dom'

import { useStores } from 'stores'
import { WEBSHOP_DEFAULTS } from 'definitions'
import ButtonIcon from 'common/ButtonIcon'
import Card from 'common/Card'
import Input from 'common/Forms/Input'

export default observer(() => {
  const { webshopStore, pageResourceStore } = useStores()
  const [searchParams, setSearchParams] = useSearchParams()
  const methods = useForm()

  const priceFrom = searchParams.get('pricefrom')
  const priceTo = searchParams.get('priceto')
  const { getValues, setValue } = methods
  const {
    PageResource: { webshop: resource },
  } = pageResourceStore
  const {
    Config: {
      data: { priceFilterParameter = 0 },
    },
    Table: {
      data: { items = [], total = 0 },
    },
  } = webshopStore

  React.useEffect(() => {
    setValue('priceFrom', priceFrom || '')
    setValue('priceTo', priceTo || '')
  }, [setValue, priceFrom, priceTo])

  const filterByPrice = () => {
    let { priceFrom, priceTo } = getValues()

    // Set from to MIN_PRICE if empty
    if (priceFrom === '') {
      priceFrom = WEBSHOP_DEFAULTS.MIN_PRICE
    }

    // Set to to MAX_PRICE if empty
    if (priceTo === '') {
      priceTo = WEBSHOP_DEFAULTS.MAX_PRICE
    }

    // Set FROM and TO to NULL if non-numeric
    if (isNaN(priceFrom)) {
      priceFrom = WEBSHOP_DEFAULTS.MIN_PRICE
    }
    if (isNaN(priceTo)) {
      priceTo = WEBSHOP_DEFAULTS.MAX_PRICE
    }

    searchParams.set('pricefrom', priceFrom)
    searchParams.set('priceto', priceTo)
    searchParams.delete('page')
    setSearchParams(searchParams)
  }

  const handleOnKeyUp = event => {
    if (event.keyCode === 13) {
      filterByPrice()
    }
  }

  const isShowPriceFilter = !!items.length && priceFilterParameter > total

  return (
    isShowPriceFilter && (
      <Card title={resource.Prices} bodyClassName="py-3 ps-0 pl-6">
        <FormProvider {...methods}>
          <div className="d-flex align-items-center">
            <span>€ </span>
            <Input type="number" name="priceFrom" className="mx-2 text-end" onKeyUp={handleOnKeyUp} />
            <span>{resource.To}</span>
            <Input type="number" name="priceTo" className="mx-2 text-end" onKeyUp={handleOnKeyUp} />
            <ButtonIcon icon="arrow_forward" className="p-1" onClick={filterByPrice} />
          </div>
        </FormProvider>
      </Card>
    )
  )
})
