import React from 'react'

import { Link, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import cx from 'classnames'

import { ROUTES } from 'definitions'
import { titlelize } from 'utils'
import { useStores } from 'stores'
import CardCollapse from 'common/CardCollapse'

export default observer(() => {
  const { webshopCategoryStore, webshopStore } = useStores()
  const location = useLocation()
  const params = useParams()
  const categoryPath = params['*']

  const {
    TopLevelCategory: topLevelCategory,
    CategoryTree: {
      data: { categories = [] },
    },
  } = webshopCategoryStore

  const getTopLevelCategory = (categories, categoryPath) => {
    if (categoryPath) {
      const tokens = categoryPath.split('/')
      if (tokens?.length) {
        const topLevelCategoryName = tokens[0].toUpperCase()
        return categories.find(item => item.categoryPath.toUpperCase() === topLevelCategoryName)
      }
    }
    return null
  }

  React.useEffect(() => {
    if (location.pathname.toLowerCase().startsWith(ROUTES.WEBSHOP_CATALOG.toLowerCase())) {
      webshopCategoryStore.TopLevelCategory = getTopLevelCategory(categories, categoryPath)
    } else if (location.pathname.toLowerCase().startsWith(ROUTES.WEBSHOP_PRODUCT.toLowerCase())) {
      if (webshopStore.CRUD.categoryPath && !webshopCategoryStore.TopLevelCategory) {
        webshopCategoryStore.TopLevelCategory = getTopLevelCategory(categories, webshopStore.CRUD.categoryPath)
      }
    }
  }, [webshopCategoryStore, location.pathname, categoryPath, categories, webshopStore.CRUD.categoryPath])

  const renderSubCategories = (category, level = 0) => {
    if (category.subCategories && category.subCategories.length > 0) {
      level += 1
      return category.subCategories.map((subCategory, index) => {
        return (
          <React.Fragment key={index}>
            <div className="mb-2" style={{ marginLeft: (level - 1) * 12 }}>
              <Link
                to={`${ROUTES.WEBSHOP_CATALOG}/${subCategory.categoryPath}`}
                className={cx(
                  'category-link d-block fw-bold text-decoration-none',
                  cx(categoryPath === subCategory.categoryPath && 'active')
                )}
              >
                {titlelize(subCategory.name)}
              </Link>
            </div>
            <div className="mb-2" style={{ marginLeft: level * 12 }}>
              {renderSubCategories(subCategory, level)}
            </div>
          </React.Fragment>
        )
      })
    }
  }

  return (
    !!topLevelCategory?.subCategories?.length && (
      <CardCollapse
        defaultIsOpen
        title={
          <Link
            to={`${ROUTES.WEBSHOP_CATALOG}/${topLevelCategory.categoryPath}`}
            className="webshop-title-color text-hover-primary"
          >
            {titlelize(topLevelCategory.name)}
          </Link>
        }
        bodyClassName="py-3 px-2"
      >
        {renderSubCategories(topLevelCategory)}
      </CardCollapse>
    )
  )
})
