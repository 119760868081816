import React from 'react'

import { useFormContext } from 'react-hook-form'

import { useStores } from 'stores'
import Button from 'common/Button'
import Input from 'common/Forms/Input'
import Popup from 'common/Popup'
import ReactSelect from 'common/Forms/ReactSelect'

export default ({ resource, target, isOpen, isOther, address, toggle, disabled, updateCart }) => {
  const { globalDataStore } = useStores()
  const { trigger } = useFormContext()

  const handleSave = async () => {
    const result = await trigger([
      'deliveryInfo.address.street',
      'deliveryInfo.address.number',
      'deliveryInfo.address.postalCode',
      'deliveryInfo.address.city',
      'deliveryInfo.address.country',
    ])
    result && toggle()
  }

  return (
    <Popup
      isOpen={isOpen}
      target={target}
      title={resource.Address}
      className="cart-address-popup"
      headerClassName="bg-white"
      bodyClassName="d-flex flex-column"
      placement="auto-start"
      trigger="legacy"
      toggle={toggle}
    >
      <Input
        name="deliveryInfo.address.building"
        label={resource.Building}
        formGroup
        disabled={disabled}
        onChange={() => updateCart()}
      />
      <Input
        name="deliveryInfo.address.street"
        rules={{ required: isOther && resource.Required }}
        label={`${resource.Street} *`}
        formGroup
        disabled={disabled}
        onChange={() => updateCart()}
      />
      <div className="d-flex flex-wrap">
        <div className="col-6 pe-2">
          <Input
            name="deliveryInfo.address.number"
            rules={{ required: isOther && resource.Required }}
            label={`${resource.StreetNo} *`}
            formGroup
            disabled={disabled}
            onChange={() => updateCart()}
          />
        </div>
        <div className="col-6 ps-2">
          <Input
            name="deliveryInfo.address.box"
            label={resource.Bus}
            formGroup
            disabled={disabled}
            onChange={() => updateCart()}
          />
        </div>
      </div>
      <div className="d-flex flex-wrap">
        <div className="col-6 pe-2">
          <Input
            name="deliveryInfo.address.postalCode"
            rules={{ required: isOther && resource.Required }}
            label={`${resource.PostalCode} *`}
            formGroup
            disabled={disabled}
            onChange={() => updateCart()}
          />
        </div>
        <div className="col-6 ps-2">
          <Input
            name="deliveryInfo.address.city"
            rules={{ required: isOther && resource.Required }}
            label={`${resource.City} *`}
            formGroup
            disabled={disabled}
            onChange={() => updateCart()}
          />
        </div>
      </div>
      <ReactSelect
        name="deliveryInfo.address.country"
        rules={{ required: isOther && resource.Required }}
        label={`${resource.Country} *`}
        serverSide
        loadData={globalDataStore.searchCountries}
        defaultOptions={globalDataStore.getDefaultCountries()}
        optionValueKey="code"
        optionLabelKey="name"
        formGroup
        isDisabled={disabled}
        onChange={() => updateCart()}
      />
      <Button type="button" color="primary" className="align-self-end" onClick={handleSave}>
        {resource.Done}
      </Button>
    </Popup>
  )
}
