import React from 'react'

import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'

import { useStores } from 'stores'
import Button from 'common/Button'
import Loader from 'common/Loader'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'
import Toaster from 'common/Toaster'

import EmployeeInfo from './EmployeeInfo'

export default observer(({ isOpen, toggle }) => {
  const { authStore, clientAreaEmployeeStore, clientAreaStore, pageResourceStore } = useStores()
  const methods = useForm()

  const { handleSubmit } = methods
  const {
    AppSession: { session },
  } = authStore
  const {
    CRUD: { error, submitting },
  } = clientAreaEmployeeStore
  const {
    CRUD: { data, loading },
  } = clientAreaStore
  const {
    PageResource: { account: resource = {}, common: commonResource = {} },
  } = pageResourceStore

  React.useEffect(() => {
    clientAreaStore.get(session.clientId)
  }, [clientAreaStore, session])

  const handleOnSubmit = async values => {
    const { site, ...rest } = values

    const payload = {
      ...rest,
      siteId: site.clientSiteId,
    }

    const result = await clientAreaEmployeeStore.saveClientEmployee(session.clientId, payload, {
      ignoreGlobalMessage: true,
    })
    if (result) {
      toggle()
      Toaster.confirm({
        title: resource.UserCreated,
        message: resource.UserInfoSavedMessage,
        submitText: commonResource.Ok,
        hideCancel: true,
        onSubmit: async () => {
          window.location.reload()
        },
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      useForm={methods}
      title={resource.Welcome}
      footer={
        <Button
          type="submit"
          color="success"
          className="btn btn-success ms-auto"
          icon="done"
          disabled={submitting || loading}
          loading={submitting}
        >
          {commonResource.Save}
        </Button>
      }
      hideClose
      className="pt-8"
      titleClassName="py-2"
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <MessageList messages={error && [error]} />
      <Loader show={loading} className="justify-content-center" />
      {data.client && (
        <EmployeeInfo
          resource={resource}
          client={data.client}
          session={session}
          punchoutSession={data.punchoutSession}
          disabled={submitting}
        />
      )}
    </Modal>
  )
})
