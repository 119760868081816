import React from 'react'

import { formatCurrency } from 'utils/formatters'
import { padLeft } from 'utils'
import ButtonLink from 'common/ButtonLink'
import Card from 'common/Card'
import Icon from 'common/Icon'
import ReactTable from 'common/ReactTable'

export default ({ resource, orderData, onViewPoLine }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'PO',
        accessor: 'poIndex',
        headerClassName: 'text-muted',
        width: 50,
        Cell: ({ value }) => padLeft(value, 1),
      },
      {
        Header: '#',
        accessor: 'lineIndex',
        headerClassName: 'text-muted',
        width: 50,
        Cell: ({ value, row }) => <ButtonLink onClick={() => onViewPoLine(row)}>{padLeft(value, 1)}</ButtonLink>,
      },
      {
        Header: resource.Description,
        accessor: 'description',
        headerClassName: 'col',
        className: 'col',
        Cell: ({ value, row }) => (
          <ButtonLink
            title={value}
            className="word-break-break-word white-space-normal"
            onClick={() => onViewPoLine(row)}
          >
            {value}
          </ButtonLink>
        ),
      },
      {
        Header: resource.Supplier,
        accessor: 'supplier.name',
        headerClassName: 'col-2',
        className: 'col-2',
      },
      {
        Header: resource.Qty,
        accessor: 'quantity.amount',
        headerClassName: 'col-1',
        className: 'col-1',
      },
      {
        Header: resource.SupplierPrice,
        accessor: 'supplierPrice.value',
        headerClassName: 'col-2 text-end',
        className: 'col-2 text-end',
        Cell: ({ value }) => (
          <div className="d-flex justify-content-between">
            <Icon icon="clear" className="pt-2 md-16" />
            <span>{value ? formatCurrency(value) : '-'}</span>
          </div>
        ),
      },
      {
        Header: resource.TotalPrice,
        accessor: 'totalPrice.value',
        headerClassName: 'col-2 text-end',
        className: 'col-2 text-end',
        Cell: ({ value }) => (value ? formatCurrency(value) : '-'),
      },
    ],

    []
  )

  return (
    <Card title={resource.LinesToOrder} color="primary" outline>
      <ReactTable columns={columns} data={orderData.linesToOrder} noRecordsLabel={resource.NoPurchaseOrdersYet} />
    </Card>
  )
}
