import React from 'react'

import { DropdownItem } from 'reactstrap'
import cx from 'classnames'

import { formatDate } from 'utils/formatters'
import DayPicker from 'common/DateParts/DayPicker'
import Dropdown from 'common/Dropdown'
import FormGroup from 'common/FormGroup'
import HelpText from 'common/HelpText'
import Input from 'common/Input'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({
  inputRef,
  value,
  defaultValue,
  label,
  placeholder,
  dateFormat,
  disabled,
  isClearable,
  formGroup,
  horizontal,
  className,
  formClassName,
  labelClassName,
  inputClassName,
  inputColClassName,
  labelCol,
  inputCol,
  helpText,
  helpTextType,
  readOnlyField,
  highlightedDate,
  onChange,
  onBlur,
  ...rest
}) => {
  const [date, setDate] = React.useState(defaultValue || value)
  const [isOpen, setIsOpen] = React.useState(false)
  const hasError = helpTextType === 'error' && helpText

  const toggle = () => setIsOpen(prev => !prev)

  const handleOnChange = newValue => {
    setDate(newValue)
    toggle()
    onChange && onChange(newValue)
  }

  const handleOnClear = event => {
    event.stopPropagation()
    setDate(null)
  }

  return (
    <FormGroup
      {...{
        label,
        formGroup,
        horizontal,
        className: cx(readOnlyField && 'read-only', formClassName),
        labelClassName,
        inputColClassName,
        labelCol,
        inputCol,
        hasError,
      }}
    >
      {readOnlyField && <ReadOnlyField className={className} value={formatDate(date, dateFormat)} />}
      {!readOnlyField && (
        <React.Fragment>
          <Dropdown
            isOpen={isOpen}
            disabled={disabled}
            className={inputClassName}
            menuClassName="py-0"
            toggleClassName="w-100"
            toggle={toggle}
            customToggle={
              <Input
                disabled={disabled}
                inputRef={inputRef}
                value={date ? formatDate(date, dateFormat) : ''}
                placeholder={placeholder}
                readOnly
                endAdornment={
                  !!date && isClearable ? (
                    <span className="material-icons-outlined" title="clear" onClick={handleOnClear}>
                      clear
                    </span>
                  ) : (
                    <span className={cx('material-icons-outlined', hasError ? 'text-danger' : 'text-muted')}>
                      calendar_month
                    </span>
                  )
                }
                className={cx('read-only', hasError && 'invalid')}
                onBlur={onBlur}
              />
            }
          >
            <DropdownItem text className="p-0">
              <DayPicker
                className={className}
                selectedDays={date}
                highlightedDate={highlightedDate}
                onChange={handleOnChange}
                {...rest}
              />
            </DropdownItem>
          </Dropdown>
          {!!helpText && <HelpText hasError={hasError}>{helpText}</HelpText>}
        </React.Fragment>
      )}
    </FormGroup>
  )
}
