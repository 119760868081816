import React from 'react'

import cx from 'classnames'

export default ({ children, type = 'button', className, disabled, onClick, ...rest }) => {
  return (
    <button
      type={type}
      className={cx('btn-link', className)}
      disabled={disabled}
      {...(!disabled ? { onClick } : {})}
      {...rest}
    >
      {children}
    </button>
  )
}
