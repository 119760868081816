import React from 'react'

import cx from 'classnames'

import { SERVICE_CONTRACT, SPOT_BUY_CENTER } from 'definitions'
import { titlelize } from 'utils'
import Checkbox from 'common/Checkbox'

export default ({ attributeName, attributeValue, attributeParamValues, clientName, categoryOnly, onChange }) => {
  const [checked, setChecked] = React.useState(false)
  const [selected, setSelected] = React.useState()

  const hasTotal = attributeValue.total > 0

  const displayValue = React.useMemo(() => {
    const value = attributeValue.value
    if (categoryOnly) {
      return titlelize(value)
    } else if (value.toLowerCase() === SERVICE_CONTRACT.CLIENT_CATALOG.toLowerCase()) {
      return clientName
    } else if (value.toLowerCase() === SERVICE_CONTRACT.SPOT_BUY_CENTER_CATALOG.toLowerCase()) {
      return SPOT_BUY_CENTER
    } else if (value === 'true') {
      return 'Yes'
    } else if (value === 'false') {
      return 'No'
    }
    return value
  }, [categoryOnly, clientName, attributeValue.value])

  const labelClassName = React.useMemo(() => {
    if (selected) return 'text-gold'
    else if (hasTotal) return 'text-primary'
    else return 'webshop-text-color'
  }, [selected, hasTotal])

  React.useEffect(() => {
    const selected = attributeParamValues.includes(attributeValue.value)
    setChecked(selected)
    setSelected(selected)
  }, [attributeValue.value, attributeParamValues])

  const handleOnChange = event => {
    setChecked(event.target.checked)
    onChange({
      key: attributeName,
      value: categoryOnly ? `/Catalog/Categories/Categories/${attributeValue.categoryPath}` : attributeValue.value,
      checked: event.target.checked,
    })
  }

  return (
    <Checkbox
      checked={checked}
      label={`${displayValue}${hasTotal ? ` (${attributeValue.total})` : ''}`}
      formGroup
      formClassName="mb-2"
      labelClassName={cx('attribute-checkbox w-100', labelClassName)}
      onChange={handleOnChange}
    />
  )
}
