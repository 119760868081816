import React from 'react'

import { Col } from 'reactstrap'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'

import { useStores } from 'stores'
import Button from 'common/Button'
import Card from 'common/Card'
import FormGroup from 'common/FormGroup'
import Input from 'common/Forms/Input'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'
import ReactSelect from 'common/Forms/ReactSelect'

export default observer(({ supplierName, onClose }) => {
  const { authStore, globalDataStore, pageResourceStore, supplierStore } = useStores()
  const [showSuccess, setShowSuccess] = React.useState(false)
  const methods = useForm()

  const { handleSubmit } = methods
  const {
    CRUD: { error, submitting },
  } = supplierStore
  const {
    PageResource: { case: resource = {}, common: commonResource = {} },
  } = pageResourceStore

  const handleOnSubmit = async values => {
    const { session } = authStore.AppSession
    const supplier = values

    const payload = {
      entityId: session.defaultEntityId,
      requesterEmail: session.email,
      supplier: {
        ...supplier,
        language: supplier.language ? supplier.language.code : null,
      },
    }

    const result = await supplierStore.requestNewSupplier(payload)
    if (result) {
      setShowSuccess(true)
      window.setTimeout(() => onClose(false), 5000)
    }
  }

  return (
    <Modal
      isOpen
      useForm={methods}
      size="lg"
      centered
      title={resource.SupplierRequestTitle}
      disableClose={submitting}
      footer={
        <React.Fragment>
          <Button type="button" text color="primary" disabled={submitting} className="me-2" onClick={onClose}>
            {commonResource.Cancel}
          </Button>
          <Button
            type="submit"
            color="success"
            className="btn btn-success"
            icon="done"
            disabled={submitting}
            loading={submitting}
          >
            {commonResource.Submit}
          </Button>
        </React.Fragment>
      }
      onClose={onClose}
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <MessageList messages={error && [error]} />
      {showSuccess && <MessageList messages={[resource.SupplierRequestSuccess]} color="success" />}
      <Card title={resource.FillInSupplierForm} fieldset>
        <Input
          name="name"
          rules={{ required: commonResource.Required }}
          label={`${resource.CompanyName} *`}
          defaultValue={supplierName}
          disabled={submitting}
          formGroup
          horizontal
        />
        <Input name="vatNumber" label={resource.VatNumber} disabled={submitting} formGroup horizontal />
        <FormGroup label={`${resource.Address} *`} formGroup horizontal inputColClassName="d-flex">
          <Col md="8" className="pe-3">
            <Input
              name="address.street"
              rules={{ required: commonResource.Required }}
              placeholder={resource.phStreet}
              disabled={submitting}
            />
          </Col>
          <Col md="4" className="ps-3">
            <Input
              name="address.number"
              rules={{ required: commonResource.Required }}
              placeholder={resource.phNr}
              disabled={submitting}
            />
          </Col>
        </FormGroup>
        <FormGroup label={`${resource.Location} *`} formGroup horizontal inputColClassName="d-flex">
          <Col md="4" className="pe-3">
            <Input
              name="address.postalCode"
              rules={{ required: commonResource.Required }}
              placeholder={resource.phZipCode}
              disabled={submitting}
            />
          </Col>
          <Col md="8" className="ps-3">
            <Input name="address.city" rules={{ required: commonResource.Required }} placeholder={resource.phCity} />
          </Col>
        </FormGroup>
        <ReactSelect
          name="address.country"
          rules={{ required: commonResource.Required }}
          label={resource.Country}
          formGroup
          horizontal
          serverSide
          loadData={globalDataStore.searchCountries}
          defaultOptions={globalDataStore.getDefaultCountries()}
          optionValueKey="code"
          optionLabelKey="name"
          isDisabled={submitting}
        />
        <ReactSelect
          name="language"
          rules={{ required: commonResource.Required }}
          label={`${resource.Language} *`}
          formGroup
          horizontal
          options={globalDataStore.Languages}
          optionValueKey="code"
          optionLabelKey="name"
          isDisabled={submitting}
        />
        <Input
          name="phone"
          rules={{ required: commonResource.Required }}
          label={`${resource.Phone} *`}
          formGroup
          horizontal
          disabled={submitting}
        />
        <Input
          name="email"
          rules={{ required: commonResource.Required }}
          label={`${resource.Email} *`}
          formGroup
          horizontal
          disabled={submitting}
        />
        <Input name="iban" label={resource.Iban} formGroup horizontal disabled={submitting} />
        <Input name="bic" label={resource.Bic} formGroup horizontal disabled={submitting} />
      </Card>
    </Modal>
  )
})
