import React from 'react'

import cx from 'classnames'

export default ({ show, text, color = 'primary', size, className }) => {
  return (
    !!show && (
      <div className={cx('loader', className)}>
        <span className={cx('svg-wrapper', `text-${color}`, size)}>
          <svg viewBox="22 22 44 44">
            <circle cx="44" cy="44" r="19.2" fill="none" strokeWidth="5" />
          </svg>
        </span>
        {text && <span className="message">{text}</span>}
      </div>
    )
  )
}
