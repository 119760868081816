import BaseService from 'services/base'

import { formatString } from 'utils/formatters'

export default class QuoteService extends BaseService {
  constructor(api) {
    const url = 'clientcase/{0}/quote/{1}'
    super(api, url)
    this.api = api
    this.url = url
  }

  getById = (caseId, id) => {
    return this.api.get(formatString(this.url, caseId, id), null, { ignoreGlobalMessage: true })
  }

  post = (caseId, payload) => {
    return this.api.post(formatString('clientcase/{0}/quote', caseId), payload, { ignoreGlobalMessage: true })
  }

  patch = (caseId, payload) => {
    const { id } = payload
    return this.api.put(formatString(this.url, caseId, id), payload, { ignoreGlobalMessage: true })
  }

  delete = payload => {
    const { caseId, id } = payload
    return this.api.delete(formatString(this.url, caseId, id))
  }

  sendToErp = payload => {
    const { caseId, id } = payload
    return this.api.put(formatString(this.url, caseId, `${id}/sendtoerp`))
  }

  sendToApproval = payload => {
    const { caseId, id } = payload
    return this.api.put(formatString(this.url, caseId, `${id}/sendtoapproval`))
  }
}
