import React from 'react'

import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Link } from 'react-router-dom'

import { ROUTES } from 'definitions'
import Icon from 'common/Icon'

import CartIcon from '../CartIcon'

export default ({ resource }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <Breadcrumb listClassName="d-flex align-items-center fw-bold">
        <BreadcrumbItem className="d-inline-flex align-items-center">
          <Icon icon="cottage" filled className="me-1 md-28 text-primary" />
          <Link to={ROUTES.CONNECTIONS}>{resource.OazoConnections}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{resource.Cart}</BreadcrumbItem>
      </Breadcrumb>
      <CartIcon />
    </div>
  )
}
