import React from 'react'

import { useFieldArray, useFormContext } from 'react-hook-form'

import { FlexTableCell, FlexTableRow } from 'common/FlexTable'
import { formatCurrency, formatNumber } from 'utils/formatters'
import Button from 'common/Button'

import GoodsReceivedLineItemFooter from './GoodsReceivedLineItemFooter'
import GoodsReceivedLineItemNew from './GoodsReceivedLineItemNew'
import GoodsReceivedLineItemReceived from './GoodsReceivedLineItemReceived'

export default ({ resource, lineItem, lineItemIndex }) => {
  const { getValues, control, setValue } = useFormContext()
  const { append, fields, remove } = useFieldArray({
    control,
    name: `items.${lineItemIndex}.goodsReceived.items`,
    shouldUnregister: true,
  })

  const calculateTotals = () => {
    const items = getValues(`items.${lineItemIndex}.goodsReceived.items`)
    const totalGoodsReceived = items.reduce(
      (current, item) => {
        const quantityReceived = parseInt(item.quantityReceived || 0)
        current.totalQuantityReceived += quantityReceived
        current.totalPrice += quantityReceived * lineItem.price

        return current
      },
      {
        totalQuantityReceived: 0,
        totalPrice: 0,
      }
    )
    setValue(`items.${lineItemIndex}.goodsReceived.totalQuantityReceived`, totalGoodsReceived.totalQuantityReceived)
    setValue(`items.${lineItemIndex}.goodsReceived.totalPrice`, totalGoodsReceived.totalPrice)
    setValue(`items.${lineItemIndex}.goodsReceived.totalPriceFormatted`, formatCurrency(totalGoodsReceived.totalPrice))
  }

  const handleAddGoodsReceivedStatus = () => {
    const items = getValues(`items.${lineItemIndex}.goodsReceived.items`)
    const totalQtyReceived = items.reduce((total, item) => total + parseInt(item.quantityReceived || 0), 0)
    const difference = lineItem.quantity - totalQtyReceived
    const quantityReceived = difference > 0 ? difference : 0
      const totalPrice = quantityReceived * lineItem.price

    append({
      timestamp: new Date(),
      quantityReceived,
      price: lineItem.price,
      totalPrice,
      new: true,
    })

    calculateTotals()
  }

  const handleRemoveEntry = index => {
    remove(index)
    calculateTotals()
  }

  return (
    <React.Fragment>
      <FlexTableRow className="bg-light flex-wrap">
        <FlexTableCell className="col-line-index col-auto">{lineItem.lineIndex}</FlexTableCell>
        <FlexTableCell className="col-2-half word-break-break-word white-space-normal">
          {lineItem.description}
        </FlexTableCell>
        <FlexTableCell className="col justify-content-end">{formatNumber(lineItem.quantity)}</FlexTableCell>
        <FlexTableCell className="col-1-half justify-content-end">{formatNumber(lineItem.price, 2)}</FlexTableCell>
        <FlexTableCell className="col-1-half justify-content-end">
          {formatNumber(lineItem.price * lineItem.quantity, 2)}
        </FlexTableCell>
        <FlexTableCell className="col-4 justify-content-end">
          <Button type="button" icon="add" size="sm" onClick={handleAddGoodsReceivedStatus}>
            {resource.AddGoodsReceivedStatus}
          </Button>
        </FlexTableCell>
      </FlexTableRow>
      {fields.map((field, index) => {
        const item = getValues(`items.${lineItemIndex}.goodsReceived.items.${index}`)
        if (item.new) {
          return (
            <GoodsReceivedLineItemNew
              key={field.id}
              resource={resource}
              lineItem={lineItem}
              lineItemIndex={lineItemIndex}
              item={item}
              index={index}
              calculateTotals={calculateTotals}
              onRemove={handleRemoveEntry}
            />
          )
        } else {
          return <GoodsReceivedLineItemReceived key={field.id} resource={resource} item={item} />
        }
      })}
      <GoodsReceivedLineItemFooter resource={resource} lineItemIndex={lineItemIndex} lineItem={lineItem} />
    </React.Fragment>
  )
}
