import BaseService from 'services/base'

export default class SupplierService extends BaseService {
  constructor(api) {
    const url = 'supplier'
    super(api, url)
    this.api = api
    this.url = url
  }

  requestNewSupplier = payload => {
    return this.api.post(`${this.url}/request`, payload, { ignoreGlobalMessage: true })
  }
}
