import React from 'react'

import { Badge } from 'reactstrap'

import { formatCurrency, formatDate } from 'utils/formatters'
import { padLeft } from 'utils/'
import ButtonLink from 'common/ButtonLink'
import Card from 'common/Card'
import ReactTable from 'common/ReactTable'

export default ({ resource, linesToPropose, onViewDetail }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'lineIndex',
        headerClassName: 'text-muted',
        width: 50,
        Cell: ({ value, row }) => <ButtonLink onClick={() => onViewDetail(row)}>{padLeft(value, 1)}</ButtonLink>,
      },
      {
        Header: resource.Description,
        accessor: 'description',
        headerClassName: 'col',
        className: 'col',
        Cell: ({ value, row }) => (
          <ButtonLink
            title={value}
            className="word-break-break-word white-space-normal"
            onClick={() => onViewDetail(row)}
          >
            {value}
          </ButtonLink>
        ),
      },
      {
        Header: resource.Delivery,
        accessor: 'deliveryInfo.deliveryDate',
        headerClassName: 'col-2',
        className: 'col-2',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: resource.Budget,
        accessor: 'budget.value',
        headerClassName: 'col-1',
        className: 'col-1',
        Cell: ({ value }) => (value ? formatCurrency(value) : '-'),
      },
      {
        Header: resource.Qty,
        accessor: 'quantity.amount',
        headerClassName: 'col-1',
        className: 'col-1',
      },
      {
        Header: resource.Proposed,
        accessor: 'status.proposed',
        headerClassName: 'col-1 border-start',
        className: 'col-1 border-start',
        Cell: ({ value, row }) => (
          <React.Fragment>
            {value && <Badge color="success">{resource.Yes}</Badge>}
            {!value && (
              <Badge color={row.status.pendingProposal ? 'success' : 'danger'}>
                {row.status.pendingProposal ? resource.Draft : resource.No}
              </Badge>
            )}
          </React.Fragment>
        ),
      },
      {
        Header: resource.Selected,
        accessor: 'status.accepted',
        headerClassName: 'col-1 border-start',
        className: 'col-1 border-start',
        Cell: ({ value }) => <Badge color={value ? 'success' : 'danger'}>{value ? resource.Yes : resource.No}</Badge>,
      },
    ],

    []
  )

  return (
    <Card title={resource.LinesToOrder} color="primary" outline>
      <ReactTable columns={columns} data={linesToPropose} noRecordsLabel={resource.NoSourceLines} />
    </Card>
  )
}
