import cx from 'classnames'
import React from 'react'

import Loader from 'common/Loader'

export default ({ show, className, loaderClassName, style, ...props }) => {
  if (show) {
    return (
      <div className={cx('content-loader', className)} style={style}>
        <Loader show className={cx(loaderClassName)} {...props} />
      </div>
    )
  }
  return false
}
