import React from 'react'

import { Outlet } from 'react-router-dom'

import { Container } from 'reactstrap'
import logoImage from 'assets/images/spot-buy-logo-120.jpg'

export default () => {
  return (
    <React.Fragment>
      <header className="py-1 text-center paper paper-1 public login">
        <div className="brand">
          <img src={logoImage} alt="Oazo logo" />
        </div>
      </header>
      <Container id="login" fluid>
        <Outlet />
      </Container>
    </React.Fragment>
  )
}
