import React from 'react'

import { observer } from 'mobx-react'

import { formatFileUrl } from 'utils/formatters'
import { useStores } from 'stores'

export default observer(() => {
  const { authStore, homeStore } = useStores()

  const {
    CRUD: { data },
  } = homeStore

  React.useEffect(() => {
    homeStore.get(null, {
      uiLanguageSetting: authStore.AppSession.session.uiLanguageSetting,
    })
  }, [homeStore, authStore])

  return (
    <div id="home">
      {data.homeContext && (
        <div>
          <div className="header">
            <h2 className="fw-light" dangerouslySetInnerHTML={{ __html: data.homeContext.homeHeader }} />
          </div>
          <div className="d-flex flex-lg-row flex-column-reverse">
            <div className="col-lg-8 col p-4 border">
              <div className="" dangerouslySetInnerHTML={{ __html: data.homeContext.homeBody }} />
            </div>
            <div className="col-lg-4 col text-center mb-4">
              <img
                alt={`Client Logo - ${data.clientLogoFileId}`}
                className="img-responsive img-thumbnail fadein"
                style={{ maxHeight: '200px' }}
                src={formatFileUrl(data.clientLogoFileId)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
})
