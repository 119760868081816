import React from 'react'

export default ({ data }) => {
  const formRef = React.useRef()
  const buttonRef = React.useRef()
  const { serverSide, punchoutURL, xmlDocument } = data

  React.useEffect(() => {
    if (serverSide) {
      buttonRef.current.click()
    } else {
      formRef.current.submit()
    }
  }, [])

  if (serverSide) {
    ;<button ref={buttonRef} id="modal-button-punchout" type="button" />
  } else {
    return (
      <form ref={formRef} method="POST" action={punchoutURL} className="collapse">
        <input name="xmlDocument" value={xmlDocument} />
        <button type="submit" />
      </form>
    )
  }
}
