import BaseService from 'services/base'

export default class AuthService extends BaseService {
  constructor(api) {
    super(api)
    this.api = api

    this.handleOnSessionExpired = this.handleOnSessionExpired.bind(this)
    this.api.onSessionExpired = this.handleOnSessionExpired

    this.handleOnRedirectToLoginProvider = this.handleOnRedirectToLoginProvider.bind(this)
    this.api.onRedirectToLoginProvider = this.handleOnRedirectToLoginProvider
  }

  handleOnSessionExpired() {
    this.onSessionExpired && this.onSessionExpired()
  }

  handleOnRedirectToLoginProvider(loginProvider, message) {
    this.onRedirectToLoginProvider && this.onRedirectToLoginProvider(loginProvider, message)
  }

  login(payload, provider) {
    if (provider) {
      return this.api.post(`auth/${provider}`, payload)
    } else {
      return this.api.post('auth', payload)
    }
  }

  logout() {
    return this.api.post('auth/logout')
  }

  getAuthSession() {
    return this.api.get('account')
  }

  post(url, payload) {
    return super.post(url, payload, { withCredentials: false })
  }
}
