import React from 'react'

import { observer } from 'mobx-react'

import { formatNumber } from 'utils/formatters'
import { useStores } from 'stores'
import Pagination from 'common/Pagination'

import ProductsHeaderSort from './ProductsHeaderSort'

export default observer(({ filters, onGoToPage, onSortChange }) => {
  const { pageResourceStore, webshopStore } = useStores()
  const { page, pageSize, sort, isFilterByPrice, isSortByPrice } = filters
  const {
    PageResource: { webshop: resource },
  } = pageResourceStore
  const {
    Config: {
      data: { priceFilterParameter = 0 },
    },
    Table: {
      data: { items = [], total },
      loading,
      totalItemCountByPrice,
    },
  } = webshopStore

  const initialPageLoading = loading && !page
  const isSortByDefault = sort?.key === 'default'
  const showHeader = isSortByDefault || isSortByPrice || (!initialPageLoading && (isFilterByPrice || items.length > 0))
  const totalCount = isFilterByPrice || isSortByPrice ? totalItemCountByPrice : total
  const isShowSort = priceFilterParameter > totalCount || !!sort?.key

  return (
    showHeader && (
      <div className="d-sm-flex align-items-md-center align-items-top mb-1 px-1">
        <div className="fs-16 fw-bold webshop-title-color">
          {totalCount ? `${formatNumber(totalCount)} ${resource.SearchResult}` : ''}
        </div>
        <div className="d-flex flex-md-row flex-column-reverse justify-content-end align-items-end mt-sm-0 mt-2 ms-auto">
          {isShowSort && <ProductsHeaderSort resource={resource} sort={sort} onSortChange={onSortChange} />}
          {totalCount > 0 && (
            <Pagination
              currentPage={page || 1}
              pageSize={pageSize}
              totalItems={totalCount}
              className="ms-4"
              onJumpToPage={onGoToPage}
            />
          )}
        </div>
      </div>
    )
  )
})
