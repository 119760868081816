import React from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import cx from 'classnames'

import { ROUTES } from 'definitions'
import { useDebounce } from 'hooks/useDebounce'
import { useStateCallback } from 'hooks/useStateCallback'
import { useStores } from 'stores'
import GlobalErrorHandler from './GlobalErrorHandler'
import GlobalMessage from 'components/Core/GlobalMessage'
import Paper from 'common/Paper'

export default observer(({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { authStore, pageResourceStore } = useStores()
  const gotoDebounced = useDebounce(prams => goto(prams), 150)
  const [moduleKey, setModuleKey] = useStateCallback()

  const {
    PageResource: { common: resource = {} },
  } = pageResourceStore
  const {
    AppSession: { session },
  } = authStore

  const modules = React.useMemo(() => {
    const showModules = session?.enabledClientModules && !session?.shouldCreateAuthInfo

    if (showModules) {
      return session.enabledClientModules
        .map(key => {
          switch (key) {
            case 'home':
              return { icon: 'home', key, label: resource.Home, url: ROUTES.HOME }
            case 'news':
              return { icon: 'rss_feed', key, label: resource.News, url: ROUTES.NEWS }
            case 'webform':
              return { icon: 'edit_note', key, label: resource.Webform, url: ROUTES.WEBFORM }
            case 'cases':
            case 'clientcases':
              return { icon: 'list_alt', key, label: resource.Cases, url: ROUTES.CASES }
            case 'prapproval':
              return { icon: 'gavel', key, label: resource.Approval, url: ROUTES.PRA }
            case 'webshop':
            case 'oazowebshop':
              return { icon: 'shopping_cart', key, label: resource.Webshop, url: ROUTES.WEBSHOP }
            case 'suppliers':
              return { icon: 'local_shipping', key, label: resource.Suppliers, url: ROUTES.SUPPLIERS }
            case 'connections':
              return { icon: 'hub', key, label: resource.OazoConnections, url: ROUTES.CONNECTIONS }
            default:
              return null
          }
        })
        .filter(item => !!item)
        .map(item => ({
          ...item,
          active: location.pathname.toLowerCase().startsWith(item.url.toLowerCase()),
        }))
    }
    return []
  }, [resource, session, location.pathname])

  const goto = ({ key, url }) => {
    setModuleKey(key, () => {
      setModuleKey(null)
      navigate(url)
    })
  }

  const activeTabIndex = modules?.findIndex(item => item.active)

  return (
    <div className="module-tabs">
      <nav className="module-tab col-lg-1 col-auto" role="tablist">
        {modules.map((tab, index) => (
          <Link
            key={index}
            to={tab.url}
            className={cx('tab-header d-flex flex-column align-items-center justify-content-center', {
              active: tab.active,
            })}
            onClick={event => {
              event.preventDefault()
              gotoDebounced({
                key: tab.key,
                url: tab.url,
              })
            }}
          >
            <span className="material-icons-outlined icon md-28">{tab.icon}</span>
            <span className="label text-center word-wrap-break-word">{tab.label}</span>
          </Link>
        ))}
      </nav>
      {!!modules.length && !moduleKey && (
        <Paper
          // key={moduleKey}
          // {...(moduleKey ? { key: moduleKey } : {})}
          className={cx('module-content col-lg-11 col', {
            'first-tab': activeTabIndex === 0,
          })}
          elevation="1"
        >
          <GlobalErrorHandler>
            <GlobalMessage />
            {children}
          </GlobalErrorHandler>
        </Paper>
      )}
    </div>
  )
})
