import React from 'react'

import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { ROUTES } from 'definitions'
import { useStores } from 'stores'

export default () => {
  const location = useLocation()
  const { authStore } = useStores()

  const {
    AppSession: { session },
  } = authStore

  if (location.pathname === ROUTES.DEFAULT) {
    const defaultPage = session.defaultPage ? `/${session.defaultPage}` : ROUTES.WEBFORM
    return <Navigate to={defaultPage} />
  } else if (session.enabledClientModules.some(item => location.pathname.startsWith(`/${item}`))) {
    return <Outlet />
  } else {
    return <Navigate to={ROUTES.FORBIDDEN} />
  }
}
