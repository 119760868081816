import BaseService from 'services/base'

export default class Saml2Service extends BaseService {
  constructor(api) {
    const url = '/sso/saml'
    super(api, url)
    this.api = api
    this.url = url
  }

  getLoginUrl(code, payload) {
    return this.get(`/${code}/login`, payload)
  }
}
