import React from 'react'

import cx from 'classnames'

import { useStores } from 'stores'
import FormGroup from 'common/FormGroup'
import Input from 'common/Forms/Input'
import RadioGroup from 'common/Forms/RadioGroup'
import ReactSelect from 'common/Forms/ReactSelect'

const EMPLOYEE = 'employee'
const OTHER = 'other'

export default ({
  resource,
  data,
  namePrefix = '',
  deliverTo: deliverToProp,
  defaultOtherRequester,
  disabled,
  isOverride,
}) => {
  const { clientRequesterStore } = useStores()
  const [deliverTo, setDeliverTo] = React.useState(deliverToProp || EMPLOYEE)

  const { contact = {}, deliveryInfo } = data
  const isEmployee = deliverTo === EMPLOYEE
  const isOther = deliverTo === OTHER

  const handleChangeDeliverTo = selected => {
    setDeliverTo(selected)
  }

  return (
    <React.Fragment>
      <FormGroup formGroup horizontal label={resource.DeliverTo} inputColClassName="d-flex flex-sm-row flex-column">
        <div className="col-sm-5 col-12">
          <RadioGroup
            name={`${namePrefix}deliveryInfo.requesterType`}
            disabled={disabled}
            rules={{ required: resource.Required }}
            radios={[
              {
                value: EMPLOYEE,
                label: resource.CompanyEmployee,
              },
              {
                value: OTHER,
                label: resource.OtherPerson,
                formClassName: isOther ? 'mb-0' : '',
              },
            ]}
            defaultValue={deliverTo}
            formClassName={cx(!!deliverTo && 'mb-1')}
            onChange={handleChangeDeliverTo}
          />
        </div>
        <div className="col-sm-7 col-12">
          {isEmployee && (
            <ReactSelect
              name={`${namePrefix}deliveryInfo.requester`}
              rules={{ required: isEmployee && resource.Required }}
              defaultValue={deliveryInfo?.requester}
              isDisabled={disabled}
              serverSide
              loadData={args =>
                clientRequesterStore.fetch(`${contact.companyId}/requester`, {
                  displayName: args.search,
                  addUserAsRequesterIfNotExist: false,
                })
              }
              loadDataPropertyKey="requesters"
              optionValueKey="key"
              optionLabelKey="value"
            />
          )}
        </div>
      </FormGroup>
      {isOther && (
        <Input
          name={`${namePrefix}deliveryInfo.otherRequester`}
          rules={{ required: isOther && !isOverride && resource.Required }}
          defaultValue={defaultOtherRequester}
          label={`${resource.FullName}*`}
          readOnly={disabled}
          formGroup
          horizontal
          inputCol={{ md: 7 }}
        />
      )}
    </React.Fragment>
  )
}
