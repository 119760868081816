import React from 'react'

import { useFormContext } from 'react-hook-form'
import cx from 'classnames'

import { FlexTableCell, FlexTableRow } from 'common/FlexTable'
import { formatNumber } from 'utils/formatters'
import { PRA_TYPE } from 'definitions'
import { useStores } from 'stores'

import Button from 'common/Button'
import ButtonIcon from 'common/ButtonIcon'
import Input from 'common/Forms/Input'
import ReadOnlyField from 'common/ReadOnlyField'

import PraFormProductItemCurrency from './PraFormProductItemCurrency'
import PraFormProductItemDetail from './PraFormProductItemDetail'
import PraFormProductItemLineIndex from './PraFormProductItemLineIndex'

export default ({
  index,
  lineItem,
  isReadOnly,
  showDelete,
  fetchApprovers,
  showCopy,
  onDeleteLine,
  onCopyLine,
  onChange,
  clientId,
  isEdit,
  isCopy,
}) => {
  const { pageResourceStore, praStore } = useStores()
  const [isOpen, setIsOpen] = React.useState(!isReadOnly)
  const { getValues, setValue } = useFormContext()

  const {
    PageResource: { pra: resource = {} },
  } = pageResourceStore
  const {
    CRUD: { data = {}, settings = {}, submitting },
  } = praStore

  const toggle = () => setIsOpen(prev => !prev)

  const handleOnDeleteLine = () => onDeleteLine(index)

  const handleCopyLine = () => onCopyLine(index)

  const handleCalculatePra = () => {
    const { lines } = getValues()
    let total = 0
    lines.forEach((item, index) => {
      const qty = parseFloat(item.quantity || 0)
      const price = parseFloat(item.price || 0)
      const subTotal = qty * price

      setValue(`lines.${index}.subTotal`, formatNumber(subTotal, 2))
      total += subTotal
    })
    setValue('totalAmount', formatNumber(total, 2))
  }

  return (
    <FlexTableRow>
      <FlexTableCell className="col-lg-auto col flex-wrap justify-content-between">
        <PraFormProductItemLineIndex resource={resource} lineItem={lineItem} index={index} />
        <div className={cx('d-lg-none col-auto text-end', showDelete && 'col-icon-3')}>
          <Button
            icon={isOpen ? 'remove_circle_outline' : 'add_circle_outline'}
            text
            size="sm"
            className="d-lg-none d-inline-flex"
            onClick={toggle}
          >
            {isOpen ? resource.ShowLess : resource.ShowMore}
          </Button>
          {showCopy && (
            <Button color="primary" icon="content_copy" text size="sm" className="ms-1" disabled={submitting}>
              {resource.Copy}
            </Button>
          )}
          {showDelete && (
            <Button
              color="danger"
              icon="clear"
              text
              size="sm"
              className="ms-1"
              disabled={submitting}
              onClick={handleOnDeleteLine}
            >
              {resource.Delete}
            </Button>
          )}
        </div>
      </FlexTableCell>
      <FlexTableCell className="col-lg col-12">
        <Input
          name={`lines.${index}.description`}
          rules={{ required: resource.Required }}
          label={`${resource.ProductDescription}${isReadOnly ? '' : ' *'} `}
          defaultValue={lineItem.description}
          placeholder={resource.ProductDescriptionPlaceholder}
          formGroup
          formClassName="col mb-lg-0 mb-1"
          className={cx(isReadOnly && 'word-break-break-word white-space-normal')}
          labelClassName="d-lg-none"
          disabled={submitting}
          readOnlyField={isReadOnly}
        />
      </FlexTableCell>
      <FlexTableCell className={cx('col-6', data.enableGoodsReceived ? 'col-lg-1-half' : 'col-lg-2 ')}>
        <Input
          type="number"
          name={`lines.${index}.quantity`}
          rules={{ required: resource.Required }}
          label={`${resource.Quantity}${isReadOnly ? '' : ' *'} `}
          defaultValue={lineItem.quantity}
          formGroup
          formClassName="col mb-lg-0 mb-1"
          labelClassName="d-lg-none"
          disabled={submitting}
          readOnlyField={isReadOnly}
          onChange={() => {
            handleCalculatePra()
            fetchApprovers()
          }}
        />
      </FlexTableCell>
      <FlexTableCell className={cx('col-6', data.enableGoodsReceived ? 'col-lg-1-half' : 'col-lg-2 ')}>
        <Input
          type="number"
          name={`lines.${index}.price`}
          label={`${resource.Price}${isReadOnly ? '' : ' *'} `}
          rules={{ required: resource.Required }}
          defaultValue={lineItem.price ? formatNumber(lineItem.price, 2) : ''}
          step="any"
          formGroup
          formClassName="col mb-lg-0 mb-1"
          labelClassName="d-lg-none"
          disabled={submitting}
          readOnlyField={isReadOnly}
          readOnly={[PRA_TYPE.WEBFORM, PRA_TYPE.WEBSHOP, PRA_TYPE.CONNECTION].includes(data.type)}
          onChange={() => {
            handleCalculatePra()
            fetchApprovers()
          }}
        />
      </FlexTableCell>
      <FlexTableCell className={cx('col-6', data.enableGoodsReceived ? 'col-lg-1-half' : 'col-lg-2 ')}>
        <Input
          name={`lines.${index}.subTotal`}
          defaultValue={formatNumber(lineItem.subtotal || 0, 2)}
          label={resource.Subtotal}
          formGroup
          formClassName="col mb-lg-0 mb-1"
          labelClassName="d-lg-none"
          textOnlyInput
        />
      </FlexTableCell>
      <PraFormProductItemCurrency
        {...{
          resource,
          currencies: praStore.Lookups?.data?.currencies || [],
          index,
          lineItem,
          settings,
          isReadOnly,
        }}
      />
      {data.enableGoodsReceived && (
        <React.Fragment>
          <FlexTableCell className="col-lg-1-half col-6 border-start">
            <ReadOnlyField
              label={resource.QtyReceived}
              value={formatNumber(lineItem.goodsReceived?.totalQuantityReceived || 0)}
              formGroup
              formClassName="col mb-lg-0 mb-1"
              labelClassName="d-lg-none"
            />
          </FlexTableCell>
          <FlexTableCell className="col-lg-1-half col-6">
            <ReadOnlyField
              label={resource.TotalReceived}
              value={formatNumber(lineItem.goodsReceived?.totalPrice || 0, 2)}
              formGroup
              formClassName="col mb-lg-0 mb-1"
              labelClassName="d-lg-none"
            />
          </FlexTableCell>
        </React.Fragment>
      )}
      <FlexTableCell
        className={cx('col-action d-lg-flex d-none align-item-center', {
          'col-action-3': showDelete && showCopy,
          'read-only': isReadOnly || !showCopy,
        })}
      >
        <ButtonIcon
          icon={isOpen ? 'remove_circle_outline' : 'add_circle_outline'}
          title={isOpen ? resource.ShowLess : resource.ShowMore}
          onClick={toggle}
        />
        {!isReadOnly && (
          <React.Fragment>
            {showCopy && (
              <ButtonIcon
                color="primary"
                icon="content_copy"
                title={resource.Copy}
                className="ms-1"
                disabled={submitting}
                onClick={handleCopyLine}
              />
            )}
            {showDelete && (
              <ButtonIcon
                color="danger"
                icon="clear"
                title={resource.Delete}
                className="ms-1"
                iconClassName="text-danger"
                disabled={submitting}
                onClick={handleOnDeleteLine}
              />
            )}
          </React.Fragment>
        )}
      </FlexTableCell>
      <PraFormProductItemDetail
        praStore={praStore}
        resource={resource}
        index={index}
        isOpen={isOpen}
        lineItem={lineItem}
        isReadOnly={isReadOnly}
        fetchApprovers={fetchApprovers}
        onChange={onChange}
        clientId={clientId}
        isEdit={isEdit}
        isCopy={isCopy}
      />
    </FlexTableRow>
  )
}
