import BaseStore from 'stores/base'

import moment from 'moment'

export default class QuoteStore extends BaseStore {
  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options
  }

  getById(caseId, id) {
    return this.getRequest(this.CRUD, () => this.service.getById(caseId, id))
  }

  async sendToApproval(payload) {
    const { data, error } = await this.service.sendToApproval(payload)
    return { data, error }
  }

  async sendToErp(payload) {
    const { data, error } = await this.service.sendToErp(payload)
    return { data, error }
  }

  prepareQuoteData(isEdit, quote) {
    if (isEdit) {
      return quote.id
        ? {
            ...quote,
            expirationDate: moment(quote.expirationDate).toDate(),
            lines: quote.lines.map(item => ({
              ...item,
              deliveryDate: moment(item.deliveryDate).toDate(),
            })),
          }
        : null
    } else {
      return {
        ...quote,
        attachments: [],
        remarks: null,
        expirationDate: moment().add(5, 'd').toDate(),
        lines: quote.lines.map(item => ({
          ...item,
          deliveryDate: moment(item.deliveryDate).toDate(),
          unitPrice: {
            currency: item.supplierPrice ? item.supplierPrice.currency : 'EUR',
          },
        })),
      }
    }
  }
}
