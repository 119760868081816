import React from 'react'

import { Badge } from 'reactstrap'
import { observer } from 'mobx-react'
import cx from 'classnames'

import { ROUTES } from 'definitions'
import { useStores } from 'stores'
import DebouncedLink from 'common/DebouncedLink'
import Icon from 'common/Icon'

export default observer(() => {
  const { connectionCartStore } = useStores()

  const { CartQuantity: quantity = 0 } = connectionCartStore

  React.useEffect(() => {
    connectionCartStore.getCartQuantity()
  }, [connectionCartStore])

  const isCircle = quantity <= 9

  return (
    <DebouncedLink
      to={ROUTES.CONNECTIONS_CART}
      className="btn btn-icon btn-icon-primary text-decoration-none cart-icon"
    >
      <Icon icon="shopping_cart" className="md-32" />
      {quantity > 0 && (
        <Badge
          color="primary"
          className={cx('border border-2 border-white fs-12', {
            'rounded-circle': isCircle,
            'rounded-pill': !isCircle,
          })}
        >
          {quantity > 99 ? '99+' : quantity}
        </Badge>
      )}
    </DebouncedLink>
  )
})
