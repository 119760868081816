import React from 'react'

import { observer } from 'mobx-react'
import { Route, Routes } from 'react-router-dom'

import { ROUTES } from 'definitions'
import AuthenticatedRoute from 'components/Core/AuthenticatedRoute'
import AuthWrapper from 'components/Core/AuthWrapper'
import GlobalErrorHandler from 'components/Core/GlobalErrorHandler'
import LoginLayout from 'common/LoginLayout'
import MainLayout from 'common/MainLayout'
import PublicLayout from 'common/PublicLayout'
import WebshopCatalogLayout from 'components/Webshop/WebshopCatalogLayout'
import WebshopLayout from 'components/Webshop/WebshopLayout'

import BelfiusLogin from 'components/Login/BelfiusLogin'
import GoedLogin from 'components/Login/GoedLogin'
import InterparkingLogin from 'components/Login/InterparkingLogin'

import CaseDetail from 'components/Case/CaseDetail'
import Cases from 'components/Case/CaseList'
import ConnectionCarts from 'components/Connection/ConnectionsCarts'
import ConnectionList from 'components/Connection/ConnectionList'
import Feedback from 'components/Feedback'
import Forbidden from 'components/Forbidden'
import Home from 'components/Home'
import LandingPage from 'components/LandingPage'
import Login from 'components/Login'
import News from 'components/News'
import Offline from 'components/Offline'
import PageNotFound from 'components/PageNotFound'
import PraList from 'components/Pra/PraList'
import ResetPassword from 'components/ResetPassword'
import Suppliers from 'components/Suppliers'
import Webform from 'components/Webform'
import WebshopCart from 'components/Webshop/WebshopCart'
import WebshopCatalog from 'components/Webshop/WebshopCatalog'
import WebshopFavourites from 'components/Webshop/WebshopFavourites'
import WebshopHome from 'components/Webshop/WebshopHome'
import WebshopProductDetail from 'components/Webshop/WebshopProductDetail'
import WebshopSearch from 'components/Webshop/WebshopSearch'

const App = () => {
  return (
    <GlobalErrorHandler isApp>
      <Routes>
        <Route path={ROUTES.OFFLINE} element={<Offline />} />
        <Route element={<PublicLayout />}>
          <Route path={ROUTES.FEEDBACK} element={<Feedback />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route element={<LoginLayout />}>
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={ROUTES.LOGIN_BELFIUS} element={<BelfiusLogin />} />
            <Route path={ROUTES.LOGIN_GOED} element={<GoedLogin />} />
            <Route path={ROUTES.LOGIN_INTERPARKING} element={<InterparkingLogin />} />
          </Route>
          <Route element={<MainLayout />}>
            <Route element={<AuthenticatedRoute />}>
              <Route path={ROUTES.DEFAULT} element={<LandingPage />} />
              <Route path={ROUTES.HOME} element={<Home />} />
              <Route path={ROUTES.WEBFORM} element={<Webform />} />
              <Route path={ROUTES.CASES} element={<Cases />} />
              <Route path={`${ROUTES.CASES}/:id/:tab`} element={<CaseDetail />} />
              <Route path={`${ROUTES.CASES}/:id`} element={<CaseDetail />} />
              <Route path={ROUTES.SUPPLIERS} element={<Suppliers />} />
              <Route path={ROUTES.PRA} element={<PraList />} />
              <Route path={ROUTES.NEWS} element={<News />} />
              <Route element={<WebshopLayout />}>
                <Route path={ROUTES.WEBSHOP} element={<WebshopHome />} />
                <Route path={ROUTES.WEBSHOP_CART} element={<WebshopCart />} />
                <Route path={ROUTES.WEBSHOP_FAVOURITES} element={<WebshopFavourites />} />
                <Route path={`${ROUTES.WEBSHOP_PRODUCT}/:id`} element={<WebshopProductDetail />} />
                <Route element={<WebshopCatalogLayout />}>
                  <Route path={ROUTES.WEBSHOP_SEARCH} element={<WebshopSearch />} />
                  <Route path={`${ROUTES.WEBSHOP_CATALOG}/*`} element={<WebshopCatalog />} />
                  <Route path={`${ROUTES.WEBSHOP_PRODUCT}`} element={<PageNotFound />} />
                </Route>
              </Route>
              <Route path={ROUTES.CONNECTIONS} element={<ConnectionList />} />
              <Route path={ROUTES.CONNECTIONS_CART} element={<ConnectionCarts />} />
            </Route>
            <Route path={ROUTES.FORBIDDEN} element={<Forbidden />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
      </Routes>
    </GlobalErrorHandler>
  )
}

export default observer(App)
