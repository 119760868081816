import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'stores'
import Breadcrumbs from './Breadcrumbs'
import CartIcon from './CartIcon'
import CategoryMenu from './CategoryMenu'
import FavouritesDropdown from './FavouritesDropdown'
import SearchPanel from './SearchPanel'

export default observer(() => {
  const { pageResourceStore } = useStores()

  const {
    PageResource: { webshop: resource, loading: pageResourceLoading },
  } = pageResourceStore

  if (!resource) return false

  return (
    <div id="webshop-header" className="mb-4">
      <div className="d-flex flex-wrap align-items-center mb-3">
        <SearchPanel resource={resource} />
        <div className="d-inline-flex ms-md-auto col-md-auto ps-2">
          <FavouritesDropdown resource={resource} />
          <CartIcon resource={resource} />
        </div>
      </div>
      <div className="d-md-flex align-items-center">
        <CategoryMenu resource={resource} pageResourceLoading={pageResourceLoading} />
        <Breadcrumbs resource={resource} />
      </div>
    </div>
  )
})
