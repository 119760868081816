import BaseStore from 'stores/base'

import moment from 'moment'

import { formatDate } from 'utils/formatters'

export default class WebformStore extends BaseStore {
  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options
  }

  getInitialData(caseId) {
    return this.getRequest(
      this.CRUD,
      async () => {
        if (caseId) {
          // Copy from case
          return await this.getCopyFromCase(caseId)
        } else {
          const { data, error } = await this.service.get()

          if (data) {
            // default delivery info
            data.deliveryInfo = {
              requesterType: 'employee',
              addressType: 'client',
            }

            // default client site
            if (data.contact.companySiteId) {
              const clientSite = data.clientSites?.find(item => item.clientSiteId === data.contact.companySiteId)
              if (clientSite) {
                data.deliveryInfo.clientSite = clientSite
                data.deliveryInfo.deliveryAddress = clientSite.address
              }
            }

            // default requester
            if (data.settings.shouldSelectRequester) {
              const { data: requesterData } = await this.service.getUserRequester(true)
              if (requesterData) {
                data.deliveryInfo.requester = requesterData.userRequester
              }
            }
          }

          return { data, error }
        }
      },
      newData => {
        this.CRUD.data = newData
      }
    )
  }

  async getCopyFromCase(caseId) {
    // Copy from case
    const { data, error } = await this.service.getCopyFromCase(caseId)

    if (data) {
      const { subject, webformHeader, webformLines = [] } = data
      const { deliveryInfo: headerDeliveryInfo } = webformHeader

      const getDeliveryInfo = deliveryInfo => {
        const requesterType = deliveryInfo.requester?.requesterId ? 'employee' : 'other'
        return (
          deliveryInfo && {
            ...deliveryInfo,
            addressType: 'other',
            requesterType,
            deliveryDate: moment(deliveryInfo.deliveryDate).toDate(),
            requester: deliveryInfo.requester && {
              key: deliveryInfo.requester.requesterId,
              value: deliveryInfo.requester.displayname,
            },
          }
        )
      }

      return {
        error,
        data: {
          subject,
          ...webformHeader,
          deliveryInfo: getDeliveryInfo(headerDeliveryInfo),
          webformLines: webformLines.map((webformLine, lineIndex) => {
            const {
              selectedCategory,
              selectedSubcategory,
              selectedGLAccount,
              amount,
              productDescription,
              contractRemarks,
              deliveryInfo: webformLineDeliveryInfo,
            } = webformLine

            return {
              ...webformLine,
              lineIndex,
              isEdit: true,
              category: selectedCategory,
              subCategory: selectedSubcategory,
              glAccount: selectedGLAccount,
              quantity: amount && { amount: amount.quantity, unit: amount.unit },
              articleDescription: productDescription,
              remarks: contractRemarks,
              deliveryInfo: getDeliveryInfo(webformLineDeliveryInfo),
            }
          }),
        },
      }
    }

    return { data, error }
  }

  submitWebform(values) {
    const { subject, deliveryInfo: headerDeliveryInfo, projectCode, webformLines } = values

    const payload = {
      subject,
      articles: webformLines.map(item => {
        const {
          attachments,
          category,
          subCategory,
          deliveryInfo: itemDeliveryInfo,
          overrideDelivery,
          quantity,
          ...rest
        } = item
        const deliveryInfo = overrideDelivery ? itemDeliveryInfo : headerDeliveryInfo
        const address =
          deliveryInfo.addressType === 'client' ? deliveryInfo.clientAddress.address : deliveryInfo.deliveryAddress
        const clientSiteName = deliveryInfo.addressType === 'client' ? deliveryInfo.clientAddress.name : ''

        return {
          ...rest,
          categoryName: category.name,
          subcategory: subCategory?.name,
          amount: {
            amount: parseFloat(quantity.amount),
            unit: quantity.unit,
          },
          budget: {
            upperLimit: parseFloat(rest.budget.upperLimit),
          },
          attachmentFileIds: attachments?.added?.map(file => file.fileId) || [],
          deliveryInfo: {
            projectCode,
            deliveryDate: formatDate(deliveryInfo.deliveryDate),
            requester:
              deliveryInfo.requesterType === 'employee'
                ? { requesterId: deliveryInfo.requester.key }
                : { displayname: deliveryInfo.otherRequester },
            deliveryAddress: {
              country: {
                code: address.country.code,
              },
              street: address.street,
              number: address.number,
              postalCode: address.postalCode,
              city: address.city,
              building: address.building,
              box: address.box,
            },
            siteName: clientSiteName,
            isService: deliveryInfo.isService,
          },
        }
      }),
    }

    return this.post(null, payload)
  }
}
