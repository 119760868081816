import React from 'react'

import { useFormContext } from 'react-hook-form'
import cx from 'classnames'

import { FlexTableCell, FlexTableRow } from 'common/FlexTable'
import { formatCurrency } from 'utils/formatters'
import ButtonIcon from 'common/ButtonIcon'
import DatePicker from 'common/Forms/DatePicker'
import Input from 'common/Forms/Input'

export default ({ resource, lineItem, lineItemIndex, item, index, calculateTotals, onRemove }) => {
  const { getValues, setValue } = useFormContext()

  const { goodsReceived = {} } = lineItem

  const handleRemoveGoodsReceivedStatus = index => onRemove(index)

  const handleQtyChanged = index => {
    const item = getValues(`items.${lineItemIndex}.goodsReceived.items.${index}`)
    const quantityReceived = parseInt(item.quantityReceived || 0)
    const totalPrice = quantityReceived * item.price

    setValue(`items.${lineItemIndex}.goodsReceived.items.${index}.totalPrice`, totalPrice)
    setValue(`items.${lineItemIndex}.goodsReceived.items.${index}.totalPriceFormatted`, formatCurrency(totalPrice))

    calculateTotals()
  }

  return (
    <FlexTableRow
      className={cx({
        'alert-success': item.quantityReceived > 0,
        'alert-danger': item.quantityReceived < 0,
      })}
    >
      <FlexTableCell className="col-line-index" />
      <FlexTableCell className="col-2-half align-items-start">
        <DatePicker
          name={`items.${lineItemIndex}.goodsReceived.items.${index}.timestamp`}
          disabledDays={{ after: new Date() }}
          inputClassName="col"
        />
      </FlexTableCell>
      <FlexTableCell className="col align-items-start">
        <Input
          type="number"
          name={`items.${lineItemIndex}.goodsReceived.items.${index}.quantityReceived`}
          rules={{ required: resource.Required }}
          formGroup
          className="text-end"
          formClassName="col mb-0"
          onChange={event => handleQtyChanged(index, item, event.target.value)}
        />
      </FlexTableCell>
      <FlexTableCell className="col-1-half align-items-start justify-content-end">
        <span className="input-padding-y">{formatCurrency(item.price)}</span>
      </FlexTableCell>
      <FlexTableCell className="col-1-half align-items-start">
        <Input
          name={`items.${lineItemIndex}.goodsReceived.items.${index}.totalPriceFormatted`}
          value={formatCurrency(item.totalPrice)}
          textOnlyInput
          className="text-end input-total-price"
        />
        <Input
          type="hidden"
          name={`items.${lineItemIndex}.goodsReceived.items.${index}.totalPrice`}
          value={goodsReceived.totalPrice}
        />
      </FlexTableCell>
      <FlexTableCell className="col-4 align-items-start">
        <div className="d-flex col align-items-center">
          <Input name={`items.${lineItemIndex}.goodsReceived.items.${index}.comment`} />
          <ButtonIcon
            icon="clear"
            color="danger"
            title={resource.Delete}
            className="ms-2 p-1"
            iconClassName="text-danger md-22"
            onClick={() => handleRemoveGoodsReceivedStatus(index)}
          />
        </div>
      </FlexTableCell>
    </FlexTableRow>
  )
}
