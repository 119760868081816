import React from 'react'

import { InputGroup } from 'reactstrap'
import cx from 'classnames'

export default ({ children, startAdornment, endAdornment, className }) => {
  if (!startAdornment && !endAdornment) return children

  return (
    <InputGroup
      className={cx(className, {
        'start-adornment': !!startAdornment,
        'end-adornment': !!endAdornment,
      })}
    >
      {startAdornment && <span className="start-adornment">{startAdornment}</span>}
      {children}
      {endAdornment && <span className="end-adornment">{endAdornment}</span>}
    </InputGroup>
  )
}
