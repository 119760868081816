import React from 'react'

import AsyncSelect from 'react-select/async'
import cx from 'classnames'
import Select, { components } from 'react-select'

import { getValueByPath } from 'utils'
import { sort } from 'utils/array'
import { useDebounce } from 'hooks/useDebounce'
import { useStores } from 'stores'
import FormGroup from 'common/FormGroup'
import HelpText from 'common/HelpText'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({
  inputRef,

  value,
  defaultValue,
  label,
  placeholder,
  className,
  formClassName,
  labelClassName,
  inputColClassName,
  formGroup,
  horizontal,
  labelCol,
  inputCol,
  helpText,
  helpTextType,

  options,
  serverSide,
  loadData,
  loadDataPropertyKey,
  optionValueKey,
  optionLabelKey,
  defaultOptions: defaultOptionsProps,
  components: componentsProp,
  closeMenuOnSelect = true,
  noOptionsMessage,
  emptySearchResultMessage,
  emptySearchResultFunction,

  readOnlyField,
  ...rest
}) => {
  const { pageResourceStore } = useStores()
  const [defaultOptions, setDefaultOptions] = React.useState(defaultOptionsProps)

  const handleSearchDebounced = useDebounce((inputValue, callback) => {
    const doSearch = async () => {
      const result = await handleSearch(inputValue)
      callback && callback(result)
      !closeMenuOnSelect && setDefaultOptions(result)
    }
    doSearch()
  })

  const hasError = helpTextType === 'error' && !!helpText
  const {
    PageResource: { common: resource = {} },
  } = pageResourceStore

  const SelectComponent = serverSide ? AsyncSelect : Select
  const getOptionValue = item => (optionValueKey ? item[optionValueKey] : item.value)
  const getOptionLabel = item => (optionLabelKey ? item[optionLabelKey] : item.label)

  const readOnlyFieldValue = React.useMemo(() => {
    if (readOnlyField) {
      if (value) return getOptionLabel(value)
      if (defaultValue) return getOptionLabel(defaultValue)
    }
    return null
  }, [readOnlyField, value, defaultValue])

  const getNoOptionMessage = ({ inputValue }) => {
    if (serverSide) {
      if (!inputValue) {
        return resource.TypeToSearch
      } else if (inputValue && inputValue.length === 1) {
        return resource.Enter1MoreCharacter
      } else {
        if (emptySearchResultFunction) {
          return emptySearchResultFunction(inputValue)
        } else {
          return emptySearchResultMessage || (serverSide ? resource.SearchReturnsNothing : resource.NoOptions)
        }
      }
    } else {
      return noOptionsMessage || resource.NoOptions
    }
  }

  const getLoadingMessage = ({ inputValue }) => {
    if (serverSide && inputValue && inputValue.length === 1) {
      return resource.Enter1MoreCharacter
    }
    return resource.Loading
  }

  const handleLoadOptions = (inputValue, callback) => {
    if ((!inputValue && defaultOptions === true) || (!!inputValue && inputValue.length > 1)) {
      handleSearchDebounced(inputValue, callback)
    } else {
      callback([])
    }
  }

  const handleSearch = async inputValue => {
    try {
      const { data, error: theError } = await loadData({
        ...(inputValue && { search: inputValue }),
      })

      const content = (loadDataPropertyKey ? data[loadDataPropertyKey] : data) || []
      let dataOptions

      if (!theError && content && content.length) {
        if (optionLabelKey) {
          dataOptions = sort(
            content.map(item => ({
              ...item,
              [optionLabelKey]: optionLabelKey.includes('.')
                ? getValueByPath(item, optionLabelKey)
                : item[optionLabelKey],
            })),
            optionLabelKey
          )
        } else {
          dataOptions = sort(content, 'label')
        }
      }
      return dataOptions || []
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('react select search error')
      // eslint-disable-next-line no-console
      console.log(e)
      return []
    }
  }

  return (
    <FormGroup
      {...{
        label,
        formGroup,
        horizontal,
        labelCol,
        inputCol,
        className: cx(readOnlyField && 'read-only', formClassName),
        labelClassName,
        inputColClassName,
        hasError,
      }}
    >
      {readOnlyField && <ReadOnlyField className={className} value={readOnlyFieldValue} />}
      {!readOnlyField && (
        <React.Fragment>
          <SelectComponent
            innerRef={inputRef}
            value={value}
            defaultValue={defaultValue}
            options={options}
            className={cx('react-select', className)}
            classNamePrefix="react-select"
            placeholder={placeholder || ' '}
            {...(serverSide && loadData
              ? {
                  loadOptions: handleLoadOptions,
                  defaultOptions,
                }
              : {})}
            {...(optionValueKey ? { getOptionValue } : {})}
            {...(optionLabelKey ? { getOptionLabel } : {})}
            components={{
              Control: ({ children, className, ...props }) => (
                <components.Control
                  className={cx('form-control', hasError && 'invalid border-danger', className)}
                  {...props}
                >
                  {children}
                </components.Control>
              ),
              ...componentsProp,
            }}
            closeMenuOnSelect={closeMenuOnSelect}
            loadingMessage={getLoadingMessage}
            noOptionsMessage={getNoOptionMessage}
            {...rest}
          />
          {!!helpText && <HelpText hasError={hasError}>{helpText}</HelpText>}
        </React.Fragment>
      )}
    </FormGroup>
  )
}
