import React from 'react'

import { useStores } from 'stores'
import Button from 'common/Button'
import CheckPunchoutSession from 'components/Core/CheckPunchoutSession'
import ContentLoader from 'common/ContentLoader'
import Image from 'common/Image'

import ConnectionListHeader from './ConnectionListHeader'

export default () => {
  const { pageResourceStore, connectionStore } = useStores()

  const [loading, setLoading] = React.useState(false)
  const [connectionId, setConnectionId] = React.useState()

  const {
    PageResource: { connections: resource = {} },
  } = pageResourceStore
  const {
    CRUD: { submitting },
    Table: {
      data: { connections: data = [] },
    },
  } = connectionStore

  React.useEffect(() => {
    const getConnections = async () => {
      setLoading(true)
      await Promise.allSettled([connectionStore.fetchTable(), pageResourceStore.get('connections')])
      setLoading(false)
    }
    getConnections()
  }, [pageResourceStore, connectionStore])

  const handleSubmit = async connectionId => {
    setConnectionId(connectionId)
    const result = await connectionStore.connect(connectionId)
    if (result) {
      window.location = result.connectionUrl
    } else {
      setConnectionId(null)
    }
  }

  return (
    <div id="connection-list" className="position-relative h-100">
      <CheckPunchoutSession message={resource?.PunchoutExpired} />
      <ContentLoader show={loading} className="pt-6" />
      <ConnectionListHeader resource={resource} />
      <div className="row">
        {data.map((item, index) => (
          <div className="col-xxl-3 col-lg-4 col-sm-6 col-12 py-3" key={index}>
            <Button
              type="button"
              color="default"
              outline
              disabled={submitting}
              className="w-100 position-relative"
              onClick={() => handleSubmit(item.id)}
            >
              <ContentLoader show={connectionId === item.id && submitting} size="xl" />
              <h5 className="mb-5 text-start">{item.name}</h5>
              <div className="d-inline-flex align-items-center justify-content-center w-100">
                <Image src={`/connections/image/${item.imageFileName}`} alt={item.name} className="connection-logo" />
              </div>
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}
