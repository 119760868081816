import React from 'react'

import { PRA_STATUS } from 'definitions'
import { useStores } from 'stores'
import Button from 'common/Button'

export default ({ resource, praItem, onCopyPra, onEditPra, onGoodsReceived }) => {
  const { authStore } = useStores()
  const { approvers } = praItem
  const action = praItem.action?.name?.toLowerCase()
  const {
    AppSession: { session },
  } = authStore

  const ActioButton = React.useMemo(() => {
    let label
    let color
    let icon

    const isAnswer = approvers.some(
      approver =>
        approver.statusLabel === 'Unstarted' &&
        approver.question &&
        approver.question.to === authStore.getLoginEmployeeId()
    )
    if (isAnswer) {
      label = resource.AnswerQuestion
      color = 'primary'
      icon = 'question_answer'
    } else {
      switch (action) {
        case 'view':
          label = resource.View
          color = 'default'
          icon = 'visibility'
          break
        case 'edit':
          label = resource.Edit
          color = 'primary'
          icon = 'edit'
          break
        case 'ask':
          label = resource.Ask
          color = 'primary'
          icon = 'question_answer'
          break
        case 'answer':
          label = resource.Answer
          color = 'primary'
          icon = 'question_answer'
          break
        case 'relaunch':
          label = resource.RelaunchArchive
          color = 'warning'
          icon = 'rocket'
          break
        case 'archive':
          label = resource.Archive
          color = 'primary'
          icon = 'archive'
          break
        case 'reassign':
          label = resource.Reassign
          color = 'primary'
          icon = 'assignment_ind'
          break
        case 'approve':
          label = resource.Action
          color = 'warning'
          icon = 'gavel'
          break
        default:
          return null
      }
    }

    return (
      <Button icon={icon} color={color} size="sm" className="with-text" onClick={onEditPra}>
        <span className="d-sm-inline d-none">{label}</span>
      </Button>
    )
  }, [resource, approvers, authStore, action])

  const isShowCopy =
    [PRA_STATUS.APPROVED.value, PRA_STATUS.PENDING.value, PRA_STATUS.UNSTARTED.value].includes(praItem.status) &&
    !session.disableCopyPraButton.includes(praItem.originName)

  return (
    <div className="actions col-xl-auto col ms-auto d-flex justify-content-end align-items-md-start align-items-xl-center align-items-end">
      {isShowCopy && (
        <Button
          type="button"
          icon="content_copy"
          color="default"
          size="sm"
          className="me-2"
          onClick={onCopyPra}
          title={resource.Copy}
        />
      )}
      {praItem.enableGoodsReceived && (
        <Button icon="local_shipping" color="primary" size="sm" className="with-text me-2" onClick={onGoodsReceived}>
          <span className="d-sm-inline d-none">{resource.ManageGoodsReceived}</span>
        </Button>
      )}
      {ActioButton}
    </div>
  )
}
