import React from 'react'

import { Badge } from 'reactstrap'

import { CASE_TYPE } from 'definitions'
import { useStores } from 'stores'
import Button from 'common/Button'
import Icon from 'common/Icon'

export default ({
  session,
  resource,
  caseItem,
  submitting,
  onViewCase,
  onCaseFeedback,
  onCopyCase,
  onAbortCase,
  onReleaseCase,
  onWebshopPunchout,
}) => {
  const { authStore } = useStores()
  const isDiyCase = caseItem.type === CASE_TYPE.SelfService

  const isShowCopy = () => {
    switch (caseItem.type) {
      case 'Webform':
        return true
      case 'BeforeTheFacts':
      case 'AfterTheFacts':
      case 'ContractPo':
        return session.hasPra && caseItem.hasPoLines
      default:
        return false
    }
  }

  const isShowActionButton = () => {
    const loggedinEmployeeId = authStore.getLoginEmployeeId()
    return (
      (caseItem.hasProposal && loggedinEmployeeId === caseItem.requester?.employeeId) || caseItem.showWebshopPunchout
    )
  }

  const isShowRejectedButton = () => {
    const loggedinEmployeeId = authStore.getLoginEmployeeId()
    return caseItem.isRejectedProposal && loggedinEmployeeId === caseItem.requester?.employeeId
  }

  return (
    <div className="actions col-xl-auto col ms-auto d-flex justify-content-end align-items-md-start align-items-xl-center align-items-end">
      {!caseItem.controllerReleased && (
        <Badge color="warning" title={resource.ToRelease} className="text-uppercase me-2 px-4">
          {resource.Pending}
        </Badge>
      )}
      {isShowCopy() && (
        <Button
          type="button"
          icon="content_copy"
          color="default"
          size="sm"
          disabled={submitting}
          onClick={onCopyCase}
          title={resource.Copy}
        />
      )}
      {isShowActionButton() && (
        <Button
          type="button"
          icon="gavel"
          className="me-2 with-text"
          color="warning"
          size="sm"
          disabled={submitting}
          {...(caseItem.showWebshopPunchout ? { onClick: onWebshopPunchout } : {})}
        >
          <span className="d-sm-inline d-none">{resource.Action}</span>
        </Button>
      )}
      {!isShowActionButton() && isShowRejectedButton() && (
        <Button
          type="button"
          icon="block"
          className="me-2 with-text"
          color="danger"
          size="sm"
          disabled={submitting}
          onClick={onViewCase}
        >
          <span className="d-sm-inline d-none">{resource.Rejected}</span>
        </Button>
      )}
      {!isShowActionButton() && !isShowRejectedButton() && (
        <React.Fragment>
          <Button
            type="button"
            icon={isDiyCase ? 'edit' : 'visibility'}
            className={'me-2 with-text'}
            color={isDiyCase ? 'primary' : 'default'}
            size="sm"
            onClick={onViewCase}
            disabled={submitting}
          >
            <span className="d-sm-inline d-none">{isDiyCase ? resource.Edit : resource.View}</span>
          </Button>
          {!caseItem.controllerReleased && session.hasClientControllerRole && (
            <React.Fragment>
              <Button
                type="button"
                icon="gavel"
                color="danger"
                size="sm"
                className="with-text"
                onClick={onAbortCase}
                disabled={submitting}
              >
                <span className="d-sm-inline d-none">{resource.Abort}</span>
              </Button>
              <Button
                type="button"
                icon="check"
                color="success"
                size="sm"
                className="with-text"
                onClick={onReleaseCase}
                disabled={submitting}
              >
                <span className="d-sm-inline d-none">{resource.Release}</span>
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <Button
        type="button"
        color="default"
        size="sm"
        title={resource.Feedback}
        className="d-inline-flex align-items-center me-0"
        onClick={onCaseFeedback}
        disabled={submitting}
      >
        <Icon icon="thumb_up_alt" className="me-1 text-success" />
        <Icon icon="thumb_down_alt" className="text-danger" />
      </Button>
    </div>
  )
}
