import { makeObservable, observable } from 'mobx'

import BaseStore, { defaults } from 'stores/base'

class PageResourceStore extends BaseStore {
  PageResource = defaults.CRUD

  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options

    makeObservable(this, {
      PageResource: observable,
    })
  }

  async get(key, url) {
    if (!this.PageResource[key]) {
      return await this.getRequest(
        this.PageResource,
        async () => this.service.get(url || key),
        newData => {
          this.PageResource[key] = newData.resource
        }
      )
    }
    return this.PageResource[key]
  }
}

export default PageResourceStore
