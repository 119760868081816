import React from 'react'

import { observer } from 'mobx-react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { useStores } from 'stores'
import Toaster from 'common/Toaster'

import CartTable from './CartTable'

export default observer(({ cart, disabled, updateCart, onClearCart, onEnableMultiAddress }) => {
  const { authStore, pageResourceStore, webshopCartStore, webshopPricingStore } = useStores()
  const { control, setValue } = useFormContext()
  const { fields, remove, replace } = useFieldArray({
    control,
    name: 'items',
    shouldUnregister: true,
  })

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { webshop: resource },
  } = pageResourceStore
  const {
    Lookups: { data: { categories, glAccounts } = {} },
    Table: { settings = {} },
  } = webshopCartStore
  const {
    ItemPricingTier: { data: pricingTiersLookup = [], loading: pricingTierLoading },
  } = webshopPricingStore

  const setPricingTier = (index, pricingTier) => {
    if (pricingTier) {
      setValue(`items.${index}.amount`, pricingTier.amount)
      setValue(`items.${index}.amountVatIncluded`, pricingTier.amountVatIncluded)
      setValue(`items.${index}.pricingTier`, pricingTier)
      setValue(`items.${index}.subTotal`, pricingTier.subTotal)
      setValue(`items.${index}.subTotalVatIncluded`, pricingTier.subTotalVatIncluded)
    }
  }

  React.useEffect(() => {
    replace(cart.items)
  }, [replace, cart])

  useDeepCompareEffect(() => {
    // Assign pricing tiers
    if (fields.length && !pricingTierLoading) {
      if (Object.keys(pricingTiersLookup).length) {
        fields.forEach((item, index) => {
          const pricingTier = webshopPricingStore.getItemPricingTier(item.itemId, item.quantity)
          setPricingTier(index, pricingTier)

          handleOnBeforeChange(index, item.quantity, item.stock)

          // Handle changing of quantity to stock value if it reaches StockStop
          const newQuantity = item.hasStockStop ? item.stock : item.quantity
          handleQuantityChange(index, item, newQuantity, true)
        })
        updateCart({ isCalculateCartQuantity: true })
        setValue('cartReady', true)
        return
      }
    }

    setValue('cartReady', false)
  }, [webshopPricingStore, fields, pricingTiersLookup, pricingTierLoading, setValue])

  const handleQuantityChange = (index, cartItem, newQuantity, skipSave) => {
    if (newQuantity) {
      const pricingTier = webshopPricingStore.getItemPricingTier(cartItem.itemId, newQuantity)
      setPricingTier(index, pricingTier)
      setValue(`items.${index}.quantity`, newQuantity)
      !skipSave && updateCart({ isCalculateCartQuantity: true })
    }
  }

  const handleOnBeforeChange = (index, quantity, stock) => {
    const name = `items.${index}.itemStockStop`
    if (quantity) {
      const hasStockStop = stock !== null && quantity > stock
      setValue(name, hasStockStop)
    } else {
      setValue(name, false)
    }
    return true
  }

  const handleMultiAddressCountChange = newQuantity => {
    setValue('multiAddressCount', newQuantity)
    if (newQuantity) {
      updateCart()
    }
  }

  const handleDeleteCartItem = ({ itemIndex, itemId, calculateDeliveryDate }) => {
    const isClearCart = fields.length <= 1

    if (isClearCart) {
      onClearCart()
    } else {
      Toaster.confirm({
        title: resource.DeleteCartItem,
        message: resource.ConfirmDeleteCartItem,
        submitText: resource.Yes,
        cancelText: resource.Cancel,
        closeOnSubmit: false,
        onSubmit: async closeModal => {
          const result = await webshopCartStore.removeCartItem({
            cartId: cart.id,
            itemId,
          })
          if (result) {
            remove(itemIndex)
            cart.items.splice(itemIndex, 1);
            updateCart({ isCalculateCartQuantity: true, calculateDeliveryDate })
          }
          closeModal()
        },
      })
    }
  }

  return (
    <CartTable
      resource={resource}
      session={session}
      settings={settings}
      cart={cart}
      fields={fields}
      categories={categories}
      glAccounts={glAccounts}
      disabled={disabled}
      updateCart={updateCart}
      onQuantityChange={handleQuantityChange}
      onMultiAddressCountChange={handleMultiAddressCountChange}
      onDeleteCartItem={handleDeleteCartItem}
      onEnableMultiAddress={onEnableMultiAddress}
      onBeforeChange={handleOnBeforeChange}
    />
  )
})
