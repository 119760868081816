import React from 'react'

import { Link } from 'react-router-dom'

import { ROUTES } from 'definitions'

export default ({ resource, subCriteria }) => {
  const noResultsFound = resource.NoResultsFound
  const translationFor = resource.ForTranslation
  const trySearchingAgain = resource.TrySearchingAgain

  return (
    <div className="py-2 px-3 webshop-text-color fw-bold">
      <p>{`${noResultsFound}${subCriteria ? ` ${translationFor} "${subCriteria}"` : ''}`}</p>
      <p>
        {trySearchingAgain} <Link to={ROUTES.WEBFORM}>{resource.WebForm}</Link>
      </p>
    </div>
  )
}
