import React from 'react'

import { useFormContext, useFormState } from 'react-hook-form'
import cx from 'classnames'

import { isSsoSaml } from 'utils/auth'
import { validatePassword } from 'utils/validators'
import Card from 'common/Card'
import Input from 'common/Forms/Input'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({ resource, session, disabled }) => {
  const { getValues } = useFormContext()
  const { errors } = useFormState()

  return (
    <React.Fragment>
      {!session.isSecured && !isSsoSaml(session.loginProvider) && !session.hasPunchoutSession && (
        <Card title={resource.ChangePassword} fieldset>
          <Input
            name="newPassword"
            rules={{
              validate: value => !value || validatePassword(value, resource.NewPasswordValidation),
            }}
            label={resource.NewPassword}
            placeholder={resource.NewPassword}
            formGroup
            horizontal
            labelCol={{ lg: 3 }}
            inputCol={{ lg: 5 }}
            disabled={disabled}
          />
          <Input
            name="confirmPassword"
            rules={{
              validate: value => {
                const { newPassword } = getValues()
                return !newPassword || newPassword === value
              },
            }}
            placeholder={`${resource.NewPassword} ${resource.Repeat}`}
            formGroup
            horizontal
            inputCol={{ lg: { offset: 3, size: 5 } }}
            formClassName="mb-0"
            className={cx(errors.confirmPassword && 'invalid')}
            disabled={disabled}
          />
          <ReadOnlyField
            value={resource.EnterNewPasswordTwice}
            formGroup
            horizontal
            inputCol={{
              lg: { offset: 3, size: 5 },
            }}
            className={cx('fs-12', errors.confirmPassword ? 'text-danger' : 'text-secondary')}
          />
        </Card>
      )}
    </React.Fragment>
  )
}
