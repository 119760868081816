import React from 'react'

import { Badge } from 'reactstrap'
import { observer } from 'mobx-react'

import { CASE_TYPE } from 'definitions'
import { useStores } from 'stores'
import Button from 'common/Button'
import Card from 'common/Card'
import Toaster from 'common/Toaster'

import PrFormModal from './PrFormModal'
import PrViewModal from './PrViewModal'
import PurchaseRequestTable from './PurchaseRequestTable'

export default observer(({ activated, clientId, caseId, caseNumber, caseType, onReloadCase }) => {
  const { authStore, pageResourceStore, purchaseRequestStore } = useStores()
  const [prViewModalOption, setPrViewModalOption] = React.useState({})
  const [prFormModalOption, setPrFormModalOption] = React.useState({})

  const { AppSession: { session } = {} } = authStore
  const {
    PageResource: { case: resource = {}, common: commonResource = {} },
  } = pageResourceStore
  const {
    Table: { data = {}, loading },
  } = purchaseRequestStore
  const prData = data.purchaseRequest

  React.useEffect(() => {
    activated && loadPurchaseRequest(caseId)
  }, [activated, caseId])

  const loadPurchaseRequest = caseId => {
    purchaseRequestStore.fetchTable(caseId)
  }

  const togglePrViewModal = prLine => setPrViewModalOption({ isOpen: !!prLine, prLine })

  const handleAddPrLine = () => setPrFormModalOption({ isOpen: true })

  const handleEditPrLine = prLine => {
    setPrFormModalOption({ isOpen: true, prLine, action: 'edit' })
  }

  const handleCopyPrLine = prLine => setPrFormModalOption({ isOpen: true, prLine, action: 'copy' })

  const handleDeletePrLine = prLine => {
    Toaster.confirm({
      title: resource.RemoveLine,
      message: resource.RemoveLineConfirmationMessage,
      submitText: commonResource.Confirm,
      cancelText: commonResource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const result = await purchaseRequestStore.deletePrLine({
          caseId,
          lineIndex: prLine.lineIndex,
        })
        closeModal()
        result && loadPurchaseRequest()
      },
    })
  }

  const handlePrFormSubmit = isEdit => {
    if (!isEdit && (!prData.lines || !prData.lines.length)) {
      onReloadCase(caseId)
    }
    loadPurchaseRequest(caseId)
  }

  return (
    <div id="purchaseRequest">
      <Card
        color="primary"
        outline
        title={
          <div className="d-flex">
            {resource.PurchaseRequest}
            <div className="ms-auto">
              {prData && prData.projectCode && (
                <Badge color="warning" className="me-2">
                  {resource.ProjectCode}: {prData.projectCode}
                </Badge>
              )}
              {prData && prData.prReference && (
                <Badge color="info">
                  {resource.PRReference}: {prData.prReference}
                </Badge>
              )}
            </div>
          </div>
        }
        footer={
          caseType === CASE_TYPE.SelfService && (
            <Button icon="add" color="primary" onClick={handleAddPrLine} disabled={loading}>
              {resource.AddPrline}
            </Button>
          )
        }
      >
        <PurchaseRequestTable
          session={session}
          resource={resource}
          loading={loading}
          prData={prData}
          caseType={caseType}
          onViewPrLine={togglePrViewModal}
          onEditPrLine={handleEditPrLine}
          onCopyPrLine={handleCopyPrLine}
          onDeletePrLine={handleDeletePrLine}
        />
        {prData && prViewModalOption.isOpen && (
          <PrViewModal
            resource={resource}
            commonResource={commonResource}
            caseNumber={caseNumber}
            settings={prData.settings}
            prLine={prViewModalOption.prLine}
            onClose={() => togglePrViewModal()}
          />
        )}
        {prData && prFormModalOption.isOpen && (
          <PrFormModal
            session={session}
            clientId={clientId}
            caseId={caseId}
            caseNumber={caseNumber}
            prTabData={data}
            prLine={prFormModalOption.prLine}
            action={prFormModalOption.action}
            onSubmit={handlePrFormSubmit}
            onClose={() => setPrFormModalOption({})}
          />
        )}
      </Card>
    </div>
  )
})
