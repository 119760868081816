import React from 'react'

import { formatDate } from 'utils/formatters'
import Card from 'common/Card'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({ resource, data }) => {
  const deliveryInfo = data.deliveryInfo

  return (
    <Card title={resource.DeliveryInformation} fieldset>
      {deliveryInfo.isMultiAddress && (
        <React.Fragment>
          <ReadOnlyField formGroup horizontal label={resource.Building} />
          <ReadOnlyField
            formGroup
            horizontal
            label={resource.PreferredDate}
            value={formatDate(deliveryInfo.deliveryDate)}
          />
          {deliveryInfo.requester && (
            <ReadOnlyField formGroup horizontal label={resource.DeliverTo} value={deliveryInfo.requester.displayname} />
          )}
          <ReadOnlyField formGroup horizontal label={resource.Address} value={resource.MultipleDeliveryAddress} />
          <ReadOnlyField formGroup horizontal label={resource.Location} />
          <ReadOnlyField formGroup horizontal label={resource.Country} />
        </React.Fragment>
      )}
      {!deliveryInfo.isMultiAddress && (
        <React.Fragment>
          <ReadOnlyField formGroup horizontal label={resource.Building} value={deliveryInfo.deliveryAddress.building} />
          <ReadOnlyField
            formGroup
            horizontal
            label={resource.PreferredDate}
            value={formatDate(deliveryInfo.deliveryDate)}
          />
          {deliveryInfo.requester && (
            <ReadOnlyField formGroup horizontal label={resource.DeliverTo} value={deliveryInfo.requester.displayname} />
          )}
          <ReadOnlyField
            formGroup
            horizontal
            label={resource.Address}
            value={`${deliveryInfo.deliveryAddress.street}
                    ${deliveryInfo.deliveryAddress.number} ${deliveryInfo.deliveryAddress.box}`}
            valueClassName="word-wrap-break-word"
          />
          <ReadOnlyField
            formGroup
            horizontal
            label={resource.Location}
            value={`${deliveryInfo.deliveryAddress.postalCode}
                    ${deliveryInfo.deliveryAddress.city}`}
          />
          <ReadOnlyField
            formGroup
            horizontal
            label={resource.Country}
            value={deliveryInfo.deliveryAddress.country.name}
          />
        </React.Fragment>
      )}
    </Card>
  )
}
