import React from 'react'

import ProdcutInfoTitle from './ProdcutTitle'
import ProductImages from './ProductImages'
import ProductInfo from './ProductInfo'

export default ({ resource, data, handleSelectedCustomField }) => {
  return (
    <div className="product-info-panel col-md col-12 border-start border-end px-md-3">
      <ProdcutInfoTitle resource={resource} data={data} />
      <div className="d-flex flex-wrap flex-xxl-row flex-column">
        <ProductImages data={data} />
        <ProductInfo resource={resource} data={data} handleSelectedCustomField={handleSelectedCustomField} />
      </div>
    </div>
  )
}
