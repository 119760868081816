import BaseService from 'services/base'

export default class PraService extends BaseService {
  constructor(api) {
    super(api)
    this.api = api
  }

  fetchTable(payload, options) {
    return this.post('searchpr/search', payload, options)
  }

  preparePra(payload, options) {
    return this.post('preparepra/prepare', payload, options)
  }

  createByKey(payload, options) {
    return this.post('createpra/createbykey', payload, options)
  }

  getApprovers(payload, options) {
    return this.post('getapprovers/getapprovers', payload, options)
  }

  fetchLookups(payload, options) {
    return this.post('getlookups/getlookups', payload, options)
  }

  searchSuppliers(payload, options) {
    return this.post('getsuppliers/getsuppliers', payload, options)
  }

  fetchGlAccounts(payload, options) {
    return this.post('getlookups/getglaccounts', payload, options)
  }

  fetchJobCodes(payload, options) {
    return this.post('getlookups/getjobcodes', payload, options)
  }

  fetchExpenseCodes(payload, options) {
    return this.post('getlookups/getexpensecodes', payload, options)
  }

  fetchDepartments(payload, options) {
    return this.post('getlookups/getdepartments', payload, options)
  }

  fetchDivisions(payload, options) {
    return this.post('getlookups/getdivisions', payload, options)
  }

  fetchBranches(payload, options) {
    return this.post('getlookups/getbranches', payload, options)
  }

  fetchCrops(payload, options) {
    return this.post('getlookups/getcrops', payload, options)
  }

  upload(payload, options) {
    return this.post('file/upload', payload, options)
  }

  submitPra(payload, options) {
    return this.post('createpra/create', payload, options)
  }

  updatePra(payload, options) {
    return this.post('dopraaction/doaction', payload, options)
  }
}
