import React from 'react'

// import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import { ROUTES, SERVICE_CONTRACT } from 'definitions'
import { sortByNumber } from 'utils/array'
import { useDebounce } from 'hooks/useDebounce'
import { useStateCallback } from 'hooks/useStateCallback'
import { useStores } from 'stores'
import Dropdown from 'common/Dropdown'
import Icon from 'common/Icon'
import Input from 'common/Input'

import SearchResult from './SearchResult'

export default ({ resource }) => {
  const { authStore, webshopStore, webshopCategoryStore } = useStores()
  const [isOpen, setIsOpen] = useStateCallback(false)
  const [results, setResults] = React.useState({})
  const beginSearchDebounced = useDebounce(event => beginSearch(event))
  const navigate = useNavigate()
  const criteriaRef = React.useRef()

  const {
    AppSession: { session },
  } = authStore
  const {
    Config: {
      data: { attributes: attributeConfigs },
      loading: configLoading,
    },
  } = webshopStore

  React.useEffect(() => {
    const closeDrawerWhenClickOutside = event => {
      // Custom handle closing of menu
      if (isOpen) {
        const menuResult = document.getElementById('cartDropdownSearch')
        if (menuResult) {
          const isClickInside = menuResult.contains(event.target)
          if (!isClickInside) {
            setIsOpen(false)
          }
        }
      }
    }

    window.document.addEventListener('click', closeDrawerWhenClickOutside)
    return () => {
      window.document.removeEventListener('click', closeDrawerWhenClickOutside)
    }
  }, [isOpen])

  React.useEffect(() => {
    const criteria = criteriaRef.current
    if (isOpen && criteria) {
      searchCategories(criteria)
      searchItemAggregrates(criteria)
      searchItems(criteria, SERVICE_CONTRACT.SPOT_BUY_CENTER_CATALOG)
      searchItems(criteria, SERVICE_CONTRACT.CLIENT_CATALOG)
    }
  }, [isOpen])

  const beginSearch = event => {
    const criteria = event.target.value
    criteriaRef.current = criteria

    setResults({})
    if (criteria) {
      setIsOpen(null, () => setIsOpen(true))
    }
  }

  const searchItems = async (criteria, serviceContract) => {
    const result = await webshopStore.searchItems({
      criteria,
      languageCode: session.uiLanguageSetting.toLowerCase(),
      pageSize: 10,
      serviceContract,
    })

    const { items, total } = result

    buildItemsResult(serviceContract, items, total)
  }

  const searchItemAggregrates = async criteria => {
    const aggregates = attributeConfigs
      ? attributeConfigs
          .filter(item => !!item.searchKey)
          .map(item => ({
            name: item.attributeName,
            searchKey: item.searchKey,
            searchKeyField: item.searchKeyField,
            displayName: item.displayName,
          }))
      : null

    const result = await webshopStore.searchItems({
      criteria,
      languageCode: session.uiLanguageSetting.toLowerCase(),
      pageSize: 0,
      aggregates,
    })

    const { itemAttributes } = result

    buildItemAttributeResult(aggregates, itemAttributes)
  }

  const searchCategories = async criteria => {
    const result = await webshopCategoryStore.searchCategories({
      criteria,
      languageCode: session.uiLanguageSetting.toLowerCase(),
    })

    const { categories, total } = result

    buildCategoriesResult(categories, total)
  }

  const buildItemsResult = (serviceContract, items, total) => {
    if (!isOpen) return

    if (items.length > 0) {
      const newResult = {}
      // Items
      if (serviceContract.toLowerCase() === SERVICE_CONTRACT.CLIENT_CATALOG.toLocaleLowerCase()) {
        newResult.clientItemResult = { items, total }
      } else {
        newResult.sbcItemResult = { items, total }
      }

      // Favourites
      const favourites = items.filter(item => item.favouriteId != null)
      const favouriteIds = new Set(favourites.map(current => current.favouriteId))
      newResult.favouriteResult = [
        ...favourites,
        ...(results.favouriteResult || []).filter(current => !favouriteIds.has(current.favouriteId)),
      ].slice(0, 10)

      setResults(prev => ({ ...prev, ...newResult }))
    }
  }

  const buildItemAttributeResult = (aggregates, itemAttributes) => {
    if (!isOpen) return

    if (itemAttributes && itemAttributes.length > 0) {
      // Attributes
      let attributeResult = itemAttributes
        .filter(item => item.attributeName !== 'categories' && item.values && item.values.length)
        .map(item => {
          const attributeConfig = aggregates.find(attrConfig => attrConfig.name === item.attributeName)
          const values = item.values ? sortByNumber(item.values.slice(0, 10)) : []

          return {
            ...item,
            searchKey: attributeConfig.searchKey,
            displayName: attributeConfig ? attributeConfig.displayName : null,
            values,
          }
        })
      if (attributeResult && attributeResult.length <= 0) {
        attributeResult = null
      }

      setResults(prev => ({ ...prev, attributeResult }))
    }
  }

  const buildCategoriesResult = (categories, total) => {
    if (!isOpen) return

    if (categories.length > 0) {
      setResults(prev => ({
        ...prev,
        categoryResult: { categories, total },
      }))
    }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      const criteria = event.target.value
      event.target.value = null
      navigate(`${ROUTES.WEBSHOP_SEARCH}?criteria=${criteria}`)
      setIsOpen(false)
    }
  }

  const handleClick = () => setIsOpen(true)

  const hasResult = !!Object.keys(results).length

  return (
    <div className="search-panel col-lg-9 col-md-10 col">
      <Dropdown
        id="cartDropdownSearch"
        isOpen={isOpen && hasResult}
        toggle={() => {}} // Empty toggle function
        menuClassName="w-100 p-0"
        toggleClassName="w-100"
        disabled={configLoading}
        customToggle={
          <Input
            startAdornment={<Icon icon="search" />}
            placeholder={resource.HeaderSearchPlaceholder}
            onClick={handleClick}
            onChange={beginSearchDebounced}
            onKeyDown={handleKeyDown}
          />
        }
      >
        <SearchResult
          webshopStore={webshopStore}
          resource={resource}
          criteria={criteriaRef.current}
          clientName={results.clientName}
          results={results}
          onClose={() => setIsOpen(false)}
        />
      </Dropdown>
    </div>
  )
}
