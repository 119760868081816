import React from 'react'

import cx from 'classnames'

import Icon from 'common/Icon'

export default ({ data = {}, hideLabel, className }) => {
  return (
    <a
      href={`${process.env.REACT_APP_API_URL}/file/${data.fileId}`}
      download={data.fileName}
      title={data.fileName}
      className={cx('file-link d-inline-block', className)}
    >
      <Icon icon="attachment" className="me-1 align-middle" />
      {!hideLabel && data.fileName}
    </a>
  )
}
