import React from 'react'

import { Alert } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react'

import { REMEMBER_ME, ROUTES } from 'definitions'
import { useAuthManager } from 'hooks/useAuthManager'
import { useStores } from 'stores'
import Button from 'common/Button'
import Checkbox from 'common/Forms/Checkbox'
import Icon from 'common/Icon'
import Input from 'common/Forms/Input'
import MessageList from 'common/MessageList'
import Paper from 'common/Paper'

const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const methods = useForm()
  const { authStore } = useStores()
  const authManager = useAuthManager()
  const [showForgotPassword, setShowForgotPassword] = React.useState(location.state?.showForgotPasswordMessage)
  const [loading, setLoading] = React.useState(false)

  const { handleSubmit, setValue } = methods
  const {
    AppSession: { error },
  } = authStore

  React.useEffect(() => {
    if (location.state?.showForgotPasswordMessage) {
      navigate(ROUTES.LOGIN, { replace: true })
    } else if (searchParams.get('logout')) {
      setSearchParams({})
    }
    showRememberMe()
  }, [])

  React.useEffect(() => {
    return () => authStore.clearErrorMessages()
  }, [authStore])

  const handleOnSubmit = async values => {
    setShowForgotPassword(false)
    try {
      setLoading(true)
      saveRememberMe(values.rememberMe, values.username)
      await authManager.login(values)
    } finally {
      setLoading(false)
    }
  }

  const saveRememberMe = (isRememberMe, userName) => {
    if (isRememberMe) {
      localStorage.setItem(REMEMBER_ME, userName)
    } else {
      if (localStorage.getItem(REMEMBER_ME)) {
        localStorage.removeItem(REMEMBER_ME)
      }
    }
  }

  const showRememberMe = () => {
    const userName = localStorage.getItem(REMEMBER_ME)
    if (userName) {
      setValue('username', userName)
      setValue('rememberMe', true)
    }
  }

  return (
    <Paper elevation="3" className="mx-sm-auto col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleOnSubmit)} className="d-flex flex-column w-100">
          <h2>Login to your account</h2>
          <MessageList messages={error && [error]} />
          {showForgotPassword && (
            <Alert color="success" className="mb-3 text-center">
              A new password has been sent to your e-mail address. Please check your mailbox.
            </Alert>
          )}
          <Input
            name="username"
            rules={{ required: 'Username is required' }}
            placeholder="username"
            formGroup
            startAdornment={<Icon icon="account_circle" filled />}
            disabled={loading}
          />
          <Input
            name="password"
            rules={{ required: 'Password is required' }}
            type="password"
            placeholder="password"
            formGroup
            startAdornment={<Icon icon="lock" filled />}
            disabled={loading}
          />
          <Checkbox name="rememberMe" label="Remember me" formGroup />
          <Button type="submit" color="primary" className="mb-3" loading={loading} disabled={loading}>
            Login
          </Button>
          <Link to={ROUTES.RESET_PASSWORD} className="ms-auto d-inline-block">
            I forgot my password!
          </Link>
          <Alert color="secondary" className="alert-request-account mb-0 text-center">
            <a
              href="mailto:support@spotbuycenter.com?Subject=account%20request%20for%20spot%20buy%20center&amp;Body=Dear%20Mr./Ms.%2C%0A%0AYou%20are%20requesting%20a%20new%20login%20for%20the%20spot%20buy%20center%20client%20zone.%0APlease%20fill%20out%20the%20following%20data%2C%20so%20we%20can%20create%20your%20personal%20account%3A%0A%0AFirst%20Name%3A%20%0ALast%20Name%3A%20%0AE-mail%20address%3A%20%0APhone%20number%3A%20%0AMobile%20number%3A%20%0A%0ACompany%3A%20%0ASite%3A%20%0ADepartment%3A%20%20%0AJob%20title%3A%20%0A%0AYou%20will%20receive%20your%20login%20%26%20password%20within%2024%20hours.%0A%0AHave%20a%20great%20spot%20buy%20day%21%0A%0A%0Aspot%20buy%20center%20bv%0Amastboomstraat%204%0A2630%20aartselaar%0A%0AT%3A%20+32.3.369.07.11%0AF%3A%20+32.3.303.63.86%0Asupport@spotbuycenter.com%0Awww.spotbuycenter.com%0A"
              className="text-secondary"
            >
              Don't have an account? Click here to request one.
            </a>
          </Alert>
        </form>
      </FormProvider>
    </Paper>
  )
}

export default observer(Login)
