import React from 'react'

import cx from 'classnames'

import { stringToInt } from 'utils'
import Button from 'common/Button'
import Input from 'common/Input'

export default ({
  inputRef,
  value: valueProp,
  step,
  min,
  max,
  disabled,
  className,
  inputGroupClassName,
  buttonClassName,
  iconClassName,
  onChange,
  onBeforeChange,
  onBlur,
  ...props
}) => {
  const [value, setValue] = React.useState('')
  const triggerOnChangeRef = React.useRef(false)

  const actualStep = step || 1

  React.useEffect(() => {
    setValue(stringToInt(valueProp || ''))
  }, [valueProp])

  const increase = () => {
    updateValue(value === '' ? actualStep : value + actualStep)
  }

  const decrease = () => {
    updateValue(value === '' ? -actualStep : value - actualStep)
  }

  const handleOnChange = event => {
    // trigger on change when user leaves the textbox.
    const newValue = stringToInt(event.target.value)
    setValue(newValue)
    triggerOnChangeRef.current = true
  }

  const handleOnBlur = event => {
    // trigger on change when user leaves the textbox.
    if (triggerOnChangeRef.current) {
      triggerOnChangeRef.current = false
      const newValue = stringToInt(event.target.value) || 0
      updateValue(newValue)
    }
    onBlur && onBlur(event)
  }

  const updateValue = newValue => {
    let accepted = true
    if (onBeforeChange) {
      accepted = onBeforeChange(newValue)
    }

    if (accepted) {
      if (newValue || newValue === 0) {
        if (newValue < min) {
          newValue = min
        } else if (max !== null && newValue > max) {
          newValue = max
        } else {
          // reset to old value if newValue is not divisible by step
          const difference = newValue % actualStep
          if (difference !== 0)  {
            newValue -= difference
          }
        }
        setValue(newValue)
        onChange && onChange(newValue)
      } else {
        setValue(newValue)
        onChange && onChange(newValue)
      }
    }
  }

  return (
    <Input
      type="number"
      value={value}
      step={step}
      min={min}
      max={max}
      inputRef={inputRef}
      startAdornment={
        <Button
          type="button"
          icon="remove"
          color="primary"
          disabled={disabled}
          className={cx('px-2', buttonClassName)}
          iconClassName={cx('md-22', iconClassName)}
          onClick={decrease}
        />
      }
      endAdornment={
        <Button
          type="button"
          icon="add"
          color="primary"
          disabled={disabled}
          className={cx('px-2', buttonClassName)}
          iconClassName={cx('md-22', iconClassName)}
          onClick={increase}
        />
      }
      disabled={disabled}
      className={cx('number-control', className)}
      inputGroupClassName={cx('number-control', inputGroupClassName)}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      {...props}
    />
  )
}
