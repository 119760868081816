import BaseService from 'services/base'

import { PUNCHOUT_TYPE } from 'definitions'

export default class ProposalService extends BaseService {
  constructor(api) {
    super(api)
    this.api = api
  }

  fetchTable(caseId) {
    return this.get(`clientcase/${caseId}/proposal`)
  }

  fetchList(caseId) {
    return this.get(`case/${caseId}/proposal`)
  }

  pickProposal(payLoad) {
    return this.post('clientarea/proposal', payLoad)
  }

  getPunchoutRequest = payload => {
    const { proposalId, punchoutType } = payload
    switch (punchoutType) {
      case PUNCHOUT_TYPE.Coupa:
        return this.get(`clientarea/proposal/punchout/cxml/${proposalId}`)
      case PUNCHOUT_TYPE.Oracle:
        return this.get(`clientarea/proposal/punchout/${proposalId}`)
      case PUNCHOUT_TYPE.SAP:
        return this.get(`clientarea/proposal/punchout/html/${proposalId}`)
      case PUNCHOUT_TYPE.Hubwoo:
        return this.get(`clientarea/proposal/punchout/hubwoo/${proposalId}`)
      case PUNCHOUT_TYPE.SAPXml:
        return this.get(`clientarea/proposal/punchout/xml/${proposalId}`)
      case PUNCHOUT_TYPE.Ariba:
        return this.get(`clientarea/proposal/punchout/ariba/${proposalId}`)
      default:
        return { error: 'Invalid punchout type.' }
    }
  }

  sendPunchoutRequest = payLoad => {
    return this.post('clientarea/proposal/punchout/xml', payLoad)
  }

  createShareUrl = payLoad => {
    return this.post('clientarea/proposal/sharedUrl/create', payLoad)
  }

  rejectProposal(payLoad) {
    return this.post('clientarea/proposal/reject', payLoad)
  }
}
