import React from 'react'

import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'

import { formatDate } from 'utils/formatters'
import { useStores } from 'stores'
import Button from 'common/Button'
import Loader from 'common/Loader'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'

import DetailPanel from './DetailPanel'
import HeaderPanel from './HeaderPanel'
import ItemListPanel from './ItemListPanel'

export default observer(({ resource, commonResource, caseId, quote: quoteProp, isEdit, onReloadSource, onClose }) => {
  const { quoteStore } = useStores()
  const methods = useForm()

  const { handleSubmit } = methods
  const {
    CRUD: { data, error, submitting, loading },
  } = quoteStore

  React.useEffect(() => {
    if (isEdit) {
      const fetchQuote = async () => {
        const result = await quoteStore.getById(caseId, quoteProp.id)
        if (result?.quoteSent === true) {
          quoteStore.CRUD.error = resource.QuoteAlreadySend
          onReloadSource(caseId)
        }
      }
      fetchQuote()
    }
  }, [quoteStore, caseId, quoteProp, isEdit])

  const quote = React.useMemo(
    () => quoteStore.prepareQuoteData(isEdit, isEdit ? data : quoteProp),
    [quoteStore, isEdit, data, quoteProp]
  )

  const isReadOnly = quote?.quoteSent === true

  const handleOnSubmit = async values => {
    const { attachments, lines } = values
    const { added = [], deleted = [] } = attachments || {}

    const payload = {
      caseId,
      lines: lines.map(item => ({
        lineIndex: item.lineIndex,
        description: item.description,
        extendedDescription: item.extendedDescription,
        deliveryDate: formatDate(item.deliveryDate),
        unitPrice: item.unitPrice,
        notApplicable: item.notApplicable,
      })),
      attachmentFileIds: added ? added.map(item => item.fileId) : [],
      remarks: values.remarks,
      expirationDate: formatDate(values.expirationDate),
      ...(isEdit
        ? {
            id: quote.id,
            deletedAttachmentFileIds: deleted,
          }
        : { rfqId: quote.id }),
    }

    const action = isEdit ? quoteStore.patch : quoteStore.post
    const result = await action(caseId, payload)
    if (result) {
      onClose()
      onReloadSource()
    }
  }

  return (
    <Modal
      isOpen
      useForm={methods}
      title={isEdit ? resource.EditQuote : resource.AddQuote}
      size="xl"
      disableClose={submitting}
      footer={
        <div className="w-100 d-flex">
          <Button type="button" text color="primary" disabled={submitting} className="me-2" onClick={onClose}>
            {commonResource.Cancel}
          </Button>
          {!isReadOnly && (
            <Button
              type="submit"
              color="success"
              className="btn btn-success ms-auto"
              icon="done"
              disabled={submitting || loading}
              loading={submitting}
            >
              {commonResource.Save}
            </Button>
          )}
        </div>
      }
      onClose={onClose}
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      {<Loader show={isEdit && loading} text={commonResource.Loading} />}
      <MessageList message={error && [error]} />
      {!!quote && (
        <React.Fragment>
          {quote.isDummy && (
            <MessageList
              color="warning"
              message={quote.isSent ? [resource.RfqNotSentToSupplier] : [resource.RfqWillNotSendToSupplier]}
              showClose={false}
            />
          )}
          {!loading && (
            <React.Fragment>
              <HeaderPanel
                resource={resource}
                commonResource={commonResource}
                quote={quote}
                disabled={isReadOnly || submitting}
              />
              <ItemListPanel
                resource={resource}
                commonResource={commonResource}
                quote={quote}
                disabled={isReadOnly || submitting}
              />
              <DetailPanel resource={resource} quote={quote} disabled={isReadOnly || submitting} />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Modal>
  )
})
