import React from 'react'

import cx from 'classnames'

export const FlexTable = ({ columns = [], children, footer, breakpoint = 'lg', className, headerClassName }) => {
  const renderHeaderCell = column => {
    if (!column.Header) return false
    if (typeof column.Header === 'string' || column.Header instanceof String) {
      return column.Header
    }

    return column.Header()
  }

  return (
    <div className={cx('flex-table', className, breakpoint && `breakpoint-${breakpoint}`)}>
      <div className={cx('flex-table-head', headerClassName)}>
        {columns.map((column, index) => (
          <div
            key={column.id || index}
            className={cx('flex-table-cell', column.className)}
            {...(column.headerStyle ? { style: column.headerStyle } : {})}
            {...(column.width ? { width: column.width } : {})}
          >
            {renderHeaderCell(column)}
          </div>
        ))}
      </div>
      <div className="flex-table-body">{children}</div>
      {footer && <div className="flex-table-foot">{footer}</div>}
    </div>
  )
}

export const FlexTableRow = ({ children, className }) => (
  <div className={cx('flex-table-row', className)}>{children}</div>
)

export const FlexTableCell = ({ children, className }) => (
  <div className={cx('flex-table-cell', className)}>{children}</div>
)
