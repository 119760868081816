import BaseService from 'services/base'

export default class FileService extends BaseService {
  constructor(api) {
    const url = 'file'
    super(api, url)
    this.api = api
    this.url = url
  }
}
