import BaseStore from 'stores/base'

export default class ClientAreaStore extends BaseStore {
  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options
  }

  async getClientSites(payload) {
    const { data, error } = await this.service.getClientSites(payload)
    return { data: data ? data.clientSites : [], error }
  }
}
