import React from 'react'

import ProductCustomFields from './ProductCustomFields'
import ProductSpecs from './ProductSpecs'

export default ({ resource, data, handleSelectedCustomField }) => {
  return (
    <div className="col ps-3">
      {data.description && (
        <div className="mb-8">
          <div className="fs-18 fw-bolder webshop-title-color mb-5">{data.description.label}</div>
          <div dangerouslySetInnerHTML={{ __html: data.description.value }} />
        </div>
      )}
      {data.deliveryInfo && (
        <div className="mb-8">
          <div className="fs-18 fw-bolder webshop-title-color mb-5">{data.deliveryInfo.label}</div>
          <div dangerouslySetInnerHTML={{ __html: data.deliveryInfo.value }} />
        </div>
      )}
      <ProductCustomFields resource={resource} data={data} handleSelectedCustomField={handleSelectedCustomField} />
      <ProductSpecs resource={resource} data={data} />
    </div>
  )
}
