import React from 'react'

import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { LOGIN_PROVIDER, REDIRECT_URL, ROUTES } from 'definitions'
import { replaceUrl } from 'utils'
import { useStores } from 'stores'
import Button from 'common/Button'
import MessageList from 'common/MessageList'
import Paper from 'common/Paper'

import GoedLogo from 'assets/images/clients/goed.svg'

export default observer(() => {
  const { saml2Store } = useStores()
  const location = useLocation()
  const [loading, setLoading] = React.useState(false)

  const {
    CRUD: { error },
  } = saml2Store

  React.useEffect(() => {
    if (location.search) {
      const searchParams = queryString.parse(location.search)
      if (searchParams.message) {
        saml2Store.setCrudError(searchParams.message)
        replaceUrl(ROUTES.LOGIN_GOED)
      } else if (searchParams.autoLogin) {
        handleLogin()
      }
    }
  }, [saml2Store, location.search])

  const handleLogin = async () => {
    setLoading(true)

    const result = await saml2Store.getLoginUrl(LOGIN_PROVIDER.GOED, {
      returnUrl: sessionStorage.getItem(REDIRECT_URL),
    })
    if (result) {
      if (result.disabled) {
        setLoading(false)
        saml2Store.CRUD.error = 'SSO SAML 2.0 login is disabled'
      } else {
        window.location = result.redirectUrl
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <Paper elevation="3" className="mx-sm-auto col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4 sso-login">
      <div className="d-flex flex-column w-100">
        <h2>Login to your account</h2>
        <MessageList messages={error && [error]} hideClose messageClassName="white-space-pre-line" />
        <div className="d-flex align-items-center flex-column">
          <div className="goed-logo mb-4 p-4 rounded">
            <img src={GoedLogo} alt="Goed" className="sso-login-logo" />
          </div>
          <Button
            outline
            color="default"
            loading={loading}
            disabled={loading}
            className="col-xxl-4 col-md-6 col-12"
            onClick={handleLogin}
          >
            Login
          </Button>
        </div>
      </div>
    </Paper>
  )
})
