import BaseStore from 'stores/base'

export default class ConnectionStore extends BaseStore {
  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options
  }

  connect(id) {
    return this.request(this.CRUD, async () => this.service.connect(id))
  }
}
