import React from 'react'

import { Badge } from 'reactstrap'
import { observer } from 'mobx-react'

import { formatCurrency, formatDate } from 'utils/formatters'
import { padLeft } from 'utils'
import Button from 'common/Button'
import ButtonLink from 'common/ButtonLink'
import Card from 'common/Card'
import Checkbox from 'common/Checkbox'
import ReactTable from 'common/ReactTable'

export default observer(({ sourceStore, resource, onViewPrLine, onCreateRfq }) => {
  const tableRef = React.useRef()
  const [selection, setSelection] = React.useState([])

  const {
    Table: { data: { linesToSource } = [], loading },
  } = sourceStore

  const getQuotesBadgeColor = status => {
    if (status.quotesReceived === 0) return 'danger'
    if (status.rfqsSent > status.quotesReceived) return 'warning'
    return 'success'
  }

  const handleSelectionChange = (selectedFlatRows = []) => setSelection([...selectedFlatRows])

  const handleCreateRfq = (selection, isDummy) => {
    onCreateRfq({ lines: selection, isDummy })
    tableRef.current && tableRef.current.toggleAllRows(false)
  }

  const columns = React.useMemo(
    () => [
      {
        accessor: 'selected',
        width: '40',
        Header: ({ isAllSelected, toggleAllRows }) => {
          return (
            <Checkbox type="checkbox" checked={isAllSelected} labelClassName="mt-1" onChange={() => toggleAllRows()} />
          )
        },
        Cell: ({ value, toggleRow }) => {
          return <Checkbox type="checkbox" checked={value} onChange={() => toggleRow()} />
        },
      },
      {
        Header: '#',
        accessor: 'lineIndex',
        headerClassName: 'col-1 text-muted',
        className: 'col-1',
        Cell: ({ value, row }) =>
          row ? <ButtonLink onClick={() => onViewPrLine(row)}>{padLeft(value, 1)}</ButtonLink> : '',
      },
      {
        Header: resource.Description,
        accessor: 'description',
        headerClassName: 'col',
        className: 'col',
        Cell: ({ value, row }) =>
          !!row && (
            <ButtonLink
              title={value}
              className="word-break-break-word white-space-normal"
              onClick={() => onViewPrLine(row)}
            >
              {value}
            </ButtonLink>
          ),
      },
      {
        Header: resource.Delivery,
        accessor: 'deliveryInfo.deliveryDate',
        headerClassName: 'col-1',
        className: 'col-1',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: resource.TotalBudget,
        accessor: 'budget.value',
        headerClassName: 'col-2 text-end',
        className: 'col-2 text-end',
        Cell: ({ value }) => (value ? formatCurrency(value) : '-'),
      },
      {
        Header: resource.Qty,
        accessor: 'quantity.amount',
        headerClassName: 'col-1 text-end',
        className: 'col-1 text-end',
      },
      {
        id: 'rfq',
        Header: resource.Rfqs,
        accessor: 'status',
        headerClassName: 'col-1 border-start',
        className: 'col-1 border-start d-flex align-items-center',
        Cell: ({ value }) => (
          <div className="d-flex align-items-center">
            <Badge color={value.rfqsSent === 0 ? 'danger' : 'success'} className="px-2 pt-1">
              {value.rfqsSent}
            </Badge>
            {!!value.rfqsPending && (
              <small className="ms-2 text-muted" title="Pending RFQs">
                ({value.rfqsPending})
              </small>
            )}
          </div>
        ),
      },
      {
        id: 'quotes',
        Header: resource.Quotes,
        accessor: 'status',
        headerClassName: 'col-1 border-start',
        className: 'col-1 border-start d-flex align-items-center',
        Cell: ({ value }) => (
          <Badge color={getQuotesBadgeColor(value)} className="px-2 pt-1">
            {value.quotesReceived}
          </Badge>
        ),
      },
    ],

    []
  )

  return (
    <Card
      title={resource.LinesToSource}
      color="primary"
      outline
      footer={
        <React.Fragment>
          <Button
            type="button"
            color="primary"
            className="me-2"
            icon="local_shipping"
            disabled={!selection.length || loading}
            onClick={() => handleCreateRfq(selection, false)}
          >
            {resource.CreateRfq}
          </Button>
          <Button
            type="button"
            color="warning"
            icon="local_shipping"
            disabled={!selection.length || loading}
            onClick={() => handleCreateRfq(selection, true)}
          >
            {resource.DirectOrder}
          </Button>
        </React.Fragment>
      }
    >
      <ReactTable
        ref={tableRef}
        columns={columns}
        data={linesToSource}
        loading={loading}
        noRecordsLabel={resource.NoPRLines}
        responsive
        useFlex
        enableSelection
        onSelectionChanged={handleSelectionChange}
      />
    </Card>
  )
})
