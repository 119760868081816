import React from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { Spinner } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

import { useDebounce } from 'hooks/useDebounce'
import ButtonIcon from 'common/ButtonIcon'
import Toaster from 'common/Toaster'

import CartSummary from './CartSummary'
import CartTable from './CartTable'

export default observer(({ connectionCartStore, globalMessageStore, resource, cart, reloadCart, onPunchout }) => {
  const navigate = useNavigate()
  const methods = useForm({
    defaultValues: cart,
  })
  const updaterCartDebounced = useDebounce(() => updateCart())

  const { handleSubmit, getValues } = methods
  const {
    CRUD: { cartId: crudCartId, submitting, action },
  } = connectionCartStore

  const disabled = submitting && action === 'checkout' && crudCartId === cart.id
  const saving = submitting && action === 'save' && crudCartId === cart.id

  const handleCheckout = () => {
    Toaster.confirm({
      title: resource.ConfirmCheckoutTitle,
      message: resource.ConfirmCheckoutBody,
      submitText: resource.Confirm,
      cancelText: resource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const result = await connectionCartStore.checkout(cart.id)

        if (result && result.success) {
          if (result.isSendToErp) {
            handleSendToErp(closeModal)
          } else {
            closeModal()
            if (result.redirectUrl) {
              navigate(result.redirectUrl)
            } else if (result?.message === 'punchout-expired') {
              globalMessageStore.show({
                color: 'warning',
                message: resource.PunchoutExpired,
              })
            }
          }
        } else {
          closeModal()
        }
      },
    })
  }

  const handleSendToErp = async closeModal => {
    const result = await connectionCartStore.getPunchout()
    if (result) {
      onPunchout(result)
    } else {
      closeModal()
    }
  }

  const handleDeleteCart = () => {
    Toaster.confirm({
      title: resource.ClearCart,
      message: resource.ConfirmDeleteCart,
      submitText: resource.Confirm,
      cancelText: resource.Cancel,
      closeOnSubmit: false,
      onSubmit: closeModal => clearCart(closeModal),
    })
  }

  const clearCart = async closeModal => {
    const result = await connectionCartStore.delete(cart.id)
    closeModal()
    result && reloadCart()
  }

  const updateCart = async () => {
    const values = getValues()
    await connectionCartStore.updateCart({ cart, values })
    connectionCartStore.getCartQuantity()
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleCheckout)} className="cart border-bottom mb-lg-0 mb-8">
        <div className="heading d-flex align-items-center">
          <h4 className="webshop-title-color fw-bolder mb-0">{cart.supplier?.name || ''}</h4>
          <ButtonIcon
            icon="delete"
            color="primary"
            title={resource.ClearCart}
            className="ms-2"
            iconClassName="md-22"
            disabled={saving || disabled}
            onClick={handleDeleteCart}
          />
          {saving && <Spinner color="secondary" type="grow" size="sm" className="ms-1" />}
        </div>
        <div className="cart-content d-flex flex-wrap">
          <CartTable
            cart={cart}
            disabled={disabled}
            saving={saving}
            updateCart={updaterCartDebounced}
            onClearCart={handleDeleteCart}
          />
          <CartSummary cart={cart} disabled={disabled} saving={saving} updateCart={updaterCartDebounced} />
        </div>
      </form>
    </FormProvider>
  )
})
