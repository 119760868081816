import { makeObservable, observable } from 'mobx'
import BaseStore from 'stores/base'

export default class GlobalDataStore extends BaseStore {
  Countries = []
  Languages = [
    { code: 'DE', name: 'Deutsch' },
    { code: 'EN', name: 'English' },
    { code: 'FR', name: 'Français' },
    { code: 'NL', name: 'Nederlands' },
  ]

  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options

    makeObservable(this, {
      Countries: observable,
      // Languages: observable,
    })

    this.searchCountries = this.searchCountries.bind(this)
    this.getCountryName = this.getCountryName.bind(this)
  }

  getDefaultCountries() {
    return [
      { code: 'BE', name: 'BELGIUM' },
      { code: 'FR', name: 'FRANCE' },
      { code: 'NL', name: 'NETHERLANDS' },
      { code: 'US', name: 'UNITED STATES' },
    ]
  }

  async getCountries() {
    if (!this.Countries || !this.Countries.length) {
      const { data, error } = await this.service.getCountries()
      if (data && !error) {
        this.Countries = data.countries
      }
    }
    return this.Countries
  }

  async searchCountries({ search }) {
    const countries = await this.getCountries()
    if (countries && search) {
      return { data: countries.filter(item => item.name.toLowerCase().includes(search.toLowerCase())) }
    }
    return { data: countries }
  }

  async getCountryName(countryCode) {
    const countries = await this.getCountries()
    const country = countries.find(item => item.code === countryCode)
    return country ? country.name : countryCode
  }

  // async getLanguages() {
  //   if (!this.Languages || !this.Languages.length) {
  //     const { data, error } = await this.service.getLanguages()
  //     if (data && !error) {
  //       this.Languages = data.languages
  //     }
  //   }
  //   return this.Languages
  // }

  // async searchLanguages({ search }) {
  //   const languages = await this.getLanguages()
  //   if (languages && search) {
  //     return { data: languages.filter(item => item.name.toLowerCase().includes(search.toLowerCase())) }
  //   }
  //   return { data: languages }
  // }
}
