import React from 'react'
import Modal from 'common/Modal'
import Input from 'common/Forms/Input'
import Button from 'common/Button'
import Card from 'common/Card'
import Toaster from 'common/Toaster'
import { useStores } from 'stores'
import ContentLoader from 'common/ContentLoader'
import { FormProvider, useForm } from 'react-hook-form'

const RejectProposalModal = ({ isOpen, onClose, caseProposals, proposal }) => {
  const { authStore, proposalStore, globalMessageStore, pageResourceStore } = useStores()
  const [loading, setLoading] = React.useState(false)
  const [caseProposal, setCaseProposal] = React.useState()
  const [reload, setReload] = React.useState(false)
  const methods = useForm()

  const { session } = authStore.AppSession

  const {
    PageResource: { case: resource = {} },
  } = pageResourceStore

  const { handleSubmit } = methods

  const proposalIds = proposal.map(proposal => proposal.proposalId)

  const handleOnSubmit = async formData => {
    Toaster.confirm({
      title: resource.ConfirmFeedbackSubmission,
      message: resource.RejectConfirmationMessage.replace(
        '<casenumber>',
        caseProposals.caseNumber + ' ' + caseProposals.description
      ),
      submitText: resource.Confirm,
      cancelText: resource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const { data } = await proposalStore.rejectProposal({
          proposalId: proposalIds.pop(),
          caseId: caseProposals.caseId,
          ...formData,
        })
        if (data) {
          closeModal()
          completeRejectProposal(data)
          onClose()
        } else {
          closeModal()
          onClose()
        }
      },
    })
  }

  const completeRejectProposal = data => {
    setCaseProposal({
      ...caseProposal,
      bundles: data.updatedCase.bundles,
    })

    globalMessageStore.show({
      title: resource.FeedbackSubmitted,
      message: `${resource.RejectedFeedbackMessage}`,
      color: 'success',
    })
    setReload(true)
    setLoading(false)
    onClose()
  }

  React.useEffect(() => {
    if (reload) {
      setLoading(true)
      window.location.reload()
      setReload(false)
    }
  }, [reload])
  return (
    <Modal
      isOpen={isOpen}
      title={resource.RejectProposal}
      size="xl"
      onClose={onClose}
      contentLabel="Reject Proposal Modal"
    >
      <ContentLoader show={loading} className="pt-6" />
      <Card
        title={
          <div className="d-flex justify-content-between">
            <div className="col-md-3">{caseProposals.caseNumber}</div>
            <div className="col-md-9">{caseProposals.description}</div>
          </div>
        }
        color="primary"
        outline
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Input
              name={`email`}
              label={resource.EmailAddress}
              placeholder={resource.EmailAddress}
              defaultValue={session.email}
              type="email"
              formGroup
              horizontal
            />
            <Input
              name={`phoneNumber`}
              label={resource.PhoneNumber}
              placeholder={resource.PhoneNumber}
              value={session.phoneNumber}
              type="number"
              formGroup
              horizontal
            />
            <Input
              name={`remarks`}
              type="textarea"
              label={`${resource.Remarks} *`}
              rules={{ required: resource.RemarksRequired }}
              placeholder={resource.Remarks}
              formGroup
              horizontal
              rows="4"
            />
            <div className="w-100 d-flex pt-3">
              <Button size="md" color="warning" className="me-2 px-4" onClick={onClose}>
                {resource.ReturnToMyCase}
              </Button>
              <Button size="md" color="danger" icon="thumb_down" className="px-4 ms-auto" type="submit">
                {resource.RejectProposal}
              </Button>
            </div>
          </form>
        </FormProvider>
      </Card>
    </Modal>
  )
}

export default RejectProposalModal
