import BaseStore from 'stores/base'

export default class ReportStore extends BaseStore {
  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options
  }

  async downloadBlob(url, payload, options) {
    const { data, error } = await this.service.downloadBlob(url, payload, options)
    return { data, error }
  }
}
