import React from 'react'

import { DropdownItem } from 'reactstrap'

import Dropdown from 'common/Dropdown'

export default ({ resource, sort: sortProp, onSortChange }) => {
  const [sort, setSort] = React.useState()

  const sortLabel = React.useMemo(() => {
    if (sort?.key === 'price') {
      if (sort.desc) {
        return `${resource.Price} ${resource.HighToLow}`
      } else {
        return `${resource.Price} ${resource.LowToHigh}`
      }
    }
    return resource.Relevance
  }, [resource, sort])

  React.useEffect(() => {
    setSort(sortProp)
  }, [sortProp])

  const handleSort = (key, desc = false) => {
    onSortChange({ key, desc })
  }

  const isDefault = !sort?.key || sort?.key === 'default'
  const isPriceAsc = sort?.key === 'price' && sort?.desc === false
  const isPriceDesc = sort?.key === 'price' && sort?.desc === true

  return (
    <Dropdown
      caret
      toggleContent={`${resource.SortBy} ${sortLabel}`}
      toggleButtonOptions={{
        color: 'primary',
      }}
      className="mt-md-0 mt-2"
    >
      <DropdownItem
        className="d-inline-flex align-items-center"
        active={isDefault}
        onClick={() => handleSort('default')}
      >
        {resource.Relevance}
      </DropdownItem>
      <DropdownItem
        className="d-inline-flex align-items-center"
        active={isPriceAsc}
        onClick={() => handleSort('price')}
      >
        {`${resource.Price} ${resource.LowToHigh}`}
      </DropdownItem>
      <DropdownItem
        className="d-inline-flex align-items-center"
        active={isPriceDesc}
        onClick={() => handleSort('price', true)}
      >
        {`${resource.Price} ${resource.HighToLow}`}
      </DropdownItem>
    </Dropdown>
  )
}
