import React from 'react'

import { Alert, Spinner } from 'reactstrap'

import ButtonIcon from 'common/ButtonIcon'

export default ({ resource, cart, saving, disabled, onClearCart, onCopyCart }) => {
  return (
    <div className="heading d-flex align-items-center">
      <h4 className="webshop-title-color fw-bolder mb-0">{cart.serviceContractName}</h4>
      <ButtonIcon
        icon="content_copy"
        color="primary"
        title={resource.CopyCart}
        className="ms-2"
        iconClassName="md-22"
        disabled={saving || disabled}
        onClick={onCopyCart}
      />
      <ButtonIcon
        icon="delete"
        color="primary"
        title={resource.ClearCart}
        iconClassName="md-22"
        disabled={saving || disabled}
        onClick={onClearCart}
      />
      {cart.isCopy && (
        <Alert title={resource.CopiedCart} className="ms-2">
          {resource.Copy}
        </Alert>
      )}
      {saving && <Spinner color="secondary" type="grow" size="sm" className="ms-1" />}
    </div>
  )
}
