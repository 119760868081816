import React from 'react'

import Card from 'common/Card'
import FileLink from 'common/FileLink'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({ resource, data, settings }) => {
  const attachments = data.attachments || []

  return (
    <Card fieldset title={resource.ProductInformation}>
      <ReadOnlyField formGroup horizontal label={resource.Category} value={data.category && data.category.name} />
      {settings.shouldSelectCategory && (
        <ReadOnlyField
          formGroup
          horizontal
          label={resource.SubCategory}
          value={data.category && data.category.subcategory}
        />
      )}
      {settings.enableGLAccount && (
        <ReadOnlyField
          formGroup
          horizontal
          label={resource.GLAccount}
          value={data.glAccount && data.glAccount.glAccount}
        />
      )}
      <ReadOnlyField
        formGroup
        horizontal
        label={resource.ProductDescription}
        value={data.description}
        valueClassName="word-wrap-break-word"
      />
      <ReadOnlyField
        formGroup
        horizontal
        label={resource.QuantityUnit}
        value={`${data.quantity.amount} ${data.quantity.unit}`}
      />
      <ReadOnlyField
        formGroup
        horizontal
        label={resource.ExtendedDescription}
        value={data.comment || data.remarks}
        valueClassName="word-wrap-break-word"
      />
      {!!attachments.length && (
        <ReadOnlyField
          formGroup
          horizontal
          label={resource.Attachments}
          value={
            <ul className="list-unstyled">
              {attachments.map((item, index) => (
                <li key={index}>
                  <FileLink data={item} />
                </li>
              ))}
            </ul>
          }
        />
      )}
      {!attachments.length && (
        <ReadOnlyField formGroup horizontal label={resource.Attachments} value={resource.NoAttachments} />
      )}
    </Card>
  )
}
