import { makeObservable, observable } from 'mobx'

export const defaults = {
  Table: {
    data: {},
    loading: false,
    error: null,
    status: null,
  },
  CRUD: {
    loading: false,
    submitting: false,
    data: {},
    error: null,
    status: null,
  },
}

export default class BaseStore {
  constructor(service) {
    this.service = service

    makeObservable(this, {
      Table: observable,
      CRUD: observable,
      List: observable,
    })

    this.request = this.request.bind(this)
    this.post = this.post.bind(this)
    this.patch = this.patch.bind(this)
  }

  Table = defaults.Table
  CRUD = defaults.CRUD
  List = defaults.Table

  async fetch(url, payload, options) {
    const { data, error } = await this.service.get(url, payload, options)
    return { data, error }
  }

  fetchTable(url, payload, options) {
    return this.getRequest(this.Table, () => this.service.fetchTable(url, payload, options))
  }

  fetchList(url, payload, options) {
    return this.getRequest(this.List, () => this.service.fetchList(url, payload, options))
  }

  get(url, payload, options) {
    return this.getRequest(this.CRUD, () => this.service.get(url, payload, options))
  }

  post(url, payload, options) {
    return this.request(this.CRUD, () => this.service.post(url, payload, options))
  }

  patch(url, payload, options) {
    return this.request(this.CRUD, () => this.service.patch(url, payload, options))
  }

  put(url, payload, options) {
    return this.request(this.CRUD, () => this.service.put(url, payload, options))
  }

  delete(url, options) {
    return this.request(this.CRUD, () => this.service.delete(url, options))
  }

  async getRequest(model, action, setData) {
    model.error = null
    model.status = null
    model.loading = true

    const { data, error, status } = await action()

    const success = data && !error
    if (success) {
      if (setData) {
        setData(data)
      } else {
        model.data = data
      }
    } else {
      model.status = status
      model.error = error
    }
    model.loading = false
    return success && data
  }

  async request(model, action) {
    model.error = null
    model.submitting = true

    const { data, error } = await action()

    model.submitting = false
    const success = data && !error
    if (!success) model.error = error
    return success && data
  }

  clearCrudData() {
    this.CRUD = defaults.CRUD
  }

  clearCrudError() {
    this.CRUD.error = null
  }

  clearTableData() {
    this.Table = defaults.Table
  }

  clearTableError() {
    this.Table.error = null
  }

  setCrudError(error) {
    this.CRUD.error = error
  }

  reset() {
    this.Table = defaults.Table
    this.CRUD = defaults.CRUD
    this.List = defaults.List
  }
}
