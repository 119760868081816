import React from 'react'

import { observer } from 'mobx-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useStores } from 'stores'

import { CASE_TYPE } from 'definitions'
import Loader from 'common/Loader'
import Tabs from 'common/Tabs'

import CaseDetailInfo from './CaseDetailInfo'
import Order from './Order'
import Propose from './Propose'
import PurchaseOrder from './PurchaseOrder'
import PurchaseRequest from './PurchaseRequest'
import Source from './Source'

export default observer(() => {
  const { authStore, clientCaseStore, pageResourceStore, purchaseRequestStore } = useStores()
  const { id: caseId, tab: tabName } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { case: resource = {}, common: commonResource },
  } = pageResourceStore
  const {
    CRUD: { data: { case: data = {} } = {} },
  } = clientCaseStore

  React.useEffect(() => {
    const getCaseDetail = async () => {
      setLoading(true)
      await Promise.allSettled([loadCaseDetail(caseId), pageResourceStore.get('case')])
      setLoading(false)
    }
    getCaseDetail()

    // clear child data
    return () => {
      purchaseRequestStore.clearTableData()
    }
  }, [pageResourceStore, caseId])

  const loadCaseDetail = caseId => clientCaseStore.get(caseId)

  const handleTabChange = key => {
    navigate(`/cases/${caseId}/${key}`)
  }

  return (
    <div id="case-detail">
      {<Loader show={loading} text={commonResource.Loading} />}
      {!loading && data.id && (
        <React.Fragment>
          <CaseDetailInfo resource={resource} caseId={caseId} data={data} />
          <Tabs
            activeTab={tabName || 'pr'}
            tabs={[
              {
                key: 'pr',
                label: resource.PR,
                icon: 'edit_note',
                content: activated => (
                  <PurchaseRequest
                    activated={activated}
                    clientId={data.client.id}
                    caseId={caseId}
                    caseNumber={data.caseNumber}
                    caseType={data.caseType}
                    onReloadCase={loadCaseDetail}
                  />
                ),
              },
              {
                key: 'source',
                label: resource.Source,
                icon: 'local_shipping',
                hidden: data.caseType !== CASE_TYPE.SelfService,
                content: activated => <Source activated={activated} caseId={caseId} caseNumber={data.caseNumber} />,
              },
              {
                key: 'propose',
                label: resource.Propose,
                icon: 'list',
                iconFilled: true,
                hidden: session.isViewer || data.caseType === CASE_TYPE.SelfService,
                content: activated => (
                  <Propose
                    activated={activated}
                    caseId={caseId}
                    caseNumber={data.caseNumber}
                    caseType={data.caseType}
                  />
                ),
              },
              {
                key: 'po',
                label: resource.PO,
                icon: 'insert_drive_file',
                content: activated => <PurchaseOrder activated={activated} caseId={caseId} />,
              },
              {
                key: 'order',
                label: resource.Order,
                icon: 'description',
                hidden: data.caseType !== CASE_TYPE.SelfService,
                content: activated => <Order activated={activated} caseId={caseId} caseNumber={data.caseNumber} />,
              },
            ]}
            tabContentClassName="px-0"
            onChange={handleTabChange}
          />
        </React.Fragment>
      )}
    </div>
  )
})
