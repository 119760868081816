import React from 'react'

import { Input } from 'reactstrap'
import cx from 'classnames'

import FormGroup from 'common/FormGroup'
import HelpText from 'common/HelpText'
import InputGroup from 'common/InputGroup'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({
  inputRef,
  type,
  value,
  defaultValue,
  label,
  formGroup,
  horizontal,
  startAdornment,
  endAdornment,
  className,
  formClassName,
  labelClassName,
  inputColClassName,
  inputGroupClassName,
  labelCol,
  inputCol,
  helpText,
  helpTextType,
  readOnly,
  readOnlyField,
  textOnlyInput,
  autoFocus,
  ...props
}) => {
  const inputFieldRef = React.useRef()
  const hasError = helpTextType === 'error' && !!helpText

  React.useEffect(() => {
    autoFocus && inputFieldRef && inputFieldRef.current.focus()
  }, [])

  return (
    <FormGroup
      {...{
        label,
        formGroup,
        horizontal,
        className: cx(readOnlyField && 'read-only', formClassName),
        labelClassName,
        inputColClassName,
        labelCol,
        inputCol,
        hasError,
      }}
    >
      {readOnlyField && <ReadOnlyField className={className} value={value || defaultValue} />}
      {!readOnlyField && (
        <React.Fragment>
          <InputGroup startAdornment={startAdornment} endAdornment={endAdornment} className={inputGroupClassName}>
            <Input
              innerRef={theRef => {
                if (inputRef) {
                  if (typeof inputRef === 'function') {
                    inputRef(theRef)
                  } else {
                    inputFieldRef.current = theRef
                  }
                }
                inputFieldRef.current = theRef
              }}
              className={cx(className, {
                invalid: hasError,
                'text-only-input': textOnlyInput,
              })}
              value={value}
              defaultValue={defaultValue}
              {...props}
              type={textOnlyInput ? 'text' : type}
              readOnly={textOnlyInput || readOnly}
            />
          </InputGroup>
          {!!helpText && <HelpText hasError={hasError}>{helpText}</HelpText>}
        </React.Fragment>
      )}
    </FormGroup>
  )
}
