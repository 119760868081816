import React from 'react'

import cx from 'classnames'

import { sort } from 'utils/array'
import { useDebounce } from 'hooks/useDebounce'
import { useStateCallback } from 'hooks/useStateCallback'
import ButtonIcon from 'common/ButtonIcon'
import ReactSelectBase from 'common/ReactSelectBase'

export default ({ defaultFilters = {}, options, className, sortKey, onChange }) => {
  const hasInputChanged = React.useRef(false)
  const defaultFiltersRef = React.useRef({ ...defaultFilters })
  const [filters, setFilters] = React.useState({ ...defaultFilters })
  const [focusFilterKey, setFocusFilterKey] = useStateCallback()
  const debouncedOnChange = useDebounce(values => onChange(values))

  const filterKeys = React.useMemo(() => Object.keys(filters), [filters])
  const flatOptions = React.useMemo(() => {
    const mappedOptions = Object.keys(options)
      .filter(item => !item.hidden)
      .map(key => ({
        key,
        label: options[key].label,
        data: options[key].data,
      }))
    return sortKey ? sort(mappedOptions, sortKey) : mappedOptions
  }, [options, sortKey])

  const filteredFlatOptions = React.useMemo(
    () => flatOptions.filter(item => !filterKeys.includes(item.key)),
    [filterKeys, flatOptions]
  )
  const isAllAdded = filterKeys.length === flatOptions.length

  const handleAddFilter = selected => {
    filters[selected] = ''
    setFilters({ ...filters })
    setFocusFilterKey(selected, () => {
      setFocusFilterKey()
    })
  }

  const handleResetFilters = () => {
    setFilters({ ...defaultFiltersRef.current })
    debouncedOnChange({ ...defaultFiltersRef.current })
  }

  const handleRemoveFilter = key => {
    delete filters[key]
    setFilters({ ...filters })
    debouncedOnChange(filters)
  }

  const handleBeforToggle = () => {
    const keys = Object.keys(filters)
    if (keys.length) {
      const lastKey = keys[keys.length - 1]
      if (!filters[lastKey]) {
        delete filters[lastKey]
        setFilters({ ...filters })
      }
    }
  }

  const handleInputKeyUp = event => {
    if (event.keyCode === 13) {
      debouncedOnChange(filters)
    }
  }

  const handleInputValueChange = (key, event) => {
    filters[key] = event.target.value
    setFilters({ ...filters })
    hasInputChanged.current = true
  }

  const handleInputBlur = event => {
    if (hasInputChanged.current) {
      debouncedOnChange(filters)
    }
    hasInputChanged.current = false
  }

  const handleReactSelectChange = (key, selected) => {
    filters[key] = selected
    setFilters({ ...filters })
    debouncedOnChange(filters)
  }

  const renderInput = (key, option) => {
    const value = filters[key]
    if (option.type === 'select') {
      return (
        <ReactSelectBase
          options={option.data}
          defaultValue={value}
          autoFocus
          defaultMenuIsOpen
          className="col px-2"
          onChange={selected => handleReactSelectChange(key, selected)}
        />
      )
    } else {
      return (
        <input
          ref={ref => {
            if (ref && focusFilterKey === key) {
              ref.focus()
            }
          }}
          className="text-input col py-0 px-2"
          defaultValue={value}
          onKeyUp={handleInputKeyUp}
          onBlur={handleInputBlur}
          onChange={event => handleInputValueChange(key, event)}
        />
      )
    }
  }

  return (
    <div className={cx('search-control form-control d-flex flex-wrap align-items-center py-1 px-2', className)}>
      <ButtonIcon icon="close" title="reset filters" className="btn-sm btn-clear me-1" onClick={handleResetFilters} />
      <div className="filters d-flex flex-wrap col">
        {filterKeys.map(key => {
          const option = options[key]
          return (
            <div key={key} className="filter-item d-inline-flex col-xxl-4 col-md-6 col-12 pe-1">
              <div className="filter-item-wrapper d-inline-flex align-items-center col">
                <ButtonIcon
                  icon="cancel"
                  iconFilled
                  title="remove filter"
                  className="btn-sm btn-clear me-1"
                  iconClassName="md-22"
                  onClick={() => handleRemoveFilter(key)}
                />
                <small className="filter-item-label text-uppercase fw-bold p-0">{option.label}:</small>
                {renderInput(key, option)}
              </div>
            </div>
          )
        })}
        {!isAllAdded && (
          <ReactSelectBase
            className="select-keys col"
            options={filteredFlatOptions}
            optionValueKey="key"
            controlShouldRenderValue={false}
            onMenuOpen={handleBeforToggle}
            onChange={selected => handleAddFilter(selected.key)}
          />
        )}
      </div>
    </div>
  )
}
