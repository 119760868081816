import React from 'react'

import { components } from 'react-select'
import cx from 'classnames'
import moment from 'moment'

import ReactSelect from 'common/ReactSelect'

const MonthSelect = ({ defaultValue, value, inputClassName = '', onChange, ...rest }) => {
  const dateToday = new Date()
  const months = React.useMemo(
    () =>
      moment.months().map((item, index) => ({
        value: index,
        label: item,
        short: moment.monthsShort(index),
      })),
    []
  )

  const handleOnChange = selection => {
    onChange && onChange(selection?.value)
  }

  return (
    <ReactSelect
      {...(value ? { value: { value, label: months[value].label } } : {})}
      {...(defaultValue ? { value: { value: defaultValue, label: months[defaultValue].label } } : {})}
      options={months}
      className={cx('date-select month-select', inputClassName)}
      isClearable
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: ({ className, value, data, ...props }) => {
          return (
            <components.Option
              className={cx(className, {
                today: value === dateToday.getMonth(),
              })}
              value={value}
              data={data}
              {...props}
            >
              {data.short}
            </components.Option>
          )
        },
      }}
      noOptionsMessage={() => 'Year does not exists'}
      onChange={handleOnChange}
      {...rest}
    />
  )
}

export default MonthSelect
