import React from 'react'

import { Link } from 'react-router-dom'
import cx from 'classnames'

import { ROUTES } from 'definitions'
import { titlelize } from 'utils'

export default ({ subCategories: subCategoriesProp, onClose }) => {
  const renderSubCategories = (categories, level = 0) => {
    if (categories && categories.length > 0) {
      level += 1
      return categories.map(category => {
        return (
          <div
            key={`category${category.id}`}
            className={cx('sub-category-list-item fs-14', 'col-md-12 col-12', level > 1 && 'ps-5')}
          >
            <div>
              <Link
                to={`${ROUTES.WEBSHOP_CATALOG}/${category.categoryPath}`}
                className="d-inline-block mb-1 white-space-nowrap"
                onClick={() => onClose()}
              >
                {titlelize(category.name)}
              </Link>
            </div>
            <div>{renderSubCategories(category.subCategories, level)}</div>
          </div>
        )
      })
    }
  }

  return (
    <div>
      <div className="title border-bottom" />
      <div className="sub-category-list py-5 px-4 border-start">{renderSubCategories(subCategoriesProp)}</div>
    </div>
  )
}
