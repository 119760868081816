import React from 'react'

import { TableList } from 'components/Case/CaseDetail/TableList'
import OrderItemStatus from './OrderItemStatus'
import OrderItemTable from './OrderItemTable'

export default ({ resource, orderData, onViewOrder }) => {
  const { orders = [], linesToOrder } = orderData
  const poLines = linesToOrder || []

  return (
    <TableList>
      {!!poLines.length && !orders.length && <div className="text-muted text-center">{resource.NoOrdersYet}</div>}
      {!!orders.length &&
        orders.map((orderItem, index) => (
          <div key={index} className="d-flex flex-wrap mb-6">
            <OrderItemTable resource={resource} orderItem={orderItem} onViewOrder={onViewOrder} />
            <OrderItemStatus resource={resource} orderItem={orderItem} />
          </div>
        ))}
    </TableList>
  )
}
