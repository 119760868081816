import BaseService from 'services/base'

export default class ClientCaseService extends BaseService {
  constructor(api) {
    const url = 'clientcase'
    super(api, url)
    this.api = api
    this.url = url
  }

  updateSubject = (caseId, payLoad) => {
    return this.put(`${caseId}/subject`, payLoad)
  }
}
