import React from 'react'

import { ATTACHMENT_TYPE } from 'definitions'

import Button from 'common/Button'
import Card from 'common/Card'
import FileAttachment from 'common/FileAttachment'

export default ({ importFileCallback, resource, disabled }) => {
  const [selectedFile, setSelectedFile] = React.useState(null)
  const [uploadDisabled, setUploadDisabled] = React.useState(true)

  const handleImportFile = () => {
    if (selectedFile && selectedFile.fileId) {
      importFileCallback({ fileId: selectedFile.fileId, resetUploadCallback: resetUpload })
    }
  }

  const resetUpload = () => {
    setSelectedFile(null)
  }

  const handleOnChange = ({ added }) => {
    if (added && added.length) {
      const file = added[0]
      if (file.success) {
        setSelectedFile(file)
        setUploadDisabled(false)
      }
    } else {
      setSelectedFile(null)
      setUploadDisabled(true)
    }
  }

  return (
    <Card title={resource.Import} color="default" outline bodyClassName="min-height-150">
      <FileAttachment
        type={ATTACHMENT_TYPE.RandomUpload}
        multiple={false}
        singleAttachmentOnly
        onChange={handleOnChange}
        hideNoAttachments
      />
      <div>
        <Button
          type="submit"
          color="primary"
          icon="file_upload"
          disabled={uploadDisabled || disabled}
          onClick={handleImportFile}
        >
          {resource.ImportButton}
        </Button>
      </div>
    </Card>
  )
}
