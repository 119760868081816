import React from 'react'

import { Link } from 'react-router-dom'
import cx from 'classnames'

import { ROUTES } from 'definitions'
import { titlelize } from 'utils'

export default ({
  resource,
  subCategories: subCategoriesProp,
  onClose,
  selectedSubcategory,
  handleSubcategoryHover,
}) => {
  const renderSubCategories = categories => {
    if (categories && categories.length > 0) {
      return categories.map(category => {
        return (
          <div
            key={`category${category.id}`}
            className={cx('sub-category-list-item fs-14', 'top-level col-md-12 col-12')}
          >
            <div>
              <Link
                to={`${ROUTES.WEBSHOP_CATALOG}/${category.categoryPath}`}
                className={cx(
                  'btn btn-text btn-text-default w-100 py-1 white-space-nowrap',
                  selectedSubcategory?.id === category.id && 'active'
                )}
                onClick={() => onClose()}
                onMouseEnter={() => handleSubcategoryHover(category)}
              >
                {titlelize(category.name)}
              </Link>
            </div>
          </div>
        )
      })
    }
  }

  return (
    <div>
      <h6 className="title d-flex align-items-center fw-bold fs-14 px-5 py-3 mb-0 border-bottom">
        {resource.SubCategories}
      </h6>
      <div className="sub-category-list d-flex flex-wrap py-5 px-4 border-start">
        {renderSubCategories(subCategoriesProp)}
      </div>
    </div>
  )
}
