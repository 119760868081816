import React from 'react'

import { PUNCHOUT_TYPE } from 'definitions'
import PunchoutCxml from './PunchoutCxml'
import PunchoutHubwoo from './PunchoutHubwoo'
import PunchoutOracle from './PunchoutOracle'
import PunchoutSap from './PunchoutSap'
import PunchoutSapXml from './PunchoutSapXml'

// eslint-disable-next-line no-empty-pattern
export default ({ isShow, punchoutType, data }) => {
  if (isShow) {
    return (
      <React.Fragment>
        {[PUNCHOUT_TYPE.Ariba, PUNCHOUT_TYPE.Coupa].includes(punchoutType) && <PunchoutCxml data={data} />}
        {punchoutType === PUNCHOUT_TYPE.Hubwoo && <PunchoutHubwoo data={data} />}
        {punchoutType === PUNCHOUT_TYPE.Oracle && <PunchoutOracle data={data} />}
        {punchoutType === PUNCHOUT_TYPE.SAP && <PunchoutSap data={data} />}
        {punchoutType === PUNCHOUT_TYPE.SAPXml && <PunchoutSapXml data={data} />}
      </React.Fragment>
    )
  }

  return false
}
