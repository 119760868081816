import React from 'react'

import cx from 'classnames'

export default ({ children, color, className, right }) => {
  return (
    <div
      className={cx('ribbon', className, {
        'ribbon-left': !right,
        'ribbon-right': right,
      })}
    >
      <div className={cx('ribbon-text', color && `bg-${color} text-white text-uppercase fs-12`)}>{children}</div>
    </div>
  )
}
