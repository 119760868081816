import BaseService from 'services/base'

export default class GlobalDataService extends BaseService {
  constructor(api) {
    super(api)
    this.api = api
  }

  getCountries() {
    return this.api.get('global/country')
  }

  getLanguages() {
    return this.api.get('global/language')
  }
}
