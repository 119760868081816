export default class BaseService {
  constructor(api, url = '') {
    this.api = api
    this.url = url

    this.formatUrl = this.formatUrl.bind(this)
    this.get = this.get.bind(this)
    this.fetchTable = this.fetchTable.bind(this)
    this.fetchList = this.fetchList.bind(this)
    this.post = this.post.bind(this)
    this.put = this.put.bind(this)
    this.patch = this.patch.bind(this)
    this.delete = this.delete.bind(this)
  }

  formatUrl(url) {
    return url ? `/${url}` : ''
  }

  get(url, payload, config) {
    return this.api.get(`${this.url}${this.formatUrl(url)}`, payload, config)
  }

  fetchTable(payload, url, config) {
    return this.get(url, payload, config)
  }

  fetchList(url, payload, config) {
    return this.get(url, payload, config)
  }

  post(url, payload, config) {
    return this.api.post(`${this.url}${this.formatUrl(url)}`, payload, config)
  }

  put(url, payload, config) {
    return this.api.put(`${this.url}${this.formatUrl(url)}`, payload, config)
  }

  patch(url, payload, config) {
    return this.api.patch(`${this.url}${this.formatUrl(url)}`, payload, config)
  }

  delete(url, config) {
    return this.api.delete(`${this.url}${this.formatUrl(url)}`, config)
  }
}
