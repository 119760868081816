import React from 'react'

import { useFormContext, useFormState } from 'react-hook-form'

import HelpText from 'common/HelpText'

export default ({ resource }) => {
  const { watch } = useFormContext()
  const { errors } = useFormState()

  const address = watch('deliveryInfo.address')

  const hasDeliveryAddress =
    !!address && (!!address.street || !!address.number || !!address.postalCode || !!address.city || !!address.country)

  const addressHasError =
    !!errors?.deliveryInfo?.address?.street ||
    !!errors?.deliveryInfo?.address?.number ||
    !!errors?.deliveryInfo?.address?.postalCode ||
    !!errors?.deliveryInfo?.address?.city ||
    !!errors?.deliveryInfo?.address?.country

  return (
    <div className="text-muted white-space-pre-wrap py-2 px-3 mb-3 fs-14">
      {hasDeliveryAddress ? (
        <address className="pe-3 m-0">
          {address.building && <div>{address.building}</div>}
          {(address.street || address.number || address.box) && (
            <div>{[address.street, address.number, address.box].filter(item => !!item).join(' ')}</div>
          )}
          {(address.postalCode || address.city) && (
            <div>{[address.postalCode, address.city].filter(item => !!item).join(' ')}</div>
          )}
          {address.country && <div>{address.country.name}</div>}
        </address>
      ) : (
        <HelpText hasError>{resource.NoAddressSpecified}</HelpText>
      )}
      {hasDeliveryAddress && !!addressHasError && <HelpText hasError>{resource.IncompleteAddress}</HelpText>}
    </div>
  )
}
