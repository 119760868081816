import React from 'react'

import { Badge } from 'reactstrap'
import { Link } from 'react-router-dom'

import { ROUTES } from 'definitions'
import { titlelize } from 'utils'

export default ({ resource, items, total, onClose }) => {
  if (items && items.length > 0) {
    return (
      <div className="d-flex flex-column mb-2">
        <Badge color="primary" className="d-flex rounded fs-16">
          {resource.Categories}
          <Badge color="white" className="ms-auto rounded-pill">
            <span className="text-primary">{total}</span>
          </Badge>
        </Badge>
        <div className="px-3 py-1">
          {items.map((item, index) => {
            const url = `${ROUTES.WEBSHOP_CATALOG}/${item.categoryPath}`

            return (
              <Link key={`category${index}`} to={url} className="text-link" onClick={onClose}>
                <div className="fs-12 fw-bold">{titlelize(item.name)}</div>
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
  return false
}
