import React from 'react'

import { useWatch } from 'react-hook-form'
import Input from 'common/Forms/Input'

export default ({ index, resource }) => {
  const watchHasSupplierDelivery = useWatch({ name: `items.${index}.hasSupplierDelivery` })

  return (
    <Input
      type="hidden"
      name={`items.${index}.hasSupplierDelivery`}
      formGroup
      rules={{
        required: !watchHasSupplierDelivery && resource.UnableSupplierDelivery,
      }}
    />
  )
}
