import React from 'react'

import { Col } from 'reactstrap'
import { observer } from 'mobx-react'
import { useFormState } from 'react-hook-form'
import cx from 'classnames'

import { useStores } from 'stores'
import FormGroup from 'common/FormGroup'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'

export default observer(
  ({
    resource,
    countries,
    isOther,
    defaultDeliveryAddress,
    namePrefix = '',
    lineIndex,
    disabled,
    isReadOnly,
    formClassName,
    labelClassName,
    inputColClassName,
    labelCol,
    inputCol,
    hideAddressBuilding,
  }) => {
    const { globalDataStore } = useStores()
    const { errors } = useFormState()

    let addressHasError
    let locationHasError

    if (errors) {
      if (lineIndex >= 0 && errors?.webformLines?.length > 0) {
        addressHasError =
          errors?.webformLines[lineIndex]?.deliveryInfo?.deliveryAddress?.street ||
          errors?.webformLines[lineIndex]?.deliveryInfo?.deliveryAddress?.number
        locationHasError =
          errors?.webformLines[lineIndex]?.deliveryInfo?.deliveryAddress?.postalCode ||
          errors?.webformLines[lineIndex]?.deliveryInfo?.deliveryAddress?.city
      } else {
        addressHasError = errors?.deliveryInfo?.deliveryAddress?.street || errors?.deliveryInfo?.deliveryAddress?.number
        locationHasError =
          errors?.deliveryInfo?.deliveryAddress?.postalCode || errors?.deliveryInfo?.deliveryAddress?.city
      }
    }

    return (
      <React.Fragment>
        {!hideAddressBuilding && (
          <Input
            disabled={disabled}
            readOnlyField={isReadOnly}
            name={`${namePrefix}deliveryInfo.deliveryAddress.building`}
            defaultValue={defaultDeliveryAddress.building}
            label={resource.Building}
            placeholder={resource.phBuilding}
            formGroup
            horizontal
            formClassName={formClassName}
            labelClassName={labelClassName}
            inputColClassName={inputColClassName}
            labelCol={labelCol}
            inputCol={inputCol}
          />
        )}
        <FormGroup
          label={`${resource.Address}${isReadOnly ? '' : ' *'} `}
          formGroup
          horizontal
          hasError={addressHasError}
          className={formClassName}
          labelClassName={labelClassName}
          inputColClassName={cx('d-flex', inputColClassName)}
          labelCol={labelCol}
          inputCol={inputCol}
        >
          <Col md="6" className="pe-3">
            <Input
              disabled={disabled}
              readOnlyField={isReadOnly}
              name={`${namePrefix}deliveryInfo.deliveryAddress.street`}
              defaultValue={defaultDeliveryAddress.street}
              rules={{ required: isOther && resource.Required }}
              placeholder={resource.phStreet}
            />
          </Col>
          <Col md="3" className="px-3">
            <Input
              disabled={disabled}
              readOnlyField={isReadOnly}
              name={`${namePrefix}deliveryInfo.deliveryAddress.number`}
              defaultValue={defaultDeliveryAddress.number}
              rules={{ required: isOther && resource.Required }}
              placeholder={resource.phNr}
            />
          </Col>
          <Col md="3" className="ps-3">
            <Input
              disabled={disabled}
              readOnlyField={isReadOnly}
              name={`${namePrefix}deliveryInfo.deliveryAddress.box`}
              defaultValue={defaultDeliveryAddress.box}
              placeholder={resource.phBox}
            />
          </Col>
        </FormGroup>
        <FormGroup
          formGroup
          horizontal
          label={`${resource.Location}${isReadOnly ? '' : ' *'} `}
          hasError={locationHasError}
          className={formClassName}
          labelClassName={labelClassName}
          inputColClassName={cx('d-flex', inputColClassName)}
          labelCol={labelCol}
          inputCol={inputCol}
        >
          <Col md="4" className="pe-3">
            <Input
              disabled={disabled}
              readOnlyField={isReadOnly}
              name={`${namePrefix}deliveryInfo.deliveryAddress.postalCode`}
              rules={{ required: isOther && resource.Required }}
              defaultValue={defaultDeliveryAddress.postalCode}
              placeholder={resource.phZipCode}
            />
          </Col>
          <Col md="8" className="ps-3">
            <Input
              disabled={disabled}
              readOnlyField={isReadOnly}
              name={`${namePrefix}deliveryInfo.deliveryAddress.city`}
              rules={{ required: isOther && resource.Required }}
              defaultValue={defaultDeliveryAddress.city}
              placeholder={resource.phCity}
            />
          </Col>
        </FormGroup>
        <ReactSelect
          isDisabled={disabled}
          readOnlyField={isReadOnly}
          name={`${namePrefix}deliveryInfo.deliveryAddress.country`}
          rules={{ required: isOther && resource.Required }}
          defaultValue={defaultDeliveryAddress.country}
          label={`${resource.Country}${isReadOnly ? '' : ' *'} `}
          options={countries}
          loadData={globalDataStore.searchCountries}
          defaultOptions={globalDataStore.getDefaultCountries()}
          optionValueKey="code"
          optionLabelKey="name"
          serverSide
          formGroup
          horizontal
          formClassName={formClassName}
          labelClassName={labelClassName}
          inputColClassName={inputColClassName}
          labelCol={labelCol}
          inputCol={inputCol}
        />
      </React.Fragment>
    )
  }
)
