import React from 'react'

import ButtonFavourite from 'components/Webshop/WebshopFavourites/ButtonFavourite'

export default ({ resource, data }) => (
  <div className="d-flex align-items-center mb-4">
    <ButtonFavourite resource={resource} itemId={data.itemId} favouriteId={data.favouriteId} className="me-2" />
    <h3 className="fs-16 fw-bold webshop-title-color mb-0">{data.name}</h3>
  </div>
)
