import React from 'react'

import { Input, Label } from 'reactstrap'
import cx from 'classnames'

import FormGroup from 'common/FormGroup'

export default ({
  disabled,
  inputRef,
  className,
  formClassName,
  labelClassName,
  formGroup,
  horizontal,
  label,
  labelCol,
  inputCol,
  formLabel,
  hideLabel = true,
  check = true,
  helpText,
  helpTextType,
  readOnlyField,
  title,
  ...rest
}) => {
  const hasError = helpTextType === 'error' && !!helpText
  return (
    <FormGroup
      check={check}
      formGroup={formGroup}
      horizontal={horizontal}
      labelCol={labelCol}
      inputCol={inputCol || { md: { size: 9, offset: 3 } }}
      hasError={hasError}
      hideLabel={hideLabel}
      title={title}
      className={formClassName}
      label={formLabel}
    >
      <Label
        className={cx(
          'mb-0 form-label d-inline-flex align-items-center',
          labelClassName,
          hasError && 'text-danger',
          !disabled && 'cursor-pointer'
        )}
      >
        <Input
          innerRef={inputRef}
          type="checkbox"
          className={cx('me-2 mt-0', className)}
          readOnly={readOnlyField}
          disabled={disabled}
          {...rest}
        />{' '}
        {label}
      </Label>
    </FormGroup>
  )
}
