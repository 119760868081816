import React from 'react'

import { DELIVER_TO } from 'definitions'
import { useStores } from 'stores'
import Input from 'common/Forms/Input'
import RadioGroup from 'common/Forms/RadioGroup'
import ReactSelect from 'common/Forms/ReactSelect'

export default ({
  resource,
  commonResource: commonResourceProp,
  namePrefix = '',
  clientId,
  deliverTo: deliverToProps = DELIVER_TO.OTHER,
  defaultRequester,
  defaultOtherRequester,
  disabled,
  formClassName,
  labelClassName,
  inputColClassName,
  labelCol,
  inputCol,
  isReadOnly,
}) => {
  const { clientAreaEmployeeStore } = useStores()
  const [deliverTo, setDeliverTo] = React.useState(deliverToProps)

  const [requester, setRequester] = React.useState(defaultRequester)

  const [otherRequester, setOtherRequester] = React.useState(defaultOtherRequester)

  const handleOnChange = newValue => {
    setRequester(newValue)
  }

  const handleOtherRequesterOnChange = newValue => {
    setOtherRequester(newValue)
  }

  const isEmployee = deliverTo === DELIVER_TO.EMPLOYEE
  const isOther = deliverTo === DELIVER_TO.OTHER
  const commonResource = commonResourceProp || resource

  const handleChangeDeliverTo = selected => {
    setDeliverTo(selected)
  }

  return (
    <React.Fragment>
      <RadioGroup
        name={`${namePrefix}deliveryInfo.deliverTo`}
        disabled={disabled}
        rules={{ required: commonResource.Required }}
        radios={[
          {
            value: DELIVER_TO.EMPLOYEE,
            label: resource.CompanyEmployee,
            children: isEmployee && (
              <ReactSelect
                name={`${namePrefix}deliveryInfo.requester`}
                rules={{ required: isEmployee && commonResource.Required }}
                defaultValue={requester}
                value={requester}
                isDisabled={disabled}
                formGroup
                horizontal
                serverSide
                loadData={args =>
                  clientAreaEmployeeStore.searchClientEmployee(clientId, {
                    displayName: args.search,
                  })
                }
                loadDataPropertyKey="employees"
                optionValueKey="id"
                optionLabelKey="name"
                placeholder={commonResource.SelectCompanyEmployee}
                inputCol={{ lg: 12, xl: 12 }}
                onChange={handleOnChange}
                readOnlyField={isReadOnly}
              />
            ),
          },
          {
            value: DELIVER_TO.OTHER,
            label: resource.OtherPerson,
          },
        ]}
        label={resource.DeliverTo}
        defaultValue={deliverTo}
        formGroup
        horizontal
        onChange={handleChangeDeliverTo}
        formClassName={formClassName}
        labelClassName={labelClassName}
        inputColClassName={inputColClassName}
        labelCol={labelCol}
        inputCol={inputCol}
        readOnlyField={isReadOnly}
      />
      {isOther && (
        <Input
          name={`${namePrefix}deliveryInfo.otherRequester`}
          rules={{ required: isOther && commonResource.Required }}
          defaultValue={otherRequester}
          label={resource.FullName}
          disabled={disabled}
          formGroup
          horizontal
          formClassName={formClassName}
          labelClassName={labelClassName}
          inputColClassName={inputColClassName}
          labelCol={labelCol}
          inputCol={inputCol}
          readOnly={isReadOnly}
          onChange={handleOtherRequesterOnChange}
        />
      )}
    </React.Fragment>
  )
}
