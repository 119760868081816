import { useLocation, useNavigate } from 'react-router-dom'

import { IS_AUTHORIZED, LOGIN_PROVIDER, LOGOUT_REQUEST, PRA_TOKEN, REDIRECT_URL, ROUTES } from 'definitions'
import { jsonToQueryString } from 'utils/formatters'
import { useStores } from 'stores'

export const useAuthManager = () => {
  const { authStore, pageResourceStore } = useStores()
  const navigate = useNavigate()
  const location = useLocation()

  const getAuthSession = async () => {
    const result = await authStore.getAuthSession()
    if (result) {
      if (result.session.isClientBuyer) {
        sessionStorage.setItem(PRA_TOKEN, result.session?.jwtoken)

        // Fetch account and common translations
        authStore.AppSession.loading = true
        await Promise.allSettled([pageResourceStore.get('account'), pageResourceStore.get('common')])
        authStore.AppSession.loading = false
      } else {
        window.location = process.env.REACT_APP_OAZO_ADMIN_URL
        return false
      }
    }
    return result
  }

  const getLoginUrl = provider => {
    switch (provider) {
      case LOGIN_PROVIDER.BELFIUS:
        return ROUTES.LOGIN_BELFIUS
      case LOGIN_PROVIDER.GOED:
        return ROUTES.LOGIN_GOED
      case LOGIN_PROVIDER.INTERPARKING:
        return ROUTES.LOGIN_INTERPARKING
      default:
        return ROUTES.LOGIN
    }
  }

  const login = async (payload, provider) => {
    const result = await authStore.login(payload, provider)
    if (result) {
      sessionStorage.setItem(IS_AUTHORIZED, true)
      await getAuthSession()
      redirectAfterLogin()
    }
  }

  const logout = async sessionExpired => {
    resetSession()
    const provider = authStore.AppSession.session?.loginProvider

    if (sessionExpired) {
      raiseLogoutRequest()
      redirectToLogin({ provider })
      return true
    } else {
      const result = await authStore.logout()
      raiseLogoutRequest()

      if (result) {
        window.location.href = getLoginUrl(provider)
      } else {
        window.location.href = `${getLoginUrl(provider)}?logout=true`
      }
      return result
    }
  }

  const logoutAfterPunchout = async () => {
    resetSession()
    const result = await authStore.logout()
    raiseLogoutRequest()
    return result
  }

  const raiseLogoutRequest = () => {
    // Trigger request to logout other open tabs
    localStorage.setItem(LOGOUT_REQUEST, true)

    // Clear trigger aftewards
    localStorage.removeItem(LOGOUT_REQUEST)
  }

  const redirectToLogin = ({ reload, provider, loginSearchParams, searchParams } = {}) => {
    if (
      ![ROUTES.LOGIN, ROUTES.LOGIN_BELFIUS, ROUTES.LOGIN_GOED, ROUTES.LOGIN_INTERPARKING].includes(
        window.location.pathname
      )
    ) {
      const searchString = searchParams ? jsonToQueryString(searchParams) : window.location.search
      sessionStorage.setItem(REDIRECT_URL, `${window.location.pathname}${searchString}`)
    }

    let url = getLoginUrl(provider)
    if (loginSearchParams) {
      url = `${url}${jsonToQueryString(loginSearchParams)}`
    }

    if (reload) {
      window.location.href = url
    } else {
      navigate(url)
    }
  }

  const redirectAfterLogin = newUrl => {
    let redirectUrl
    if (newUrl) {
      redirectUrl = newUrl
    } else {
      const urlsToCheck = [ROUTES.LOGIN, ROUTES.LOGIN_BELFIUS, ROUTES.LOGIN_GOED, ROUTES.LOGIN_INTERPARKING]

      redirectUrl = sessionStorage.getItem(REDIRECT_URL)
      sessionStorage.removeItem(REDIRECT_URL)

      if (!redirectUrl && urlsToCheck.includes(redirectUrl)) {
        redirectUrl = ROUTES.DEFAULT
      } else if (!redirectUrl && urlsToCheck.includes(location.pathname)) {
        redirectUrl = ROUTES.DEFAULT
      }
    }

    if (redirectUrl) {
      navigate(redirectUrl, { replace: true })
    }
  }

  const resetSession = () => {
    sessionStorage.clear()
  }

  return {
    getAuthSession,
    login,
    logout,
    logoutAfterPunchout,
    raiseLogoutRequest,
    redirectToLogin,
    redirectAfterLogin,
    resetSession,
  }
}
