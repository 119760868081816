import React from 'react'

import { formatFileUrl } from 'utils/formatters'
import { PRA_TYPE } from 'definitions'

import Card from 'common/Card'
import Checkbox from 'common/Forms/Checkbox'
import FormGroup from 'common/FormGroup'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'

import PraSupplierInfo from 'components/Pra/PraSupplierInfo'

export default ({ loggedinEmployeeId, praStore, clientId, resource, commonResource, isReadOnly, fetchApprovers }) => {
  const [supplier, setSupplier] = React.useState()

  const {
    CRUD: { data = {}, settings = {}, submitting },
    Lookups: { data: { employees = [] } = {} },
  } = praStore

  React.useEffect(() => {
    setSupplier(data.supplierId)
  }, [data.supplierId])

  const defaultRequester = React.useMemo(() => {
    if (data.requester) {
      return data.requester
    } else {
      return employees.find(item => item.id === loggedinEmployeeId)
    }
  }, [loggedinEmployeeId, data, employees])

  const handleSupplierChange = newSupplier => {
    setSupplier(newSupplier)
  }

  return (
    <Card title={resource.GeneralInformation} fieldset>
      <div className="d-flex flex-lg-row flex-column-reverse">
        <div className="col-lg-8">
          <ReactSelect
            name="supplierId"
            rules={{ required: commonResource.Required }}
            defaultValue={data.supplierId}
            label={`${resource.Supplier}${isReadOnly ? '' : ' *'} `}
            isDisabled={submitting || [PRA_TYPE.WEBFORM, PRA_TYPE.WEBSHOP, PRA_TYPE.DIY, PRA_TYPE.CONNECTION].includes(data.type)}
            readOnlyField={isReadOnly}
            formGroup
            horizontal
            labelCol={{ lg: 3 }}
            inputCol={{ lg: 9 }}
            serverSide
            loadData={args =>
              praStore.searchSuppliers({
                clientId,
                criteria: args.search,
              })
            }
            loadDataPropertyKey="data"
            optionValueKey="id"
            optionLabelKey="name"
            labelClassName="text-lg-end"
            onChange={handleSupplierChange}
          />
          <FormGroup formGroup className="ps-md-1 offset-lg-3" inputCol={{ lg: 9 }}>
            <PraSupplierInfo supplier={supplier} resource={resource} hideName />
          </FormGroup>
          <ReactSelect
            name="requester"
            rules={{ required: commonResource.Required }}
            defaultValue={defaultRequester}
            label={`${resource.Requester}${isReadOnly ? '' : ' *'} `}
            isDisabled={submitting}
            readOnlyField={isReadOnly}
            formGroup
            horizontal
            labelCol={{ lg: 3 }}
            inputCol={{ lg: 9 }}
            options={employees}
            optionValueKey="id"
            optionLabelKey="name"
            labelClassName="text-lg-end"
            onChange={fetchApprovers}
          />
          {settings.enableProjectCode && (
            <Input
              name="projectCode"
              label={resource.ProjectCode}
              defaultValue={data.projectCode}
              formGroup
              horizontal
              labelCol={{ lg: 3 }}
              inputCol={{ lg: 9 }}
              labelClassName="text-lg-end"
              readOnlyField={!settings.allowAdministratorOverride && isReadOnly}
              disabled={submitting}
            />
          )}
          {settings.enableContractPo && ![PRA_TYPE.WEBFORM, PRA_TYPE.WEBSHOP, PRA_TYPE.CONNECTION].includes(data.type) && (
            <Checkbox
              name="isContractPo"
              label={resource.ContractPo}
              defaultChecked={data.isContractPo}
              formGroup
              horizontal
              inputCol={{ lg: { size: 9, offset: 3 } }}
              labelClassName="text-lg-end"
              disabled={submitting || isReadOnly}
            />
          )}
        </div>
        <div className="col-lg-4 col text-center mb-4 ps-lg-3 pt-lg-0 pt-2">
          {settings.clientLogo && (
            <img
              alt={`Client Logo - ${settings.client?.name}`}
              className="img-responsive img-thumbnail fadein"
              style={{ maxHeight: '200px' }}
              src={formatFileUrl(settings.clientLogo)}
            />
          )}
        </div>
      </div>
    </Card>
  )
}
