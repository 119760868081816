import React from 'react'

import { Alert } from 'reactstrap'

import { OFFLINE_MESSAGE, ROUTES } from 'definitions'
import { replaceUrl } from 'utils'
import Paper from 'common/Paper'

import logoImage from 'assets/images/spot-buy-logo-120.jpg'

export default () => {
  const [title, setTitle] = React.useState()
  const [message, setMessage] = React.useState()

  React.useEffect(() => {
    replaceUrl(ROUTES.DEFAULT)
    const offlineMessage = sessionStorage.getItem(OFFLINE_MESSAGE)
    if (offlineMessage) {
      const matches = offlineMessage.match(/\[(.*?)\]/g)
      if (matches?.length) {
        setTitle(matches[0]?.replace(/[\\[\]]+/g, '')?.trim() || '')
        if (matches && matches.length > 1) {
          setMessage(matches[1]?.replace(/[\\[\]]+/g, '')?.trim() || '')
        }
      }
    }
    sessionStorage.removeItem(OFFLINE_MESSAGE)
  }, [])

  return (
    <div id="offline" className="d-flex justify-content-center p-3">
      <Paper elevation="1" className="wrapper py-5 px-3 text-center">
        <img src={logoImage} alt="Oazo logo" />
        <Alert color="secondary" className="p-5 my-5 fs-24 fw-bold">
          {title || 'Scheduled maintenance'}
        </Alert>
        <section className="message">
          {message && <div dangerouslySetInnerHTML={{ __html: message }} />}
          {!message && (
            <React.Fragment>
              <p>
                Scheduled maintenance is in progress.
                <br />
                We expect to be back shortly.
              </p>
              <p>Thank you for your understanding.</p>
            </React.Fragment>
          )}
        </section>
      </Paper>
    </div>
  )
}
