import BaseService from 'services/base'

export default class FeedbackService extends BaseService {
  constructor(api) {
    super(api)
    this.api = api
  }

  getFeedback(caseId) {
    return this.get(`getclientcomplaint/${caseId}`)
  }

  submitFeedback(payload) {
    return this.post('createclientcomplaint', payload)
  }
}
