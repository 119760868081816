import React from 'react'

import cx from 'classnames'

import { ATTACHMENT_TYPE } from 'definitions'
import { useDropzone } from 'react-dropzone'
import { useStateCallback } from 'hooks/useStateCallback'
import { useStores } from 'stores'
import Button from 'common/Button'
import ButtonIcon from 'common/ButtonIcon'
import FileLink from 'common/FileLink'
import FileRestrictionTooltip from 'common/FileRestrictionTooltip'
import FormGroup from 'common/FormGroup'
import HelpText from 'common/HelpText'

export default ({
  inputRef,

  value,
  defaultValue,
  name,
  label,
  type = ATTACHMENT_TYPE.Attachment,
  multiple = true,
  disabled,

  formGroup,
  horizontal,
  className,
  formClassName,
  labelClassName,
  inputColClassName,
  labelCol,
  inputCol,
  helpText,
  helpTextType,

  onChange,
}) => {
  const { praStore, pageResourceStore } = useStores()
  const fileInputRef = React.useRef()
  const [newFiles, setNewFiles] = useStateCallback([]) // Files are to be added file attachments
  const [attachments, setAttachments] = React.useState(defaultValue?.attachments || value?.attachments) // The existing attachments
  const [removedAttachments, setRemovedAttachments] = React.useState([]) // The existing attachments that removed by user
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: selection => handleFileChange(selection),
    multiple,
  })

  const hasError = helpTextType === 'error' && !!helpText

  const options = getOptions(type)
  const {
    PageResource: { common: resource = {} },
  } = pageResourceStore

  const triggerOnChange = (attachments, theAdded, theDeleted) => {
    const successFiles = theAdded.filter(item => item.success)
    onChange &&
      onChange({
        attachments,
        added: successFiles,
        deleted: theDeleted,
      })
  }

  const handleUploadFile = () => fileInputRef.current.click()

  const handleFileChange = selection => {
    if (selection && selection.length) {
      let id = newFiles.length
      const addFiles = Array.from(selection).map(item => {
        let error = null

        if (item.size > options.maxFileSize) {
          error = resource.FileIsTooLarge
        }

        return {
          id: id++,
          data: item,
          name: item.name,
          submitting: !error,
          error,
        }
      })

      fileInputRef.current.value = ''
      setNewFiles([...newFiles, ...addFiles], combined => {
        combined
          .filter(item => item.submitting)
          .forEach(item => {
            uploadFile(combined, item)
          })
      })
    }
  }
  const uploadFile = async (combined, file) => {
    const payload = new FormData()
    payload.append('file', file.data)
    payload.append('fileType', type)

    const { data, error } = await praStore.upload(payload)

    const theFile = combined.find(item => item.id === file.id)

    theFile.submitting = false
    theFile.error = error
    theFile.fileId = data ? data.fileId : null
    theFile.success = !error && !!data && !!data.fileId
    setNewFiles([...combined], added => triggerOnChange(attachments, added, removedAttachments))
  }

  const handleRemoveFile = theFile => {
    if (!disabled) {
      const goodbyeFiles = newFiles.filter(item => item.id !== theFile.id)
      setNewFiles([...goodbyeFiles], added => triggerOnChange(attachments, added, removedAttachments))
    }
  }

  const handleRemoveAttachment = theFile => {
    const newAttachments = attachments.filter(item => item.fileId !== theFile.fileId)
    setAttachments([...newAttachments])

    removedAttachments.push(theFile.fileId)
    setRemovedAttachments(removedAttachments)
    triggerOnChange(attachments, newAttachments, removedAttachments)
  }

  const handleDropClick = e => {
    // Prevent the default behavior of the click event
    e.preventDefault()
  }

  return (
    <FormGroup
      {...{
        label,
        formGroup,
        horizontal,
        className: formClassName,
        labelClassName,
        inputColClassName,
        labelCol,
        inputCol,
        hasError,
      }}
    >
      {(!attachments || !attachments.length) && (
        <div className="mt-1 mb-2">
          <em className="text-muted">{resource.NoAttachments}</em>
        </div>
      )}
      {!!attachments && !!attachments.length && (
        <ul className="list-unstyled">
          {attachments.map(item => (
            <li key={item.fileId}>
              <FileLink data={item} />
              {!disabled && (
                <ButtonIcon
                  icon="highlight_off"
                  iconClassName="md-18 text-danger fw-bold"
                  className="p-0 ms-1"
                  title="Remove attachment"
                  color="white"
                  onClick={() => handleRemoveAttachment(item)}
                />
              )}
            </li>
          ))}
        </ul>
      )}
      <input
        ref={fileInputRef}
        type="file"
        name={name}
        accept={options.accept}
        multiple={multiple}
        className="d-none"
        onChange={event => handleFileChange(event.target.files)}
      />
      <div
        className={cx('file-attachment', { 'is-drag-active': isDragActive })}
        {...getRootProps()}
        onClick={handleDropClick}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop files here</p>
        ) : (
          <div className={cx('d-flex justify-content-between', className)}>
            <Button type="button" color="primary" icon="attach_file" disabled={disabled} onClick={handleUploadFile}>
              {resource.Upload}
            </Button>
            <FileRestrictionTooltip resource={resource} accept={options.accept} />
          </div>
        )}
      </div>
      <div className="my-3" ref={inputRef}>
        {newFiles.map((item, index) => (
          <small key={index} className="d-flex text-help">
            <span className="col-6 d-inline-flex align-items-center">
              <strong>{item.name}</strong>
              {(item.success || item.error) && !disabled && (
                <ButtonIcon
                  icon="highlight_off"
                  iconClassName="md-18 text-danger fw-bold"
                  className="p-0 ms-1"
                  title="Remove file"
                  color="white"
                  onClick={() => handleRemoveFile(item)}
                />
              )}
            </span>
            <span className="col-6">
              <span className="upload-progress">
                {item.submitting && <strong className="text-info">{resource.Uploading}</strong>}
                {item.success && <strong className="text-success me-1">{resource.UploadCompleted}</strong>}
                {item.error && (
                  <span className="text-danger">
                    <strong className="me-1">{resource.UploadFailed}</strong>
                    {item.error}
                  </span>
                )}
              </span>
            </span>
          </small>
        ))}
      </div>
      {!!helpText && <HelpText hasError={hasError}>{helpText}</HelpText>}
    </FormGroup>
  )
}

const getOptions = type => {
  switch (type) {
    case ATTACHMENT_TYPE.Attachment:
      return {
        accept:
          '.ai,.csv,.doc,.docx,.eml,.jpeg,.jpg,.msg,.pdf,.png,.ppt,.pttx,.rar,.txt,.xls,.xlsx,.zip,.xml,.tif,.tiff,.eps',
        maxFileSize: 10485760, // 10 MB
        maxFileSizeLabel: '10 MB',
      }
    default:
      return {}
  }
}
