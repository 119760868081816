import BaseService from 'services/base'

export default class CaseService extends BaseService {
  constructor(api) {
    const url = 'case'
    super(api, url)
    this.api = api
    this.url = url
  }

  abortCase = (caseId, payLoad) => {
    return this.put(`${caseId}/abort`, payLoad, { ignoreGlobalMessage: true })
  }

  copyCase(caseId) {
    return this.post(`${caseId}/copy`)
  }

  getWebshopCheckoutMessage() {
    return this.api.get('webshop/checkoutmessage')
  }

  getProposalSelectedMessage() {
    return this.api.get('webshop/pickproposalmessage')
  }

  releaseCase(caseId) {
    return this.put(`${caseId}/release`)
  }

  webshopPunchout(caseId) {
    return this.post(`${caseId}/webshoppunchout`)
  }
}
