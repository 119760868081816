import React from 'react'

import { useController, useFormContext, useFormState } from 'react-hook-form'

import { getError } from 'utils/validators'

export default ({ tag: Component, name, label, rules = {}, defaultValue, onChange, onBlur, ...props }) => {
  const { control } = useFormContext()
  const { errors } = useFormState()
  const {
    field: { ref, ...field },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })
  const error = errors && getError(errors, name)

  const handleOnChange = data => {
    field.onChange(data)
    onChange && onChange(data)
  }

  const handleOnBlur = data => {
    field.onBlur(data)
    onBlur && onBlur(data)
  }

  return (
    <Component
      {...field}
      inputRef={ref}
      helpTextType="error"
      helpText={error}
      label={label}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      {...props}
    />
  )
}
