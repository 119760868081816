import React from 'react'

import { Link } from 'react-router-dom'

import { ROUTES } from 'definitions'
import Button from 'common/Button'

export default ({ resource, defaultPage, submitting, hasData, submitted, viewOnly }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      {defaultPage === 'webform' && (
        <Link to={ROUTES.WEBFORM} className="btn btn-warning text-decoration-none">
          {resource['Return.Webform']}
        </Link>
      )}
      {defaultPage === 'cases' && (
        <Link to={ROUTES.CASES} className="btn btn-warning text-decoration-none">
          {resource['Return.Cases']}
        </Link>
      )}
      {hasData && !submitted && !viewOnly && (
        <Button type="submit" icon="done" color="success" disabled={submitting} loading={submitting}>
          Submit
        </Button>
      )}
    </div>
  )
}
