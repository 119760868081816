import React from 'react'

import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useSearchParams } from 'react-router-dom'

import { isNull, replaceUrl } from 'utils'
import { ROUTES } from 'definitions'
import { useStores } from 'stores'
import Loader from 'common/Loader'
import Toaster from 'common/Toaster'
import WebformFooter from 'components/Webform/WebformFooter'
import WebformInfo from 'components/Webform/WebformInfo'
import WebformProducts from 'components/Webform/WebformProducts'

export default observer(() => {
  const { pageResourceStore, webformStore, globalMessageStore } = useStores()
  const [loading, setLoading] = React.useState(true)
  const [isOpenLineIndex, setIsOpenLineIndex] = React.useState()
  const [searchParams] = useSearchParams()
  const methods = useForm({
    defaultValues: {
      webformLines: [],
    },
  })
  const { append, fields, replace, remove } = useFieldArray({
    control: methods.control,
    name: 'webformLines',
    shouldUnregister: true,
  })

  const { getValues, handleSubmit, reset, trigger } = methods
  const {
    PageResource: { webform: resource = {} },
  } = pageResourceStore
  const isLineExpanded = isNull(isOpenLineIndex, -1) >= 0

  React.useEffect(() => {
    const getWebform = async () => {
      try {
        setLoading(true)
        const caseId = searchParams.get('copy-case')
        await Promise.allSettled([webformStore.getInitialData(caseId), pageResourceStore.get('webform')])

        if (caseId) {
          replaceUrl(ROUTES.WEBFORM)
          const data = webformStore.CRUD.data
          if (data) {
            // Add copied lines from case
            if (data.webformLines?.length > 0) {
              replace(data.webformLines)
            }
          }
        }
      } finally {
        setLoading(false)
      }
    }
    getWebform()
  }, [pageResourceStore, webformStore, searchParams])

  const toggle = lineIndex => setIsOpenLineIndex(lineIndex)

  const handleOnSubmit = values => {
    globalMessageStore.close()
    Toaster.confirm({
      title: resource.ConfirmWebformSubmission,
      message: resource.SubmitWebformMessage,
      submitText: resource.Confirm,
      cancelText: resource.Cancel,
      onSubmit: async () => {
        const result = await webformStore.submitWebform(values)
        if (result) {
          reset({ webformLines: [] })
          if (result.pendingForController) {
            globalMessageStore.show({
              title: resource.PendingForControllerTitle,
              message: resource.PendingForControllerMessage,
              color: 'success',
            })
          } else {
            globalMessageStore.show({
              title: resource.WebformSubmitted,
              message: resource.SubmittedSuccessfullyMessage,
              color: 'success',
            })
          }
        }
      },
    })
  }

  const handleAddProductLine = async () => {
    const result = await trigger()
    if (result) {
      const articleDescription = fields.length === 0 ? getValues('subject') : ''
      append({ articleDescription })
      toggle(fields.length)
    }
  }

  const handleLineExit = (isRemove, lineIndex, isPrompt) => {
    const doCollapse = () => {
      isRemove && remove(lineIndex)
      if (lineIndex !== isOpenLineIndex && isOpenLineIndex > lineIndex) {
        toggle(isOpenLineIndex - 1)
      } else {
        toggle(null)
      }
    }

    if (isPrompt) {
      Toaster.confirm({
        title: resource.RemoveProductLine,
        message: resource.RemoveLineConfirmationMessage,
        submitText: resource.Confirm,
        cancelText: resource.Cancel,
        onSubmit: () => {
          doCollapse()
        },
      })
    } else {
      doCollapse()
    }
  }

  const handleLineSave = async () => {
    const result = await trigger()
    result && toggle(null)
    return result
  }

  const handleLineEdit = async lineIndex => {
    if (isLineExpanded && lineIndex !== isOpenLineIndex) {
      Toaster.confirm({
        title: resource.Alert,
        message: resource.CantEditLinesSimultaneously,
        submitText: resource.Dismiss,
        cancelText: resource.Cancel,
      })
    } else if (lineIndex === isOpenLineIndex) {
      toggle(null)
    } else {
      toggle(lineIndex)
    }
  }

  const handleLineCopy = lineIndex => {
    if (isOpenLineIndex) {
      Toaster.confirm({
        title: resource.Alert,
        message: resource.CantEditLinesSimultaneously,
        submitText: resource.Dismiss,
        cancelText: resource.Cancel,
      })
    } else {
      const theLine = getValues(`webformLines.${lineIndex}`)
      append({ ...theLine })
      toggle(fields.length)
    }
  }

  return (
    <div id="webform">
      {<Loader show={loading} className="justify-content-center" />}
      {!loading && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleOnSubmit)} noValidate>
            <WebformInfo resource={resource} />
            <WebformProducts
              resource={resource}
              fields={fields}
              isOpenLineIndex={isOpenLineIndex}
              onExit={handleLineExit}
              onSave={handleLineSave}
              onEdit={handleLineEdit}
              onCopy={handleLineCopy}
            />
            <WebformFooter
              resource={resource}
              fields={fields}
              disabled={isLineExpanded}
              onAddProductLine={handleAddProductLine}
              isLineExpanded={isLineExpanded}
            />
          </form>
        </FormProvider>
      )}
    </div>
  )
})
