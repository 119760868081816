import React from 'react'

import { Alert } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'

import { ROUTES } from 'definitions'
import { useStores } from 'stores'
import Button from 'common/Button'
import Input from 'common/Forms/Input'
import MessageList from 'common/MessageList'
import Paper from 'common/Paper'

const ResetPassword = () => {
  const navigate = useNavigate()
  const { accountStore } = useStores()
  const methods = useForm()
  const { handleSubmit } = methods

  const {
    CRUD: { submitting, error },
  } = accountStore

  React.useEffect(() => {
    return () => accountStore.clearCrudError()
  }, [accountStore])

  const handleOnSubmit = async values => {
    const success = await accountStore.forgotPassword(values)
    if (success) {
      navigate(ROUTES.LOGIN, { state: { showForgotPasswordMessage: true } })
    }
  }

  return (
    <Paper elevation="3" className="mx-sm-auto col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4">
      <MessageList messages={error && [error]} className="mb-0" />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleOnSubmit)} className="d-flex flex-column w-100">
          <h2>Reset password</h2>
          <Alert color="secondary" className="">
            <div>Please enter your e-mail address.</div>
            <div>A new password will be sent to this address.</div>
          </Alert>
          <Input
            name="email"
            rules={{ required: 'E-mail address is required' }}
            placeholder="e-mail address"
            formGroup
            startAdornment={<span className="material-icons-outlined">email</span>}
            disabled={submitting}
          />
          <div className="d-flex justify-content-between align-items-end">
            <Link to={ROUTES.LOGIN} className="fs-12">
              Back to login form
            </Link>
            <Button
              type="submit"
              color="primary"
              loading={submitting}
              disabled={submitting}
              icon="check"
              className="px-4"
            >
              Send Password
            </Button>
          </div>
        </form>
      </FormProvider>
    </Paper>
  )
}

export default observer(ResetPassword)
