import React from 'react'

import { Alert } from 'reactstrap'

import { APPROVER_STATUS, PRA_STATUS } from 'definitions'
import { formatDate, praLongDateTimeFormat } from 'utils/formatters'
import Card from 'common/Card'
import Icon from 'common/Icon'
import ReactTable from 'common/ReactTable'

export default ({ resource, pra }) => {
  const orderSent =
    pra.status === PRA_STATUS.APPROVED.value && !!pra.poPdfAttachment && !!pra.poPdfAttachment.downloadLink

  const getApproverStatus = approver => {
    let label
    let color

    switch (approver.statusLabel) {
      case APPROVER_STATUS.REASSIGNED:
        label = resource.reassign
          ? `${resource.Reassigned || 'Reassigned'} ${resource.To || 'to'} ${approver.reassign.toName} ${
              resource.By || 'by'
            } ${approver.reassign.byName}`
          : resource.Reassigned
        color = 'info'
        break
      case APPROVER_STATUS.QUESTIONED:
        label = approver.question
          ? `${resource.Question || 'Question'} ${
              approver.question.toName ? `${resource.To || 'to'} ${approver.question.toName}` : ''
            } `
          : resource.Question
        color = 'danger'
        break
      case APPROVER_STATUS.ANSWERED:
        label = approver.question
          ? `${resource.Answer || 'Answer'} ${
              approver.question.toName ? `${resource.To || 'to'} ${approver.question.byName}` : ''
            } `
          : resource.Answer
        color = 'info'
        break
      case APPROVER_STATUS.INVALID:
        return false
      case APPROVER_STATUS.ABORTED:
        label = resource.Aborted
        color = 'danger'
        break
      case APPROVER_STATUS.OUTOFOFFICE:
        label = `${resource.OutOfficeRemark || 'Automatic OOO reassign to'} ${approver.backupApproverName}`
        color = 'info'
        break
      case APPROVER_STATUS.APPROVED:
        label = resource.Approved
        color = 'success'
        break
      case APPROVER_STATUS.REJECTED:
        label = resource.Rejected
        color = 'danger'
        break
      case APPROVER_STATUS.RELAUNCHED:
        label = resource.Relaunched
        color = 'warning'
        break
      default:
        // unstarted
        if (approver.question && approver.question.pendingQuestion && approver.id !== pra.currentApproverId) {
          label = `${resource.PendingAnswer || 'Pending answer to'} ${approver.question.byName}`
          color = 'info'
        } else {
          return false
        }
        break
    }

    return (
      <React.Fragment>
        <Alert color={color} className="mb-0 py-1 px-3">
          {label}
        </Alert>
        {approver.comment && (
          <Alert color="light" className="mt-1 mb-0 py-1 px-3">
            {approver.comment}
          </Alert>
        )}
      </React.Fragment>
    )
  }

  const columns = React.useMemo(
    () => [
      {
        accessor: 'name',
        headerClassName: 'col-3',
        className: 'col-3 align-top',
        Header: resource.ApproverName,
      },
      {
        headerClassName: 'col',
        className: 'col',
        footerClassName: 'align-top fw-normal',
        Header: resource.Action,
        Cell: ({ row }) => getApproverStatus(row),
        Footer: () => (
          <div>
            <Alert color="success" className="mb-1 py-1 px-3">
              {resource.OrderSent}
            </Alert>
            {!!pra.poPdfAttachment && (
              <div>
                <Icon icon="attachment" className="me-1 align-middle" />
                <a href={pra.poPdfAttachment.downloadLink}>{pra.poPdfAttachment.fileName}</a>
              </div>
            )}
          </div>
        ),
      },
      {
        accessor: 'date',
        headerClassName: 'col-3',
        className: 'col-3 align-top',
        footerClassName: 'align-top fw-normal',
        Header: resource.Date,
        Cell: ({ value }) => formatDate(value, praLongDateTimeFormat),
        Footer: () => !!pra.poPdfAttachment && pra.poPdfAttachment.description,
      },
    ],

    [resource.Action, resource.ApproverName, resource.Date]
  )

  return (
    <Card fieldset title={resource.ApprovalFlow}>
      <div className="position-relative">
        <ReactTable
          columns={columns}
          data={pra.approvers}
          responsive
          showFooter={orderSent}
          className="approvers-table"
        />
      </div>
    </Card>
  )
}
