import moment from 'moment'

import { range } from 'utils/array'

let defaultYearRange = []

export const MIN_DATE = new Date('1970-01-01T00:00:00.000')
export const MAX_DATE = new Date('2099-12-31T00:00:00.000')

export const getWeekRange = date => {
  const startOfWeek = moment(date).startOf('week').toDate()
  return [...Array(5).keys()].map(index =>
    moment(startOfWeek)
      .add(index + 1, 'days')
      .toDate()
  )
}

export const getYearRange = () => {
  if (!defaultYearRange || !defaultYearRange.length) {
    const startYear = MIN_DATE.getFullYear()
    const endYear = moment().add(20, 'y').year()

    const maxYear = endYear - startYear
    defaultYearRange = range(startYear, maxYear).sort().reverse()
  }

  return defaultYearRange
}
