import React from 'react'

import { Collapse } from 'reactstrap'
import { observer } from 'mobx-react'
import { useFormContext } from 'react-hook-form'

import Checkbox from 'common/Forms/Checkbox'
import DatePicker from 'common/Forms/DatePicker'
import FormGroup from 'common/FormGroup'
import WebformInfoDeliveryInfo from 'components/Webform/WebformInfoDeliveryInfo'
import WebformInfoDeliveryRequester from 'components/Webform/WebformInfoDeliveryRequester'

export default observer(({ webformStore, resource, lineItem, index, disabled }) => {
  const [isOpen, setIsOpen] = React.useState(lineItem.overrideDelivery)
  const { getValues } = useFormContext()

  const {
    CRUD: { data },
  } = webformStore
  const { settings = {} } = data
  const namePrefix = `webformLines.${index}.`
  const headerDeliveryInfo = getValues('deliveryInfo')

  const handleOverrideDeliveryChange = event => {
    setIsOpen(event.target.checked)
  }

  return (
    <React.Fragment>
      <Checkbox
        name={`${namePrefix}overrideDelivery`}
        label={resource.OverrideDeliveryInformation}
        labelClassName="fst-italic fw-bold"
        formGroup
        horizontal
        onChange={handleOverrideDeliveryChange}
      />
      <Collapse isOpen={isOpen}>
        {isOpen && (
          <React.Fragment>
            <FormGroup formGroup horizontal inputColClassName="offset-md-3">
              {resource.DeliveryInformation}
            </FormGroup>
            <DatePicker
              name={`${namePrefix}deliveryInfo.deliveryDate`}
              rules={{ required: isOpen && resource.Required }}
              defaultValue={headerDeliveryInfo.deliveryDate}
              label={resource.PreferredDate}
              disabled={disabled}
              formGroup
              horizontal
              inputClassName="col-sm-6"
            />
            {settings.shouldSelectRequester && (
              <WebformInfoDeliveryRequester
                resource={resource}
                data={data}
                namePrefix={namePrefix}
                disabled={disabled || settings.disableWebformLineReqesterOverride}
                deliverTo={headerDeliveryInfo.requesterType}
                defaultOtherRequester={headerDeliveryInfo.otherRequester}
                isOverride
              />
            )}
            <WebformInfoDeliveryInfo
              resource={resource}
              data={data}
              addressType={headerDeliveryInfo.addressType}
              namePrefix={namePrefix}
              lineIndex={index}
              disabled={disabled}
            />
          </React.Fragment>
        )}
      </Collapse>
    </React.Fragment>
  )
})
