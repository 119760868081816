import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'stores'
import Button from 'common/Button'
import Checkbox from 'common/Checkbox'

export default observer(({ resource, caseProposal, proposals, filler }) => {
  const { proposalStore } = useStores()
  const selectionRef = React.useRef([])
  const [showShareMessage, setShowShareMessage] = React.useState(false)

  const {
    CRUD: { submitting },
  } = proposalStore

  const handleSelectProposal = (event, proposalId) => {
    const selection = selectionRef.current.filter(item => item !== proposalId)
    if (event.target.checked) {
      selection.push(parseInt(proposalId))
    }
    selectionRef.current = selection
  }

  const handleShareProposal = async () => {
    const proposalIds = selectionRef.current
    setShowShareMessage(!proposalIds.length)

    if (proposalIds.length) {
      const result = await proposalStore.createShareUrl({ proposalIds })
      if (result) {
        const url =
          `mailto:?body=${resource.ShareGreetings},` +
          `\n\n${resource.ShareBody1} "${caseProposal.description}" ${resource.ShareBody2}` +
          `\n${result.url}&subject=${resource.ShareSubjectPrefix}${caseProposal.description} #${caseProposal.caseNumber}`

        window.location = encodeURI(url)
      }
    }
  }

  return (
    <tr>
      <th className="col-3">
        <Button icon="share" color="info" disabled={submitting} loading={submitting} onClick={handleShareProposal}>
          {resource.ShareWithAColleague}
        </Button>
        {showShareMessage && (
          <div className="d-flex align-items-center py-1">
            <span className="material-icons-outlined text-danger me-1">error_outline</span>
            <span className="text-danger">{resource.ShareWithAColleagueErrorMessage}</span>
          </div>
        )}
      </th>
      {proposals.map((proposal, index) => (
        <td key={index} className="col-3 align-middle text-center">
          <Checkbox onClick={event => handleSelectProposal(event, proposal.proposalId)} />
        </td>
      ))}
      {filler.map(item => (
        <td key={item} className="bg-light col-3" />
      ))}
    </tr>
  )
})
