import BaseStore from 'stores/base'

export default class FeedbackStore extends BaseStore {
  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options
  }

  getFeedback(caseId) {
    return this.getRequest(this.CRUD, async () => this.service.getFeedback(caseId))
  }

  submitFeedback(payload) {
    return this.request(this.CRUD, async () => this.service.submitFeedback(payload))
  }
}
