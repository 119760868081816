import BaseStore from 'stores/base'

export default class Saml2Store extends BaseStore {
  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options
  }

  getLoginUrl(code, payload) {
    return this.getRequest(this.CRUD, () => this.service.getLoginUrl(code, payload))
  }
}
