import React from 'react'

import { DropdownItem } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'

import { useStores } from 'stores'
import Dropdown from 'common/Dropdown'
import Input from 'common/Forms/Input'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'

export default observer(({ data, isOpen, onSuccess, onClose }) => {
  const { caseStore, globalMessageStore, pageResourceStore } = useStores()
  const methods = useForm({
    defaultValues: { ...data },
  })

  const { handleSubmit, setValue } = methods
  const {
    PageResource: { case: resource = {}, common: commonResource = {} },
  } = pageResourceStore
  const {
    AbortedCase: { error, submitting },
  } = caseStore

  const handleOnSubmit = async values => {
    const { caseId, caseNumber, ...rest } = values
    const payload = { ...rest, fromCaseItem: true }

    const success = await caseStore.abortCase(caseId, payload)
    if (success) {
      const caseLink = `<a href="/cases/${caseId}" rel="internal">${caseNumber}</a>`
      globalMessageStore.show({
        title: resource.CaseAbortedTitle,
        message: resource.CaseAbortedMessage.replace('{{CaseNumber}}', caseLink),
        color: 'success',
        isHtml: true,
      })
      onClose()
      onSuccess()
    }
  }

  const handleSelected = status => {
    setValue('status', status)
    // const result = await trigger()
    handleSubmit(handleOnSubmit)()
  }

  return (
    <Modal
      isOpen={isOpen}
      title={resource.AbortCase}
      onClose={onClose}
      disableClose={submitting}
      hideSubmit
      footer={
        <Dropdown
          disabled={submitting}
          caret
          toggleContent={resource.Abort}
          toggleButtonOptions={{
            color: 'danger',
            icon: 'block',
            disabled: submitting,
            loading: submitting,
          }}
        >
          <DropdownItem onClick={() => handleSelected('AbortedCancelledByController')}>
            {resource.AbortCancelledByController}
          </DropdownItem>
          <DropdownItem onClick={() => handleSelected('AbortedToBeTreatedInternally')}>
            {resource.AbortTreatedInternally}
          </DropdownItem>
        </Dropdown>
      }
    >
      <MessageList messages={error && [error]} />
      <FormProvider {...methods}>
        <Input
          name="reason"
          rules={{ required: commonResource.Required }}
          disabled={submitting}
          label={resource.ProvideReason}
          placeholder={resource.AbortReason}
          autoFocus
        />
      </FormProvider>
    </Modal>
  )
})
