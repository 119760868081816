import BaseStore from 'stores/base'

export default class NewsStore extends BaseStore {
  constructor(api, options) {
    super(api)
    this.api = api
  }

  getNews(payload) {
    return this.getRequest(this.CRUD, () => this.service.getNews(payload))
  }
}
