import React from 'react'

import Card from 'common/Card'
import Input from 'common/Forms/Input'
import PraFileAttachment from 'common/Forms/PraFileAttachment'
import ReadOnlyAttachment from 'common/ReadOnlyAttachment'

export default ({ data, resource, disabled, isReadOnly }) => {
  return (
    <Card title={resource.OtherInformation} fieldset className="mb-4">
      {isReadOnly && (
        <ReadOnlyAttachment
          value={data.supplierAttachments}
          label={resource.AttachmentsForSuppliers}
          noAttachment={resource.NoAttachments}
          formGroup
          horizontal
          labelCol={{ lg: 3 }}
          inputCol={{ lg: 7 }}
          labelClassName="text-lg-end"
        />
      )}
      {!isReadOnly && (
        <PraFileAttachment
          name="supplierAttachments"
          label={resource.AttachmentsForSuppliers}
          defaultValue={{ attachments: data.supplierAttachments }}
          formGroup
          horizontal
          labelCol={{ lg: 3 }}
          inputCol={{ lg: 7 }}
          labelClassName="text-lg-end"
          disabled={disabled}
        />
      )}
      {isReadOnly && (
        <ReadOnlyAttachment
          value={data.approverAttachments}
          label={resource.AttachmentsForApprovers}
          noAttachment={resource.NoAttachments}
          formGroup
          horizontal
          labelCol={{ lg: 3 }}
          inputCol={{ lg: 7 }}
          labelClassName="text-lg-end"
        />
      )}

      {!isReadOnly && (
        <PraFileAttachment
          name="approverAttachments"
          label={resource.AttachmentsForApprovers}
          defaultValue={{ attachments: data.approverAttachments }}
          formGroup
          horizontal
          labelCol={{ lg: 3 }}
          inputCol={{ lg: 7 }}
          labelClassName="text-lg-end"
          disabled={disabled}
        />
      )}
      <Input
        type="textarea"
        name="remarks"
        defaultValue={data.remarks}
        label={resource.RemarksForApprovers}
        formGroup
        horizontal
        labelCol={{ lg: 3 }}
        inputCol={{ lg: 7 }}
        labelClassName="text-lg-end"
        rows="4"
        disabled={disabled}
        readOnlyField={isReadOnly}
      />
    </Card>
  )
}
