import React from 'react'

import cx from 'classnames'

export default ({ title, actions, className, titleClassName, actionClassName }) => {
  return (
    <div className={cx('layout-title', className)}>
      <h2 className={cx(titleClassName)}>{title}</h2>
      {actions && <div className={cx('actions', actionClassName)}>{actions}</div>}
    </div>
  )
}
