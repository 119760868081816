export const range = (start, end) => (end > 0 ? [...Array(end).keys()].map(page => page + start) : [])

export const sort = (items = [], key) =>
  items.slice().sort((item1, item2) => (item1[key] ? item1[key].localeCompare(item2[key]) : 0))

export const sortByNumber = (items = [], key) =>
  items.slice().sort((item1, item2) => {
    if (key) return item1[key] ? item1[key] - item2[key] : 0
    else return item1 ? item1 - item2 : 0
  })

export const sortByNumberDesc = (items = [], key) =>
  items.slice().sort((item1, item2) => {
    if (key) return item1[key] ? item2[key] - item1[key] : 0
    else return item1 ? item2 - item1 : 0
  })

export const sortByBoolean = (items = [], key) =>
  items.slice().sort((item1, item2) => {
    if (key) return item2[key] - item1[key]
    else return item2 - item1
  })

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item].slice(),
    }),
    {}
  )

export const selectMany = (items = [], key) => (items ? items.reduce((list, item) => list.concat(item[key]), []) : [])

export const unique = (items, key) => {
  return items.filter((item, index, self) => {
    if (key) {
      return self.findIndex(item2 => item2[key] === item[key]) === index
    } else {
      return self.indexOf(item) === index
    }
  })
}
