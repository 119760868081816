import React from 'react'

import { Badge } from 'reactstrap'

import { formatDate, praLongDateTimeFormat } from 'utils/formatters'
import { PRA_STATUS } from 'definitions'
import Icon from 'common/Icon'

export default ({ resource, praItem }) => {
  const getPraBadge = () => {
    switch (praItem.status) {
      case PRA_STATUS.APPROVED.value:
        return <Badge color="success">{resource.Approved}</Badge>
      case PRA_STATUS.REJECTED.value:
        return <Badge color="danger">{resource.Rejected}</Badge>
      case PRA_STATUS.PENDING.value:
        return <Badge color="warning">{resource.Pending}</Badge>
      case PRA_STATUS.ABORTED.value:
        return <Badge color="danger">{resource.Aborted}</Badge>
      case 'OutOfOffice':
        return <Badge>{`${resource.OutOfficeRemark} ${praItem.backupApproverName}`}</Badge>
      default:
        return <Badge color="info">{resource.OnHold}</Badge>
    }
  }

  return (
    <div className="d-flex col mb-lg-0 mb-2 flex-sm-row flex-column">
      <div className="col-auto mb-2">{getPraBadge()}</div>
      <div className="col px-sm-3 px-0">
        <small className="text-muted d-flex flex-wrap flex-sm-row flex-column mb-xl-0 mb-2">
          <div className="d-flex align-items-center mb-1 me-2">
            <span>
              <strong>{resource.PR}: </strong>
              {praItem.reference}
            </span>
            {praItem.status === PRA_STATUS.APPROVED.value && (
              <span>
                <strong>{resource.PO}: </strong>
                {praItem.poReference}
              </span>
            )}
          </div>
          <div className="d-flex align-items-center mb-1 me-2">
            <Icon icon="person" filled className="md-12 me-1 text-muted" />
            <span className="requester-name">{praItem.requester}</span>
          </div>
          <div className="d-flex align-items-center mb-1 me-2">
            <Icon icon="schedule" filled className="md-12 me-1 text-muted" />
            <span title={resource.dateCreated}>{formatDate(praItem.dateCreated, praLongDateTimeFormat)}</span>
          </div>
          <div className="d-flex align-items-center mb-1 me-2">
            <Icon icon="edit" filled className="md-12 me-1 text-muted" />
            <span>{praItem.creator}</span>
          </div>
          {praItem.remarks && (
            <div className="d-flex align-items-center mb-1 me-2">
              <Icon icon="notes" filled className="md-12 me-1 text-muted" />
              <span>{praItem.remarks}</span>
            </div>
          )}
        </small>
        <div className="fw-semibold word-break-break-word white-space-normal">{praItem.description}</div>
      </div>
    </div>
  )
}
