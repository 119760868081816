import React from 'react'

import { Badge } from 'reactstrap'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import cx from 'classnames'

import { ROUTES } from 'definitions'
import { useStores } from 'stores'
import DebouncedLink from 'common/DebouncedLink'
import Icon from 'common/Icon'

export default observer(() => {
  const { webshopCartStore, webshopPricingStore } = useStores()
  const location = useLocation()

  const {
    CartQuantity: quantity = 0,
    CRUD: { submitting },
    Lookups: { loading: lookupsLoading },
  } = webshopCartStore
  const {
    ItemPricingTier: { loading: pricingTierLoading },
  } = webshopPricingStore

  React.useEffect(() => {
    if (!location.pathname !== ROUTES.WEBSHOP_CART) {
      webshopCartStore.calculateCartQuantity()
    }
  }, [webshopCartStore, location.pathname])

  const isCircle = quantity <= 9

  return (
    <DebouncedLink
      to={ROUTES.WEBSHOP_CART}
      tabIndex="-1"
      className={cx('btn btn-icon btn-icon-primary text-decoration-none cart-icon')}
      disabled={submitting || lookupsLoading || pricingTierLoading}
    >
      <Icon icon="shopping_cart" className="md-32" />
      {quantity > 0 && (
        <Badge
          color="primary"
          className={cx('border border-2 border-white fs-12', {
            'rounded-circle': isCircle,
            'rounded-pill': !isCircle,
          })}
        >
          {quantity > 99 ? '99+' : quantity}
        </Badge>
      )}
    </DebouncedLink>
  )
})
