import React from 'react'

import { Alert } from 'reactstrap'
import cx from 'classnames'

import { CASE_TYPE } from 'definitions'
import { formatDate } from 'utils/formatters'
import LayoutTitle from 'common/LayoutTitle'
import Ribbon from 'common/Ribbon'

import CaseDetailInfoStatus from 'components/Case/CaseDetail/CaseDetailInfoStatus'
import EditSubjectModal from 'components/Case/CaseDetail/EditSubjectModal'

export default ({ resource, caseId, data }) => {
  const [isOpen, setIsOpen] = React.useState()
  const { client, caseType, operator, requester } = data

  const toggle = () => setIsOpen(prev => !prev)

  return (
    <div className="case-detail-info mb-5">
      <LayoutTitle
        title={data.subject || 'Enter Subject Here'}
        actions={<CaseDetailInfoStatus resource={resource} status={data.status} onEditSubject={toggle} />}
        className="case-detail-info-header mb-0 d-flex flex-md-row flex-column align-items-center"
        actionClassName="ms-md-2 m-0 mt-md-0 mt-2"
        titleClassName={cx('me-2', !data.subject && 'font-italic')}
      />
      <p className="text-muted d-flex fs-12 mb-0 d-inline-flex flex-md-row flex-column align-items-lg-center align-items-start">
        <span className="mr-20 me-3">{data.caseNumber}</span>
        <span className="me-3 d-inline-flex align-items-center">
          <span className="material-icons-outlined md-14 me-1 text-muted">business</span>
          <span>{client.name}</span>
        </span>
        <span className="me-3 d-inline-flex align-items-center">
          <span className="material-icons-outlined md-14 me-1 text-muted">person</span>
          <span>{requester.name}</span>
        </span>
        <span className="me-3 d-inline-flex align-items-center">
          <span className="material-icons-outlined md-14 me-1 text-muted">schedule</span>
          <span>{formatDate(data.requestDate, 'dddd, D MMMM YYYY, H:mm')}</span>
        </span>
        {operator && (
          <React.Fragment>
            <span className="d-inline-flex align-items-center">
              <span className="material-icons-outlined md-14 me-1 text-muted">edit</span>
              <span>{operator.employeeName}</span>
            </span>
          </React.Fragment>
        )}
      </p>
      {!data.status.caseOpen && (
        <Alert color="warning" className="mt-6">
          {resource.CaseActionsDisabled}
        </Alert>
      )}
      {caseType === CASE_TYPE.Webform && (
        <Ribbon color="primary" right>
          Webform
        </Ribbon>
      )}
      {caseType === CASE_TYPE.Webshop && (
        <Ribbon color="pink" right>
          Webshop
        </Ribbon>
      )}
      {[CASE_TYPE.BeforeTheFacts, CASE_TYPE.AfterTheFacts].includes(caseType) && (
        <Ribbon color="warning" right>
          PO
        </Ribbon>
      )}
      {caseType === CASE_TYPE.ContractPo && (
        <Ribbon color="warning" right>
          CONTRACT PO
        </Ribbon>
      )}
      {caseType === CASE_TYPE.SelfService && (
        <Ribbon color="purple" right>
          DIY
        </Ribbon>
      )}
      {caseType === CASE_TYPE.SelfService && (
        <Ribbon color="purple" right>
          DIY
        </Ribbon>
      )}
      {caseType === CASE_TYPE.Connection && (
        <Ribbon color="light-pink" right>
          Connections
        </Ribbon>
      )}
      {isOpen && <EditSubjectModal isOpen caseId={caseId} subject={data.subject} onClose={toggle} />}
    </div>
  )
}
