import React from 'react'

import { FlexTableCell, FlexTableRow } from 'common/FlexTable'
import { formatCurrency, formatNumber } from 'utils/formatters'
import Input from 'common/Forms/Input'

export default ({ resource, lineItemIndex, lineItem }) => {
  const { goodsReceived = {} } = lineItem

  return (
    <FlexTableRow className="fw-bold">
      <FlexTableCell className="col-line-index" />
      <FlexTableCell className="col-2-half">{resource.TotalReceived}</FlexTableCell>
      <FlexTableCell className="col justify-content-end">
        <Input
          name={`items.${lineItemIndex}.goodsReceived.totalQuantityReceived`}
          value={formatNumber(goodsReceived.totalQuantityReceived)}
          textOnlyInput
          className="text-end p-0 fw-bold"
        />
      </FlexTableCell>
      <FlexTableCell className="col-1-half justify-content-end">{formatCurrency(lineItem.price)}</FlexTableCell>
      <FlexTableCell className="col-1-half justify-content-end">
        <Input
          name={`items.${lineItemIndex}.goodsReceived.totalPriceFormatted`}
          value={formatCurrency(goodsReceived.totalPrice)}
          textOnlyInput
          className="text-end p-0 fw-bold"
        />
        <Input
          type="hidden"
          name={`items.${lineItemIndex}.goodsReceived.totalPrice`}
          value={goodsReceived.totalPrice}
        />
      </FlexTableCell>
      <FlexTableCell className="col-4" />
    </FlexTableRow>
  )
}
