import React from 'react'

import { praLongDateFormat } from 'utils/formatters'
import { useStores } from 'stores'
import DatePicker from 'common/Forms/DatePicker'

import CartSummaryDeliveryAddress from './CartSummaryDeliveryAddress'
import CartSummaryDeliveryTo from './CartSummaryDeliveryTo'
import CartSummaryFooter from './CartSummaryFooter'

export default ({ cart, updateCart, disabled, saving }) => {
  const { authStore, pageResourceStore, webshopCartStore,  clientRequesterStore} = useStores()

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { connections: resource },
  } = pageResourceStore

  return (
    <div className="cart-summary py-3 px-2 mb-4 col-lg-auto col-12">
      <DatePicker
        name="deliveryInfo.preferredDate"
        rules={{ required: resource.Required }}
        label={<h5 className="webshop-title-color fw-bolder mb-0">{resource.PreferredDate}</h5>}
        formGroup
        dateFormat={praLongDateFormat}
        disabled={disabled}
        disabledDays={[
          {
            before: new Date(),
          },
        ]}
        onChange={() => updateCart()}
      />
      <CartSummaryDeliveryAddress
        resource={resource}
        webshopCartStore={webshopCartStore}
        clientId={session.clientId}
        cart={cart}
        disabled={disabled}
        updateCart={updateCart}
      />
      <CartSummaryDeliveryTo
        resource={resource}
        clientRequesterStore={clientRequesterStore}
        clientId={session.clientId}
        deliverTo={cart.deliveryInfo?.deliverTo}
        disabled={disabled}
        updateCart={updateCart}
      />
      <CartSummaryFooter resource={resource} cart={cart} submitting={disabled} saving={saving} />
    </div>
  )
}
