import React from 'react'

import Button from 'common/Button'

export default ({ data }) => {
  const [currentImage, setCurrentImage] = React.useState()

  const images = data.images

  React.useEffect(() => images?.length && setCurrentImage(images[0].largeImageUrl), [images])

  return (
    <div className="product-images-panel d-flex flex-xxl-column flex-row-reverse mb-4">
      <div className="current-image d-flex align-items-center justify-content-center border p-1 rounded mb-3">
        {currentImage && <img src={currentImage} alt={data.name} />}
        {!currentImage && <h2 className="no-image">No Image</h2>}
      </div>
      <div className="product-images d-flex flex-wrap flex-xxl-row flex-column">
        {!!images &&
          images.map((item, index) => (
            <div key={index} className="col-auto ms-xxl-0 ms-3 me-3 mb-xxl-0 mb-3">
              <Button
                type="button"
                text
                color="default"
                className="button-image border p-0"
                onClick={() => setCurrentImage(item.largeImageUrl)}
              >
                <div className="product-image" style={{ backgroundImage: `url("${item.imageUrl}")` }} />
              </Button>
            </div>
          ))}
      </div>
    </div>
  )
}
