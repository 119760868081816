import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'stores'
import NewPunchoutEmployeeModal from 'components/Account/NewPunchoutEmployeeModal'

export default observer(() => {
  const { authStore } = useStores()
  const [isOpen, setIsOpen] = React.useState(false)

  const {
    AppSession: { session },
  } = authStore

  React.useEffect(() => {
    session.shouldCreateAuthInfo && toggle()
  }, [session])

  const toggle = () => setIsOpen(prev => !prev)

  return <React.Fragment>{isOpen && <NewPunchoutEmployeeModal isOpen toggle={toggle} />}</React.Fragment>
})
