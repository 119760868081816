import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'stores'
import Button from 'common/Button'

export default observer(({ resource, fields, disabled, onAddProductLine, isLineExpanded }) => {
  const { webformStore } = useStores()

  const {
    CRUD: { submitting },
  } = webformStore

  return (
    <div className="d-flex justify-content-md-between flex-md-row flex-column align-items-md-start">
      <Button
        type="button"
        icon="add"
        color="default"
        disabled={disabled || submitting}
        className="col-12 col-md-auto mb-4 mb-md-0"
        onClick={onAddProductLine}
      >
        {resource.AddProductLine}
      </Button>
      <div className="d-inline-flex flex-column col-12 col-md-auto">
        <Button
          type="submit"
          color="success"
          icon="check"
          size="lg"
          loading={submitting}
          disabled={submitting || disabled || !fields.length}
          className="mb-3"
        >
          {resource.SubmitWebform}
        </Button>
        {(!fields.length || isLineExpanded) && (
          <small className="text-danger fst-italic">{resource.AddLinesBeforeSubmittingMessage}</small>
        )}
      </div>
    </div>
  )
})
