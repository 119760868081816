import React from 'react'

import { Alert, DropdownItem } from 'reactstrap'

import { PRA_APPROVER_STATUS, PRA_STATUS } from 'definitions'
import Button from 'common/Button'
import Dropdown from 'common/Dropdown'

import ApprovalFlowPopup from './ApprovalFlowPopup'

export default ({
  praStore,
  resource,
  index,
  session,
  pra,
  settings,
  approvers,
  approver,
  loggedinEmployeeId,
  disabled,
  onReAssign,
}) => {
  const [dropDownText, setDropDownText] = React.useState(resource.Select)
  const [popupOptions, setPopupOptions] = React.useState({})

  const currentApprover = pra.currentApproverId || {}
  const isCurrentApprover = approver.id === loggedinEmployeeId && approver.id === currentApprover.id
  const isAdministratorProxyEnabled = session.isAdministratorProxy && !isCurrentApprover

  const isBeforeLastAbort = React.useMemo(() => {
    let lastAbortedIndex = -1
    const abortedApprovers = approvers.filter(item => item.status === PRA_APPROVER_STATUS.ABORTED)
    if (abortedApprovers.length) {
      lastAbortedIndex = abortedApprovers.reverse()[0].status
    }
    return approver.lineIndex < lastAbortedIndex
  }, [approvers, approver])

  const approverStates = React.useMemo(
    () => ({
      unstarted: {
        color: 'light',
        text: '',
        dropDownText: '',
      },
      approve: {
        color: 'success',
        text: resource.Approved,
        dropDownText: resource.Approve,
        popupTitle: resource.Approve,
        action: 'approve',
      },
      reject: {
        color: 'danger',
        text: resource.Rejected,
        dropDownText: resource.Reject,
        popupTitle: resource.AddComment,
        action: 'reject',
      },
      relaunch: {
        color: 'warning',
        text: resource.Relaunched,
        dropDownText: resource.Relaunch,
        popupTitle: resource.AddComment,
        action: 'relaunch',
      },
      reassign: {
        color: 'warning',
        text:
          approver.reassign != null
            ? `${resource.Reassigned} ${resource.To} ${approver.reassign.toName} ${resource.By} ${approver.reassign.byName}`
            : resource.Reassigned,
        dropDownText: resource.Reassign,
        popupTitle: resource.AddComment,
        action: 'reassign',
      },
      question: {
        color: 'danger',
        text:
          approver.question != null
            ? `${resource.Question} ${approver.question.toName ? `${resource.To} ${approver.question.toName}` : ''}`
            : resource.Question,
        dropDownText: resource.AskQuestion,
        popupTitle: resource.AskQuestion,
        action: 'question',
      },
      answer: {
        color: 'info',
        text:
          approver.question != null
            ? `${resource.Answer} ${approver.question.toName ? `${resource.To} ${approver.question.byName}` : ''}`
            : resource.Answer,
        dropDownText: resource.Answer,
        popupTitle: resource.Answer,
        action: 'answer',
      },
      abort: {
        color: 'danger',
        text: resource.Aborted,
        dropDownText: '',
      },
      outOfOffice: {
        color: 'warning',
        text: `${resource.OutOfOfficeRemark} ${approver.backupApproverName} `,
        dropDownText: '',
      },
      invalid: {
        color: 'light',
        text: resource.Invalid,
        dropDownText: '',
      },
    }),
    [approver, resource]
  )

  const approverStatus = React.useMemo(() => {
    if (approver) {
      switch (approver.status) {
        case PRA_APPROVER_STATUS.APPROVED:
          return approverStates.approve
        case PRA_APPROVER_STATUS.REJECTED:
          return approverStates.reject
        case PRA_APPROVER_STATUS.RELAUNCHED:
          return approverStates.relaunch
        case PRA_APPROVER_STATUS.REASSIGN:
        case PRA_APPROVER_STATUS.REASSIGNED:
          return approverStates.reassign
        case PRA_APPROVER_STATUS.QUESTIONED:
          return approverStates.question
        case PRA_APPROVER_STATUS.ANSWERED:
          return approverStates.answer
        case PRA_APPROVER_STATUS.ABORTED:
          return approverStates.abort
        case PRA_APPROVER_STATUS.OUT_OF_OFFICE:
          return approverStates.outOfOffice
        case PRA_APPROVER_STATUS.INVALID:
          return approverStates.invalid
        default:
          break
      }
    }
    return approverStates.unstarted
  }, [approver, approverStates])

  const getActionApproverStatus = action => {
    switch (action.name) {
      case 'Ask Question':
        return approverStates.question
      default:
        return approverStates[action.name.toLowerCase()]
    }
  }

  const handleSelectAction = status => {
    if (status) {
      setDropDownText(status.dropDownText)
      setPopupOptions({
        isOpen: true,
        title: status.popupTitle,
        action: status.action,
        dropDownText: status.dropDownText,
      })
    } else {
      setDropDownText(resource.Select)
      setPopupOptions({ isOpen: false })
    }
  }

  const isPraAborted = pra.status === PRA_STATUS.ABORTED.id
  const isPraAbortedOrIsBeforeLastAbort = isPraAborted || isBeforeLastAbort

  if (pra.id === 0 || pra.status === 1 || approver.status === PRA_APPROVER_STATUS.OUT_OF_OFFICE)
    return (
      <Alert color={approverStatus.color} className="py-1 px-3 mb-0 col-12">
        {approverStatus.text}
      </Alert>
    )
  else if (approver.status === 0 && (isCurrentApprover || approver.id === loggedinEmployeeId)) {
    // filter actions that can be available for current approver
    const actions = pra.actions
      ? pra.actions.filter(action => {
          const actionName = action.name.toLowerCase()
          return (
            !['edit', 'view', 'answer'].includes(actionName) || // do not include edit, view and answer
            (actionName === 'answer' && approver.question && approver.question.pendingQuestion)
          ) // include anwser if there's no pending question
        })
      : []

    // show actions available for current approver
    if (actions.length > 0) {
      const dropdownId = `approver-dropdown-${index}`
      return (
        <div>
          {isPraAborted && <div className={`approver-status ${approverStatus.color}`}>{approverStatus.text}</div>}
          {!isPraAbortedOrIsBeforeLastAbort && (
            <React.Fragment>
              <Dropdown
                toggleContent={dropDownText}
                caret
                disabled={disabled}
                toggleButtonOptions={{
                  id: dropdownId,
                }}
              >
                {actions.map((action, actionIndex) => {
                  const actionApproverStatus = getActionApproverStatus(action)
                  return (
                    <DropdownItem key={actionIndex} onClick={() => handleSelectAction(actionApproverStatus)}>
                      {actionApproverStatus.dropDownText}
                    </DropdownItem>
                  )
                })}
              </Dropdown>
              {popupOptions.isOpen && (
                <ApprovalFlowPopup
                  praStore={praStore}
                  resource={resource}
                  session={session}
                  target={dropdownId}
                  pra={pra}
                  settings={settings}
                  approver={approver}
                  loggedinEmployeeId={loggedinEmployeeId}
                  action={popupOptions.action}
                  title={popupOptions.title}
                  onClose={() => handleSelectAction()}
                  onSubmit={() => setDropDownText(popupOptions.dropDownText)}
                />
              )}
            </React.Fragment>
          )}
        </div>
      )
    }
  } else if (
    approver.status === 0 &&
    approver.question &&
    approver.question.pendingQuestion &&
    approver.id !== pra.currentApproverId.id
  ) {
    // show indicator that there's a pending question to another approver
    const message = `${resource.PendingAnswer || 'Pending answer to'} ${approver.question.byName}`
    return (
      <Alert color="info" className="py-1 px-3 mb-0 col-12">
        {message}
      </Alert>
    )
  } else if (approverStatus.text) {
    // show status text as is
    return (
      <Alert color={approverStatus.color} className="py-1 px-3 mb-0 col-12">
        {approverStatus.text}
      </Alert>
    )
  } else if (
    approver.status === 0 &&
    (pra.creator.id === loggedinEmployeeId || isAdministratorProxyEnabled) &&
    pra.status !== 3
  ) {
    // show creator's ability to re-assign
    const buttonId = `approver-button-reassign-${index}`
    return (
      <div>
        {isPraAborted && <div className={`approver-status ${approverStatus.color}`}>{approverStatus.text}</div>}
        {!isPraAbortedOrIsBeforeLastAbort && (
          <React.Fragment>
            <Button
              id={buttonId}
              color="default"
              disabled={disabled}
              onClick={() => handleSelectAction(approverStates.reassign)}
            >
              {resource.Reassign}
            </Button>
            {popupOptions.isOpen && (
              <ApprovalFlowPopup
                praStore={praStore}
                resource={resource}
                session={session}
                target={buttonId}
                pra={pra}
                settings={settings}
                approver={approver}
                loggedinEmployeeId={loggedinEmployeeId}
                action={popupOptions.action}
                title={popupOptions.title}
                creatorOrAdmin
                onClose={() => handleSelectAction()}
              />
            )}
          </React.Fragment>
        )}
      </div>
    )
  }

  return false
}
