import cx from 'classnames'
import React from 'react'

import Checkbox from 'common/Checkbox'
import FormGroup from 'common/FormGroup'
import HelpText from 'common/HelpText'

export default ({
  inputRef,
  radios,
  name,
  value,
  defaultValue,
  label,
  formGroup,
  horizontal,
  disabled,
  className,
  formClassName,
  labelClassName,
  inputColClassName,
  labelCol,
  inputCol,
  helpText,
  helpTextType,
  readOnlyField,
  onChange,
}) => {
  const hasError = helpTextType === 'error' && !!helpText

  return (
    <FormGroup
      {...{
        label,
        formGroup,
        horizontal,
        className: cx('radio-group', formClassName),
        labelClassName,
        inputColClassName,
        labelCol,
        inputCol,
        hasError,
      }}
    >
      {radios.map((item, index) => (
        <React.Fragment key={index}>
          <Checkbox
            inputRef={inputRef}
            {...(item.id ? { id: item.id } : {})}
            type="radio"
            name={name}
            value={item.value}
            label={item.label}
            defaultChecked={(defaultValue || value) === item.value}
            disabled={disabled || readOnlyField}
            formGroup
            className={cx('checkbox-radio', className, item.className)}
            formClassName={cx(item.formClassName)}
            labelClassName={cx(hasError && 'text-danger', item.labelClassName)}
            onChange={event => onChange(event.target.value)}
          />
          {item.children}
        </React.Fragment>
      ))}
      {!!helpText && <HelpText hasError={hasError}>{helpText}</HelpText>}
    </FormGroup>
  )
}
