import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'stores'
import ContentLoader from 'common/ContentLoader'

import BundleDetailModal from './BundleDetailModal'
import Bundles from './Bundles'
import ProposalTable from './ProposalTable'
import PrViewModal from 'components/Case/CaseDetail/PurchaseRequest/PrViewModal'

export default observer(({ activated, caseId, caseNumber, caseType }) => {
  const { pageResourceStore, proposalStore } = useStores()
  const [prViewModalOption, setPrViewModalOption] = React.useState({})
  const [bundleModalOption, setBundleModalOptionModalOption] = React.useState({})

  const {
    PageResource: { case: resource = {}, common: commonResource },
  } = pageResourceStore
  const {
    Table: {
      data: { proposal: data = {} },
      loading,
    },
  } = proposalStore

  React.useEffect(() => activated && proposalStore.fetchTable(caseId), [proposalStore, caseId, activated])

  const handleViewPrLine = prLine => setPrViewModalOption({ isOpen: !!prLine, prLine })

  const handleViewBundleDetail = proposeItem => setBundleModalOptionModalOption({ isOpen: !!proposeItem, proposeItem })

  return (
    <div className="position-relative">
      <ContentLoader show={loading} />
      {data && (
        <React.Fragment>
          <ProposalTable
            resource={resource}
            linesToPropose={data.linesToPropose}
            caseType={caseType}
            onViewDetail={handleViewPrLine}
          />
          <hr className="triangle" />
          <Bundles resource={resource} propose={data} onViewDetail={handleViewBundleDetail} />
          {bundleModalOption.isOpen && (
            <BundleDetailModal
              resource={resource}
              commonResource={commonResource}
              data={bundleModalOption.proposeItem}
              onClose={() => handleViewBundleDetail()}
            />
          )}

          {prViewModalOption.isOpen && (
            <PrViewModal
              resource={resource}
              commonResource={commonResource}
              caseNumber={caseNumber}
              settings={{}}
              prLine={prViewModalOption.prLine}
              onClose={() => handleViewPrLine()}
            />
          )}
        </React.Fragment>
      )}
    </div>
  )
})
