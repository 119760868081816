import React from 'react'

import { observer } from 'mobx-react'
import { useFieldArray, useForm } from 'react-hook-form'

import { FlexTable } from 'common/FlexTable'
import { useStores } from 'stores'

import Card from 'common/Card'
import Loader from 'common/Loader'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'

import GoodsReceivedFooter from './GoodsReceivedFooter'
import GoodsReceivedLineItem from './GoodsReceivedLineItem'

export default observer(({ praId, onClose, onSubmit }) => {
  const { authStore, goodsReceivedStore, pageResourceStore } = useStores()
  const methods = useForm({
    shouldFocusError: false,
  })
  const { fields, replace } = useFieldArray({
    control: methods.control,
    name: 'items',
    shouldUnregister: true,
  })

  const { handleSubmit } = methods
  const {
    PageResource: { pra: resource = {} },
  } = pageResourceStore
  const {
    CRUD: { data, error, loading, submitting },
  } = goodsReceivedStore

  React.useEffect(() => {
    const getGoodsReceived = async () => {
      const result = await goodsReceivedStore.get(praId)
      if (result) {
        const preparedLineItems = goodsReceivedStore.prepareLineItems(result)
        replace(preparedLineItems || [])
      }
    }
    getGoodsReceived()
  }, [goodsReceivedStore, praId, replace])

  const handleOnSubmit = async values => {
    const userId = authStore.getLoginEmployeeId()
    const sessionId = authStore.AppSession.session.id

    const result = goodsReceivedStore.submit({ praId, userId, sessionId, values })
    if (result) {
      onClose()
      onSubmit(result)
    }
  }

  return (
    <Modal
      id="goods-received-modal"
      isOpen
      useForm={methods}
      title={resource.GoodsReceived}
      size="xl"
      centered
      footer={<GoodsReceivedFooter resource={resource} submitting={submitting} loading={loading} onClose={onClose} />}
      disableClose={submitting}
      toggle={onClose}
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <MessageList messages={error && [error]} />
      <Loader show={loading} className="justify-content-center" />
      {!loading && !!data.praId && (
        <Card title={resource.POLines} color="primary" outline>
          <FlexTable
            columns={[
              { className: 'col-line-index col-auto', Header: `${resource.PO}#` },
              { className: 'col-2-half', Header: resource.Description },
              { className: 'col justify-content-end', Header: resource.Qty },
              { className: 'col-1-half justify-content-end', Header: resource.Unit },
              { className: 'col-1-half justify-content-end', Header: resource.Total },
              { className: 'col-4', Header: resource.Comments },
            ]}
            className="flex-table-hover"
          >
            {fields.map((lineItem, index) => (
              <GoodsReceivedLineItem key={lineItem.id} resource={resource} lineItem={lineItem} lineItemIndex={index} />
            ))}
          </FlexTable>
        </Card>
      )}
    </Modal>
  )
})
