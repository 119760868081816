import React from 'react'

import { formatCurrency } from 'utils/formatters'
import Card from 'common/Card'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({ resource, supplierQuotes }) => {
  return (
    <Card title={resource.Quotes} fieldset>
      {supplierQuotes.map((item, index) => (
        <React.Fragment key={index}>
          <ReadOnlyField label={resource.Supplier} value={item.supplier.name} formGroup horizontal />
          <ReadOnlyField
            label={resource.Price}
            value={item.unitPrice ? formatCurrency(item.unitPrice.value) : '-'}
            formGroup
            horizontal
          />
        </React.Fragment>
      ))}
    </Card>
  )
}
