import React from 'react'

import { Container } from 'reactstrap'

import logoImage from 'assets/images/spot-buy-logo-120.jpg'

import AccountModal from 'components/Account/AccountModal'
import SelectCompanyModal from 'components/Core/SelectCompanyModal'
import UserMenu from 'components/Header/UserMenu'

export default () => {
  const [isOpenAccountModal, setIsOpenAccountModal] = React.useState(false)
  const [isOpenSelectCompanyModal, setIsOpenSelectCompanyModal] = React.useState(false)

  const toggleAccountModal = () => setIsOpenAccountModal(prev => !prev)

  const toggleSelectCompanyModal = () => setIsOpenSelectCompanyModal(prev => !prev)

  return (
    <header className="paper paper-1 px-sm-3 px-1">
      <Container fluid className="d-flex py-1 px-sm-3 px-0">
        <a href="/" className="brand">
          <img src={logoImage} alt="Oazo logo" />
        </a>
        <div className="ms-auto d-flex align-items-center">
          <div className="user-info me-lg-4 me-1">
            <span className="me-md-4">mastboomstraat 4, 2630 aartselaar</span>
            <span>+32.3.369.07.11</span>
          </div>
          <UserMenu onShowSelectCompany={toggleSelectCompanyModal} onShowAccount={toggleAccountModal} />
          {isOpenAccountModal && <AccountModal isOpen onClose={toggleAccountModal} />}
          {isOpenSelectCompanyModal && <SelectCompanyModal isOpen toggle={toggleSelectCompanyModal} />}
        </div>
      </Container>
    </header>
  )
}
