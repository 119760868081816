import React from 'react'

import { Badge } from 'reactstrap'

import { formatCurrency, formatDate } from 'utils/formatters'
import { padLeft } from 'utils'
import Button from 'common/Button'
import Card from 'common/Card'
import Modal from 'common/Modal'
import ReadOnlyAttachment from 'common/ReadOnlyAttachment'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({ resource, commonResource, data, countries, onClose }) => {
  const { purchaseOrder, poLine } = data
  const attachments = poLine.attachments || []
  const deliveryInfo = poLine.deliveryInfo

  const country =
    deliveryInfo && !deliveryInfo.isMultiAddress && countries
      ? countries.find(item => item.code === deliveryInfo.deliveryAddress.country.code)
      : null

  return (
    <Modal
      isOpen
      size="xl"
      title={`${resource.ViewPOLine} ${padLeft(poLine.lineIndex, 1)}`}
      actions={
        <Badge color="primary" className="me-5">
          {resource.POReference}: {purchaseOrder.externalPoReference}
        </Badge>
      }
      footer={
        <Button type="button" text color="primary" onClick={onClose}>
          {commonResource.Cancel}
        </Button>
      }
      footerClassName="justify-content-start"
      onClose={onClose}
    >
      <Card title={resource.ProductInformation} fieldset className="mb-4">
        <ReadOnlyField
          label={resource.ProductDescription}
          value={poLine.description}
          className="word-wrap-break-word"
          formGroup
          horizontal
        />
        <ReadOnlyField
          label={resource.QuantityUnit}
          value={`${poLine.quantity.amount} ${poLine.quantity.unit}`}
          formGroup
          horizontal
        />
        <ReadOnlyField
          label={resource.ClientPrice}
          value={poLine.clientPrice ? formatCurrency(poLine.clientPrice.value) : '-'}
          formGroup
          horizontal
        />
        <ReadOnlyField
          label={resource.TotalPrice}
          value={poLine.subTotalVatExcl ? formatCurrency(poLine.subTotalVatExcl.value) : '-'}
          formGroup
          horizontal
        />
        <ReadOnlyField
          label={resource.ExtendedDescription}
          value={poLine.extendedDescription}
          className="word-wrap-break-word"
          formGroup
          horizontal
        />
        <ReadOnlyField
          label={resource.Remarks}
          value={poLine.remarks}
          className="word-wrap-break-word"
          formGroup
          horizontal
        />
        <ReadOnlyAttachment
          label={resource.Attachments}
          value={attachments}
          noAttachment={resource.NoAttachments}
          formGroup
          horizontal
        />
      </Card>

      <Card title={resource.DeliveryInformation} fieldset>
        {deliveryInfo.isMultiAddress && (
          <React.Fragment>
            <ReadOnlyField label={resource.Building} formGroup horizontal />
            <ReadOnlyField
              label={resource.PreferredDate}
              value={formatDate(deliveryInfo.deliveryDate)}
              formGroup
              horizontal
            />
            {deliveryInfo.requester && (
              <ReadOnlyField
                label={resource.DeliverTo}
                value={deliveryInfo.requester.displayname}
                formGroup
                horizontal
              />
            )}
            <ReadOnlyField label={resource.Address} value={resource.MultipleDeliveryAddress} formGroup horizontal />
            <ReadOnlyField label={resource.Location} formGroup horizontal />
            <ReadOnlyField label={resource.Country} formGroup horizontal />
          </React.Fragment>
        )}
        {!deliveryInfo.isMultiAddress && (
          <React.Fragment>
            <ReadOnlyField
              label={resource.Building}
              value={deliveryInfo.deliveryAddress.building}
              formGroup
              horizontal
            />
            <ReadOnlyField
              label={resource.PreferredDate}
              value={formatDate(deliveryInfo.deliveryDate)}
              formGroup
              horizontal
            />
            {deliveryInfo.requester && (
              <ReadOnlyField
                label={resource.DeliverTo}
                value={deliveryInfo.requester.displayname}
                formGroup
                horizontal
              />
            )}
            <ReadOnlyField
              label={resource.Address}
              value={`${deliveryInfo.deliveryAddress.street}
                    ${deliveryInfo.deliveryAddress.number} ${deliveryInfo.deliveryAddress.box}`}
              className="word-wrap-break-word"
              formGroup
              horizontal
            />
            <ReadOnlyField
              label={resource.Location}
              value={`${deliveryInfo.deliveryAddress.postalCode}
                    ${deliveryInfo.deliveryAddress.city}`}
              formGroup
              horizontal
            />
            <ReadOnlyField
              label={resource.Country}
              value={country ? country.name : deliveryInfo.deliveryAddress.country.name}
              formGroup
              horizontal
            />
          </React.Fragment>
        )}
      </Card>
    </Modal>
  )
}
