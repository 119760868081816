import { makeObservable, observable } from 'mobx'
import BaseStore from 'stores/base'

export default class GlobalMessageStore extends BaseStore {
  Info = {
    title: null,
    messages: null,
    color: 'danger',
    isHtml: false,
  }

  constructor(api) {
    super(api)
    this.api = api

    makeObservable(this, {
      Info: observable,
    })

    this.show = this.show.bind(this)
    this.showMessages = this.showMessages.bind(this)

    this.handleOnApiError = this.handleOnApiError.bind(this)
    this.service.onApiError = this.handleOnApiError

    this.handleOnPraApiError = this.handleOnPraApiError.bind(this)
    this.service.onPraApiError = this.handleOnPraApiError
  }

  handleOnApiError(result) {
    this.onApiError && this.onApiError(result)
  }

  handleOnPraApiError(result) {
    this.onPraApiError && this.onPraApiError(result)
  }

  show({ message, ...rest }) {
    this.showMessages({ messages: [message], ...rest })
  }

  showMessages(data) {
    this.Info = { ...data }
  }

  close() {
    this.Info = {}
  }
}
