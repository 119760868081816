import React from 'react'

export default ({ data, resource }) => {
  return (
    <div>
      {data.validationErrors && data.validationErrors.length > 0 && <div>{resource.CheckEmailForMoreInfo}</div>}

      <div>{resource.ErrorsOccured}</div>
      <ul>
        {data.validationErrors &&
          data.validationErrors.length > 0 &&
          data.validationErrors.map((item, index) => (
            <li key={index}>
              {item.name}
              <ul>
                {item.validationErrors.map((childItem, childIndex) => (
                  <li key={`${index}-${childIndex}`}>{childItem}</li>
                ))}
              </ul>
            </li>
          ))}
      </ul>
    </div>
  )
}
