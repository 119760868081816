import React from 'react'

import { observer } from 'mobx-react'
import cx from 'classnames'

import { useStores } from 'stores'
import ContentLoader from 'common/ContentLoader'

import CategoryItem from './CategoryItem'

export default observer(() => {
  const { authStore, pageResourceStore, webshopCategoryStore } = useStores()

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { webshop: resource },
  } = pageResourceStore
  const {
    TopLevelCategories: {
      data: { categories = [] },
      loading,
    },
  } = webshopCategoryStore

  React.useEffect(() => {
    webshopCategoryStore.fetchTopLevelCategories(session.uiLanguageSetting.toLowerCase())
  }, [webshopCategoryStore, session])

  return (
    <div id="webshop" className={cx('position-relative p-3 rounded', categories.length && 'bg-webshop')}>
      <ContentLoader show={loading} className="align-items-start pt-6" />
      <div className="list-container d-flex flex-wrap">
        {categories.map(category => {
          return <CategoryItem key={category.id} resource={resource} category={category} />
        })}
      </div>
    </div>
  )
})
