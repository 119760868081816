import React from 'react'

import cx from 'classnames'

import { getValueByPath } from 'utils'
import { useStateCallback } from 'hooks/useStateCallback'
import ContentLoader from 'common/ContentLoader'

export default React.forwardRef(
  (
    {
      columns = [],
      data,
      loading,
      loadingMessage,
      className,
      tableClassName,
      noRecordsLabel,
      showFooter,
      responsive,
      useFlex,
      enableSelection,
      selectedProperty = 'selected',
      onSelectionChanged,
    },
    ref
  ) => {
    const [selection, setSelection] = useStateCallback([])

    React.useEffect(() => {
      if (enableSelection && data) {
        const defaultSelection = data.filter(item => item[selectedProperty])
        setSelection(defaultSelection)
      } else {
        setSelection([])
      }
    }, [data, enableSelection, selectedProperty])

    React.useImperativeHandle(ref, () => ({
      toggleAllRows: select => {
        toggleAllRows(select)
      },
    }))

    const isAllSelected = () => !!data && !!data.length && !!selection.length && selection.length === data.length

    const toggleAllRows = selected => {
      const allSelected = isAllSelected()
      data.forEach(item => {
        item[selectedProperty] = selected === undefined ? !allSelected : selected
      })
      setSelection(
        [...data.filter(item => item[selectedProperty])],
        value => onSelectionChanged && onSelectionChanged(value)
      )
    }

    const toggleRow = (index, selected) => {
      const row = data[index]
      row[selectedProperty] = selected === undefined ? !row[selectedProperty] : selected
      setSelection(
        [...data.filter(item => item[selectedProperty])],
        value => onSelectionChanged && onSelectionChanged(value)
      )
    }

    const getCellValue = ({ item, index, column }) => {
      if (column.accessor) {
        if (typeof column.accessor === 'function') {
          const accessor = column.accessor({ index, column, row: item })
          return getValueByPath(item, accessor)
        }
        return getValueByPath(item, column.accessor)
      }
      return null
    }

    const renderHeaderCell = column => {
      if (!column || !column.Header) return false
      if (typeof column.Header === 'string' || column.Header instanceof String) {
        return column.Header
      }

      return column.Header({
        ...(enableSelection
          ? {
              isAllSelected: isAllSelected(),
              toggleAllRows,
            }
          : {}),
      })
    }

    const renderCell = params => {
      const { item, index, column } = params
      const value = getCellValue(params)
      if (column.Cell) {
        return column.Cell({
          index,
          row: item,
          value,
          ...(enableSelection
            ? {
                toggleRow: selected => toggleRow(index, selected),
                toggleAllRows,
              }
            : {}),
        })
      }
      return value
    }

    const renderFooterCell = column => {
      if (!column || !column.Footer) return false
      if (typeof column.Footer === 'string' || column.Footer instanceof String) {
        return column.Footer
      }
      return column.Footer({ data })
    }

    const cells = React.useMemo(() => columns.filter(column => !column.fullRow), [columns])
    const cellFullRows = React.useMemo(() => columns.filter(column => column.fullRow && !column.hidden), [columns])

    return (
      <div
        className={cx('react-table-container', className, {
          responsive,
          'use-flex': useFlex,
          loading,
        })}
      >
        <ContentLoader show={loading} text={loadingMessage} />
        <table role="table" className={cx('table table-hover', tableClassName)}>
          <thead>
            <tr role="row">
              {cells.map((column, index) => (
                <th
                  key={index}
                  role="columnheader"
                  colSpan={column.headerColSpan || 1}
                  className={cx('table-column', column.headerClassName)}
                  {...(column.headerStyle ? { style: column.headerStyle } : {})}
                  {...(column.width ? { width: column.width } : {})}
                >
                  {renderHeaderCell(column)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!!data &&
              data.map((item, index) => (
                <React.Fragment key={index}>
                  <tr role="row">
                    {cells.map((column, columnIndex) => (
                      <td
                        key={`${index}-${columnIndex}`}
                        role="cell"
                        colSpan={column.colSpan || 1}
                        rowSpan={column.rowSpan || 1}
                        className={cx('border-bottom', column.className)}
                        {...(column.style ? { style: column.style } : {})}
                        {...(column.width ? { width: column.width } : {})}
                      >
                        {renderCell({ item, index, column })}
                      </td>
                    ))}
                  </tr>
                  {!!cellFullRows.length &&
                    cellFullRows.map((column, columnIndex) => (
                      <tr key={`full-row-${index}-${columnIndex}`} role="row">
                        <td
                          role="cell"
                          colSpan={cells.length}
                          className={cx('border-bottom', column.className)}
                          {...(column.style ? { style: column.style } : {})}
                          {...(column.width ? { width: column.width } : {})}
                        >
                          {renderCell({ item, index, column })}
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
          </tbody>
          {!!data && showFooter && (
            <tfoot>
              <tr>
                {cells.map((column, index) => (
                  <th
                    key={index}
                    colSpan={column.footerColSpan || 1}
                    className={cx(column.footerClassName)}
                    {...(column.headerStyle ? { style: column.footerStyle } : {})}
                    {...(column.width ? { width: column.width } : {})}
                  >
                    {renderFooterCell(column)}
                  </th>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
        {!!data && !data.length && <h5 className="text-muted my-2">{noRecordsLabel}</h5>}
      </div>
    )
  }
)
