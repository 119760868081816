import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'stores'
import ContentLoader from 'common/ContentLoader'

import PoViewModal from './PoViewModal'
import PurchaseOrderPanel from './PurchaseOrderPanel'

export default observer(({ activated, caseId }) => {
  const [poViewModalOption, setPoViewModalOption] = React.useState({})
  const { pageResourceStore, purchaseOrderStore } = useStores()

  const {
    PageResource: { case: resource = {}, common: commonResource = {} },
  } = pageResourceStore
  const {
    Table: {
      data: { po: purchaseOrders = [] },
      loading,
    },
  } = purchaseOrderStore

  React.useEffect(() => activated && purchaseOrderStore.fetchTable(caseId), [purchaseOrderStore, caseId, activated])

  const handlePoViewModal = data => setPoViewModalOption({ isOpen: !!data, data })

  return (
    <div className="position-relative">
      <ContentLoader show={loading} />
      <React.Fragment>
        {purchaseOrders &&
          purchaseOrders.map((item, index) => (
            <PurchaseOrderPanel key={index} resource={resource} purchaseOrder={item} onViewDetail={handlePoViewModal} />
          ))}
        {(!purchaseOrders || !purchaseOrders.length) && resource.NoPOLines}
        {poViewModalOption.isOpen && (
          <PoViewModal
            resource={resource}
            commonResource={commonResource}
            data={poViewModalOption.data}
            onClose={() => handlePoViewModal()}
          />
        )}
      </React.Fragment>
    </div>
  )
})
