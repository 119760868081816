import React from 'react'

import { useFormContext } from 'react-hook-form'

import Button from 'common/Button'

export default ({ resource, submitting, loading, onClose }) => {
  const { watch } = useFormContext()

  const watchItems = watch('items')

  const enableSubmitButton =
    !!watchItems && watchItems.some(item => !!item.goodsReceived.items && !!item.goodsReceived.items.length)

  return (
    <div className="col d-flex justify-content-between">
      <Button type="button" text onClick={onClose} className="me-2">
        {resource.Cancel}
      </Button>
      <Button type="submit" icon="done" color="success" disabled={!enableSubmitButton || submitting || loading}>
        {resource.Save}
      </Button>
    </div>
  )
}
