import React from 'react'

import { useSearchParams } from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { useStores } from 'stores'
import { WEBSHOP_DEFAULTS } from 'definitions'

import Products from './Products'
import ProductsFooter from './ProductsFooter'
import ProductsHeader from './ProductsHeader'

export default ({ params }) => {
  const { authStore, webshopStore } = useStores()
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    AppSession: { session },
  } = authStore
  const {
    Config: {
      data: { attributes: attributeConfigs },
    },
  } = webshopStore

  const aggregates = React.useMemo(
    () =>
      attributeConfigs
        ?.filter(item => item.searchKey !== 'categories' && !!item.searchKey)
        .map(item => ({
          name: item.attributeName,
          searchKeyField: item.searchKeyField,
        })),
    [attributeConfigs]
  )

  const filters = React.useMemo(() => {
    const newFilters = {}
    let from = 0
    let priceFrom
    let priceTo

    for (const key of searchParams.keys()) {
      let value = searchParams.get(key)

      switch (key) {
        case 'criteria':
          newFilters[key] = value
          break
        case 'page':
          value = parseInt(value || 1)
          from = value ? (value - 1) * WEBSHOP_DEFAULTS.PAGE_SIZE : 0
          newFilters[key] = value
          break
        case 'pricefrom':
          priceFrom = parseInt(value)
          newFilters.priceFrom = priceFrom
          break
        case 'priceto':
          priceTo = parseInt(value || WEBSHOP_DEFAULTS.MAX_PRICE)
          newFilters.priceTo = priceTo
          break
        case 'sort':
          if (value) {
            const tokens = value.split(',')
            if (tokens.length) {
              newFilters.sort = {
                key: tokens[0],
                desc: tokens.length > 1 && tokens[1] === 'desc',
              }
            }
          }
          break
        default: {
          const attributes = newFilters.attributes || []
          const attributeConfig = attributeConfigs.find(item => item.attributeName === key)
          if (attributeConfig) {
            value.split(',').forEach(item => {
              attributes.push({
                key: attributeConfig.searchKeyField,
                value: item,
              })
            })
            newFilters.attributes = attributes
          }
          break
        }
      }
    }

    const isFilterByPrice = priceFrom != null && priceTo != null && priceFrom >= 0 && priceTo >= 1
    const isSortByPrice = newFilters.sort?.key === 'price'
    const isByPrice = isFilterByPrice || isSortByPrice
    return {
      ...newFilters,
      pageSize: WEBSHOP_DEFAULTS.PAGE_SIZE,
      from: isByPrice ? 0 : from,
      isByPrice,
      isFilterByPrice,
      isSortByPrice,
    }
  }, [searchParams, attributeConfigs])

  useDeepCompareEffect(() => {
    // Reset item price and search within search
    const textboxSubCriteria = document.getElementById('textboxSubCriteria')
    if (textboxSubCriteria) textboxSubCriteria.value = ''
    webshopStore.SubCriteria = ''
    webshopStore.clearItemPricing()
  }, [webshopStore, params])

  useDeepCompareEffect(() => {
    const { pageSize, isFilterByPrice, isSortByPrice, ...restOfFilters } = filters

    // Fetch webshop items
    webshopStore.fetchTable({
      aggregates,
      languageCode: session.uiLanguageSetting,
      ...params,
      ...restOfFilters,
      pageSize: filters.isByPrice ? WEBSHOP_DEFAULTS.MAX_ITEM_COUNT_SORT_BY_PRICE : pageSize,
      subCriteria: webshopStore.SubCriteria,
    })
  }, [webshopStore, session.uiLanguageSetting, filters, params, aggregates, webshopStore.SubCriteria])

  useDeepCompareEffect(() => {
    // Fetch webshop sidebar attributes
    webshopStore.fetchSidebarAttributes({
      aggregates,
      languageCode: session.uiLanguageSetting,
      ...params,
      criteria: filters.criteria,
      from: 0,
      pageSize: 0,
    })
  }, [webshopStore, session.uiLanguageSetting, filters.criteria, params, aggregates])

  const handleGotoPage = page => {
    searchParams.set('page', page)
    setSearchParams(searchParams)
  }

  const handleSortChange = sort => {
    if (sort?.key) {
      searchParams.set('sort', `${sort.key}${sort.desc ? ',desc' : ''}`)
    } else {
      searchParams.delete('sort')
    }
    setSearchParams(searchParams)
  }

  return (
    <div className="product-list webshop-text-color p-3 rounded bg-webshop">
      <ProductsHeader filters={filters} onGoToPage={handleGotoPage} onSortChange={handleSortChange} />
      <Products filters={filters} />
      <ProductsFooter
        page={filters.page}
        pageSize={filters.pageSize}
        isByPrice={filters.isByPrice}
        onGoToPage={handleGotoPage}
      />
    </div>
  )
}
