import React from 'react'

import { formatDate } from 'utils/formatters'
import { padLeft } from 'utils'
import Button from 'common/Button'
import Card from 'common/Card'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'
import ReactTable from 'common/ReactTable'
import ReadOnlyAttachment from 'common/ReadOnlyAttachment'
import ReadOnlyField from 'common/ReadOnlyField'

import SupplierDetail from 'components/Case/CaseDetail/Source/RfqFormModal/SupplierDetail'

export default ({ resource, commonResource, rfq, onClose }) => {
  const { attachments = [], lines = [], supplier } = rfq

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'lineIndex',
        headerClassName: 'text-muted',
        className: 'text-muted',
        Cell: ({ value }) => padLeft(value, 1),
      },
      {
        Header: resource.Description,
        accessor: 'extendedDescription',
        headerClassName: 'col-7',
        className: 'col-7',
        Cell: ({ value, row }) => (
          <div>
            <div className="mb-15 word-break-break-word white-space-normal">{row.description}</div>
            <div className="mb-15 word-break-break-word white-space-normal">{value}</div>
          </div>
        ),
      },
      {
        Header: resource.Delivery,
        accessor: 'deliveryInfo.deliveryDate',
        headerClassName: 'col1 text-start',
        className: 'col-1 text-start',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: resource.Qty,
        accessor: 'quantity.amount',
        headerClassName: 'col-1 text-start',
        className: 'col-1 text-start',
      },
      {
        Header: resource.Unit,
        accessor: 'quantity.unit',
        headerClassName: 'col-1 text-start',
        className: 'col-1 text-start',
      },
    ],

    []
  )

  return (
    <Modal
      isOpen
      title={resource.ViewRfq}
      size="xl"
      footer={
        <Button type="button" text color="primary" onClick={onClose}>
          {commonResource.Cancel}
        </Button>
      }
      footerClassName="justify-content-start"
      onClose={onClose}
    >
      {rfq.id && (
        <React.Fragment>
          {rfq.isDummy && (
            <MessageList
              color="warning"
              messages={rfq.isSent ? [resource.RfqNotSentToSupplier] : [resource.RfqWillNotSendToSupplier]}
              showClose={false}
            />
          )}
          <Card title={resource.PrLines} color="primary" outline className="mb-4">
            <ReactTable data={lines} columns={columns} responsive />
          </Card>
          <Card title={resource.RfqDetails}>
            <SupplierDetail label={resource.Supplier} supplier={supplier} />
            <ReadOnlyAttachment
              label={commonResource.Attachments}
              value={attachments}
              noAttachment={commonResource.NoAttachments}
              formGroup
              horizontal
            />
            <ReadOnlyField
              label={resource.ExtendedDescription}
              value={rfq.remarks}
              valueClassName="word-wrap-break-word"
              formGroup
              horizontal
            />
          </Card>
        </React.Fragment>
      )}
    </Modal>
  )
}
