import BaseStore from 'stores/base'

const fileHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export default class FileStore extends BaseStore {
  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options
  }

  upload(payload) {
    return this.service.post(null, payload, fileHeaders)
  }
}
