import React from 'react'

import { Container } from 'reactstrap'
import { Outlet } from 'react-router-dom'
import Header from 'components/Header'
import ModuleTabs from 'components/Core/ModuleTabs'
import Registry from 'components/Core/Registry'

export default () => {
  return (
    <React.Fragment>
      <Header />
      <Container className="main-layout" fluid>
        <Registry />
        <ModuleTabs>
          <Outlet />
        </ModuleTabs>
      </Container>
    </React.Fragment>
  )
}
