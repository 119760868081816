import React from 'react'

import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'

import { padLeft } from 'utils'
import { useStores } from 'stores'
import Button from 'common/Button'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'

import DeliveryInfo from './DeliveryInfo'
import ProductInfo from './ProductInfo'

export default observer(
  ({ session, clientId, caseId, caseNumber, prTabData, prLine: prLineProp, action, onSubmit, onClose }) => {
    const { pageResourceStore, purchaseRequestStore } = useStores()
    const methods = useForm({
      defaultValues: {},
    })

    const { handleSubmit } = methods
    const {
      CRUD: { error, submitting },
    } = purchaseRequestStore
    const {
      PageResource: { case: resource = {}, common: commonResource = {} },
    } = pageResourceStore
    const isEdit = action === 'edit'

    const prLine = React.useMemo(
      () =>
        purchaseRequestStore.preparePrLine({
          prLine: prLineProp,
          action,
          prTabData,
          sessionClientSiteId: session.siteId,
        }),

      [purchaseRequestStore, prLineProp, action, prTabData]
    )

    const handleOnSubmit = async values => {
      const { settings } = prTabData.purchaseRequest
      const result = await purchaseRequestStore.submitPrLine({
        caseId,
        settings,
        isEdit,
        values: { lineIndex: prLine.lineIndex, ...values },
      })
      if (result) {
        onClose()
        onSubmit(isEdit)
      }
    }

    return (
      <Modal
        isOpen
        useForm={methods}
        size="xl"
        title={`Case ${caseNumber} - ${isEdit ? `line ${padLeft(prLine.lineIndex, 1)}` : 'add line'}`}
        footer={
          <div className="w-100 d-flex">
            <Button type="button" text color="primary" disabled={submitting} className="me-2" onClick={onClose}>
              {commonResource.Cancel}
            </Button>
            <Button
              type="submit"
              color="success"
              className="btn btn-success ms-auto"
              icon="done"
              disabled={submitting}
              loading={submitting}
            >
              {commonResource.Save}
            </Button>
          </div>
        }
        disableClose={submitting}
        onClose={onClose}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <MessageList messages={error && [error]} />
        {prLine && (
          <React.Fragment>
            <ProductInfo
              resource={resource}
              commonResource={commonResource}
              prTabData={prTabData}
              prLine={prLine}
              disabled={submitting}
            />
            <DeliveryInfo
              resource={resource}
              commonResource={commonResource}
              clientId={clientId}
              prTabData={prTabData}
              prLine={prLine}
              disabled={submitting}
            />
          </React.Fragment>
        )}
      </Modal>
    )
  }
)
