import React from 'react'

import { observer } from 'mobx-react'
import { useSearchParams } from 'react-router-dom'

import { useDebounce } from 'hooks/useDebounce'
import { useStores } from 'stores'
import { WEBSHOP_ATTRIBUTE_TYPE } from 'definitions'
import AttributePanel from './AttributePanel'

export default observer(({ categoryOnly, excludeCategory }) => {
  const { authStore, webshopStore } = useStores()
  const [searchParams, setSearchParams] = useSearchParams()
  const setSearchParamsDebounced = useDebounce(setSearchParams, 300)

  const {
    AppSession: { session },
  } = authStore
  const {
    Config: {
      data: { attributes: attributeConfigData = [] },
    },
    Table: {
      data: { itemAttributes: itemAttributesData = [] },
    },
    SidebarAttributes: {
      data: { itemAttributes: sidebarAttributesData = [] },
    },
  } = webshopStore

  const getCategoryAttributes = attributes => {
    const categoryAttribute = attributes.find(item => item.attributeName === 'categories')
    if (categoryAttribute) {
      return [
        {
          values:
            categoryAttribute.values?.map(itemValue => {
              const tokens = itemValue.value.split('/')
              return {
                ...itemValue,
                categoryPath: itemValue.value,
                value: tokens[tokens.length - 1],
              }
            }) || [],
        },
      ]
    }
    return []
  }

  const validTypes = React.useMemo(() => Object.values(WEBSHOP_ATTRIBUTE_TYPE), [])

  const sidebarAttributes = React.useMemo(() => {
    if (sidebarAttributesData?.length) {
      let attributes = sidebarAttributesData
      if (categoryOnly) {
        attributes = getCategoryAttributes(sidebarAttributesData)
      } else if (excludeCategory) {
        attributes = sidebarAttributesData?.filter(item => item.attributeName !== 'categories') || []
      }

      return attributeConfigData
        .map(attributeConfig => {
          const attribute = attributes.find(
            item =>
              item.attributeName === attributeConfig.attributeName &&
              (validTypes.includes(attributeConfig.attributeType) || !attributeConfig.attributeType)
          )

          return {
            ...attributeConfig,
            values:
              attribute?.values?.map(item => ({
                ...item,
                total: 0,
              })) || [],
          }
        })
        .filter(item => item.values?.length > 0)
    }
    return []
  }, [attributeConfigData, categoryOnly, excludeCategory, sidebarAttributesData, validTypes])

  const attributes = React.useMemo(() => {
    if (itemAttributesData?.length) {
      sidebarAttributes.forEach(attribute => {
        if (attribute.values?.length) {
          attribute.values.forEach(attributeValue => {
            const attributeFound = itemAttributesData.find(item => item.attributeName === attribute.attributeName)
            let valueFound

            if (attributeFound?.values?.length) {
              valueFound = attributeFound.values.find(
                item => item.value?.toLowerCase() === attributeValue.value?.toLowerCase()
              )
            }

            if (valueFound) {
              attributeValue.total = valueFound.total
            } else {
              attributeValue.total = 0
            }
          })
        }
      })
    }
    return sidebarAttributes || []
  }, [sidebarAttributes, itemAttributesData])

  const handleAttributeFilterChange = attribute => {
    const tokens = searchParams.get(attribute.key)?.split(',') || []
    const index = tokens.findIndex(item => item === attribute.value)

    if (attribute.checked) {
      if (index === -1) {
        tokens.push(attribute.value)
      }
    } else {
      if (index !== -1) {
        tokens.splice(index, 1)
      }
    }

    if (tokens.length) {
      searchParams.set(attribute.key, tokens.join(','))
    } else {
      searchParams.delete(attribute.key)
    }

    searchParams.delete('page')
    setSearchParamsDebounced(searchParams)
  }

  if (!attributes.length) return false

  return (
    <div className="attributes-panel">
      {attributes.map(attribute => (
        <AttributePanel
          key={attribute.attributeId}
          attribute={attribute}
          searchParams={searchParams}
          clientName={session.clientName}
          categoryOnly={categoryOnly}
          onChange={handleAttributeFilterChange}
        />
      ))}
    </div>
  )
})
