import React from 'react'

import { useFormContext } from 'react-hook-form'

import Card from 'common/Card'
import Checkbox from 'common/Checkbox'
import HelpText from 'common/HelpText'
import RadioGroup from 'common/Forms/RadioGroup'

const CheckboxToggle = ({
  show,
  name,
  label,
  defaultChecked,
  helpText,
  mandatory,
  title,
  disabled,
  footer,
  onChange,
}) => {
  const { setValue } = useFormContext()

  const handleOnChange = event => {
    // Workaround since uncontrolled disabled checkbox is buggy in react-hook-form
    setValue(name, event.target.checked)
    onChange && onChange(event)
  }

  if (show) {
    return (
      <div className="col-md-6 col-12 mb-3">
        <Checkbox
          name={name}
          label={label}
          formClassName="mb-0"
          formGroup
          disabled={disabled || mandatory}
          defaultChecked={defaultChecked}
          {...(mandatory ? { title } : {})}
          onChange={handleOnChange}
        />
        <HelpText className="ms-6">{helpText}</HelpText>
        {footer}
      </div>
    )
  }
  return false
}

export default ({ resource, session, disabled }) => {
  const [pendingAction, setPendingAction] = React.useState(session.emailNotification.pendingAction.active)
  const { emailNotification } = session

  return (
    <Card title={resource.EmailNotificationTitle} fieldset bodyClassName="d-flex flex-wrap">
      <CheckboxToggle
        show={emailNotification.changePassword.visible}
        name="emailNotification.changePassword"
        defaultChecked={emailNotification.changePassword.active}
        label={resource.EmailNotificationChangePassword}
        helpText={resource.EmailNotificationChangePasswordDescription}
        mandatory={emailNotification.changePassword.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.webformSummary.visible}
        name="emailNotification.webformSummary"
        defaultChecked={emailNotification.webformSummary.active}
        label={resource.EmailNotificationWebformSummary}
        helpText={resource.EmailNotificationWebformSummaryDescription}
        mandatory={emailNotification.webformSummary.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.webshopCheckoutConfirmation.visible}
        name="emailNotification.webshopCheckoutConfirmation"
        defaultChecked={emailNotification.webshopCheckoutConfirmation.active}
        label={resource.EmailNotificationWebshopCheckoutConfirmation}
        helpText={resource.EmailNotificationWebshopCheckoutConfirmationDescription}
        mandatory={emailNotification.webshopCheckoutConfirmation.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.abortCase.visible}
        name="emailNotification.abortCase"
        defaultChecked={emailNotification.abortCase.active}
        label={resource.EmailNotificationAbortCase}
        helpText={resource.EmailNotificationAbortCaseDescription}
        mandatory={emailNotification.abortCase.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.proposalsAvailable.visible}
        name="emailNotification.proposalsAvailable"
        defaultChecked={emailNotification.proposalsAvailable.active}
        label={resource.EmailNotificationProposalsAvailable}
        helpText={resource.EmailNotificationProposalsAvailableDescription}
        mandatory={emailNotification.proposalsAvailable.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.proposalsSelected.visible}
        name="emailNotification.proposalsSelected"
        defaultChecked={emailNotification.proposalsSelected.active}
        label={resource.EmailNotificationProposalsSelected}
        helpText={resource.EmailNotificationProposalsSelectedDescription}
        mandatory={emailNotification.proposalsSelected.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.proposalsRevoked.visible}
        name="emailNotification.proposalsRevoked"
        defaultChecked={emailNotification.proposalsRevoked.active}
        label={resource.EmailNotificationProposalsRevoked}
        helpText={resource.EmailNotificationProposalsRevokedDescription}
        mandatory={emailNotification.proposalsRevoked.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.orderConfirmation.visible}
        name="emailNotification.orderConfirmation"
        defaultChecked={emailNotification.orderConfirmation.active}
        label={resource.EmailNotificationOrderConfirmation}
        helpText={resource.EmailNotificationOrderConfirmationDescription}
        mandatory={emailNotification.orderConfirmation.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.feedbackAcknowledgement.visible}
        name="emailNotification.feedbackAcknowledgement"
        defaultChecked={emailNotification.feedbackAcknowledgement.active}
        label={resource.EmailNotificationFeedbackSummary}
        helpText={resource.EmailNotificationFeedbackSummaryDescription}
        mandatory={emailNotification.feedbackAcknowledgement.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.pendingAction.visible}
        name="emailNotification.pendingAction"
        defaultChecked={emailNotification.pendingAction.active}
        label={resource.EmailNotificationPendingAction}
        helpText={resource.EmailNotificationPendingActionDescription}
        mandatory={emailNotification.pendingAction.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
        footer={
          <RadioGroup
            name="emailNotification.schedule.pendingActionWeekly"
            defaultValue={emailNotification.schedule?.pendingActionWeekly}
            radios={[
              {
                value: false,
                label: resource.Daily,
                formClassName: 'px-8',
              },
              {
                value: true,
                label: resource.Weekly,
                formClassName: 'px-8',
              },
            ]}
            formGroup
            formClassName="mt-2 d-flex"
            disabled={emailNotification.pendingAction.mandatory || !pendingAction}
          />
        }
        onChange={event => setPendingAction(event.target.checked)}
      />
      <CheckboxToggle
        show={emailNotification.praEmailNextApprover.visible}
        name="emailNotification.praEmailNextApprover"
        defaultChecked={emailNotification.praEmailNextApprover.active}
        label={resource.EmailNotificationPraNextApprover}
        helpText={resource.EmailNotificationPraNextApproverDescription}
        mandatory={emailNotification.praEmailNextApprover.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.praEmailComment.visible}
        name="emailNotification.praEmailComment"
        defaultChecked={emailNotification.praEmailComment.active}
        label={resource.EmailNotificationPRAComment}
        helpText={resource.EmailNotificationPRACommentDescription}
        mandatory={emailNotification.praEmailComment.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.praEmailRejected.visible}
        name="emailNotification.praEmailRejected"
        defaultChecked={emailNotification.praEmailRejected.active}
        label={resource.EmailNotificationPraRejected}
        helpText={resource.EmailNotificationPraRejectedDescription}
        mandatory={emailNotification.praEmailRejected.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.praEmailApproved.visible}
        name="emailNotification.praEmailApproved"
        defaultChecked={emailNotification.praEmailApproved.active}
        label={resource.EmailNotificationPraApproved}
        helpText={resource.EmailNotificationPraApprovedDescription}
        mandatory={emailNotification.praEmailApproved.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.praSupplierPurchaseOrder.visible}
        name="emailNotification.praSupplierPurchaseOrder"
        defaultChecked={emailNotification.praSupplierPurchaseOrder.active}
        label={resource.EmailNotificationPraSupplierPurchaseOrder}
        helpText={resource.EmailNotificationPraSupplierPurchaseOrderDescription}
        mandatory={emailNotification.praSupplierPurchaseOrder.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.praSalesInvoiceGoodsReceived.visible}
        name="emailNotification.praSalesInvoiceGoodsReceived"
        defaultChecked={emailNotification.praSalesInvoiceGoodsReceived.active}
        label={resource.EmailNotificationPraSalesInvoiceGoodsReceipt}
        helpText={resource.EmailNotificationPraSalesInvoiceGoodsReceiptDescription}
        mandatory={emailNotification.praSalesInvoiceGoodsReceived.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
      <CheckboxToggle
        show={emailNotification.salesInvoiceGoodsReceived.visible}
        name="emailNotification.salesInvoiceGoodsReceived"
        defaultChecked={emailNotification.salesInvoiceGoodsReceived.active}
        label={resource.EmailNotificationNonPraSalesInvoiceGoodsReceipt}
        helpText={resource.EmailNotificationNonPraSalesInvoiceGoodsReceiptDescription}
        mandatory={emailNotification.salesInvoiceGoodsReceived.mandatory}
        title={resource.EmailNotificationMandatory}
        disabled={disabled}
      />
    </Card>
  )
}
