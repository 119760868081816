import React from 'react'

import { useParams } from 'react-router-dom'

import { useStores } from 'stores'
import WebshopProductList from 'components/Webshop/WebshopProductList'

export default () => {
  const { webshopStore } = useStores()

  const params = useParams()
  const categoryPath = params['*']

  React.useEffect(() => {
    webshopStore.IsFromSearch = false
  }, [webshopStore])

  return (
    <div id="webshop" className="col position-relative">
      <WebshopProductList params={{ isByCategory: true, categoryPath }} />
    </div>
  )
}
