import React from 'react'

import { FormText } from 'reactstrap'
import cx from 'classnames'

export default ({ children, hasError, color, className }) => {
  return (
    <FormText {...(hasError ? { color: 'danger' } : { color })} className={cx('ms-1', className)}>
      {children}
    </FormText>
  )
}
