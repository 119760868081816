import React from 'react'

import FormGroup from 'common/FormGroup'

import FileAttachment from 'common/Forms/FileAttachment'
import Input from 'common/Forms/Input'

export default ({ resource, index, multiAddressAttachment, disabled, isReadOnly }) => {
  const [hasAttachment, setHasAttachment] = React.useState(!!multiAddressAttachment?.fileId)

  return (
    <FormGroup
      label={resource.DeliveryAddress}
      formGroup
      horizontal
      labelCol={{ lg: 3, xl: 5 }}
      inputCol={{ lg: 9, xl: 7 }}
      className="col-12 mx-0"
      labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
      inputColClassName="px-lg-3 p-0"
    >
      <Input type="hidden" name={`lines.${index}.deliveryInfo.isMultiAddress`} />
      <FileAttachment
        name={`lines.${index}.deliveryInfo.multiAddressAttachment`}
        rules={{
          validate: newValue => {
            if (!newValue?.added?.length) {
              const notYetAdded = !newValue?.attachments?.length
              const deleted = !!newValue?.deleted?.length

              if (notYetAdded || deleted) {
                return resource.MultiAddressRequiredAttachment
              }
            }
            return true
          },
        }}
        defaultValue={hasAttachment ? { attachments: [multiAddressAttachment] } : null}
        label={resource.MultipleDeliveryAddress}
        buttonUploadLabel={hasAttachment ? resource.ReplaceAttachment : resource.UploadAttachment}
        singleAttachmentOnly
        promptDeleteAttachment
        hideDeleteAttachment
        disabled={disabled}
        readOnly={isReadOnly}
        formGroup
        formClassName="pt-2 px-0 mb-0"
        onChange={newValue => {
          setHasAttachment(!!newValue.added?.length)
        }}
      />
    </FormGroup>
  )
}
