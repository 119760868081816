import React from 'react'

import cx from 'classnames'
import FormGroup from 'common/FormGroup'

export default ({
  label,
  value,
  formGroup,
  horizontal,
  className,
  formClassName,
  labelClassName,
  inputColClassName,
  labelCol,
  inputCol,
}) => (
  <FormGroup
    {...{
      label,
      formGroup,
      horizontal,
      className: cx('read-only', formClassName),
      labelClassName,
      inputColClassName,
      labelCol,
      inputCol,
    }}
  >
    <div className={cx('form-value', className)}>{value}</div>
  </FormGroup>
)
