import React from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from 'definitions'
import { useDebounce } from 'hooks/useDebounce'
import Toaster from 'common/Toaster'

import CartCheckReady from './CartCheckReady'
import CartHeader from './CartHeader'
import CartItems from './CartItems'
import CartSummary from './CartSummary'

export default observer(({ webshopCartStore, globalMessageStore, resource, cart, reloadCart, onPunchout }) => {
  const updaterCartDebounced = useDebounce(params => updateCart(params))
  const navigate = useNavigate()
  const methods = useForm({
    defaultValues: cart,
  })

  const { handleSubmit, getValues, setValue, trigger } = methods
  const {
    CRUD: { cartId: crudCartId, submitting, action },
  } = webshopCartStore

  const disabled = submitting && action === 'checkout' && crudCartId === cart.id
  const saving = submitting && action === 'save' && crudCartId === cart.id

  const setCartValues = async (newCart, { calculateDeliveryDate }) => {
    setValue('adminCost', newCart.adminCost)
    setValue('total', newCart.total)
    setValue('totalVatIncluded', newCart.totalVatIncluded)
    setValue('shipping', newCart.shipping)
    setValue('shippingVatIncluded', newCart.shippingVatIncluded)
    setValue('feeAmount', newCart.feeAmount)
    setValue('feeAmountVatIncluded', newCart.feeAmountVatIncluded)
    setValue('grandTotal', newCart.grandTotal)
    setValue('grandTotalVatIncluded', newCart.grandTotalVatIncluded)
    setValue('transports', newCart.transports)

    if (calculateDeliveryDate) {
      setValue('preferredDate', newCart.preferredDate)
      setValue('estimatedDeliveryDate', newCart.estimatedDeliveryDate)
    }


    if (newCart.items) {
      for (const item of newCart.items) {
        const index = cart.items.findIndex(item2 => item2.itemId === item.itemId)
        if (index >= 0) {
          if (calculateDeliveryDate) {
            const estimatedDeliveryDateFieldName = `items.${index}.estimatedDeliveryDate`
            setValue(estimatedDeliveryDateFieldName, item.estimatedDeliveryDate)
          }

          const hasSupplierDeliveryFieldName = `items.${index}.hasSupplierDelivery`
          setValue(hasSupplierDeliveryFieldName, item.hasSupplierDelivery)
          await trigger(hasSupplierDeliveryFieldName)
        }
      }
    }
  }

  const updateCart = async ({
    calculate = true,
    isCalculateCartQuantity = false,
    calculateDeliveryDate = false,
  } = {}) => {
    const values = getValues()
    if (values.items) {
      const result = await webshopCartStore.updateCart({
        cart,
        values,
        calculate,
        calculateDeliveryDate,
      })
      if (result) {
        if (isCalculateCartQuantity) {
          webshopCartStore.calculateCartQuantity()
        }
        setCartValues(result, { calculateDeliveryDate })
      }
    }
  }

  const handleCheckout = () => {
    Toaster.confirm({
      title: resource.ProceedCheckoutTitle,
      message: (
        <div>
          <div>{resource.ProceedCheckoutBodyLine1}</div>
          <div>{resource.ProceedCheckoutBodyLine2}</div>
        </div>
      ),
      submitText: resource.ProceedCheckoutButtonText,
      cancelText: resource.ProceedCheckoutCancelText,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const result = await webshopCartStore.checkout(cart.id)

        if (result && result.success) {
          const deleteResult = await webshopCartStore.delete(cart.id)
          if (deleteResult?.success) {
            if (result.redirectUrl === ROUTES.WEBSHOP_PUNCHOUT) {
              handleSendToErp(closeModal)
            } else if (result.redirectUrl) {
              closeModal()
              navigate(result.redirectUrl)
            }
          } else {
            closeModal()
          }
        } else {
          closeModal()
          if (result?.message === 'PunchoutExpires') {
            globalMessageStore.show({
              color: 'warning',
              message: (
                <span>
                  <strong>{resource.Warning}</strong> {resource.PunchoutExpireDisableCheckout}
                </span>
              ),
            })
          }
        }
      },
    })
  }

  const handleSendToErp = async closeModal => {
    const result = await webshopCartStore.getPunchoutData()
    closeModal()
    if (result) {
      onPunchout(result)
    }
  }

  const handleCopyCart = () => {
    Toaster.confirm({
      title: resource.CopyCart,
      message: resource.ConfirmCopyCart,
      submitText: resource.Yes,
      cancelText: resource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const result = await webshopCartStore.copyCart({ cartId: cart.id })
        closeModal()
        result && reloadCart()
      },
    })
  }

  const handleClearCart = () => {
    Toaster.confirm({
      title: resource.ClearCart,
      message: resource.ConfirmDeleteCart,
      submitText: resource.Yes,
      cancelText: resource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const result = await webshopCartStore.delete(cart.id)
        closeModal()
        result && reloadCart()
      },
    })
  }

  const handleEnableMultiAddress = async (index, itemId, enable) => {
    const result = await webshopCartStore.enableMultiAddress({
      cartId: cart.id,
      itemId,
      enable,
    })
    if (result?.success) {
      const quantity = enable ? getValues(`items.${index}.quantity`) : 1
      setValue('transports', [])
      setValue('isMultiAddress', enable)
      setValue('multiAddressCount', quantity)

      await updateCart({ calculate: false })
      reloadCart()
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleCheckout)} className="cart position-relative border-bottom pb-3 mb-5">
        <CartCheckReady />
        <CartHeader
          resource={resource}
          cart={cart}
          saving={saving}
          disabled={disabled}
          onCopyCart={handleCopyCart}
          onClearCart={handleClearCart}
        />
        <div className="cart-content d-flex flex-wrap">
          <CartItems
            cart={cart}
            disabled={disabled}
            updateCart={updaterCartDebounced}
            onClearCart={handleClearCart}
            onEnableMultiAddress={handleEnableMultiAddress}
          />
          <CartSummary cart={cart} disabled={disabled} saving={saving} updateCart={updaterCartDebounced} />
        </div>
      </form>
    </FormProvider>
  )
})
