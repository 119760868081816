import React from 'react'

import { padLeft } from 'utils'
import Button from 'common/Button'
import Modal from 'common/Modal'

import DeliveryInfoPanel from './DeliveryInfoPanel'
import ProductInfoPanel from './ProductInfoPanel'
import SupplierQuotesPanel from './SupplierQuotesPanel'

export default ({ resource, commonResource, caseNumber, settings = {}, prLine, onClose }) => {
  const supplierQuotes = prLine.supplierQuotes || []

  return (
    <Modal
      isOpen
      size="xl"
      title={`${resource.Case} ${caseNumber} - ${resource.Line} ${padLeft(prLine.lineIndex, 1)}`}
      footer={
        <Button type="button" text color="primary" onClick={onClose}>
          {commonResource.Cancel}
        </Button>
      }
      footerClassName="justify-content-start"
      onClose={onClose}
    >
      {prLine && (
        <React.Fragment>
          <ProductInfoPanel resource={resource} data={prLine} settings={settings} />
          <DeliveryInfoPanel resource={resource} data={prLine} />
          {supplierQuotes && !!supplierQuotes.length && (
            <SupplierQuotesPanel resource={resource} supplierQuotes={supplierQuotes} />
          )}
        </React.Fragment>
      )}
    </Modal>
  )
}
