import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'stores'
import MessageList from 'common/MessageList'

export default observer(({ message }) => {
  const { authStore } = useStores()
  const {
    AppSession: { session },
  } = authStore

  const showMessage = session.punchoutType.toLowerCase() !== 'nonerp' && !session.hasPunchoutSession

  return showMessage && !!message && <MessageList messages={[message]} hideClose />
})
