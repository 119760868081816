import React from 'react'

import { Alert } from 'reactstrap'
import cx from 'classnames'

import { useStateCallback } from 'hooks/useStateCallback'

export default ({
  title,
  messages,
  color = 'danger',
  disableScroll,
  isHtml,
  bullet,
  className,
  titleClassName,
  messageClassName,
  closeClassName,
  hideClose,
}) => {
  const [isOpen, setIsOpen] = useStateCallback()

  React.useEffect(() => {
    setIsOpen(!!messages && !!messages.length, () => {
      if (!disableScroll && messages && messages.length) {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      }
    })
  }, [setIsOpen, disableScroll, messages])

  const toggle = () => setIsOpen(prev => !prev)

  const ContainerWrapper = bullet ? 'ul' : React.Fragment
  const Item = bullet ? 'li' : 'p'

  return (
    <Alert
      isOpen={isOpen}
      toggle={toggle}
      color={color}
      className={cx(className, hideClose && 'pe-4')}
      closeClassName={cx(closeClassName, hideClose && 'd-none')}
    >
      {title && <h5 className={cx('alert-heading fw-bold', titleClassName)}>{title}</h5>}
      <ContainerWrapper>
        {!!messages &&
          messages.map((message, index) =>
            isHtml ? (
              <Item
                key={index}
                className={cx('message-item mb-0 word-wrap-break-word', messageClassName)}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            ) : (
              <Item key={index} className={cx('message-item mb-0 word-wrap-break-word', messageClassName)}>
                {message}
              </Item>
            )
          )}
      </ContainerWrapper>
    </Alert>
  )
}
