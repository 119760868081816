import React from 'react'

import { observer } from 'mobx-react'
import { useWatch } from 'react-hook-form'

import { useStores } from 'stores'
import ContentLoader from 'common/ContentLoader'

export default observer(() => {
  const watchCartReady = useWatch({ name: 'cartReady' })
  const { webshopCartStore, webshopPricingStore } = useStores()

  const {
    Table: { loading: tableLoading },
    Lookups: { loading: lookupsLoading },
  } = webshopCartStore
  const {
    ItemPricingTier: { loading: pricingTierLoading },
  } = webshopPricingStore

  const loading = tableLoading || lookupsLoading || pricingTierLoading

  return <ContentLoader show={!watchCartReady && loading} className="align-items-start pt-6" />
})
