import React from 'react'

import { useSearchParams } from 'react-router-dom'

import { useStores } from 'stores'
import MessageList from 'common/MessageList'

export default () => {
  const { authStore, caseStore, pageResourceStore } = useStores()
  const [searchParams, setSearchParams] = useSearchParams()
  const [checkoutMessage, setCheckoutMessage] = React.useState()
  const [pickProposalMessage, setPickProposalMessage] = React.useState()

  const showCheckoutMessage = searchParams.get('showCheckoutMessage')
  const showProposalSelectedMessage = searchParams.get('showProposalSelectedMessage')
  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { case: resource },
  } = pageResourceStore

  React.useEffect(() => {
    const doCheckoutMessage = async () => {
      const result = await caseStore.getWebshopCheckoutMessage()
      result && setCheckoutMessage(result.checkoutMessage)
      setSearchParams('')
    }

    if (resource && showCheckoutMessage) {
      doCheckoutMessage()
    }
  }, [resource, caseStore, showCheckoutMessage, setSearchParams])

  React.useEffect(() => {
    const doShowMessage = async () => {
      const result = await caseStore.getProposalSelectedMessage()
      result && setPickProposalMessage(`${resource.ProposalSelectedSuccessfully} ${result.pickProposalMessage}`)
      setSearchParams('')
    }

    if (showProposalSelectedMessage && resource?.ProposalSelectedSuccessfully) {
      doShowMessage()
    }
  }, [caseStore, resource, setSearchParams, showProposalSelectedMessage])

  React.useEffect(() => {
    return () => {
      setCheckoutMessage(null)
      setPickProposalMessage(null)
    }
  }, [])

  return (
    !!resource && (
      <React.Fragment>
        {!session.pickProposalsWithoutPunchout && !session.hasPunchoutSession && (
          <MessageList messages={[`<strong>${resource.Warning}!</strong> ${resource.NoPunchOutSession}`]} isHtml />
        )}
        {!!checkoutMessage && <MessageList messages={[checkoutMessage]} color="success" />}
        {!!pickProposalMessage && <MessageList messages={[pickProposalMessage]} color="success" />}
      </React.Fragment>
    )
  )
}
