import React from 'react'

import { DropdownItem } from 'reactstrap'
import cx from 'classnames'

import ButtonIcon from 'common/ButtonIcon'
import Dropdown from 'common/Dropdown'
import Icon from 'common/Icon'

export default ({ resource, onChange, defaultFilters }) => {
  const [filters, setFilters] = React.useState(defaultFilters)

  const handleFilterChange = value => {
    const newFilters = { ...filters, ...value }
    setFilters(newFilters)
    onChange(newFilters)
  }

  const isAction = filters.sortOrder === 'action'
  const isDate = filters.sortOrder === 'date'

  return (
    <div className="col-auto ms-auto d-flex align-items-start pt-lg-3 pt-2 px-1">
      <ButtonIcon
        icon="folder_open"
        title={resource.OpenCases}
        disabled={!filters.isClosed}
        className={cx('mr-1', !filters.isClosed && 'active')}
        onClick={() => handleFilterChange({ isClosed: false })}
      />
      <ButtonIcon
        icon="inventory"
        title={resource.ClosedCases}
        disabled={filters.isClosed}
        className={cx('mr-1', filters.isClosed && 'active')}
        onClick={() => handleFilterChange({ isClosed: true })}
      />
      <Dropdown
        caret
        toggleButtonOptions={{
          icon: isAction ? 'thumb_up' : 'calendar_month',
          title: isAction ? resource.SortByAction : resource.SortByDate,
          text: true,
        }}
      >
        <DropdownItem
          className="d-inline-flex align-items-center"
          active={isAction}
          onClick={() => handleFilterChange({ sortOrder: 'action' })}
        >
          <Icon icon="thumb_up" className="me-2" />
          {resource.SortByAction}
        </DropdownItem>
        <DropdownItem
          className="d-inline-flex align-items-center"
          active={isDate}
          onClick={() => handleFilterChange({ sortOrder: 'date' })}
        >
          <Icon icon="calendar_month" className="me-2" />
          {resource.SortByDate}
        </DropdownItem>
      </Dropdown>
    </div>
  )
}
