import React from 'react'

import { components } from 'react-select'
import { observer } from 'mobx-react'

import { ADDRESS_TYPE } from 'definitions'
import { sort } from 'utils/array'
import { useStores } from 'stores'
import RadioGroup from 'common/Forms/RadioGroup'
import ReactSelect from 'common/Forms/ReactSelect'
import ReadOnlyField from 'common/ReadOnlyField'

import DeliveryInfoAddress from './DeliveryInfoAddress'

export default observer(
  ({
    resource,
    namePrefix = '',
    clientSites: clientSitesData,
    addressType: addressTypeProp = ADDRESS_TYPE.OTHER,
    defaultClientSite,
    defaultDeliveryAddress,
    lineIndex,
    disabled,
    isReadOnly,
    isReadOnlyShowDeliveryAddress,
    formClassName,
    labelClassName,
    inputColClassName,
    labelCol,
    inputCol,
    hideAddressBuilding,
    onChange,
  }) => {
    const [addressType, setAddressType] = React.useState(addressTypeProp)
    const { clientAreaStore } = useStores()
    const isClient = addressType === ADDRESS_TYPE.CLIENT
    const isOther = addressType === ADDRESS_TYPE.OTHER

    const clientSites = React.useMemo(() => sort(clientSitesData, 'name'), [clientSitesData])

    const handleChangeAddressType = selected => {
      setAddressType(selected)
      onChange &&
        onChange({
          addressType: selected,
          key: 'addressType',
          value: selected,
        })
    }

    return (
      <React.Fragment>
        <RadioGroup
          disabled={disabled}
          readOnlyField={isReadOnly}
          radios={[
            {
              value: ADDRESS_TYPE.CLIENT,
              label: resource.CompanySite,
              children: isClient && !isReadOnlyShowDeliveryAddress && (
                <ReactSelect
                  isDisabled={disabled}
                  readOnlyField={isReadOnly}
                  name={`${namePrefix}deliveryInfo.clientAddress`}
                  rules={{ required: isClient && resource.Required }}
                  defaultValue={defaultClientSite}
                  formGroup
                  horizontal
                  inputCol={{ lg: 12, xl: 12 }}
                  options={clientSites}
                  serverSide
                  loadData={args =>
                    clientAreaStore.getClientSites({
                      search: args.search,
                      size: 50,
                    })
                  }
                  defaultOptions
                  optionValueKey="clientSiteId"
                  optionLabelKey="name"
                  components={{
                    Option: ({ data, ...innerProps }) => {
                      const address = data.address
                      return (
                        <components.Option data={data} {...innerProps}>
                          <strong className="d-block">{data.name}</strong>
                          <small className="d-block muted address-mini">{`${address.street} ${address.number} ${
                            address.box
                          }, ${address.postalCode} ${address.city}, ${address.country ? address.country.name : ''}`}</small>
                        </components.Option>
                      )
                    },
                  }}
                  onChange={selected => {
                    onChange &&
                      onChange({
                        addressType,
                        key: 'clientAddress',
                        value: selected,
                      })
                  }}
                />
              ),
            },
            {
              value: ADDRESS_TYPE.OTHER,
              label: resource.OtherAddress,
            },
          ]}
          name={`${namePrefix}deliveryInfo.addressType`}
          label={resource.DeliveryAddress}
          defaultValue={addressType}
          formGroup
          horizontal
          formClassName={formClassName}
          labelClassName={labelClassName}
          inputColClassName={inputColClassName}
          labelCol={labelCol}
          inputCol={inputCol}
          onChange={handleChangeAddressType}
        />
        {isOther && !isReadOnlyShowDeliveryAddress && (
          <DeliveryInfoAddress
            {...{
              resource,
              isOther,
              defaultDeliveryAddress,
              namePrefix,
              lineIndex,
              disabled,
              isReadOnly,
              formClassName,
              labelClassName,
              inputColClassName,
              labelCol,
              inputCol,
              hideAddressBuilding,
            }}
          />
        )}
        {isReadOnlyShowDeliveryAddress && (
          <React.Fragment>
            {!hideAddressBuilding && (
              <ReadOnlyField
                label={resource.Building}
                value={defaultDeliveryAddress.building}
                formGroup
                horizontal
                formClassName={formClassName}
                labelClassName={labelClassName}
                inputColClassName={inputColClassName}
                labelCol={labelCol}
                inputCol={inputCol}
              />
            )}
            <ReadOnlyField
              label={resource.Address}
              value={`${defaultDeliveryAddress.street} ${defaultDeliveryAddress.number} ${defaultDeliveryAddress.box}`}
              formGroup
              horizontal
              formClassName={formClassName}
              labelClassName={labelClassName}
              inputColClassName={inputColClassName}
              labelCol={labelCol}
              inputCol={inputCol}
            />
            <ReadOnlyField
              label={resource.Location}
              value={`${defaultDeliveryAddress.postalCode} ${defaultDeliveryAddress.city}`}
              formGroup
              horizontal
              formClassName={formClassName}
              labelClassName={labelClassName}
              inputColClassName={inputColClassName}
              labelCol={labelCol}
              inputCol={inputCol}
            />
            <ReadOnlyField
              label={resource.Country}
              value={defaultDeliveryAddress.countryName || defaultDeliveryAddress.countryCode}
              formGroup
              horizontal
              formClassName={formClassName}
              labelClassName={labelClassName}
              inputColClassName={inputColClassName}
              labelCol={labelCol}
              inputCol={inputCol}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
)
