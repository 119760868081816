import React from 'react'

export default ({ data }) => {
  const formRef = React.useRef()
  const { punchoutURL, cartXML } = data

  React.useEffect(() => {
    formRef.current.submit()
  }, [])

  return (
    <form ref={formRef} method="POST" action={punchoutURL} className="collapse">
      <input name="cXML-base64" value={cartXML} />
      <button type="submit" />
    </form>
  )
}
