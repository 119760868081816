import React from 'react'

import { useLocation } from 'react-router-dom'

import { useStores } from 'stores'
import ErrorBoundary from './ErrorBoundary'

export default ({ children, isApp }) => {
  const [error, setError] = React.useState()
  const [info, setInfo] = React.useState()
  const location = useLocation()
  const { authStore } = useStores()

  React.useEffect(() => {
    resetError(null)
  }, [location.pathname])

  const handleCatchError = (newError, info) => {
    setError(newError)
    setInfo(info)
  }

  const resetError = () => {
    setError(null)
    setInfo(null)
  }

  return (
    <ErrorBoundary
      isApp={isApp}
      session={authStore.AppSession.session}
      error={error}
      info={info}
      onError={handleCatchError}
      onReset={resetError}
    >
      {children}
    </ErrorBoundary>
  )
}
