import React from 'react'
import ReactDOM from 'react-dom'

import ConfirmModal from 'common/Toaster/ConfirmModal'

const PORTAL_ELEMENT = 'portal'

class ConfirmManager {
  constructor() {
    ReactDOM.render(<ConfirmModal bindActions={this.bindActions} />, document.getElementById(PORTAL_ELEMENT))
  }

  bindActions = (open, close) => {
    this.openModal = open
    this.closeModal = close
  }

  open = options => {
    this.openModal && this.openModal(options)
  }

  close = () => {
    this.closeModal && this.closeModal()
  }
}

export default new ConfirmManager()
