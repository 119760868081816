import React from 'react'

import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'

import { useAuthManager } from 'hooks/useAuthManager'
import { useStores } from 'stores'
import CheckPunchoutSession from 'components/Core/CheckPunchoutSession'
import ContentLoader from 'common/ContentLoader'
import Punchout from 'components/Punchout'

import Cart from './Cart'
import CartHeader from './CartHeader'

export default observer(() => {
  const { authStore, connectionCartStore, globalMessageStore, pageResourceStore, webshopCartStore } = useStores()
  const [punchoutOptions, setPunchoutOptions] = React.useState({})
  const authManager = useAuthManager()
  const location = useLocation()

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { connections: resource, loading: pageResourceLoading },
  } = pageResourceStore
  const {
    Table: { data, loading: tableLoading },
  } = connectionCartStore

  const loading = tableLoading || pageResourceLoading
  const hasCarts = !!data && !!data.length

  React.useEffect(() => {
    pageResourceStore.get('connections')
    connectionCartStore.getCarts()
    webshopCartStore.getLookups({ clientId: session.clientId })
  }, [session, connectionCartStore, pageResourceStore, webshopCartStore, location.key])

  const reloadCart = () => {
    connectionCartStore.getCarts()
    connectionCartStore.getCartQuantity()
  }

  const handlePunchout = async payload => {
    await authManager.logoutAfterPunchout()
    setPunchoutOptions({ isShow: !!payload, ...payload })
  }

  return (
    <div key={location.key} id="connection-carts">
      <CheckPunchoutSession message={resource?.PunchoutExpired} />
      {!pageResourceLoading && !!resource && <CartHeader resource={resource} />}
      <div className="position-relative">
        <ContentLoader show={loading} className="align-items-start pt-6" />
        {(!loading || hasCarts) &&
          !!resource &&
          data.map(cart => (
            <Cart
              key={cart.id}
              connectionCartStore={connectionCartStore}
              globalMessageStore={globalMessageStore}
              cart={cart}
              resource={resource}
              reloadCart={reloadCart}
              onPunchout={handlePunchout}
            />
          ))}
      </div>
      {punchoutOptions.isShow && (
        <Punchout isShow punchoutType={punchoutOptions.punchoutType} data={punchoutOptions.punchoutData} />
      )}
    </div>
  )
})
