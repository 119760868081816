import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'stores'
import Pagination from 'common/Pagination'

export default observer(({ page, pageSize, isByPrice, onGoToPage }) => {
  const { webshopStore } = useStores()

  const {
    Table: {
      data: { items = [], total },
      loading,
      totalItemCountByPrice,
    },
  } = webshopStore

  const initialPageLoading = loading && !page
  const showFooter = !initialPageLoading && (isByPrice || items.length > 0)
  const totalCount = isByPrice ? totalItemCountByPrice : total

  return (
    showFooter && (
      <div className="d-flex justify-content-end mt-1 px-1">
        <Pagination currentPage={page || 1} pageSize={pageSize} totalItems={totalCount} onJumpToPage={onGoToPage} />
      </div>
    )
  )
})
