import BaseService from 'services/base'

export default class AccountService extends BaseService {
  constructor(api) {
    const url = 'account'
    super(api, url)
    this.api = api
    this.url = url
  }

  forgotPassword(payload) {
    return this.post('forgotPassword', payload)
  }

  changePassword(payload) {
    return this.put('password', payload, { ignoreGlobalMessage: true })
  }

  updateEmailNotification(clientId, employeeId, payload) {
    return this.put(`client/${clientId}/employee/${employeeId}/emailnotification`, payload, {
      ignoreGlobalMessage: true,
    })
  }

  updateAccountInfo(payload) {
    return this.put('updateAccountInfo', payload, { ignoreGlobalMessage: true })
  }
}
