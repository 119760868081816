import React from 'react'

import { Col } from 'reactstrap'
import { useFormState } from 'react-hook-form'

import { formatString } from 'utils/formatters'
import { validateMinimum } from 'utils/validators'
import FormGroup from 'common/FormGroup'
import Input from 'common/Forms/Input'

export default ({ resource, commonResource, defaultQuantity, disabled }) => {
  const { errors } = useFormState()

  const quantityUnitHasError = errors && (!!errors?.quantity?.amount || errors?.quantity?.unit)

  return (
    <FormGroup
      label={`${resource.QuantityUnit} *`}
      formGroup
      horizontal
      hasError={quantityUnitHasError}
      inputCol={{ md: 5 }}
      inputColClassName="d-flex"
    >
      <Col md="6" className="pe-3">
        <Input
          name="quantity.amount"
          rules={{
            required: commonResource.Number,
            validate: value => {
              return validateMinimum(value, 1, formatString(commonResource.MinValue, 1))
            },
          }}
          defaultValue={defaultQuantity?.amount}
          type="number"
          className="mx-0 mb-0"
          min="0"
          disabled={disabled}
        />
      </Col>
      <Col md="6" className="ps-3">
        <Input
          name="quantity.unit"
          rules={{ required: commonResource.Required }}
          defaultValue={defaultQuantity?.unit}
          placeholder={resource.UnitPlaceholder}
          className="mx-0 mb-0"
          disabled={disabled}
        />
      </Col>
    </FormGroup>
  )
}
