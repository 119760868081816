import React from 'react'

import { Badge } from 'reactstrap'

import { formatDate } from 'utils/formatters'
import Button from 'common/Button'

export default ({ session, resource, caseItem, submitting, onViewCase, onCopyCase }) => {
  const dateClosedFormatted = formatDate(caseItem.dateClosed, 'dddd, D MMMM YYYY, H:mm')

  const isShowCopy = () => {
    switch (caseItem.type) {
      case 'Webform':
        return true
      case 'BeforeTheFacts':
      case 'AfterTheFacts':
      case 'ContractPo':
        return session.hasPra && caseItem.hasPoLines
      default:
        return false
    }
  }

  return (
    <div className="actions col-xl-auto col ms-auto d-flex justify-content-end align-items-md-start align-items-xl-center align-items-end">
      {isShowCopy() && (
        <Button
          type="button"
          icon="content_copy"
          color="default"
          size="sm"
          disabled={submitting}
          onClick={onCopyCase}
        />
      )}
      <Button
        type="button"
        icon="visibility"
        color="default"
        size="sm"
        className="with-text"
        disabled={submitting}
        onClick={onViewCase}
      >
        <span className="d-sm-inline d-none">{resource.View}</span>
      </Button>
      <Badge color={caseItem.caseAborted ? 'danger' : 'success'} className="p-2">
        <span className="material-icons-outlined md-16 me-1 d-sm-inline-block d-none">block</span>
        <span
          className="font-weight-normal"
          title={`${caseItem.caseAborted ? 'Aborted' : 'Completed'} - ${dateClosedFormatted}`}
        >
          {dateClosedFormatted}
        </span>
      </Badge>
    </div>
  )
}
