import React from 'react'

import cx from 'classnames'

import { formatDate } from 'utils/formatters'
import Button from 'common/Button'
import DateMonthSelect from 'common/DateMonthSelect'
import DateYearSelect from 'common/DateYearSelect'

export default ({ date, years, onChange }) => {
  const [showEditMonth, setShowEditMonth] = React.useState(false)
  const [showEditYear, setShowEditYear] = React.useState(false)

  const handleOnMonthChange = newMonth => {
    const newValue = new Date(date.getFullYear(), newMonth, date.getDate())
    onChange(newValue)
  }

  const handleOnYearChange = newYear => {
    const newValue = new Date(newYear, date.getMonth(), date.getDate())
    onChange(newValue)
  }

  return (
    <div
      className={cx('DayPicker-Caption', {
        'date-select-open': showEditMonth || showEditYear,
      })}
    >
      <div className="buttons">
        {!showEditMonth && (
          <Button
            type="button"
            tabIndex="-1"
            color="text"
            className="button-month"
            onClick={() => setShowEditMonth(true)}
          >
            {formatDate(date, 'MMMM')}
          </Button>
        )}
        {showEditMonth && (
          <DateMonthSelect
            plain
            defaultValue={date.getMonth()}
            autoFocus
            defaultMenuIsOpen
            isClearable={false}
            onMenuClose={() => setShowEditMonth(false)}
            onChange={handleOnMonthChange}
          />
        )}
        {!showEditYear && (
          <Button
            type="button"
            color="text"
            tabIndex="-1"
            className="button-year"
            onClick={() => setShowEditYear(true)}
          >
            {formatDate(date, 'YYYY')}
          </Button>
        )}
        {showEditYear && (
          <DateYearSelect
            plain
            defaultValue={date?.getFullYear()}
            years={years}
            autoFocus
            defaultMenuIsOpen
            inputClassName={cx('year-picker')}
            isClearable={false}
            onMenuClose={() => setShowEditYear(false)}
            onChange={handleOnYearChange}
          />
        )}
      </div>
    </div>
  )
}
