import React from 'react'

import { useFormContext } from 'react-hook-form'

import { PRA_GET_APPROVERS_TYPE } from 'definitions'
import { toIntId } from 'utils'
import ReactSelect from 'common/Forms/ReactSelect'

export default ({ praStore, resource, index, data, approversData, approver, loggedinEmployeeId, isReadOnly }) => {
  const [approverOptions, setApproverOptions] = React.useState([])
  const { setValue } = useFormContext()

  const isShowFreeChoice = React.useMemo(
    () =>
      !isReadOnly && ((approver.id === 0 && approver.isFreeChoice) || (approver.status === 0 && approver.isFreeChoice)),
    [approver, isReadOnly]
  )

  React.useEffect(() => {
    if (isShowFreeChoice) {
      const getApproversLookup = async () => {
        const { data: result } = await praStore.getApproversLookup({
          clientId: data.clientId,
          creatorId: data.creator?.id,
          currentApproverId: null,
          praId: data.id,
          requestorId: data.creator?.id,
          type: PRA_GET_APPROVERS_TYPE.FOR_CREATOR_NPLUSX,
        })
        if (result?.approvers) {
          setApproverOptions(result.approvers)
        }
      }
      getApproversLookup()
    }
  }, [praStore, data, isShowFreeChoice])

  React.useEffect(() => {
    if (isShowFreeChoice) {
      const { freeChoiceNplusOneApprover, totalAmount } = data
      let defaultApprover

      if (freeChoiceNplusOneApprover) {
        defaultApprover = freeChoiceNplusOneApprover
      } else if (approversData?.some(item => item.isFreeChoice && item.id > 0)) {
        const tmpApprover = approversData.find(item => item.isFreeChoice === true && item.id > 0)
        if (tmpApprover.id > 0) {
          defaultApprover = tmpApprover
        }
      } else if (approverOptions?.length > 0) {
        const loggedinUserId = loggedinEmployeeId
        const approver = approversData?.find(item => item.id === loggedinUserId)

        if (approver) {
          const nplusOneId = toIntId(approver.nplusOneId)
          const nplusOne = approverOptions.find(item => item.id === nplusOneId)

          if (nplusOne) {
            // Check if nplus1 meets loa
            if (nplusOne.authorityAmount >= totalAmount) {
              defaultApprover = nplusOne
            } else {
              // if not get next from list of nplusX
              const nplusXApprovers = approverOptions
              if (nplusXApprovers.length > 0) {
                // gets the first approver with lowest LOA
                const nplusX = nplusXApprovers
                  .slice(1)
                  .sort(
                    (currentApprover, nextApprover) => currentApprover.authorityAmount - nextApprover.authorityAmount
                  )[0]
                if (nplusX) {
                  defaultApprover = nplusX
                }
              }
            }
          } else {
            const tmpApprover = approverOptions && approverOptions.length > 0 ? approverOptions[0] : {}
            defaultApprover = tmpApprover
          }
        }

        setValue(`approvers.${index}`, defaultApprover)
      }
    }
  }, [index, data, approverOptions, approversData, loggedinEmployeeId, isShowFreeChoice, setValue])

  if (isShowFreeChoice) {
    return (
      <ReactSelect
        name={`approvers.${index}`}
        rules={{ required: resource.Required }}
        options={approverOptions}
        optionValueKey="id"
        optionLabelKey="name"
      />
    )
  }

  return approver.name
}
