import React from 'react'

import { Badge, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import cx from 'classnames'

import { ROUTES } from 'definitions'
import { titlelize } from 'utils'
import { useStores } from 'stores'
import Dropdown from 'common/Dropdown'
import Loader from 'common/Loader'

import SubCategoriesPanel from './SubCategoriesPanel'
import SubCategoryGroupPanel from './SubCategoryGroupPanel'

export default observer(({ resource, pageResourceLoading }) => {
  const { authStore, webshopCategoryStore } = useStores()
  const [selectedCategory, setSelectedCategory] = React.useState()
  const [isOpen, setIsOpen] = React.useState()
  const [selectedSubcategory, setSelectedSubcategory] = React.useState()

  const {
    AppSession: { session },
  } = authStore
  const {
    CategoryTree: {
      data: { categories = [] },
      loading: categoryLoading,
    },
  } = webshopCategoryStore

  const loading = categoryLoading || pageResourceLoading

  const toggle = () => setIsOpen(prev => !prev)

  React.useEffect(() => {
    setSelectedCategory(null)
    webshopCategoryStore.fetchCategoryTree({
      languageCode: session.uiLanguageSetting.toLowerCase(),
    })
  }, [webshopCategoryStore, session])

  const handleCategoryHover = category => {
    setSelectedCategory(category)
    setSelectedSubcategory(null)
  }

  const handleSubcategoryHover = subcategory => {
    setSelectedSubcategory(subcategory)
  }

  return (
    <div className="category-menu">
      <Dropdown
        isOpen={isOpen}
        toggle={toggle}
        caret
        toggleContent={resource.AllCategories}
        toggleButtonOptions={{ color: 'primary' }}
        className="me-3 mb-md-0 mb-3"
      >
        <DropdownItem text className="p-0">
          <div className="d-flex webshop-text-color">
            <div className="">
              <h5
                className={cx(
                  'title d-flex align-items-center fw-bold fs-18 px-5 py-3 mb-0 ',
                  !loading && 'border-bottom'
                )}
              >
                <span className="me-4">{resource.Categories}</span>
                <Loader show={loading} size="sm" className="p-0" />
                {!loading && (
                  <Badge color="primary" className="rounded-pill">
                    {categories.length}
                  </Badge>
                )}
              </h5>
              {!loading && (
                <div className="py-5 px-4">
                  {categories.length > 0
                    ? categories.map(item => {
                        return (
                          <Link
                            key={item.id}
                            to={`${ROUTES.WEBSHOP_CATALOG}/${item.categoryPath}`}
                            className={cx(
                              'btn btn-text btn-text-default button-category w-100 py-1 white-space-nowrap',
                              selectedCategory?.id === item.id && 'active'
                            )}
                            onMouseEnter={() => handleCategoryHover(item)}
                            onClick={() => setIsOpen(false)}
                          >
                            {titlelize(item.name)}
                          </Link>
                        )
                      })
                    : resource.NoResults}
                </div>
              )}
            </div>
            {!loading && selectedCategory && selectedCategory.subCategories && (
              <SubCategoriesPanel
                resource={resource}
                subCategories={selectedCategory.subCategories}
                onClose={() => setIsOpen(false)}
                selectedSubcategory={selectedSubcategory}
                handleSubcategoryHover={subcategory => handleSubcategoryHover(subcategory)}
              />
            )}
            {!loading && selectedSubcategory && !!selectedSubcategory?.subCategories && (
              <SubCategoryGroupPanel
                subCategories={selectedSubcategory.subCategories}
                onClose={() => setIsOpen(false)}
              />
            )}
          </div>
        </DropdownItem>
      </Dropdown>
    </div>
  )
})
