import React from 'react'

import { Badge } from 'reactstrap'
import { Link } from 'react-router-dom'

export default ({ attribute, searchUrl, onClose }) => {
  const title = attribute.displayName || attribute.attributeName

  if (attribute.values.length > 0) {
    const items = attribute.values.slice(0, 10)

    return (
      <div className="d-flex flex-column mb-2">
        <Badge color="primary" className="d-flex rounded fs-16">
          {title}
        </Badge>
        <div className="px-3 py-1">
          {items.map((item, index) => {
            const url = `${searchUrl}&${attribute.attributeName}=${item.value.replace('&', '%26')}`

            return (
              <Link key={`${title}${index}`} to={url} className="text-link" onClick={onClose}>
                <div className="fs-11 fw-semibold">
                  {item.value}
                  <span> ({item.total})</span>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
  return false
}
