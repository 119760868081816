import React from 'react'

import CheckClientHolding from './CheckClientHolding'
import CheckLogout from './CheckLogout'
import CheckNewPunchoutEmployee from './CheckNewPunchoutEmployee'

export default () => {
  return (
    <React.Fragment>
      <CheckClientHolding />
      <CheckLogout />
      <CheckNewPunchoutEmployee />
    </React.Fragment>
  )
}
