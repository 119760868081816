import React from 'react'

import { Badge } from 'reactstrap'

import Button from 'common/Button'

const ABORT_BY_CONTROLLER = 'Abort, cancelled by controller'
const ABORT_INTERNALLY = 'Abort, request to be treated internally'

export default ({ resource, status, onEditSubject }) => {
  const caseAbortedStatus = React.useMemo(() => {
    if (status.caseAbortedStatus) {
      // eslint-disable-next-line default-case
      switch (status.caseAbortedStatus.toLowerCase()) {
        case 'aborted cancelled case':
          return resource.AbortedCancelledCase
        case 'aborted double case':
          return resource.AbortedDoubleCase
        case 'aborted test case':
          return resource.AbortedTestCase
        case 'aborted managed by customer':
          return resource.AbortedManagedByCustomer
      }
    }
    return status.caseAbortedStatus
  }, [resource, status])

  if (status.caseOpen)
    return (
      <Button icon="edit" color="default" size="sm" className="text-uppercase btn-edit-subject" onClick={onEditSubject}>
        {resource.EditButton}
      </Button>
    )

  if (!status.caseAborted)
    return (
      <Badge color="success" className="me-2">
        {resource.Completed}
      </Badge>
    )

  return (
    <React.Fragment>
      {caseAbortedStatus === ABORT_BY_CONTROLLER && (
        <Badge color="danger" className="me-2">
          {resource.AbortCancelledByController}
        </Badge>
      )}
      {caseAbortedStatus === ABORT_INTERNALLY && (
        <Badge color="danger" className="me-2">
          {resource.AbortTreatedInternally}
        </Badge>
      )}
      {![ABORT_BY_CONTROLLER, ABORT_INTERNALLY].includes(caseAbortedStatus) && (
        <Badge color="danger" className="me-2">
          {caseAbortedStatus}
        </Badge>
      )}
    </React.Fragment>
  )
}
