import React from 'react'

import { ListGroup } from 'reactstrap'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from 'definitions'
import { useDebounce } from 'hooks/useDebounce'
import { useStores } from 'stores'
import Button from 'common/Button'
import ContentLoader from 'common/ContentLoader'
import LayoutTitle from 'common/LayoutTitle'
import Loader from 'common/Loader'
import Toaster from 'common/Toaster'

import AbortCaseModal from './AbortCaseModal'
import CaseAlerts from './CaseAlerts'
import CaseFilters from './CaseFilters'
import CaseItem from './CaseItem'
import Punchout from 'components/Punchout'

export default observer(() => {
  const { authStore, caseStore, clientCaseStore, pageResourceStore } = useStores()
  const navigate = useNavigate()
  const filtersRef = React.useRef({})
  const pageRef = React.useRef(0)
  const [data, setData] = React.useState([])
  const debounceLoadMoreData = useDebounce(() => {
    pageRef.current += 1
    handleLoadData(pageRef.current)
  })
  const [aborCaseOptions, setAborCaseOptions] = React.useState({})
  const [punchoutOptions, setPunchoutOptions] = React.useState({})

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { loading: pageResourceLoading, case: resource = {}, common: commonResource },
  } = pageResourceStore
  const {
    Table: { loading },
  } = caseStore
  const pageLoading = loading || pageResourceLoading

  React.useEffect(() => {
    pageResourceStore.get('case')
  }, [pageResourceStore])

  React.useEffect(() => {
    const checkScroll = () => {
      // Check if there's more products to load when scrolling more than 70%
      if (caseStore.Table.data.hasMorePages) {
        const wrapper = window.document.documentElement
        if (wrapper.scrollTop + wrapper.clientHeight >= Math.ceil(0.95 * wrapper.scrollHeight)) {
          debounceLoadMoreData()
        }
      }
    }
    window.document.addEventListener('scroll', checkScroll, false)
    return () => {
      window.document.removeEventListener('scroll', checkScroll, false)
    }
  }, [caseStore])

  const handleFilterChange = values => {
    filtersRef.current = values
    handleLoadData()
  }

  const handleLoadData = async page => {
    const { type, ...rest } = filtersRef.current
    const result = await caseStore.fetchTable({
      ...rest,
      ...(type ? { type: type.value } : {}),
      client: session.clientId,
      page: page || 0,
    })

    if (result) {
      if (page > 0) {
        setData(prevData => [...prevData, ...result.cases])
      } else {
        pageRef.current = 0
        setData([...result.cases])
      }
    } else {
      setData([])
    }
  }

  const toggleAbortCaseModal = payload => {
    setAborCaseOptions({ isOpen: !!payload, data: payload })
  }

  const handlePunchout = payload => {
    setPunchoutOptions({ isShow: true, ...payload })
  }

  const handleCreateClientCase = () => {
    Toaster.confirm({
      title: resource.CreateDiy,
      message: resource.CreateDiyPrompt,
      submitText: resource.Yes,
      cancelText: resource.No,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const result = await clientCaseStore.post()
        closeModal()
        if (result) {
          navigate(`${ROUTES.CASES}/${result.caseId}`)
        }
      },
    })
  }

  return (
    <div id="cases">
      <CaseAlerts />
      <LayoutTitle
        title={resource.MyCases}
        actionClassName="ms-4"
        actions={
          resource.CreateDiy &&
          session.enableSelfService &&
          (session.hasPunchoutSession || session.hasPra) && (
            <Button color="purple" icon="add" onClick={handleCreateClientCase} className="btn-diy">
              {resource.CreateDiy}
            </Button>
          )
        }
      />
      <CaseFilters onChange={handleFilterChange} />
      {!pageLoading && !data.length && <p className="fs-italic fs-5 m-2">{resource.NoCasesFound}</p>}
      <div className="position-relative">
        <ContentLoader
          show={pageLoading && pageRef.current === 0}
          size="lg"
          className="p-3 justify-content-center align-items-start"
        />
        <ListGroup className="cases-list list-hover">
          {data.map(caseItem => {
            return (
              <CaseItem
                key={caseItem.id}
                session={session}
                caseStore={caseStore}
                resource={resource}
                caseItem={caseItem}
                onAbortCase={toggleAbortCaseModal}
                onReload={handleLoadData}
                onPunchout={handlePunchout}
              />
            )
          })}
        </ListGroup>
        <Loader
          show={pageLoading && pageRef.current > 0}
          text={commonResource.Loading}
          className="justify-content-center my-4"
        />
      </div>
      {aborCaseOptions.isOpen && (
        <AbortCaseModal
          isOpen
          data={aborCaseOptions.data}
          onSuccess={handleLoadData}
          onClose={() => toggleAbortCaseModal()}
        />
      )}
      {punchoutOptions.isShow && <Punchout {...punchoutOptions} />}
    </div>
  )
})
