import React from 'react'

// import { formatFileUrl } from 'utils/formatters'
import Card from 'common/Card'

import PraSupplierInfo from 'components/Pra/PraSupplierInfo'
import PraViewProducts from './PraViewProducts'

export default ({ resource, pra }) => {
  const { supplier } = pra
  return (
    <Card fieldset title={resource.GeneralInformation}>
      <div className="d-flex flex-lg-row flex-column-reverse mb-4">
        {supplier && (
          <PraSupplierInfo
            supplier={{
              ...supplier,
              address: {
                street: supplier.address,
                postalCode: supplier.postalCode,
                city: supplier.city,
                country: { name: supplier.country },
              },
            }}
            resource={resource}
            className="col-lg-12"
          />
        )}
        {/* <div className="col-lg-4 col text-center mb-4">
          {pra.clientLogo && (
            <img
              alt={`Client Logo - ${pra.clientLogo}`}
              className="img-responsive img-thumbnail fadein"
              style={{ maxHeight: '200px' }}
              src={formatFileUrl(pra.clientLogo)}
            />
          )}
        </div> */}
      </div>
      <div className="mb-4 fw-bold">
        {resource.ProjectCode}: {pra.projectCode}
      </div>
      <PraViewProducts resource={resource} pra={pra} />
    </Card>
  )
}
