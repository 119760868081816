import React from 'react'

import DynamicSearch from 'common/DynamicSearch'
import PraFilterActions from './PraFilterActions'

export default ({ session, resource, onChange }) => {
  const filtersRef = React.useRef({
    search: {
      approver: session.loggedinEmployeeName,
    },
    actions: {
      isArchived: false,
      sort: 2,
    },
  })

  React.useEffect(() => {
    triggerChange()
  }, [])

  const triggerChange = () => {
    onChange({ ...filtersRef.current.search, ...filtersRef.current.actions })
  }

  const handleSearchFilterChange = values => {
    filtersRef.current.search = values
    triggerChange()
  }

  const handleActionFilterChange = values => {
    filtersRef.current.actions = values
    triggerChange()
  }

  return (
    <div className="d-flex flex-lg-row flex-column mb-lg-7 mb-5">
      <DynamicSearch
        resource={resource}
        defaultFilters={filtersRef.current.search}
        sortKey="sort"
        options={{
          approver: { label: resource.Approver, sort: 0 },
          status: {
            label: resource.Status,
            type: 'select',
            data: [
              { value: 'approved', label: resource.Approved },
              { value: 'rejected', label: resource.Rejected },
              { value: 'pending', label: resource.Pending },
              { value: 'unstarted', label: resource.OnHold },
              { value: 'aborted', label: resource.Aborted },
            ],
            sort: 1,
          },
          reference: { label: resource.PRReference, sort: 2 },
          description: { label: resource.Description, sort: 3 },
          creator: { label: resource.Creator, sort: 4 },
          requester: { label: resource.Requester, sort: 5 },
          costCenter: { label: resource.CostCenter, sort: 6 },
          supplier: { label: resource.Supplier, sort: 7 },
          value: { label: resource.Value, sort: 8 },
          poReference: { label: resource.POReference, sort: 9 },
          projectCode: { label: resource.ProjectCode, hidden: !session.isProjectCodeEnabled, sort: 10 },
          category: { label: resource.Category, sort: session.isProjectCodeEnabled ? 11 : 10 },
          jobCode: { label: resource.JobCode, hidden: !session.enableJobCode, sort: 12 },
          expenseCode: { label: resource.ExpenseCode, hidden: !session.enableExpenseCode, sort: 13 },
        }}
        className="col"
        onChange={handleSearchFilterChange}
      />
      <PraFilterActions
        resource={resource}
        defaultFilters={filtersRef.current.actions}
        onChange={handleActionFilterChange}
      />
    </div>
  )
}
