import { range } from 'utils/array'

export const getValueByPath = (value, path, defaultValue) =>
  value &&
  String(path)
    .split('.')
    .reduce((item, key) => {
      try {
        item = item[key] || defaultValue
      } catch (ex) {
        return defaultValue
      }
      return item
    }, value)

export const prettyPrintJson = value => {
  return value && JSON.stringify(JSON.parse(JSON.stringify(value)), null, 2)
}

export const getRandomUniqueNumber = () => new Date().valueOf()

export const isNull = (value, defaultValue) => (value === undefined || value === null ? defaultValue || value : value)

export const stringToInt = value =>
  value !== '' && (typeof value === 'string' || value instanceof String) ? parseInt(value) : value

export const padLeft = (value, number) =>
  `${range(0, number)
    .map(() => 0)
    .join('')}${value}`

export const replaceUrl = (url, state = {}) => window.history.replaceState(state, '', url)

export const toIntId = stringId => {
  if (stringId && stringId.includes('/')) {
    const tokens = stringId.split('/')
    if (tokens.length > 1) {
      return parseInt(tokens[1])
    }
  }
  return null
}

export const titlelize = (value, delimeter = '-') =>
  value &&
  value
    .split(delimeter)
    .map(word => [word[0].toUpperCase(), word.slice(1).toLowerCase()].join(''))
    .join(delimeter)
