import React from 'react'

import { Badge } from 'reactstrap'

import { formatDate } from 'utils/formatters'
import Button from 'common/Button'
import Icon from 'common/Icon'
import PdfLink from 'common/PdfLink'

import { TableListStatus, TableListStatusItem } from 'components/Case/CaseDetail/TableList'

export default ({
  resource,
  session,
  quotation,
  onSendRfq,
  onCreateQuote,
  onSendQuoteToErp,
  onSendQuoteToApproval,
}) => {
  const { quote, rfq, status, supplier } = quotation

  const handleAddQuote = () => onCreateQuote({ ...rfq, supplier })

  return (
    <TableListStatus>
      <TableListStatusItem>
        <div className="d-flex align-items-center">
          <Badge color={status.rfqSent ? 'success' : 'danger'}>
            <Icon icon={status.rfqSent ? 'done' : 'clear'} className="md-12" />
          </Badge>
          <span className="d-flex ps-2 me-1">
            {rfq.isDummy && resource.DirectOrderFinal}
            {!rfq.isDummy && resource.RfqSent}
          </span>
          {rfq.pdfFileId && (
            <PdfLink fileId={rfq.pdfFileId} fileName={rfq.documentNumber} title={resource.DownloadRfqPdf} />
          )}
          {rfq.flagLogoFileId && (
            <img
              src={`${process.env.REACT_APP_API_URL}/file/${rfq.flagLogoFileId}`}
              className="entity-flag"
              alt="logo"
            />
          )}
        </div>
        {status.rfqSent && (
          <small className="text-muted">
            <em>{formatDate(status.dateRfqSent, 'DD MMMM YYYY')}</em>
          </small>
        )}
        {!status.rfqSent && (
          <Button color="primary" size="sm" className="text-lowercase" onClick={() => onSendRfq(rfq)}>
            {rfq.isDummy && resource.Finalize}
            {!rfq.isDummy && resource.SendNow}
          </Button>
        )}
      </TableListStatusItem>
      <TableListStatusItem>
        <div className="d-flex align-items-center">
          <Badge color={status.quoteReceived ? 'success' : 'danger'}>
            <Icon icon={status.quoteReceived ? 'done' : 'clear'} className="md-12" />
          </Badge>
          <span className="d-flex ps-2 me-1">{resource.QuoteReceived}</span>
        </div>
        {status.quoteReceived && (
          <small className="text-muted">
            <em>{formatDate(status.dateQuoteReceived, 'DD MMMM YYYY')}</em>
          </small>
        )}
        {!status.quoteReceived && status.rfqSent && (
          <Button color="primary" size="sm" className="text-lowercase" onClick={handleAddQuote}>
            {resource.Add}
          </Button>
        )}
      </TableListStatusItem>
      <TableListStatusItem>
        <div className="d-flex align-items-center">
          <Badge color={status.quoteReceived && status.quoteSent ? 'success' : 'danger'}>
            <Icon icon={status.quoteReceived && status.quoteSent ? 'done' : 'clear'} className="md-12" />
          </Badge>
          <span className="d-flex ps-2 me-1">
            {session.hasPunchoutSession && resource.SendQuoteToErp}
            {!session.hasPunchoutSession && session.hasPra && resource.SendQuoteToApproval}
          </span>
        </div>
        {status.quoteReceived && status.quoteSent && (
          <small className="text-muted">
            <em>{formatDate(status.dateQuoteSent, 'DD MMMM YYYY')}</em>
          </small>
        )}
        {status.quoteReceived && !status.quoteSent && (
          <Button
            color="primary"
            size="sm"
            className="text-lowercase"
            onClick={() => {
              if (session.hasPunchoutSession) {
                onSendQuoteToErp(quote)
              } else if (session.hasPra) {
                onSendQuoteToApproval(quote)
              }
            }}
          >
            {resource.Send}
          </Button>
        )}
      </TableListStatusItem>
    </TableListStatus>
  )
}
