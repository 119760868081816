import React from 'react'

import { useNavigate } from 'react-router-dom'

import { CLIENTS, ROUTES } from 'definitions'
import { useAuthManager } from 'hooks/useAuthManager'
import { useStores } from 'stores'
import Toaster from 'common/Toaster'

import PrViewModal from 'components/Case/CaseDetail/PurchaseRequest/PrViewModal'
import Punchout from 'components/Punchout'
import Quotations from './Quotations'
import QuoteFormModal from './QuoteFormModal'
import QuoteViewModal from './QuoteViewModal'
import RfqFormModal from './RfqFormModal'
import RfqViewModal from './RfqViewModal'
import SourceTable from './SourceTable'

export default ({ activated, caseId, caseNumber }) => {
  const { authStore, globalMessageStore, quoteStore, sourceStore, pageResourceStore } = useStores()
  const [prViewModalOption, setPrViewModalOption] = React.useState({})
  const [rfqFormModalOption, setRfqFormModalOption] = React.useState({})
  const [rfqViewModalOption, setRfqViewModalOption] = React.useState({})
  const [quoteFormModalOption, setQuoteFormModalOption] = React.useState({})
  const [quoteViewModalOption, setQuoteViewModalOption] = React.useState({})
  const [punchoutOptions, setPunchoutOptions] = React.useState({})
  const navigate = useNavigate()
  const authManager = useAuthManager()

  const { AppSession: { session } = {} } = authStore
  const {
    PageResource: { case: resource = {}, common: commonResource = {} },
  } = pageResourceStore

  const loadSource = caseId => sourceStore.fetchTable(caseId)

  React.useEffect(() => activated && loadSource(caseId), [caseId, activated])

  const handlePrViewModal = prLine => setPrViewModalOption({ isOpen: !!prLine, prLine })

  const handleCreateRfq = rfq => setRfqFormModalOption({ isOpen: !!rfq, rfq })

  const handleEditRfq = rfq => setRfqFormModalOption({ isOpen: !!rfq, rfq, isEdit: true })

  const handleViewRfq = (rfq, supplier) => setRfqViewModalOption({ isOpen: !!rfq, rfq: { ...rfq, supplier } })

  const handleDeleteRfq = rfq => {
    Toaster.confirm({
      title: resource.RemoveRfq,
      message: `${resource.RemoveRfqQuestion} #${rfq.documentIndex}`,
      submitText: commonResource.Confirm,
      cancelText: commonResource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const { error } = await sourceStore.delete({
          caseId,
          id: rfq.id,
        })
        closeModal()
        !error && loadSource(caseId)
      },
    })
  }

  const handleSendRfq = rfq => {
    Toaster.confirm({
      title: resource.SendRfq,
      message: resource.SendRfqQuestion,
      submitText: commonResource.Confirm,
      cancelText: commonResource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        await sourceStore.sendRfq({
          caseId,
          id: rfq.id,
        })
        closeModal()
        loadSource(caseId)
      },
    })
  }

  const handleCreateQuote = quote => setQuoteFormModalOption({ isOpen: !!quote, quote })

  const handleEditQuote = quote => setQuoteFormModalOption({ isOpen: !!quote, quote, isEdit: true })

  const handleViewQuote = quote => setQuoteViewModalOption({ isOpen: !!quote, quote })

  const handleDeleteQuote = quote => {
    Toaster.confirm({
      title: resource.RemoveQuote,
      message: `${resource.RemoveQuoteQuestion} #${quote.documentIndex}`,
      submitText: commonResource.Confirm,
      cancelText: commonResource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const { error } = await quoteStore.delete({
          caseId,
          id: quote.id,
        })
        closeModal()
        !error && loadSource(caseId)
      },
    })
  }

  const handleSendQuoteToApproval = quote => {
    Toaster.confirm({
      title: resource.SendQuoteToApproval,
      message: resource.SendQuoteToApprovalQuestion,
      submitText: commonResource.Confirm,
      cancelText: commonResource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const { data, error } = await quoteStore.sendToApproval({
          caseId,
          id: quote.id,
        })
        closeModal()
        if (error) {
          loadSource(caseId)
        } else {
          navigate(`${ROUTES.PRA}?key=${data.praKey}`)
        }
      },
    })
  }

  const handleSendQuoteToErp = quote => {
    Toaster.confirm({
      title: resource.SendQuoteToErp,
      message: resource.SendQuoteToErpQuestion,
      submitText: commonResource.Confirm,
      cancelText: commonResource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const { data, error } = await quoteStore.sendToErp({
          caseId,
          id: quote.id,
        })
        closeModal()
        loadSource(caseId)
        if (!error) {
          if (session.clientAbbreviation === CLIENTS.TLG) {
            globalMessageStore.show({
              title: resource.ProposalSelected,
              message: resource.ProposalSelectedSuccessfully,
              color: 'success',
            })
          } else {
            const { error: logOutError } = await authManager.logoutAfterPunchout()
            if (!logOutError) {
              setPunchoutOptions({ isShow: true, punchoutType: session.punchoutType, data: data.punchoutData })
            }
          }
        }
      },
    })
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <SourceTable
          sourceStore={sourceStore}
          resource={resource}
          onViewPrLine={handlePrViewModal}
          onCreateRfq={handleCreateRfq}
        />
        <hr className="triangle" />
        {prViewModalOption.isOpen && (
          <PrViewModal
            resource={resource}
            commonResource={commonResource}
            caseNumber={caseNumber}
            settings={{
              shouldSelectCategory: false,
              enableGLAccount: false,
            }}
            prLine={prViewModalOption.prLine}
            onClose={() => handlePrViewModal()}
          />
        )}

        <Quotations
          onEditRfq={handleEditRfq}
          onViewRfq={handleViewRfq}
          onDeleteRfq={handleDeleteRfq}
          onSendRfq={handleSendRfq}
          onViewQuote={handleViewQuote}
          onCreateQuote={handleCreateQuote}
          onEditQuote={handleEditQuote}
          onDeleteQuote={handleDeleteQuote}
          onSendQuoteToErp={handleSendQuoteToErp}
          onSendQuoteToApproval={handleSendQuoteToApproval}
        />

        {rfqViewModalOption.isOpen && (
          <RfqViewModal
            resource={resource}
            commonResource={commonResource}
            rfq={rfqViewModalOption.rfq}
            onClose={() => handleViewRfq()}
          />
        )}

        {rfqFormModalOption.isOpen && (
          <RfqFormModal
            resource={resource}
            commonResource={commonResource}
            caseId={caseId}
            rfq={rfqFormModalOption.rfq}
            isEdit={rfqFormModalOption.isEdit}
            onReloadSource={loadSource}
            onClose={() => handleCreateRfq()}
          />
        )}

        {quoteFormModalOption.isOpen && (
          <QuoteFormModal
            resource={resource}
            commonResource={commonResource}
            caseId={caseId}
            quote={quoteFormModalOption.quote}
            isEdit={quoteFormModalOption.isEdit}
            onReloadSource={() => loadSource(caseId)}
            onClose={() => handleEditQuote()}
          />
        )}

        {quoteViewModalOption.isOpen && (
          <QuoteViewModal
            resource={resource}
            commonResource={commonResource}
            quote={quoteViewModalOption.quote}
            onClose={() => handleViewQuote()}
          />
        )}

        {punchoutOptions.isShow && <Punchout {...punchoutOptions} />}
      </div>
    </div>
  )
}
