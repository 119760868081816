import { makeObservable, observable } from 'mobx'

import { ADDRESS_TYPE, DELIVER_TO } from 'definitions'
import { parseDate } from 'utils/formatters'
import BaseStore, { defaults } from 'stores/base'

export default class WebshopStore extends BaseStore {
  CartQuantity = 0
  CartReady = false
  Lookups = { ...defaults.CRUD, loaded: false }
  Table = { ...defaults.Table, data: [], loading: true }

  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options

    makeObservable(this, {
      CartQuantity: observable,
      CartReady: observable,
      Lookups: observable,
    })
  }

  addItemToCart({ item, pricing, quantity, remarks }) {
    const subTotal = quantity * pricing.amount
    const subTotalVatIncluded = quantity * pricing.amountVatIncluded

    const payload = {
      serviceContract: item.serviceContract || '',
      supplierCode: item.supplierCode,
      itemId: item.itemId,

      quantity,
      baseAmount: pricing.baseAmount,
      amount: pricing.amount,
      amountVatIncluded: pricing.amountVatIncluded,
      subTotal,
      subTotalVatIncluded,

      vatRate: pricing.vatRate,
      eoVatCode: pricing.eoVatCode,

      discountPercentage: pricing.discountPercentage,
      feePercentage: pricing.feePercentage,
      feeAmount: pricing.feeAmount,
      remarks,
    }

    return this.request(this.CRUD, () => this.service.addItemToCart(payload))
  }

  async calculateCartQuantity() {
    const { data, error } = await this.service.calculateCartQuantity()
    const success = data && !error
    if (success) {
      this.CartQuantity = data.quantity
    }
    return { data, error }
  }

  checkout(cartId) {
    this.CRUD.cartId = cartId
    this.CRUD.action = 'checkout'
    return this.request(this.CRUD, () => this.service.checkout(cartId))
  }

  enableMultiAddress(payload) {
    this.CRUD.cartId = payload.cartId
    this.CRUD.action = 'multi-address'
    return this.request(this.CRUD, () => this.service.enableMultiAddress(payload))
  }

  copyCart(payload) {
    this.CRUD.cartId = payload.cartId
    this.CRUD.action = 'copycart'
    return this.request(this.CRUD, () => this.service.copyCart(payload))
  }

  fetchTable(payload) {
    return this.getRequest(
      this.Table,
      () => this.service.fetchTable(payload),
      newData => {
        const { carts, ...rest } = newData

        this.Table.settings = rest
        if (rest.success) {
          this.Table.data = carts.map(cart => {
            const {
              address,
              companySite,
              deliverTo,
              useOtherAddress,
              preferredDate,
              multiAddressFileId,
              multiAddressFileName,
              ...restCart
            } = cart

            return {
              ...restCart,
              multiAddressAttachment: {
                attachments:
                  cart.isMultiAddress && cart.multiAddressFileId
                    ? [
                        {
                          fileId: cart.multiAddressFileId,
                          fileName: cart.multiAddressFileName,
                        },
                      ]
                    : [],
              },
              deliveryInfo: {
                address,
                clientAddress: companySite,
                addressType: useOtherAddress ? ADDRESS_TYPE.OTHER : ADDRESS_TYPE.CLIENT,
                preferredDate: preferredDate ? parseDate(preferredDate) : null,
                deliverTo: deliverTo && {
                  type: deliverTo.isOtherPerson ? DELIVER_TO.OTHER : DELIVER_TO.EMPLOYEE,
                  clientRequester: deliverTo.clientRequesterId
                    ? {
                        key: deliverTo.clientRequesterId,
                        value: deliverTo.displayName,
                      }
                    : null,
                  personName: deliverTo.personName,
                },
              },
              items: cart.items.map(cartItem => {
                return {
                  ...cartItem,
                  category: !!cartItem.categoryCode &&
                    !!cartItem.categoryName && {
                      code: cartItem.categoryCode,
                      name: cartItem.categoryName,
                    },
                  subCategory: cartItem.subCategory && { id: 0, name: cartItem.subCategory },
                }
              }),
            }
          })
        }
      }
    )
  }

  async getLookups(payload) {
    if (!this.Lookups.loaded) {
      return await this.getRequest(
        this.Lookups,
        () => this.service.getLookups(payload),
        newData => {
          this.Lookups.data = newData
          this.Lookups.loaded = true
        }
      )
    }
    return {}
  }

  async getClientSites(payload) {
    const { data, error } = await this.service.getClientSites(payload)
    return { data: data ? data.sites : [], error }
  }

  async getClientEmployees(payload) {
    const { data, error } = await this.service.getClientEmployees(payload)
    return { data: data ? data.employees : [], error }
  }

  async getPunchoutData() {
    const { data, error } = await this.service.getPunchoutData()
    const success = data && !error
    return success && data
  }

  removeCartItem(payload) {
    return this.getRequest(this.CRUD, () => this.service.removeCartItem(payload))
  }

  async updateCart({ cart, values, calculate, calculateDeliveryDate }) {
    const { deliveryInfo, items } = values
    const { addressType, clientAddress, address, deliverTo = {}, preferredDate } = deliveryInfo || {}

    const isOtherAddress = addressType === ADDRESS_TYPE.OTHER
    const otherAddress = address || cart.deliveryInfo?.address
    const [multiAddressAttachment] = values.multiAddressAttachment?.added || []
    const [multiAddressAttachmentDeletedFileId] = values.multiAddressAttachment?.deleted || []

    const payload = {
      calculate,
      calculateDeliveryDate,
      cart: {
        id: cart.id,
        clientId: cart.clientId,
        serviceContract: cart.serviceContract,

        preferredDate,
        companySiteId: clientAddress?.clientSiteId,
        useOtherAddress: isOtherAddress,
        deliverTo: {
          isOtherPerson: deliverTo.type === DELIVER_TO.OTHER,
          clientRequesterId: deliverTo.clientRequester?.key,
          displayName: deliverTo.clientRequester?.value,
          personName: deliverTo.personName,
        },
        address: isOtherAddress ? otherAddress : clientAddress?.address,

        adminCost: cart.adminCost,
        total: values.total,
        totalVatIncluded: cart.totalVatIncluded,
        shipping: cart.shipping,
        shippingVatIncluded: cart.shippingVatIncluded,
        feeAmount: cart.feeAmount,
        feeAmountVatIncluded: cart.feeAmountVatIncluded,
        grandTotal: cart.grandTotal,
        grandTotalVatIncluded: cart.grandTotalVatIncluded,

        items: items.map(item => {
          const {
            category,
            subCategory,
            glAccount,
            imageUrl,
            pricingTier,
            pricingTiers,
            suggestedCategories,
            ...cartItem
          } = item

          return {
            ...cartItem,
            pricingTier: pricingTier && {
              shippingAmount: pricingTier.shippingAmount,
              shippingAmountVatIncluded: pricingTier.shippingAmountVatIncluded,
              shippingVatRate: pricingTier.shippingVatRate,
              shippingEoVatCode: pricingTier.shippingEoVatCode,
              shippingVatCode: pricingTier.shippingVatCode,
            },
            categoryCode: category?.code,
            subCategory: subCategory?.name,
            glAccount: glAccount && {
              id: glAccount.id,
              glAccount: glAccount.glAccount,
            },
          }
        }),

        multiAddressCount: values.isMultiAddress ? values.multiAddressCount || 1 : 1,
        isMultiAddress: values.isMultiAddress,
        uploadMultiAddressFileId: multiAddressAttachment?.fileId,
        deleteMultiAddressFileId: multiAddressAttachmentDeletedFileId,
        projectCode: values.projectCode,
      },
    }

    this.CRUD.cartId = cart.id
    this.CRUD.action = 'save'
    const result = await this.request(this.CRUD, () => this.service.updateCart(payload))
    return result?.success && result.cart
  }
}
