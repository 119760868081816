import React from 'react'

import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'

import ButtonIcon from 'common/ButtonIcon'

export default ({
  children,
  isOpen,
  title,
  toggle,
  className,
  containerClassName,
  headerClassName,
  bodyClassName,
  hideClose,
  onClose,
  ...rest
}) => {
  return (
    <Popover {...rest} isOpen={isOpen} toggle={toggle} className={containerClassName} popperClassName={className}>
      <PopoverHeader className={headerClassName}>
        <span className="title">{title}</span>
        {!hideClose && <ButtonIcon icon="close" className="p-1" iconClassName="md-18" onClick={onClose || toggle} />}
      </PopoverHeader>
      <PopoverBody className={bodyClassName}>{children}</PopoverBody>
    </Popover>
  )
}
