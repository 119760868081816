import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'stores'
import CaseFilterActions from './CaseFilterActions'
import DynamicSearch from 'common/DynamicSearch'

export default observer(({ onChange }) => {
  const { authStore, caseStore, pageResourceStore } = useStores()
  const filtersRef = React.useRef({
    search: {
      requester: authStore.AppSession.session.loggedinEmployeeName,
    },
    actions: {
      isClosed: false,
      sortOrder: 'action',
    },
  })

  const {
    PageResource: { case: resource = {} },
  } = pageResourceStore
  const {
    Table: {
      data: { casesSearchCount, allCasesCount },
    },
  } = caseStore

  React.useEffect(() => {
    triggerChange()
  }, [])

  const triggerChange = () => {
    onChange({ ...filtersRef.current.search, ...filtersRef.current.actions })
  }

  const handleSearchFilterChange = values => {
    filtersRef.current.search = values
    triggerChange()
  }

  const handleActionFilterChange = values => {
    filtersRef.current.actions = values
    triggerChange()
  }

  return (
    <div className="d-flex flex-lg-row flex-column mb-lg-7 mb-5">
      <div className="col">
        <div className="case-stats text-end">
          <small>
            <span className="text-muted">{resource.Found} </span>
            {casesSearchCount}
            <span className="text-muted"> {resource.Of} </span>
            {allCasesCount}
            <span className="text-muted text-lowercase"> {resource.OpenCases}</span>
          </small>
        </div>
        <DynamicSearch
          resource={resource}
          defaultFilters={filtersRef.current.search}
          options={{
            type: {
              label: resource.CaseType,
              type: 'select',
              data: [
                { value: 'Webform', label: 'Webform' },
                { value: 'Webshop', label: 'Webshop' },
                { value: 'ContractPo', label: 'Contract PO' },
                { value: 'SelfService', label: 'DIY' },
                { value: 'PO', label: 'PO' },
                { value: 'Connection', label: 'Connections' },
              ],
            },
            caseNumber: { label: resource.CaseNumber },
            requester: { label: resource.Requester },
            po: { label: resource.POReference },
            subject: { label: resource.Subject },
            prReference: { label: resource.PRReference },
            projectCode: { label: resource.ProjectCode },
          }}
          onChange={handleSearchFilterChange}
        />
      </div>
      <CaseFilterActions
        resource={resource}
        defaultFilters={filtersRef.current.actions}
        onChange={handleActionFilterChange}
      />
    </div>
  )
})
