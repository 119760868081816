import BaseService from 'services/base'

import { formatString } from 'utils/formatters'

export default class ClientAreaEmployeeService extends BaseService {
  constructor(api) {
    const url = 'clientArea/client/{0}/employee'
    super(api, url)
    this.api = api
    this.url = url
  }

  saveClientEmployee(clientId, payload, options) {
    return this.api.post(formatString(this.url, clientId), payload, options)
  }

  searchClientEmployee(clientId, payload, options) {
    return this.api.get(`clientarea/${clientId}/employee/search`, payload, options)
  }
}
