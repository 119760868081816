import React from 'react'

import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'

import { useStores } from 'stores'
import Button from 'common/Button'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'

import AccountInfo from './AccountInfoPanel'
import ChangePasswordPanel from './ChangePasswordPanel'
import EmailNotificationPanel from './EmailNotificationPanel'
import OutOfOfficePanel from './OutOfOfficePanel'

export default observer(({ isOpen, onClose }) => {
  const { accountStore, authStore, globalMessageStore, pageResourceStore, praStore } = useStores()
  const methods = useForm()

  const { handleSubmit, setValue } = methods
  const {
    AppSession: { session },
  } = authStore
  const {
    CRUD: { error, submitting },
  } = accountStore
  const {
    PageResource: { account: resource = {}, common: commonResource = {} },
  } = pageResourceStore

  const enableOutOfOffice = session.hasPra && session.enableOutOfOffice

  React.useEffect(() => {
    // Workaround since uncontrolled disabled checkbox is buggy in react-hook-form
    const { emailNotification } = session
    setValue('emailNotification.changePassword', emailNotification.changePassword.active)
    setValue('emailNotification.webformSummary', emailNotification.webformSummary.active)
    setValue('emailNotification.webshopCheckoutConfirmation', emailNotification.webshopCheckoutConfirmation.active)
    setValue('emailNotification.abortCase', emailNotification.abortCase.active)
    setValue('emailNotification.proposalsAvailable', emailNotification.proposalsAvailable.active)
    setValue('emailNotification.proposalsSelected', emailNotification.proposalsSelected.active)
    setValue('emailNotification.proposalsRevoked', emailNotification.proposalsRevoked.active)
    setValue('emailNotification.orderConfirmation', emailNotification.orderConfirmation.active)
    setValue('emailNotification.feedbackAcknowledgement', emailNotification.feedbackAcknowledgement.active)
    setValue('emailNotification.pendingAction', emailNotification.pendingAction.active)
    setValue('emailNotification.praEmailNextApprover', emailNotification.praEmailNextApprover.active)
    setValue('emailNotification.praEmailComment', emailNotification.praEmailComment.active)
    setValue('emailNotification.praEmailRejected', emailNotification.praEmailRejected.active)
    setValue('emailNotification.praEmailApproved', emailNotification.praEmailApproved.active)
    setValue('emailNotification.praSupplierPurchaseOrder', emailNotification.praSupplierPurchaseOrder.active)
    setValue('emailNotification.praSalesInvoiceGoodsReceived', emailNotification.praSalesInvoiceGoodsReceived.active)
    setValue('emailNotification.salesInvoiceGoodsReceived', emailNotification.salesInvoiceGoodsReceived.active)
  }, [setValue, session])

  const handleOnSubmit = async values => {
    const updateEmailNotification = accountStore.updateEmailNotification(
      session.clientId,
      authStore.getLoginEmployeeId(),
      {
        setEmailNotification: {
          ...values.emailNotification,
          schedule: {
            pendingActionWeekly: values.emailNotification.schedule?.pendingActionWeekly === 'true',
          },
        },
      }
    )

    const payload = {
      uiLanguageSetting: values.language.value,
      outOfOfficeSetting: {},
    }

    if (enableOutOfOffice) {
      const { employee, ...restOutoOfOffice } = values.outOfOffice || {}
      payload.outOfOfficeSetting = {
        ...(employee
          ? {
              employeeId: employee.id,
              name: employee.name,
            }
          : {}),
        ...restOutoOfOffice,
      }
    }

    const updateAccountInfo = accountStore.updateAccountInfo(payload)

    await Promise.allSettled([updateEmailNotification, updateAccountInfo])
    const passwordChanged = !!values.confirmPassword
    if (passwordChanged) {
      changePassword(values)
    } else {
      window.location.reload()
    }
  }

  const changePassword = async values => {
    const { newPassword, confirmPassword } = values
    const payload = { newPassword, confirmPassword }

    const result = await accountStore.changePassword(payload)
    if (result) {
      globalMessageStore.show({
        title: resource.PasswordChanged,
        message: resource.PasswordUpdated,
        color: 'success',
      })

      onClose()
      window.setTimeout(() => {
        window.location.reload()
      }, 1500)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      useForm={methods}
      size="xl"
      title={resource.MyAccount}
      footer={
        <div className="w-100 d-flex">
          <Button type="button" text color="primary" disabled={submitting} className="me-2" onClick={onClose}>
            {commonResource.Cancel}
          </Button>
          {!session.isSecured && (
            <Button
              type="submit"
              color="success"
              className="ms-auto"
              icon="done"
              disabled={submitting}
              loading={submitting}
            >
              {commonResource.Save}
            </Button>
          )}
        </div>
      }
      disableClose={submitting}
      onClose={onClose}
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <MessageList messages={error && [error]} />
      <div className="offset-lg-1 col-lg-10">
        <AccountInfo resource={resource} session={session} disabled={submitting} />
        <ChangePasswordPanel resource={resource} session={session} disabled={submitting} />
        {enableOutOfOffice && (
          <OutOfOfficePanel
            authStore={authStore}
            praStore={praStore}
            resource={resource}
            commonResource={commonResource}
            session={session}
            disabled={submitting}
          />
        )}
        <EmailNotificationPanel resource={resource} session={session} disabled={submitting} />
      </div>
    </Modal>
  )
})
