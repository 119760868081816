import React from 'react'

import { useWatch } from 'react-hook-form'

import { praLongDateFormat } from 'utils/formatters'
import DatePicker from 'common/Forms/DatePicker'

export default ({ pageResourceStore, cart, updateCart, disabled }) => {
  const {
    PageResource: { webshop: resource },
  } = pageResourceStore

  const estimatedDeliveryDate = useWatch({ name: 'estimatedDeliveryDate', defaultValue: cart.estimatedDeliveryDate })

  const highlightedDate = React.useMemo(() => {
    if (cart.deliveryInfo.preferredDate) {
      return null
    } else {
      // Add 3 days later for suggested date
      const dateNow = new Date()
      return new Date(dateNow.setDate(dateNow.getDate() + 3))
    }
  }, [cart.deliveryInfo.preferredDate])

  // Add 1 day later for delivery date
  const deliveryDate = React.useMemo(() => {
    if (estimatedDeliveryDate) {
      return new Date(estimatedDeliveryDate)
    } else {
      const dateNow = new Date()
      return new Date(dateNow.setDate(dateNow.getDate() + 1))
    }
  }, [estimatedDeliveryDate])

  return (
    <DatePicker
      name="deliveryInfo.preferredDate"
      rules={{
        required: resource.Required,
      }}
      label={<h5 className="webshop-title-color fw-bolder mb-0">{resource.PreferredDate}</h5>}
      formGroup
      dateFormat={praLongDateFormat}
      disabled={disabled}
      disabledDays={[
        {
          before: deliveryDate,
        },
      ]}
      onChange={() => updateCart()}
      todayButton=""
      highlightedDate={highlightedDate}
    />
  )
}
