import { makeObservable, observable } from 'mobx'

import BaseStore from 'stores/base'

class AuthStore extends BaseStore {
  AppSession = {
    session: {},
    settings: {},

    loading: false,
    submitting: false,

    error: null,
    status: null,
  }

  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options

    makeObservable(this, {
      AppSession: observable,
    })

    this.handleOnSessionExpired = this.handleOnSessionExpired.bind(this)
    this.service.onSessionExpired = this.handleOnSessionExpired

    this.handleOnRedirectToLoginProvider = this.handleOnRedirectToLoginProvider.bind(this)
    this.service.onRedirectToLoginProvider = this.handleOnRedirectToLoginProvider
  }

  handleOnSessionExpired = () => {
    this.onSessionExpired && this.onSessionExpired()
  }

  handleOnRedirectToLoginProvider(loginProvider, message) {
    this.onRedirectToLoginProvider && this.onRedirectToLoginProvider(loginProvider, message)
  }

  login(payload, provider) {
    return this.request(this.AppSession, () => this.service.login(payload, provider))
  }

  logout() {
    return this.request(this.AppSession, () => this.service.logout())
  }

  getAuthSession() {
    return this.getRequest(
      this.AppSession,
      () => this.service.getAuthSession(),
      newData => {
        this.AppSession.session = {
          ...newData.session,
          enabledClientModules: newData.session.enabledClientModules.split(',').map(item => item?.trim()),
        }
        this.AppSession.settings = newData.settings
      }
    )
  }

  getSession() {
    return this.AppSession?.session
  }

  getLoginEmployeeId() {
    if (this.getSession()) {
      return parseInt((this.getSession().loggedinEmployeeId || '/').split('/')[1])
    }
    return null
  }

  clearErrorMessages() {
    this.AppSession.error = null
    this.CRUD.error = null
  }
}

export default AuthStore
