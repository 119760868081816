import React from 'react'

import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'

import { useStores } from 'stores'
import ContentLoader from 'common/ContentLoader'
import ProductItem from 'components/Webshop/WebshopProductList/ProductItem'

export default observer(() => {
  const { authStore, pageResourceStore, webshopStore, webshopFavouriteStore } = useStores()
  const location = useLocation()

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { webshop: resource },
  } = pageResourceStore
  const {
    Table: { data, loading },
  } = webshopFavouriteStore
  const {
    ItemPricing: { data: itemPricingLookup = {}, loading: itemPricingLoading },
  } = webshopStore

  React.useEffect(() => {
    webshopFavouriteStore.fetchTable({
      topRows: 60,
      languageCode: session.uiLanguageSetting.toLowerCase(),
    })
  }, [webshopFavouriteStore, session.uiLanguageSetting, location.key])

  React.useEffect(() => {
    // For loading webshop item pricing
    if (data?.length) {
      webshopStore.getItemPricing({
        clientId: session.clientId,
        items: data.map(item => ({
          itemId: item.itemId,
          serviceContract: item.serviceContract,
        })),
      })
    }
  }, [webshopStore, data, session.clientId])

  return (
    <div key={location.key} id="webshop" className="position-relative">
      <ContentLoader show={loading} className="align-items-start pt-6" />
      <div className="product-list webshop-text-color p-3 rounded bg-webshop">
        <div className="d-flex flex-wrap">
          {data.map((item, index) => (
            <ProductItem
              key={`${item.itemId}-${index}`}
              resource={resource}
              item={item}
              itemPricingLookup={itemPricingLookup}
              itemPricingLoading={itemPricingLoading}
              isFavourite
            />
          ))}
        </div>
      </div>
    </div>
  )
})
