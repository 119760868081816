import React from 'react'

import { useWatch } from 'react-hook-form'

import { formatCurrency } from 'utils/formatters'
import { PUNCHOUT_TYPE } from 'definitions'
import Button from 'common/Button'

export default ({ resource, session, cart, submitting, saving }) => {
  const [watchAdminCost, watchTotal, watchShipping, watchFeeAmount, watchGrandTotal, watchCartReady, watchItems] =
    useWatch({
      name: ['adminCost', 'total', 'shipping', 'feeAmount', 'grandTotal', 'cartReady', 'items'],
    })

  const hasNoAccess = React.useMemo(() => {
    if (watchItems?.length) {
      return watchItems.some(item => !item.itemName)
    }
    return false
  }, [watchItems])

  const { punchoutType, hasPunchoutSession } = session
  const adminCost = watchAdminCost === undefined ? cart.adminCost : watchAdminCost
  const total = watchTotal === undefined ? cart.total : watchTotal
  const shipping = watchShipping === undefined ? cart.shipping : watchShipping
  const feeAmount = watchFeeAmount === undefined ? cart.feeAmount : watchFeeAmount
  const grandTotal = watchGrandTotal === undefined ? cart.feeAmount : watchGrandTotal

  const punchoutExpired = punchoutType.toLowerCase() !== PUNCHOUT_TYPE.NonErp.toLowerCase() && !hasPunchoutSession

  return (
    <div className="filler border-top p-1">
      <div className="webshop-title-color fw-bold pt-5 mb-5">
        <div>
          {resource.Total} {formatCurrency(total)}
        </div>
        <div>
          {resource.Shipping} {formatCurrency(shipping)}
        </div>
        {feeAmount > 0 && (
          <div>
            {resource.ServiceFee} {formatCurrency(feeAmount)}
          </div>
        )}
        {adminCost > 0 && (
          <div>
            <div>{resource.AdminCost}</div>
            <div>
              {resource.AdminCostMinimumAmount} : {formatCurrency(adminCost)}
            </div>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-center mt-4 mb-3">
        <h5 className="webshop-title-color mb-0 fw-bolder">
          {resource.GrandTotal}: {formatCurrency(grandTotal || 0)}
        </h5>
      </div>
      <Button
        type="submit"
        color="primary"
        size="lg"
        className="w-100"
        disabled={hasNoAccess || !watchCartReady || submitting || saving || punchoutExpired}
        loading={submitting}
      >
        {resource.Checkout}
      </Button>
    </div>
  )
}
