import React from 'react'

import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'

import { useStores } from 'stores'
import Button from 'common/Button'
import Loader from 'common/Loader'
import MessageList from 'common/MessageList'
import Modal from 'common/Modal'

import DetailPanel from './DetailPanel'
import ItemListPanel from './ItemListPanel'
import SupplierRequestModal from './SupplierRequestModal'

export default observer(({ resource, commonResource, caseId, rfq: rfqProp, isEdit, onReloadSource, onClose }) => {
  const { sourceStore } = useStores()
  const [supplierModalOptions, setSupplierModalOptions] = React.useState({})
  const [rfq, setRfq] = React.useState()
  const methods = useForm()

  const { handleSubmit } = methods
  const {
    CRUD: { error, submitting, loading },
  } = sourceStore

  React.useEffect(() => {
    const {
      Table: {
        data: { linesToSource },
      },
      prepareRfqData,
    } = sourceStore

    if (isEdit) {
      const fetchRfq = async () => {
        if (isEdit && rfqProp.id) {
          const result = await sourceStore.getById(caseId, rfqProp.id)
          if (result) {
            if (result.isSent) {
              sourceStore.CRUD.error = resource.RfqAlreadySend
              onReloadSource(caseId)
            }
            setRfq(prepareRfqData(true, result, linesToSource))
          }
        }
      }
      fetchRfq()
    } else {
      setRfq(prepareRfqData(false, rfqProp, linesToSource))
    }
  }, [sourceStore, caseId, rfqProp, isEdit])

  const toggleSupplierModal = supplierName =>
    setSupplierModalOptions({
      isOpen: !!supplierName,
      supplierName,
    })

  const handleOnSubmit = async values => {
    const { attachments, supplier, suppliers, lines } = values
    const { added = [], deleted = [] } = attachments || {}

    const payload = {
      caseId,
      prLinesInfo: lines
        .filter(item => item.selected)
        .map(item => ({
          lineIndex: parseInt(item.lineIndex),
          extendedDescription: item.remarks,
        })),
      attachmentFileIds: added ? added.map(item => item.fileId) : [],
      remarks: values.remarks,
      isDirectOrder: rfq.isDummy,
      ...(isEdit
        ? {
            id: rfq.id,
            supplierId: supplier.id,
            deletedAttachmentFileIds: deleted,
          }
        : {
            supplierIds: suppliers.map(item => item.id),
          }),
    }

    const action = isEdit ? sourceStore.patch : sourceStore.post
    const result = await action(caseId, payload)
    if (result) {
      onClose()
      onReloadSource(caseId)
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen
        useForm={methods}
        title={isEdit ? resource.EditRfqDraft : resource.CreateRfq}
        size="xl"
        disableClose={submitting}
        footer={
          <div className="w-100 d-flex">
            <Button type="button" text color="primary" disabled={submitting} className="me-2" onClick={onClose}>
              {commonResource.Cancel}
            </Button>
            {rfq?.isSent !== true && (
              <Button
                type="submit"
                color="success"
                className="btn btn-success ms-auto"
                icon="done"
                disabled={submitting || loading}
                loading={submitting}
              >
                {commonResource.Save}
              </Button>
            )}
          </div>
        }
        onClose={onClose}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        {<Loader show={isEdit && loading} text={commonResource.Loading} />}
        <MessageList message={error && [error]} />
        {!!rfq && (
          <React.Fragment>
            {rfq.isDummy && (
              <MessageList
                color="warning"
                message={rfq.isSent ? [resource.RfqNotSentToSupplier] : [resource.RfqWillNotSendToSupplier]}
              />
            )}
            {!loading && (
              <React.Fragment>
                <ItemListPanel resource={resource} rfq={rfq} disabled={submitting} />
                <DetailPanel
                  resource={resource}
                  commonResource={commonResource}
                  rfq={rfq}
                  isEdit={isEdit}
                  disabled={submitting}
                  onRequestForNewSupplier={supplierName => toggleSupplierModal(supplierName)}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Modal>
      {supplierModalOptions.isOpen && (
        <SupplierRequestModal supplierName={supplierModalOptions.supplierName} onClose={() => toggleSupplierModal()} />
      )}
    </React.Fragment>
  )
})
