import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'stores'
import ContentLoader from 'common/ContentLoader'

import { TableList } from 'components/Case/CaseDetail/TableList'
import QuotationItem from './QuotationItem'
import QuotationStatus from './QuotationStatus'

export default observer(
  ({
    onViewRfq,
    onEditRfq,
    onDeleteRfq,
    onSendRfq,
    onViewQuote,
    onCreateQuote,
    onEditQuote,
    onDeleteQuote,
    onSendQuoteToErp,
    onSendQuoteToApproval,
  }) => {
    const { authStore, pageResourceStore, sourceStore } = useStores()

    const {
      AppSession: { session },
    } = authStore
    const {
      Table: { data: { quotations = [] } = {}, loading },
    } = sourceStore
    const {
      PageResource: { case: resource = {}, common: commonResource = {} },
    } = pageResourceStore

    return (
      <TableList>
        {!quotations.length && <div className="text-muted text-center">{resource.NoQuotationsYet}</div>}
        {!!quotations.length && (
          <div className="position-relative">
            <ContentLoader show={loading} text={commonResource.Loading} className="align-items-start pt-5" />
            {quotations.map((item, index) => (
              <div key={index} className="d-flex flex-wrap mb-6">
                <QuotationItem
                  resource={resource}
                  quotation={item}
                  onViewRfq={onViewRfq}
                  onEditRfq={onEditRfq}
                  onDeleteRfq={onDeleteRfq}
                  onViewQuote={onViewQuote}
                  onEditQuote={onEditQuote}
                  onDeleteQuote={onDeleteQuote}
                />
                <QuotationStatus
                  resource={resource}
                  session={session}
                  quotation={item}
                  onSendRfq={onSendRfq}
                  onCreateQuote={onCreateQuote}
                  onSendQuoteToErp={onSendQuoteToErp}
                  onSendQuoteToApproval={onSendQuoteToApproval}
                />
              </div>
            ))}
          </div>
        )}
      </TableList>
    )
  }
)
