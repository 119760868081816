import BaseService from 'services/base'

export default class PurchaseRequestService extends BaseService {
  constructor(api) {
    const url = 'clientcase'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchTable(caseId) {
    return this.api.get(`${this.url}/${caseId}/pr`)
  }

  createPrLine = (caseId, payload, config) => {
    return this.api.post(`${this.url}/${caseId}/prline`, payload, config)
  }

  updatePrLine = (caseId, payload, config) => {
    const { lineIndex } = payload
    return this.api.put(`${this.url}/${caseId}/prline/${lineIndex}`, payload, config)
  }

  deletePrLine = payload => {
    const { caseId, lineIndex } = payload
    return this.api.delete(`${this.url}/${caseId}/prline/${lineIndex}`)
  }
}
