import BaseStore from 'stores/base'

export default class SourceStore extends BaseStore {
  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options

    this.getLines = this.getLines.bind(this)
    this.prepareRfqData = this.prepareRfqData.bind(this)
  }

  fetchTable(caseId) {
    return this.getRequest(
      this.Table,
      () => this.service.fetchTable(caseId),
      newData => {
        this.Table.data = { ...newData.source }
      }
    )
  }

  getById(caseId, id) {
    return this.getRequest(this.CRUD, () => this.service.getById(caseId, id))
  }

  async sendRfq(payload) {
    const { data, error } = await this.service.sendRfq(payload)
    return { data, error }
  }

  prepareRfqData(isEdit, rfq, linesToSource) {
    if (isEdit) {
      return {
        ...rfq,
        lines: this.getLines(linesToSource, rfq.lines),
      }
    } else {
      const lines = this.getLines(
        linesToSource,
        rfq.lines.map(item => ({
          ...item,
          prLineIndex: item.lineIndex,
        }))
      )
      return { ...rfq, lines }
    }
  }

  getLines(linesToSource, selection) {
    if (selection) {
      const selectedLines = selection?.map(item => item.prLineIndex)

      const lines = linesToSource?.map(item => {
        const selectedLine = selection.find(current => current.prLineIndex === item.lineIndex) || {}
        return {
          ...item,
          extendedDescription: selectedLine.extendedDescription || selectedLine.remarks,
          selected: selectedLines.includes(item.lineIndex),
        }
      })
      return lines || []
    }
    return linesToSource
  }
}
