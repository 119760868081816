import React from 'react'

import { WEBSHOP_ATTRIBUTE_TYPE } from 'definitions'
import CardCollapse from 'common/CardCollapse'

import AttributeCheckbox from './AttributeCheckbox'

export default ({ attribute, searchParams, clientName, categoryOnly, onChange }) => {
  const attributeName = attribute.attributeName

  const attributeParamValues = React.useMemo(() => {
    return searchParams.get(attributeName)?.split(',') || []
  }, [attributeName, searchParams])

  return (
    <CardCollapse defaultIsOpen title={attribute.displayName || attributeName} bodyClassName="py-3 px-2">
      {attribute.values
        .filter(x => x.total > 0)
        .map((attributeValue, index) => {
          const attributeType = attribute.attributeType || WEBSHOP_ATTRIBUTE_TYPE.SELECT
          return (
            <React.Fragment key={index}>
              {attributeType === WEBSHOP_ATTRIBUTE_TYPE.SELECT && (
                <AttributeCheckbox
                  attributeName={attributeName}
                  attributeValue={attributeValue}
                  checked={attribute.checked}
                  attributeParamValues={attributeParamValues}
                  clientName={clientName}
                  categoryOnly={categoryOnly}
                  onChange={onChange}
                />
              )}
              {attributeType === WEBSHOP_ATTRIBUTE_TYPE.TEXT && (
                <label className="attribute-attributeValue">
                  <input defaultValue={attributeValue.value} />({attributeValue.total})
                </label>
              )}
            </React.Fragment>
          )
        })}
    </CardCollapse>
  )
}
