import React from 'react'

import { Alert, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import cx from 'classnames'

import { ROUTES } from 'definitions'
import { useStores } from 'stores'
import ButtonIcon from 'common/ButtonIcon'
import ContentLoader from 'common/ContentLoader'
import Dropdown from 'common/Dropdown'
import Icon from 'common/Icon'

export default observer(({ resource }) => {
  const { authStore, webshopFavouriteStore } = useStores()
  const [isOpen, setIsOpen] = React.useState()

  const {
    AppSession: { session },
  } = authStore
  const {
    HeaderFavourites: { data, loading },
  } = webshopFavouriteStore

  React.useEffect(() => {
    webshopFavouriteStore.fetchHeaderFavourites({
      topRows: 5,
      languageCode: session.uiLanguageSetting.toLowerCase(),
    })
  }, [webshopFavouriteStore, session.uiLanguageSetting])

  const handleClose = () => setIsOpen(false)

  const handleMouseEnter = () => {
    !isOpen && setIsOpen(true)
  }

  const handleMouseLeave = () => {
    isOpen && setIsOpen(false)
  }

  const hasFavourites = !!data.length

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(prev => !prev)}
      customToggle={
        <ButtonIcon
          color="gold"
          icon={hasFavourites ? 'favorite' : 'favorite_border'}
          iconClassName={cx('md-32', hasFavourites && 'text-gold')}
        />
      }
      direction="end"
      className="favourites-dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DropdownItem text className="border-bottom">
        <ContentLoader show={loading} />
        <div className="d-flex">
          <Link
            to={ROUTES.WEBSHOP_FAVOURITES}
            className="webshop-title-color text-hover-primary fs-18 fw-bold line-height-24"
            onClick={handleClose}
          >
            {resource.Favorites}
          </Link>
          <Icon
            icon={hasFavourites ? 'favorite' : 'favorite_border'}
            className={cx('md-32 ms-auto', hasFavourites && 'text-gold')}
          />
        </div>
      </DropdownItem>
      {!hasFavourites && (
        <DropdownItem text className="border-bottom">
          <div>{resource.NoFavorites}</div>
          <div>{resource.AddProductsByClickingOn}</div>
        </DropdownItem>
      )}
      {data.map(item => (
        <React.Fragment key={item.id}>
          {!item.name && (
            <DropdownItem text className="p-2 border-bottom">
              <Alert color="primary" className="mb-0 p-3">
                {resource.ProductAccessDeniedByUserGroup}
              </Alert>
            </DropdownItem>
          )}
          {!!item.name && (
            <DropdownItem text className="py-1 px-3 border-bottom">
              <Link
                to={`${ROUTES.WEBSHOP_PRODUCT}/${item.itemId}`}
                className="d-flex align-items-center w-100"
                onClick={handleClose}
              >
                <div className="product-image" style={{ backgroundImage: `url(${item.imageUrl})` }} />
                <div className="webshop-title-color text-hover-primary fw-bold ms-2 col">{item.name}</div>
              </Link>
            </DropdownItem>
          )}
        </React.Fragment>
      ))}
      <DropdownItem text className="p-2">
        <Link to={ROUTES.WEBSHOP_FAVOURITES} className="btn btn-primary w-100" onClick={handleClose}>
          {resource.ViewAll}
        </Link>
      </DropdownItem>
    </Dropdown>
  )
})
