import React from 'react'

import { observer } from 'mobx-react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { ROUTES } from 'definitions'
import { useStores } from 'stores'
import SelectCompanyModal from 'components/Core/SelectCompanyModal'

export default observer(() => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { accountClientStore, authStore } = useStores()
  const [isOpenSelectCompanyModal, setIsOpenSelectCompanyModal] = React.useState(false)

  const {
    AppSession: { session },
  } = authStore

  React.useEffect(() => {
    if (!session.clientId && session.showClientSelection) {
      switch (location.pathname) {
        case ROUTES.PRA: {
          const praId = searchParams.get('pr')
          if (praId) {
            clientSelectByPraId(praId)
          } else {
            toggleSelectCompanyModal()
          }
          break
        }
        default:
          toggleSelectCompanyModal()
          break
      }
    }
  }, [session, location.pathname, searchParams])

  const toggleSelectCompanyModal = () => setIsOpenSelectCompanyModal(prev => !prev)

  const clientSelectByPraId = async praId => {
    const result = await accountClientStore.selectClientByPraId(praId)
    if (result && result.message) {
      toggleSelectCompanyModal()
    } else {
      window.location.reload()
    }
  }

  return (
    <React.Fragment>
      <SelectCompanyModal isOpen={isOpenSelectCompanyModal} toggle={toggleSelectCompanyModal} hideClose />
    </React.Fragment>
  )
})
