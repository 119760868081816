import React from 'react'

import { DropdownItem } from 'reactstrap'

import Button from 'common/Button'
import Dropdown from 'common/Dropdown'
import Icon from 'common/Icon'

import DownloadReportModal from './PraReportModal'

export default ({ resource, onCreatePra, disableCreatePra }) => {
  const [reportModalOption, setReportModalOption] = React.useState({})

  const handleDownloadReport = report => setReportModalOption({ isOpen: !!report, report })

  return (
    <div className="d-inline-flex align-items-center">
      {!disableCreatePra && (
        <Button color="primary" icon="add" onClick={onCreatePra}>
          <span className="d-sm-inline d-none">{resource.CreatePurchaseRequest}</span>
          <span className="d-sm-none d-inline">{resource.Create}</span>
        </Button>
      )}
      <Dropdown toggleContent={resource.Reports} caret toggleButtonOptions={{ icon: 'file_download' }} className="ms-2">
        <DropdownItem className="d-inline-flex align-items-center" onClick={() => handleDownloadReport('audit-trail')}>
          <Icon icon="file_download" className="me-2" /> {resource.ApprovalAuditTrailFileName}
        </DropdownItem>
        <DropdownItem className="d-inline-flex align-items-center" onClick={() => handleDownloadReport('po')}>
          <Icon icon="file_download" className="me-2" /> {resource.PurchaseOrderReportFileName}
        </DropdownItem>
      </Dropdown>
      {reportModalOption.isOpen && (
        <DownloadReportModal
          resource={resource}
          report={reportModalOption.report}
          onClose={() => handleDownloadReport()}
        />
      )}
    </div>
  )
}
