import React from 'react'

import Button from 'common/Button'
import Modal from 'common/Modal'

export default ({ bindActions }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [options, setOptions] = React.useState({})

  const { title, message, closeOnSubmit = true, submitText, cancelText, hideCancel, onSubmit, onCancel } = options

  const handleOpen = newOptions => {
    setIsOpen(true)
    setOptions(newOptions)
  }

  const handleSubmit = () => {
    if (closeOnSubmit) {
      handleCancel()
      onSubmit && onSubmit()
    } else {
      setSubmitting(true)
      onSubmit && onSubmit(handleCancel)
    }
  }

  const handleCancel = () => {
    setSubmitting(false)
    setIsOpen(false)
    setOptions({})
    onCancel && onCancel()
  }

  const toggle = () => {
    if (!isOpen) {
      setIsOpen(true)
    } else {
      handleCancel()
    }
  }

  React.useEffect(() => {
    bindActions(handleOpen, handleCancel)
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      disableClose={submitting}
      hideClose={hideCancel}
      toggle={toggle}
      keyboard
      footer={
        <div className="w-100 d-flex">
          {!hideCancel && (
            <Button text disabled={submitting} className="me-2 px-4" onClick={handleCancel}>
              {cancelText}
            </Button>
          )}
          <Button
            color="success"
            className="px-4 ms-auto"
            loading={submitting}
            disabled={submitting}
            onClick={handleSubmit}
          >
            {submitText}
          </Button>
        </div>
      }
    >
      <span className="confirm-message white-space-break-spaces">{message}</span>
    </Modal>
  )
}
