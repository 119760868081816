import React from 'react'

import { ADDRESS_TYPE } from 'definitions'
import { praLongDateFormat } from 'utils/formatters'
import { useStores } from 'stores'
import DatePicker from 'common/Forms/DatePicker'

import ClientEmployee from './PraFormClientEmployee'
import DeliveryInfo from 'components/Sections/DeliveryInfo'
import PraFormProductItemMultipleAddress from './PraFormProductItemMultipleAddress'

export default ({ praStore, resource, index, lineItem, isReadOnly, onChange, clientId, isEdit }) => {
  const { pageResourceStore } = useStores()

  const {
    CRUD: { settings = {}, submitting },
    Lookups: { data: { sites = [] } = {} },
  } = praStore

  const {
    PageResource: { common: commonResource = {} },
  } = pageResourceStore

  const defaultClientSite = React.useMemo(() => {
    const clientSiteId = lineItem.deliveryInfo?.clientSiteId || settings.defaultSiteId
    return sites.find(item => item.id === clientSiteId)
  }, [lineItem.deliveryInfo?.clientSiteId, settings.defaultSiteId, sites])

  const defaultDeliveryAddress = React.useMemo(() => {
    return lineItem.deliveryInfo?.deliveryAddress || defaultClientSite?.address
  }, [lineItem.deliveryInfo?.deliveryAddress, defaultClientSite])

  const defaultRequester = React.useMemo(() => {
    if (isEdit && lineItem.deliveryInfo?.requester && lineItem.deliveryInfo?.requester?.requesterId) {
      return {
        id: lineItem.deliveryInfo?.requester?.requesterId,
        name: lineItem.deliveryInfo?.requester?.displayname,
      }
    } else if (!isEdit && lineItem.deliveryInfo?.requester && lineItem.deliveryInfo?.requester?.key) {
      return {
        id: lineItem.deliveryInfo?.requester?.key,
        name: lineItem.deliveryInfo?.requester?.value,
      }
    } else {
      return {
        id: settings?.userRequester?.key,
        name: settings?.userRequester?.value,
      }
    }
  }, [isEdit, lineItem.deliveryInfo?.requester, settings?.userRequester])

  const defaultOtherRequester = React.useMemo(() => {
    if (isEdit) {
      if (lineItem.deliveryInfo?.requester && !lineItem.deliveryInfo?.requester?.requesterId) {
        return lineItem.deliveryInfo?.requester?.displayname
      }
    } else {
      return lineItem.deliveryInfo?.otherRequester
    }
  }, [isEdit, lineItem.deliveryInfo?.requester, lineItem.deliveryInfo?.otherRequester])

  return (
    <React.Fragment>
      <DatePicker
        name={`lines.${index}.deliveryInfo.deliveryDate`}
        rules={{ required: resource.Required }}
        label={`${resource.PreferredDate}${isReadOnly ? '' : ' *'} `}
        defaultValue={lineItem.deliveryInfo?.deliveryDate}
        dateFormat={praLongDateFormat}
        formGroup
        horizontal
        labelCol={{ lg: 3, xl: 5 }}
        inputCol={{ lg: 7, xl: 5 }}
        disabled={submitting}
        readOnlyField={isReadOnly || isEdit}
        formClassName="col-12 mx-0"
        labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
        inputColClassName="px-lg-3 px-0"
        onChange={newValue =>
          onChange('deliveryInfo', {
            key: 'deliveryDate',
            value: newValue,
          })
        }
      />
      {!lineItem.deliveryInfo?.isMultiAddress && (
        <DeliveryInfo
          resource={resource}
          namePrefix={`lines.${index}.`}
          clientSites={sites}
          addressType={
            lineItem.deliveryInfo && lineItem.deliveryInfo?.clientSiteId === 0
              ? ADDRESS_TYPE.OTHER
              : ADDRESS_TYPE.CLIENT
          }
          defaultClientSite={defaultClientSite}
          defaultDeliveryAddress={defaultDeliveryAddress}
          disabled={submitting}
          isReadOnly={isReadOnly || isEdit}
          isReadOnlyShowDeliveryAddress={isReadOnly || isEdit}
          labelCol={{ lg: 3, xl: 5 }}
          inputCol={{ lg: 7, xl: 5 }}
          formClassName="col-12 mx-0"
          labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
          inputColClassName="px-lg-3 px-0"
          onChange={selected => onChange('deliveryInfo', selected)}
        />
      )}
      {!!lineItem.deliveryInfo?.isMultiAddress && (
        <PraFormProductItemMultipleAddress
          resource={resource}
          index={index}
          multiAddressAttachment={lineItem.deliveryInfo?.multiAddress}
          disabled={submitting}
          isReadOnly={isReadOnly || isEdit}
        />
      )}
      <ClientEmployee
        resource={resource}
        namePrefix={`lines.${index}.`}
        commonResource={commonResource}
        clientId={clientId}
        deliverTo={defaultOtherRequester ? 'other' : 'employee'}
        defaultRequester={defaultRequester}
        defaultOtherRequester={defaultOtherRequester}
        disabled={submitting}
        labelCol={{ lg: 3, xl: 5 }}
        inputCol={{ lg: 7, xl: 5 }}
        formClassName="col-12 mx-0"
        labelClassName="px-lg-3 py-lg-2 p-0 pb-2 text-lg-end"
        inputColClassName="px-lg-3 px-0"
        isReadOnly={isReadOnly || isEdit}
      />
    </React.Fragment>
  )
}
