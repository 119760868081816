import React from 'react'

import { DropdownItem } from 'reactstrap'
import { observer } from 'mobx-react'

import { useAuthManager } from 'hooks/useAuthManager'
import { useStores } from 'stores'
import Dropdown from 'common/Dropdown'
import Toaster from 'common/Toaster'

import punchoutLogo from 'assets/images/oracle-logo-32.png'

export default observer(({ onShowSelectCompany, onShowAccount }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { authStore, pageResourceStore } = useStores()
  const authManager = useAuthManager()

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { account: resource = {} },
  } = pageResourceStore

  const toggle = () => setIsOpen(prev => !prev)

  const handleLogout = () => {
    Toaster.confirm({
      title: resource.Logout,
      message: resource.LogoutConfirmation,
      closeOnSubmit: false,
      submitText: resource.Confirm,
      cancelText: resource.Cancel,
      onSubmit: () => {
        authManager.logout()
      },
    })
  }

  const handleReturnToErp = () => {
    window.location = session.erpUrl
  }

  if (!session.loggedinEmployeeId) return false

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      menuRole="menu"
      caret
      className="user-menu mx-lg-5 mx-sm-2 mx-0"
      menuClassName="dropdown-menu-end"
      toggleContent={
        <React.Fragment>
          {!!session.hasPunchoutSession && (
            <img src={punchoutLogo} alt="punchout" className="punchout-logo rounded-pill me-1" />
          )}
          {`${session.firstName} ${session.lastName}`}
        </React.Fragment>
      }
      toggleButtonOptions={{
        icon: !session.hasPunchoutSession ? 'account_circle' : '',
        iconFilled: true,
        iconClassName: 'md-28 me-1',
        text: true,
      }}
    >
      {session.showClientSelection && (
        <DropdownItem className="d-inline-flex align-items-center ps-2 pe-5" onClick={onShowSelectCompany}>
          <span className="material-icons-outlined me-2">swap_horiz</span> {resource.SwitchCompany}
        </DropdownItem>
      )}
      <DropdownItem className="d-inline-flex align-items-center ps-2 pe-5" onClick={onShowAccount}>
        <span className="material-icons-outlined me-2">account_box</span> {resource.Account}
      </DropdownItem>
      {session.erpUrl && (
        <DropdownItem className="d-inline-flex align-items-center ps-2 pe-5" onClick={handleReturnToErp}>
          <span className="material-icons-outlined me-2">webhook</span> {resource.ReturnToErp}
        </DropdownItem>
      )}
      <hr className="my-2 mx-4" />
      <DropdownItem className="d-inline-flex align-items-center ps-2 pe-5" onClick={handleLogout}>
        <span className="material-icons-outlined me-2">logout</span> {resource.Logout}
      </DropdownItem>
    </Dropdown>
  )
})
