import React from 'react'

import { observer } from 'mobx-react'

import { formatCurrency } from 'utils/formatters'
import { useStores } from 'stores'
import ReactTable from 'common/ReactTable'

export default observer(({ resource, itemId }) => {
  const { webshopPricingStore } = useStores()

  const {
    ItemPricingTier: { data: pricingTiersData, loading },
  } = webshopPricingStore

  const pricingTiers = React.useMemo(() => {
    if (pricingTiersData && !loading) {
      const pricingTiers = pricingTiersData[itemId]
      return pricingTiers
    }
    return []
  }, [itemId, pricingTiersData, loading])

  const columns = React.useMemo(
    () => [
      {
        Header: resource.Tier,
        accessor: 'quantity',
        Cell: ({ value }) => `${value}+ ${resource.Pieces}`,
      },
      {
        Header: resource.PriceExclVAT,
        accessor: 'amount',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: resource.PriceInclVAT,
        accessor: 'amountVatIncluded',
        Cell: ({ value }) => formatCurrency(value),
      },
    ],
    [resource]
  )

  return (
    !loading && (
      <ReactTable data={pricingTiers} noRecordsLabel={resource.NoPricingTier} className="fs-12" columns={columns} />
    )
  )
})
