import React from 'react'

import { Col } from 'reactstrap'
import { useFormState } from 'react-hook-form'

import { formatString } from 'utils/formatters'
import { validateMinimum } from 'utils/validators'
import FormGroup from 'common/FormGroup'
import Input from 'common/Forms/Input'

export default ({ commonResource, resource, index }) => {
  const { errors } = useFormState()

  const quantityUnitHasError =
    errors &&
    errors?.webformLines?.length === true &&
    (!!errors?.webformLines[index]?.quantity?.amount || errors?.webformLines[index]?.quantity?.unit)

  return (
    <FormGroup
      label={`${resource.QuantityUnit} *`}
      formGroup
      horizontal
      hasError={quantityUnitHasError}
      inputCol={{ md: 4 }}
      inputColClassName="d-flex"
    >
      <Col md="6" className="pe-3">
        <Input
          name={`webformLines.${index}.quantity.amount`}
          rules={{
            required: commonResource.Number,
            validate: value => {
              return validateMinimum(value, 1, formatString(commonResource.MinValue, 1))
            },
          }}
          type="number"
          className="mx-0 mb-0"
          min="0"
        />
      </Col>
      <Col md="6" className="ps-3">
        <Input
          name={`webformLines.${index}.quantity.unit`}
          rules={{ required: resource.Required }}
          placeholder={resource.phUnit}
          className="mx-0 mb-0"
          defaultValue={resource.UnitValue}
        />
      </Col>
    </FormGroup>
  )
}
