import BaseService from 'services/base'

export default class PageResourceService extends BaseService {
  constructor(api) {
    const url = 'pageresource'
    super(api, url)
    this.api = api
    this.url = url
  }
}
