import BaseService from 'services/base'

export default class WebshopCategoryService extends BaseService {
  constructor(api) {
    const url = 'webshop'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchTable(payload) {
    return this.post('category/search', payload)
  }

  fetchCategoryTree(payload) {
    return this.get('categories/treelist', payload)
  }

  searchCategories(payload) {
    return this.post('category/search', payload)
  }
}
