import React from 'react'

import Card from 'common/Card'
import ReadOnlyField from 'common/ReadOnlyField'

export default ({ resource, pra }) => {
  return (
    <Card fieldset title={resource.OtherInformation}>
      <ReadOnlyField
        label={resource.Remarks}
        value={pra.remarks}
        formGroup
        horizontal
        className="word-wrap-break-word"
      />
    </Card>
  )
}
