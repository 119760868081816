import React from 'react'

export default ({ proposals, resource, filler }) => {
  const transposedRows = React.useMemo(() => {
    // Compute number of rows to display
    const rowsCount = proposals.reduce((count, item) => {
      const itemCount =
        (item.isSupplierVatExemp ? 1 : 0) + (item.isSupplierForeignWithVat ? 1 : 0) + (item.enableSbcFeeMessage ? 1 : 0)

      if (itemCount > count) {
        count = itemCount
      }
      return count
    }, 0)

    // Close proposal list before converting to vertical gri
    const clones = proposals.map(item => ({ ...item }))

    // Convert list to vertical grid using the cloned list
    return [...Array(rowsCount).keys()].map(_ => {
      return clones.reduce((list, item) => {
        const { isSupplierVatExemp, isSupplierForeignWithVat, enableSbcFeeMessage, ...rest } = item
        const newItem = { ...rest }
        if (isSupplierVatExemp) {
          newItem.isSupplierVatExemp = true
          delete item.isSupplierVatExemp
        } else if (isSupplierForeignWithVat) {
          newItem.isSupplierForeignWithVat = true
          delete item.isSupplierForeignWithVat
        } else if (enableSbcFeeMessage) {
          newItem.enableSbcFeeMessage = true
          delete item.enableSbcFeeMessage
        }
        list.push(newItem)
        return list
      }, [])
    })
  }, [proposals])

  const renderVatMessage = (message = '', proposal) => (
    <div
      className="word-wrap-break-word"
      dangerouslySetInnerHTML={{
        __html: message.replace('{{faqLink}}', proposal.faqLink),
      }}
    />
  )

  const renderSbcFeeMessage = (message = '', proposal) => (
    <div
      className="word-wrap-break-word"
      dangerouslySetInnerHTML={{
        __html: message
          .replace('{{addressableFee}}', proposal.addressableFee)
          .replace('{{nonAddressableFee}}', proposal.nonAddressableFee)
          .replace('{{sbcFeeMessageText}}', proposal.sbcFeeMessageText),
      }}
    />
  )

  return (
    <React.Fragment>
      {transposedRows.map((row, rowIndex) => (
        <tr key={rowIndex}>
          <th />
          {row.map((proposal, index) => (
            <td key={index} className="white-space-break-spaces white-space-normal">
              {proposal.isSupplierVatExemp && renderVatMessage(resource.VatExemptMessage, proposal)}
              {proposal.isSupplierForeignWithVat && renderVatMessage(resource.ForeignSupplierWithVatMessage, proposal)}
              {proposal.enableSbcFeeMessage && renderSbcFeeMessage(resource.SbcFeeMessage, proposal)}
            </td>
          ))}
          {filler.map(item => (
            <td key={item} className="bg-light" />
          ))}
        </tr>
      ))}
    </React.Fragment>
  )
}
