import React from 'react'

export default () => {
  return (
    <div id="forbidden">
      <h3>Forbidden</h3>
      <p>You are not authorized to access this page.</p>
    </div>
  )
}
