import React from 'react'

import moment from 'moment'

import Card from 'common/Card'
import DatePicker from 'common/Forms/DatePicker'

import SupplierPanel from './SupplierPanel'

export default ({ resource, commonResource, quote, disabled }) => {
  const startingDate = React.useMemo(() => moment().add(1, 'd').toDate(), [])
  const { supplier = {} } = quote

  return (
    <div className="d-flex flex-wrap justify-content-between">
      <div className="d-flex col-lg-6 col-12 pe-lg-5 pe-0 mb-4">
        <Card title={resource.QuoteInformation} outline className="flex-fill">
          <DatePicker
            name="expirationDate"
            rules={{ required: commonResource.Required }}
            label={resource.ExpirationDate}
            defaultValue={quote.expirationDate}
            disabledDays={[
              {
                before: startingDate,
              },
            ]}
            disabled={disabled}
            formGroup
            horizontal
          />
        </Card>
      </div>
      <SupplierPanel {...{ resource, supplier }} />
    </div>
  )
}
