import React from 'react'

import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { createSearchParams, useLocation, useParams, useSearchParams, Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import { ROUTES } from 'definitions'
import { titlelize } from 'utils'
import { useStores } from 'stores'
import Icon from 'common/Icon'

export default observer(({ resource }) => {
  const { webshopCategoryStore, webshopStore } = useStores()
  const location = useLocation()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [breadcrumbs, setBreadcrumbs] = React.useState([])

  const categoryPath = params['*']
  const criteria = searchParams.get('criteria')

  const flattenedCategoryTreeList = (categories, dictionary = {}) => {
    if (categories) {
      return categories.reduce((dictionary, item) => {
        dictionary[item.categoryPath] = {
          ...item,
          subCategories: null,
          categoryPath: null,
        }
        return flattenedCategoryTreeList(item.subCategories, dictionary)
      }, dictionary)
    }
    return dictionary
  }

  const getBreadCrumbsFromCategoryPath = React.useCallback((flatCategories, newCategoryPath) => {
    const newBreadcrumbs = []
    const tokens = newCategoryPath.split('/')
    tokens.reduce((path, token) => {
      path = path ? `${path}/${token}` : token
      const url = `${ROUTES.WEBSHOP_CATALOG}/${path}`

      const category = flatCategories[path] || { name: token }
      newBreadcrumbs.push({
        url,
        label: titlelize(category.name, ' '),
      })
      return path
    }, '')

    return newBreadcrumbs
  }, [])

  const flatCategories = React.useMemo(() => {
    if (webshopCategoryStore.CategoryTree.data.categories?.length) {
      return flattenedCategoryTreeList(webshopCategoryStore.CategoryTree.data.categories)
    }
    return []
  }, [webshopCategoryStore.CategoryTree.data.categories])

  React.useEffect(() => {
    if (location.pathname.toLowerCase().startsWith(ROUTES.WEBSHOP_CATALOG.toLowerCase())) {
      const newBreadcrumbs = getBreadCrumbsFromCategoryPath(flatCategories, categoryPath)
      setBreadcrumbs(newBreadcrumbs)
    }
  }, [categoryPath, flatCategories, getBreadCrumbsFromCategoryPath, location.pathname])

  React.useEffect(() => {
    if (location.pathname.toLowerCase().startsWith(ROUTES.WEBSHOP_SEARCH.toLowerCase())) {
      setBreadcrumbs([{ label: `Search results for "${criteria}"` }])
    }
  }, [criteria, location.pathname])

  React.useEffect(() => {
    if (location.pathname.toLowerCase().startsWith(ROUTES.WEBSHOP_PRODUCT.toLowerCase())) {
      if (webshopStore.CRUD.categoryPath && !webshopStore.CRUD.loading && webshopStore.CRUD.data.item?.name) {
        if (webshopStore.IsFromSearch && location.state?.previousPathname && location.state?.previousSearch) {
          const searchParams = createSearchParams(location.state.previousSearch)
          setBreadcrumbs([
            {
              url: `${location.state.previousPathname}${location.state.previousSearch}`,
              label: `Search results for "${searchParams.get('criteria')}"`,
            },
            { label: webshopStore.CRUD.data.item?.name },
          ])
        } else {
          const newBreadcrumbs = getBreadCrumbsFromCategoryPath(flatCategories, webshopStore.CRUD.categoryPath)
          newBreadcrumbs.push({ label: webshopStore.CRUD.data.item?.name })
          setBreadcrumbs(newBreadcrumbs)
        }
      }
    }
  }, [
    flatCategories,
    getBreadCrumbsFromCategoryPath,
    webshopStore.CRUD.categoryPath,
    webshopStore.CRUD.data.item?.name,
    webshopStore.CRUD.loading,
  ])

  React.useEffect(() => {
    if (location.pathname.toLowerCase().startsWith(ROUTES.WEBSHOP_CART.toLowerCase())) {
      setBreadcrumbs([{ label: resource.Cart }])
    }
  }, [location.pathname, resource.Cart])

  React.useEffect(() => {
    if (location.pathname.toLowerCase() === ROUTES.WEBSHOP_FAVOURITES.toLowerCase()) {
      setBreadcrumbs([{ label: resource.Favorites }])
    }
  }, [location.pathname, resource.Favorites])

  React.useEffect(() => {
    if (location.pathname.toLowerCase() === ROUTES.WEBSHOP.toLowerCase()) {
      setBreadcrumbs([])
    }
  }, [location.pathname])

  return (
    <Breadcrumb listClassName="mb-0 d-flex align-items-center fw-bold">
      <BreadcrumbItem className="d-inline-flex align-items-center">
        <Icon icon="cottage" filled className="me-1 md-28 text-primary" />
        <Link to={ROUTES.WEBSHOP}>{resource.Home}</Link>
      </BreadcrumbItem>
      {breadcrumbs.map((item, index) => {
        const isCurrentPage = index === breadcrumbs.length - 1
        return (
          <BreadcrumbItem key={index} active={isCurrentPage}>
            {isCurrentPage && item.label}
            {!isCurrentPage && <Link to={item.url}>{item.label}</Link>}
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  )
})
