import BaseService from 'services/base'

export default class ReportService extends BaseService {
  constructor(api) {
    const url = 'report'
    super(api, url)
    this.api = api
    this.url = url
  }

  downloadBlob(url, payload, options) {
    return this.get(url, payload, { ...options, responseType: 'blob' })
  }
}
