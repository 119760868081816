import React from 'react'

import { useFormContext } from 'react-hook-form'
import moment from 'moment'

import { PRA_GET_APPROVERS_TYPE } from 'definitions'
import Card from 'common/Card'
import DatePicker from 'common/Forms/DatePicker'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'

export default ({ authStore, praStore, resource, commonResource, session, disabled }) => {
  const { getValues } = useFormContext()
  const [approvers, setApprovers] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [selectedStartDate, setSelectedStartDate] = React.useState()

  React.useEffect(() => {
    const getApprovers = async () => {
      const loggedinEmployeeId = authStore.getLoginEmployeeId()

      setLoading(true)
      const { data: result } = await praStore.getApproversLookup({
        clientId: session.clientId,
        loggedinEmployeeId,
        type: PRA_GET_APPROVERS_TYPE.OUT_OF_OFFICE,
      })
      setLoading(false)

      if (result?.approvers) {
        setApprovers(result.approvers)
      }
    }
    getApprovers()
  }, [authStore, praStore, session.clientId])

  const defaults = React.useMemo(() => {
    if (session.outOfOffice) {
      const startDate = moment(session.outOfOffice.startDate)
      const endDate = moment(session.outOfOffice.endDate)

      return {
        employee: session.outOfOffice && {
          id: session.outOfOffice.employeeId,
          name: session.outOfOffice.name,
        },
        remark: session.outOfOffice.remark,
        startDate: startDate.isValid() ? startDate.toDate() : null,
        endDate: endDate.isValid() ? endDate.toDate() : null,
      }
    }

    return {}
  }, [session.outOfOffice])

  const handleStartDateChange = newStartDate => {
    setSelectedStartDate(newStartDate)
  }

  return (
    <React.Fragment>
      {session.hasPra && session.enableOutOfOffice && (
        <Card title={resource.OutOfOffice} fieldset>
          <ReactSelect
            name="outOfOffice.employee"
            defaultValue={defaults.employee}
            label={resource.OutOfOffice}
            placeholder={resource.NoOutOfOffice}
            options={approvers}
            optionValueKey="id"
            optionLabelKey="name"
            formGroup
            horizontal
            labelCol={{ lg: 3 }}
            inputCol={{ lg: 5 }}
            isLoading={loading}
            isDisabled={disabled}
            isClearable
          />
          <Input
            name="outOfOffice.remark"
            rules={{
              validate: value => {
                const employee = getValues('outOfOffice.employee')
                if (employee && !value) {
                  return commonResource.Required
                }
                return true
              },
            }}
            defaultValue={defaults.remark}
            label={resource.Remark}
            placeholder={resource.phRemark}
            formGroup
            horizontal
            labelCol={{ lg: 3 }}
            inputCol={{ lg: 5 }}
            disabled={disabled}
          />
          <DatePicker
            name="outOfOffice.startDate"
            rules={{
              validate: value => {
                const employee = getValues('outOfOffice.employee')
                if (employee && !value) {
                  return commonResource.Required
                }
                return true
              },
            }}
            defaultValue={defaults.startDate}
            label={resource.StartDate}
            formGroup
            horizontal
            labelCol={{ lg: 3 }}
            inputCol={{ lg: 5 }}
            disabledDays={[
              {
                before: new Date(),
              },
            ]}
            disabled={disabled}
            onChange={handleStartDateChange}
          />
          <DatePicker
            name="outOfOffice.endDate"
            rules={{
              validate: value => {
                const startDate = getValues('outOfOffice.startDate')

                if (startDate && value) {
                  const startDateMoment = moment(startDate)
                  const endDateMoment = moment(value)

                  if (endDateMoment.isBefore(startDateMoment)) {
                    return resource.EndDateGreaterThanStartDate
                  }
                }

                return true
              },
            }}
            defaultValue={defaults.endDate}
            label={resource.EndDate}
            placeholder={resource.phEndDate}
            formGroup
            horizontal
            labelCol={{ lg: 3 }}
            inputCol={{ lg: 5 }}
            disabled={disabled}
            disabledDays={[
              {
                before: selectedStartDate || defaults.startDate,
              },
            ]}
          />
        </Card>
      )}
    </React.Fragment>
  )
}
