import BaseService from 'services/base'

export default class WebshopService extends BaseService {
  constructor(api) {
    const url = 'webshop'
    super(api, url)
    this.api = api
    this.url = url
  }

  checkWebshopPermission(payload) {
    return this.get('items/check', payload)
  }

  fetchTable({ isByCategory, ...payload }) {
    if (isByCategory) {
      return this.post('category/path/items', payload)
    } else {
      return this.post('items/search', payload)
    }
  }

  getProductDetail({ itemId, ...payload }) {
    return this.get(`item/${itemId}`, payload)
  }

  getConfiguration(payload) {
    return this.post('config', payload)
  }

  getItemPricing(payload) {
    return this.post('item/pricing', payload)
  }

  searchItems(payload) {
    return this.post('items/search', payload)
  }
}
