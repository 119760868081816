import React from 'react'

import { CASE_TYPE } from 'definitions'
import { formatCurrency, formatDate } from 'utils/formatters'
import { padLeft } from 'utils'
import Button from 'common/Button'
import ButtonLink from 'common/ButtonLink'
import Icon from 'common/Icon'
import ReactTable from 'common/ReactTable'

export default ({
  session,
  resource,
  prData,
  loading,
  caseType,
  onViewPrLine,
  onEditPrLine,
  onCopyPrLine,
  onDeletePrLine,
}) => {
  const prLines = prData?.lines || []
  const isSelfService = caseType === CASE_TYPE.SelfService

  const columns = React.useMemo(
    () => [
      {
        accessor: 'lineIndex',
        headerClassName: 'text-muted',
        className: 'text-muted',
        width: 50,
        Header: '#',
        Cell: ({ row, value }) => <ButtonLink onClick={() => onViewPrLine(row)}>{padLeft(value, 1)}</ButtonLink>,
      },
      {
        accessor: 'description',
        headerClassName: 'col',
        className: 'col',
        Header: resource.Description,
        Cell: ({ value, row }) => (
          <div className="d-flex align-items-center">
            <ButtonLink
              title={value}
              className="word-break-break-word white-space-normal"
              onClick={() => onViewPrLine(row)}
            >
              {value}
            </ButtonLink>
            {row.comment && <Icon icon="chat_bubble" tag="sup" className="text-warning me-1 md-16" />}
          </div>
        ),
      },
      {
        accessor: 'deliveryInfo.deliveryDate',
        headerClassName: 'col-1',
        className: 'col-1',
        Header: resource.Delivery,
        Cell: ({ value }) => formatDate(value),
      },
      {
        accessor: 'budget',
        headerClassName: 'col-1 text-end',
        className: 'col-1 text-end',
        Header: resource.Budget,
        Cell: ({ value: budget }) => (!!budget && !!budget.value ? formatCurrency(budget.value) : '-'),
      },
      {
        accessor: 'quantity.amount',
        headerClassName: 'col-1 text-end',
        className: 'col-1 text-end',
        Header: resource.Qty,
      },
      {
        accessor: 'quantity.unit',
        headerClassName: 'col-1',
        className: 'col-1',
        Header: resource.Unit,
      },
      {
        headerClassName: 'col-3 border-start',
        className: 'col-3 border-start',
        Cell: ({ row }) => {
          return (
            <div>
              {(!isSelfService || !row.canBeEdited) && (
                <Button
                  icon="visibility"
                  title={resource.ViewLine}
                  color="default"
                  size="sm"
                  className="me-1"
                  onClick={() => onViewPrLine(row)}
                >
                  {resource.View}
                </Button>
              )}
              {isSelfService && row.canBeEdited && !session.isViewer && (
                <Button
                  icon="edit"
                  title={resource.EditLine}
                  color="primary"
                  size="sm"
                  className="me-1"
                  onClick={() => onEditPrLine(row)}
                >
                  {resource.Edit}
                </Button>
              )}
              {isSelfService && !session.isViewer && (
                <Button
                  icon="content_copy"
                  title={resource.CopyLine}
                  color="primary"
                  size="sm"
                  className="me-1"
                  disabled={row.deactivateLineToPropose}
                  onClick={() => onCopyPrLine(row)}
                >
                  {resource.Copy}
                </Button>
              )}
              {isSelfService && row.canBeEdited && !session.isViewer && (
                <Button
                  icon="clear"
                  title={resource.DeleteLine}
                  color="danger"
                  size="sm"
                  className="me-1"
                  disabled={row.deactivateLineToPropose}
                  onClick={() => onDeletePrLine(row)}
                >
                  {resource.Delete}
                </Button>
              )}
            </div>
          )
        },
      },
    ],

    []
  )

  return <ReactTable data={prLines} columns={columns} loading={loading} responsive />
}
