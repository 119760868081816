import BaseStore from 'stores/base'

export default class ProposalStore extends BaseStore {
  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options
  }

  async pickProposal(payload) {
    const { data, error } = await this.service.pickProposal(payload)
    return { data, error }
  }

  async getPunchoutRequest(payload) {
    const { data, error } = await this.service.getPunchoutRequest(payload)
    return { data, error }
  }

  async sendPunchoutRequest(payload) {
    const { data, error } = await this.service.sendPunchoutRequest(payload)
    return { data, error }
  }

  createShareUrl(payload) {
    return this.request(this.CRUD, () => this.service.createShareUrl(payload))
  }

  async rejectProposal(payload) {
    const { data, error } = await this.service.rejectProposal(payload)
    return { data, error }
  }
}
