import React from 'react'

import { useFormContext, useFormState } from 'react-hook-form'

import { getError } from 'utils/validators'

export default ({ tag: Component, name, label, rules = {}, onChange, onBlur, inputRef, ...props }) => {
  const { register } = useFormContext()
  const { errors } = useFormState()

  const { ref, onChange: fieldOnChange, onBlur: fieldOnBlur, ...field } = register(name, rules)

  const error = errors && getError(errors, name)

  return (
    <Component
      {...field}
      inputRef={theRef => {
        ref(theRef)
        inputRef && inputRef(theRef)
      }}
      helpTextType="error"
      helpText={error}
      label={label}
      onChange={event => {
        fieldOnChange(event)
        onChange && onChange(event)
      }}
      onBlur={event => {
        fieldOnBlur(event)
        onBlur && onBlur(event)
      }}
      {...props}
    />
  )
}
