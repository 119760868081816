import React from 'react'

import { DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'

import { ROUTES } from 'definitions'
import AttributesResult from './AttributesResult'
import CategoriesResult from './CategoriesResult'
import FavouritesResult from './FavouritesResult'
import ItemsResult from './ItemsResult'

export default ({ webshopStore, resource, clientName, criteria, results, onClose }) => {
  const { sbcItemResult, clientItemResult, attributeResult, categoryResult, favouriteResult } = results
  const searchUrl = `${ROUTES.WEBSHOP_SEARCH}?criteria=${criteria}`

  const showCategoryPanel =
    (!!categoryResult && !!categoryResult.categories && !!categoryResult.categories.length) || !!attributeResult
  const showFavouritesPanel = !!favouriteResult && !!favouriteResult.length

  const handleProductClick = () => {
    webshopStore.IsFromSearch = false
    onClose()
  }

  return (
    <DropdownItem text className="py-2 px-3 w-100 search-result">
      <div className="d-flex flex-md-nowrap flex-wrap">
        {showCategoryPanel && (
          <div className="search-result-panel">
            {categoryResult && (
              <CategoriesResult
                resource={resource}
                items={categoryResult.categories}
                total={categoryResult.total}
                onClose={onClose}
              />
            )}
            {attributeResult &&
              attributeResult.map(item => (
                <AttributesResult key={item.attributeName} attribute={item} searchUrl={searchUrl} onClose={onClose} />
              ))}
          </div>
        )}
        <div className="search-result-panel products ps-md-2">
          {clientItemResult && (
            <ItemsResult
              resource={resource}
              items={clientItemResult.items}
              title={clientName}
              total={clientItemResult.total}
              onClose={handleProductClick}
            />
          )}
          {sbcItemResult && (
            <ItemsResult
              resource={resource}
              items={sbcItemResult.items}
              title={resource.SpotBuyCenter}
              total={sbcItemResult.total}
              onClose={handleProductClick}
              sbcCatalog
            />
          )}
        </div>
        {showFavouritesPanel && (
          <div className="search-result-panel ps-md-2">
            <FavouritesResult resource={resource} items={favouriteResult} onClose={handleProductClick} />
          </div>
        )}
      </div>
      {(attributeResult || clientItemResult || sbcItemResult || categoryResult) && (
        <div className="text-end mb-2">
          <Link
            to={searchUrl}
            className="text-decoration-none border-bottom border-2 border-primary fw-semibold fs-16"
            onClick={onClose}
          >
            {resource.ShowAllResultsFor} <span className="fw-bolder">{criteria}</span>
          </Link>
        </div>
      )}
    </DropdownItem>
  )
}
