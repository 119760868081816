import React from 'react'

import { useStores } from 'stores'
import Input from 'common/Forms/Input'

import CartSummaryDeliveryAddress from './CartSummaryDeliveryAddress'
import CartSummaryDeliveryTo from './CartSummaryDeliveryTo'
import CartSummaryFooter from './CartSummaryFooter'
import CartSummaryMultipleAddress from './CartSummaryMultipleAddress'
import CartSummaryPreferredDate from './CartSummaryPreferredDate'

export default ({ cart, updateCart, disabled, saving }) => {
  const { authStore, pageResourceStore, webshopCartStore, clientRequesterStore } = useStores()

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { webshop: resource },
  } = pageResourceStore
  const {
    Table: { settings = {} },
  } = webshopCartStore  

  return (
    <div className="cart-summary py-3 px-2 mb-4 col-lg-auto col-12">
      {settings.enableProjectCode && (
          <Input
              name="projectCode"
              label={<h5 className="webshop-title-color fw-bolder mb-0">{resource.ProjectCode}</h5>}
              defaultValue={cart.projectCode}
              disabled={disabled}
              formGroup
              onChange={() => updateCart()}
          />
      )}
      <CartSummaryPreferredDate
        pageResourceStore={pageResourceStore}
        cart={cart}
        updateCart={updateCart}
        disabled={disabled}
      />
      {cart.isMultiAddress && (
        <CartSummaryMultipleAddress
          resource={resource}
          multiAddressAttachment={cart.multiAddressAttachment}
          onChange={() => updateCart()}
        />
      )}
      {!cart.isMultiAddress && (
        <CartSummaryDeliveryAddress
          resource={resource}
          webshopCartStore={webshopCartStore}
          clientId={session.clientId}
          cart={cart}
          disabled={disabled}
          updateCart={updateCart}
        />
      )}
      <CartSummaryDeliveryTo
        resource={resource}
        clientRequesterStore={clientRequesterStore}
        clientId={session.clientId}
        deliverTo={cart.deliveryInfo?.deliverTo}
        disabled={disabled}
        updateCart={updateCart}
      />
      <CartSummaryFooter resource={resource} session={session} cart={cart} submitting={disabled} saving={saving} />
    </div>
  )
}
