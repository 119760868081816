import { makeObservable, observable } from 'mobx'

import { ADDRESS_TYPE, DELIVER_TO } from 'definitions'
import { formatDate, parseDate } from 'utils/formatters'
import { toIntId } from 'utils'
import BaseStore, { defaults } from 'stores/base'

export default class ConnectionCartStore extends BaseStore {
  CartQuantity = 0
  Table = { ...defaults.Table, data: [] }

  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options

    makeObservable(this, {
      CartQuantity: observable,
    })
  }

  getCarts() {
    return this.getRequest(
      this.Table,
      () => this.service.get(),
      newData => {
        this.Table.data = newData.carts.map(cart => {
          const { items = [], deliveryInfo } = cart
          const { deliverTo, preferredDate } = deliveryInfo || {}

          return {
            ...cart,
            deliveryInfo: {
              ...deliveryInfo,
              addressType: deliveryInfo.addressType?.toLowerCase(),
              preferredDate: parseDate(preferredDate),
              deliverTo: {
                type: deliverTo.isOtherPerson ? DELIVER_TO.OTHER : DELIVER_TO.EMPLOYEE,
                clientRequester: deliverTo.clientRequesterId
                  ? {
                      key: deliverTo.clientRequesterId,
                      value: deliverTo.displayName,
                    }
                  : null,
                personName: deliverTo.personName,
              },
            },
            items: items.map(item => {
              const { category } = item
              return {
                ...item,
                category: category?.code ? category : null,
                subCategory: category?.subCategory ? { id: 0, name: category.subCategory } : null,
              }
            }),
          }
        })
      }
    )
  }

  async getCartQuantity() {
    const { data, error } = await this.service.getCartQuantity()
    const success = data && !error
    if (success) {
      this.CartQuantity = data.quantity
    }
    return { data, error }
  }

  updateCart({ cart, values }) {
    const { deliveryInfo, items } = values
    const { addressType, clientAddress, address, deliverTo = {}, preferredDate } = deliveryInfo || {}
    const isOtherPerson = deliverTo.type === DELIVER_TO.OTHER

    const otherAddress = address || cart.deliveryInfo?.address

    const payload = {
      cartId: cart.id,
      cart: {
        deliveryInfo: {
          preferredDate: formatDate(preferredDate),
          addressType,
          clientSiteId: toIntId(clientAddress?.id),
          address: addressType === ADDRESS_TYPE.CLIENT ? clientAddress?.address : otherAddress,
          deliverTo: {
            isOtherPerson,
            clientRequesterId: deliverTo.clientRequester?.key,
            displayName: deliverTo.clientRequester?.value,
            personName: deliverTo.personName,
          },
        },
        items: items.map(item => {
          const { category, subCategory, glAccount } = item
          return {
            itemIndex: item.itemIndex,
            category: {
              code: category?.code,
              name: category?.name,
              subCategory: subCategory?.name,
            },
            glAccount: glAccount && {
              id: glAccount.id,
              glAccount: glAccount.glAccount,
            },
            remarks: item.remarks,
          }
        }),
      },
    }

    this.CRUD.cartId = cart.id
    this.CRUD.action = 'save'
    return this.request(this.CRUD, () => this.service.put(cart.id, payload))
  }

  checkout(cartId) {
    this.CRUD.cartId = cartId
    this.CRUD.action = 'checkout'
    return this.request(this.CRUD, () => this.service.checkout(cartId))
  }

  getPunchout() {
    return this.getRequest(
      this.CRUD,
      () => this.service.getPunchout(),
      () => {
        // do not set newData to CRUD
      }
    )
  }
}
