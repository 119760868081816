import React from 'react'

import { FormProvider } from 'react-hook-form'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import cx from 'classnames'

import ButtonIcon from 'common/ButtonIcon'

export default ({
  isOpen,
  useForm,
  title,
  actions,
  children,
  footer,
  backdrop = 'static',
  keyboard = false,
  disableClose,
  hideClose,
  className,
  titleClassName,
  footerClassName,
  actionClassName,
  toggle,
  onClose,
  onSubmit,
  ...rest
}) => {
  return (
    <Modal
      isOpen={isOpen}
      keyboard={keyboard}
      backdrop={backdrop}
      className={className}
      contentClassName="paper paper-6 p-0"
      {...rest}
    >
      <ModalFormProvider useForm={useForm} onSubmit={onSubmit}>
        <ModalHeader
          toggle={toggle}
          close={
            <ButtonIcon
              title="Close modal"
              disabled={disableClose || hideClose}
              icon="close"
              className={cx(hideClose && 'd-none')}
              onClick={onClose || toggle}
            />
          }
        >
          <div className={cx('modal-title-label', titleClassName)}>{title}</div>
          {actions && <div className={cx('modal-title-actions', actionClassName)}>{actions}</div>}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        {footer && <ModalFooter className={cx(footerClassName)}>{footer}</ModalFooter>}
      </ModalFormProvider>
    </Modal>
  )
}

const ModalFormProvider = ({ useForm, children, onSubmit }) => {
  if (!useForm) return children

  return (
    <FormProvider {...useForm}>
      <form onSubmit={onSubmit}>{children}</form>
    </FormProvider>
  )
}
