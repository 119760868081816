import React from 'react'

import { observer } from 'mobx-react'

import { useFieldArray, useFormContext } from 'react-hook-form'
import cx from 'classnames'

import { FlexTable, FlexTableCell, FlexTableRow } from 'common/FlexTable'
import { formatCurrency, formatNumber } from 'utils/formatters'
import { useStores } from 'stores'
import ButtonIcon from 'common/ButtonIcon'
import Icon from 'common/Icon'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'
import Toaster from 'common/Toaster'

import CartItemCategory from './CartTableCategory'

export default observer(({ cart, disabled, updateCart, onClearCart }) => {
  const { authStore, pageResourceStore, webshopCartStore } = useStores()
  const { control, getValues, setValue  } = useFormContext()
  const { fields, remove, replace } = useFieldArray({
    control,
    name: 'items',
    shouldUnregister: true,
  })

  const {
    AppSession: { session },
  } = authStore
  const {
    PageResource: { connections: resource },
  } = pageResourceStore
  const {
    Lookups: { data: { categories = [], glAccounts = [] } = {} },
  } = webshopCartStore

  React.useEffect(() => {
    replace(cart.items)
  }, [cart, replace])

  const updteTotal = (fieldId) => {    
    const remainingItems = fields.filter((item) => {
        return item.id !== fieldId
    })
    const total = remainingItems.reduce((a,b) =>
      a + b.subTotal, 0
    )
    setValue('cartTotal', total)
  }

  const handleDeleteCartItem = (itemIndex, fieldId) => {
    const isClearCart = fields.length <= 1

    if (isClearCart) {
      onClearCart()
    } else {
      Toaster.confirm({
        title: resource.DeleteCartItem,
        message: resource.ConfirmDeleteCartItem,
        submitText: resource.Confirm,
        cancelText: resource.Cancel,
        // closeOnSubmit: !isClearCart,
        onSubmit: () => {
          remove(itemIndex) 
          updteTotal(fieldId)        
          updateCart()          
        },
      })
    }
  }

  return (
    <FlexTable
      className="cart-table pe-lg-2 col-lg col-12 mb-md-0 mb-4"
      headerClassName="fs-12 fw-normal border-top"
      breakpoint="xl"
      columns={[
        { className: 'col-action' },
        { className: 'col align-items-end', Header: resource.Product },
        { className: 'col-2 justify-content-end align-items-end text-end', Header: resource.Quantity },
        { className: 'col-2 justify-content-end align-items-end text-end', Header: resource.PriceWithouVat },
        { className: 'col-2 justify-content-end align-items-end text-end', Header: resource.SubtotalWithoutVat },
      ]}
    >
      {fields.map((field, index) => {
        const lineItem = getValues(`items.${index}`)
        const subTotal = field.quantity * field.price
        const isEven = index % 2 === 0

        return (
          <React.Fragment key={field.id}>
            <FlexTableRow className={cx('border-0 pt-3', isEven && 'table-striped-bg')}>
              <FlexTableCell className="col-xl-action col-auto align-items-start px-0">
                <ButtonIcon
                  icon="delete"
                  color="primary"
                  title={resource.RemoveCartItem}
                  iconClassName="md-22"
                  disabled={disabled}
                  onClick={() => handleDeleteCartItem(index, field.id)}
                />
              </FlexTableCell>
              <FlexTableCell className="col d-flex flex-wrap align-items-start">
                <Input type="hidden" name={`items.${index}.itemIndex`} />
                <div
                  className={cx('product-image col-auto mb-1', !field.imageUrl && 'no-image')}
                  style={field.imageUrl ? { backgroundImage: `url('${field.imageUrl}')` } : {}}
                >
                  {!field.imageUrl && <Icon icon="image" />}
                </div>
                <div className={cx('col-sm col-12', !!field.imageUrl && 'ms-sm-3')}>
                  <div className="fw-bold fs-14">{field.desciption}</div>
                  <div className="d-xl-none d-flex">
                    <span className="fw-bold col-7">{resource.Quantity}:</span>
                    <span className="col-5">{formatNumber(field.quantity)}</span>
                  </div>
                  <div className="d-xl-none d-flex">
                    <span className="fw-bold col-7">{resource.PriceWithouVat}:</span>
                    <span className="col-5">{formatCurrency(field.price)}</span>
                  </div>
                  <div className="d-xl-none d-flex">
                    <span className="fw-bold col-7">{resource.SubtotalWithoutVat}:</span>
                    <span className="col-5">{formatCurrency(subTotal)}</span>
                  </div>
                </div>
              </FlexTableCell>
              <FlexTableCell className="col-2 d-xl-flex d-none align-items-start justify-content-end">
                {formatNumber(field.quantity)}
              </FlexTableCell>
              <FlexTableCell className="col-2 d-xl-flex d-none align-items-start justify-content-end">
                {formatCurrency(field.price)}
              </FlexTableCell>
              <FlexTableCell className="col-2 d-xl-flex d-none align-items-start justify-content-end">
                {formatCurrency(subTotal)}
              </FlexTableCell>
            </FlexTableRow>
            <FlexTableRow className={cx(isEven && 'table-striped-bg align-items-start')}>
              <FlexTableCell className="col-action d-xl-flex d-none" />
              <FlexTableCell className="col-xl col-12 pb-xl-2 pb-0 flex-wrap">
                <CartItemCategory
                  index={index}
                  resource={resource}
                  showSubCategories={session.shouldSelectSubcategory}
                  categories={categories}
                  category={lineItem?.category}
                  subCategory={lineItem?.subCategory}
                  disabled={disabled}
                  updateCart={updateCart}
                />
                {session.enableGLAccount && (
                  <ReactSelect
                    name={`items.${index}.glAccount`}
                    rules={{ required: resource.Required }}
                    label={resource.GLAccount}
                    placeholder={resource.SelectGLAccount}
                    options={glAccounts}
                    optionValueKey="id"
                    optionLabelKey="glAccount"
                    isClearable
                    isDisabled={disabled}
                    formGroup
                    formClassName="w-100"
                    labelClassName="d-xl-none"
                    onChange={() => updateCart()}
                  />
                )}
              </FlexTableCell>
              <FlexTableCell className="col-xl-6 col pt-xl-2 pt-0 align-items-start">
                <Input
                  name={`items.${index}.remarks`}
                  type="textarea"
                  rows="3"
                  disabled={disabled}
                  formGroup
                  label={resource.Remarks}
                  formClassName="col-12"
                  labelClassName="d-xl-none"
                  onChange={() => updateCart()}
                />
              </FlexTableCell>
            </FlexTableRow>
          </React.Fragment>
        )
      })}
    </FlexTable>
  )
})
