import React from 'react'

import { formatDate, praLongDateTimeFormat } from 'utils/formatters'
import { PRA_STATUS } from 'definitions'
import Icon from 'common/Icon'

export default ({ data, resource }) => (
  <small className="text-muted d-flex flex-wrap px-1">
    <div className="d-flex align-items-center mb-1 me-2">
      <span>
        <strong>{resource.PR}: </strong>
        {data.reference}
      </span>
      {data.status === PRA_STATUS.APPROVED.id && (
        <span>
          <strong>{resource.PO}: </strong>
          {data.poReference}
        </span>
      )}
    </div>
    {data.requester && (
      <div className="d-flex align-items-center mb-1 me-2">
        <Icon icon="person" filled className="md-12 me-1 text-muted" />
        <span className="requester-name">{data.requester.name}</span>
      </div>
    )}
    {data.dateCreated && (
      <div className="d-flex align-items-center mb-1 me-2">
        <Icon icon="schedule" filled className="md-12 me-1 text-muted" />
        <span title={resource.dateCreated}>{formatDate(data.dateCreated, praLongDateTimeFormat)}</span>
      </div>
    )}
    <div className="d-flex align-items-center mb-1 me-2">
      <Icon icon="edit" filled className="md-12 me-1 text-muted" />
      <span>{data.creator.name}</span>
    </div>
  </small>
)
