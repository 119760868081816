import React from 'react'

import cx from 'classnames'

export default ({ icon, tag: Tag = 'span', id, filled, title, className, style }) => {
  return (
    <Tag
      id={id}
      title={title}
      className={cx(
        'icon',
        {
          'material-icons': filled,
          'material-icons-outlined': !filled,
        },
        className
      )}
      style={style}
    >
      {icon}
    </Tag>
  )
}
