import React from 'react'

import cx from 'classnames'

import { useStateCallback } from 'hooks/useStateCallback'
import Icon from 'common/Icon'

export default ({ tabs = [], activeTab: theActiveTab, className, tabContentClassName, onChange }) => {
  const [activeTab, setActiveTab] = useStateCallback(theActiveTab)
  const renderedTagsRef = React.useRef(activeTab !== null || activeTab !== undefined ? [activeTab] : [])
  const renderedTabs = renderedTagsRef.current

  const toggleTab = newActiveTab => {
    if (activeTab === newActiveTab) {
      // Re-render active tab
      setActiveTab(null, () => setActiveTab(newActiveTab))
    } else {
      !renderedTabs.includes(newActiveTab) && renderedTabs.push(newActiveTab)
      setActiveTab(newActiveTab, () => {
        onChange && onChange(newActiveTab)
      })
    }
  }

  const filteredTabs = React.useMemo(() => tabs.filter(tab => !tab.hidden), [tabs])

  return (
    <div className={cx('tabs', className)}>
      <nav className="nav-tabs d-flex flex-wrap" role="tablist">
        {filteredTabs.map(tab => (
          <button
            key={tab.key}
            className={cx('nav-tab-button', activeTab === tab.key && 'active')}
            onClick={() => toggleTab(tab.key)}
          >
            {tab.icon && <Icon icon={tab.icon} filled={tab.iconFilled} />}
            {tab.label}
            <div className="active-indicator" />
          </button>
        ))}
      </nav>
      {filteredTabs
        .filter(tab => renderedTabs.includes(tab.key))
        .map(tab => {
          const activated = activeTab === tab.key
          return (
            <div key={tab.key} className={cx('tab-content', !activated && 'd-none', tabContentClassName)}>
              {tab.content(activated)}
            </div>
          )
        })}
    </div>
  )
}
