import BaseStore from 'stores/base'

export default class ClientSupplierStore extends BaseStore {
  constructor(service) {
    super(service)
    this.service = service
  }

  importClientSupplier(payload) {
    return this.request(this.CRUD, () => this.service.importClientSupplier(payload))
  }
}
