import React from 'react'

import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import cx from 'classnames'

import Button from 'common/Button'

export default ({
  id,
  children,
  disabled,
  isOpen: isOpenProps,
  toggle: toggleProps,
  toggleTag: ToggleTagComponent = 'div',
  toggleContent,
  toggleButtonOptions,
  customToggle,
  caret,
  direction,
  className,
  toggleClassName,
  menuClassName,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    isOpenProps !== undefined && setIsOpen(isOpenProps)
  }, [isOpenProps])

  const toggle = () => {
    if (toggleProps) {
      toggleProps()
    } else {
      setIsOpen(value => !value)
    }
  }

  return (
    <Dropdown
      id={id}
      isOpen={isOpen}
      className={cx(className)}
      toggle={toggle}
      direction={direction}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <DropdownToggle
        tag={ToggleTagComponent}
        className={cx('d-inline-flex align-items-center', toggleClassName, !disabled && 'cursor-pointer')}
      >
        {!customToggle && (
          <Button color="default" {...(caret ? { iconEnd: 'arrow_drop_down' } : {})} {...toggleButtonOptions}>
            {toggleContent}
          </Button>
        )}
        {customToggle}
      </DropdownToggle>
      <DropdownMenu className={cx('paper paper-2 border-0', menuClassName)}>{children}</DropdownMenu>
    </Dropdown>
  )
}
