import React from 'react'

import { DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useWatch } from 'react-hook-form'

import { ROUTES } from 'definitions'
import { selectMany } from 'utils/array'
import { useDebounce } from 'hooks/useDebounce'
import { useStores } from 'stores'
import Button from 'common/Button'
import Dropdown from 'common/Dropdown'
import ItemHasStockStop from '../../ItemHasStockStop'
import NumberControl from 'common/Forms/NumberControl'

export default observer(({ resource, data, setPricingTier, onBeforeChange }) => {
  const { webshopCartStore } = useStores()
  const debounceSetPricingTier = useDebounce(setPricingTier)
  const pricing = useWatch({ name: 'pricing' })

  const {
    CRUD: { submitting },
  } = webshopCartStore

  const variants = React.useMemo(() => {
    if (data.itemFamilies?.length) {
      const newVariants = selectMany(data.itemFamilies, 'itemFamilyVariants')

      return {
        selectedVariant: newVariants.find(item => item.id === data.itemId),
        familyVariants: newVariants.filter(item => item.id !== data.itemId),
      }
    }
    return {}
  }, [data.itemFamilies, data.itemId])

  const isValidProduct = React.useMemo(() => {
    return !!data.supplierCode && !!pricing
  }, [data.supplierCode, pricing])

  return (
    <div className="bg-webshop rounded p-5 mb-5">
      {!!variants.familyVariants?.length && (
        <div className="mb-3">
          <h3 className="fs-20 fw-bolder webshop-title-color mb-1">{resource.OtherOptions}</h3>
          <Dropdown
            disabled={submitting}
            caret
            toggleContent={variants.selectedVariant?.variantDescription || ''}
            toggleButtonOptions={{
              color: 'white',
              className: 'fs-18',
              disabled: submitting,
            }}
          >
            {!!variants.familyVariants &&
              variants.familyVariants.map(item => (
                <DropdownItem
                  key={item.id}
                  tag={Link}
                  to={`${ROUTES.WEBSHOP_PRODUCT}/${item.id}`}
                  className="text-decoration-none"
                >
                  {item.variantDescription}
                </DropdownItem>
              ))}
          </Dropdown>
        </div>
      )}
      <div className="mb-5 border-bottom col-md-12 col-sm-6 col-12">
        <h3 className="fs-20 fw-bolder webshop-title-color mb-1">{resource.Quantity}</h3>
        <NumberControl
          name="quantity"
          rules={{ required: resource.Required }}
          defaultValue={data.minimumOrderQty}
          step={data.quantityStep || 1}
          min={data.minimumOrderQty || 1}
          max={data.stock}
          formGroup
          className="fs-18"
          formClassName="mb-5"
          iconClassName="md-28"
          disabled={submitting}
          onChange={debounceSetPricingTier}
          onBeforeChange={newQuantity => onBeforeChange(newQuantity, data.stock)}
        />
        <ItemHasStockStop name={`item.itemStockStop`} resource={resource} stock={data.stock} />
      </div>
      <Button type="submit" size="lg" disabled={!isValidProduct || submitting} loading={submitting} className="w-100">
        {resource.AddToCart}
      </Button>
    </div>
  )
})
