import { makeObservable, observable } from 'mobx'

import BaseStore, { defaults } from 'stores/base'

export default class CaseStore extends BaseStore {
  AbortedCase = defaults.CRUD

  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options

    makeObservable(this, {
      AbortedCase: observable,
    })
  }

  abortCase(caseId, payload) {
    return this.request(this.AbortedCase, () => this.service.abortCase(caseId, payload))
  }

  copyCase(caseId) {
    return this.request(this.CRUD, () => this.service.copyCase(caseId))
  }

  async getWebshopCheckoutMessage() {
    const { data, error } = await this.service.getWebshopCheckoutMessage()
    return !error && data
  }

  async getProposalSelectedMessage() {
    const { data, error } = await this.service.getProposalSelectedMessage()
    return !error && data
  }

  releaseCase(caseId) {
    return this.request(this.CRUD, () => this.service.releaseCase(caseId))
  }

  webshopPunchout(caseId) {
    return this.request(this.CRUD, () => this.service.webshopPunchout(caseId))
  }
}
