import React from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'stores'
import ContentLoader from 'common/ContentLoader'

import OrderList from './OrderList'
import OrderViewModal from './OrderViewModal'
import PoLineViewModal from './PoLineViewModal'
import PurchaseOrderTable from './PurchaseOrderTable'

export default observer(({ activated, caseId, caseNumber }) => {
  const { pageResourceStore, orderStore } = useStores()
  const [poViewModalOption, setPoViewModalOption] = React.useState({})
  const [orderViewModalOption, setOrderViewModalOption] = React.useState({})

  const {
    PageResource: { case: resource = {}, common: commonResource = {} },
  } = pageResourceStore
  const {
    Table: {
      data: { order: orderData },
      loading,
    },
  } = orderStore

  React.useEffect(() => activated && orderStore.fetchTable(caseId), [orderStore, caseId, activated])

  const handleViewPoLine = data => setPoViewModalOption({ isOpen: !!data, data })

  const handleViewOrder = data => setOrderViewModalOption({ isOpen: !!data, data })

  return (
    <div className="position-relative">
      <ContentLoader show={loading} />
      {orderData && (
        <React.Fragment>
          <PurchaseOrderTable
            resource={resource}
            orderData={orderData}
            loading={loading}
            onViewPoLine={handleViewPoLine}
          />
          <hr className="triangle" />
          <OrderList resource={resource} orderData={orderData} onViewOrder={handleViewOrder} />
          {poViewModalOption.isOpen && (
            <PoLineViewModal
              resource={resource}
              commonResource={commonResource}
              caseNumber={caseNumber}
              data={poViewModalOption.data}
              onClose={() => handleViewPoLine()}
            />
          )}
          {orderViewModalOption.isOpen && (
            <OrderViewModal
              resource={resource}
              commonResource={commonResource}
              data={orderViewModalOption.data}
              onClose={() => handleViewOrder()}
            />
          )}
        </React.Fragment>
      )}
    </div>
  )
})
