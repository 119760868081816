import React from 'react'

import cx from 'classnames'

import Icon from 'common/Icon'

export default ({ supplier = {}, resource, hideName, className }) => {
  const { address } = supplier
  return (
    <div className={cx(className)}>
      {!hideName && (
        <div className="mb-2 fw-bold">
          {resource.Supplier}: {supplier.name}
        </div>
      )}
      <dl className="dl-horizontal dl-icons mb-0 border rounded p-2">
        <dd className="d-flex align-items-center">
          <Icon icon="place" className="md-16 align-self-start pt-1 me-3" />
          {address?.street} {address?.number}
          <br />
          {address?.postalCode} {address?.city}
          <br />
          {address?.country?.name}
          <br />
        </dd>
        <dd className="d-flex align-items-center">
          <Icon icon="phone" className="md-16 me-3" />{' '}
          {supplier.telephone ? supplier.telephone : <em className="text-muted">/</em>}
        </dd>
        <dd className="d-flex align-items-center">
          <Icon icon="email" className="md-16 me-3" />{' '}
          {supplier.emailAddress ? supplier.emailAddress : <em className="text-muted">/</em>}
        </dd>
      </dl>
    </div>
  )
}
