import React from 'react'

import { Container } from 'reactstrap'
import logoImage from 'assets/images/spot-buy-logo-120.jpg'

export default ({ children }) => {
  return (
    <React.Fragment>
      <header className="py-1 text-center paper paper-1 public">
        <div className="brand">
          <img src={logoImage} alt="Oazo logo" />
        </div>
      </header>
      <Container fluid>{children}</Container>
    </React.Fragment>
  )
}
