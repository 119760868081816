import React from 'react'

import { Badge } from 'reactstrap'

import { formatDateTime } from 'utils/formatters'
import Icon from 'common/Icon'
import PdfLink from 'common/PdfLink'

import { TableListStatus, TableListStatusItem } from 'components/Case/CaseDetail/TableList'

export default ({ resource, orderItem }) => {
  const { order, status } = orderItem

  const isCancelled = !!order.cancelReason

  return (
    <TableListStatus>
      <TableListStatusItem>
        <div className="d-flex align-items-center">
          <Badge color={status.orderSent ? 'success' : 'danger'}>
            <Icon icon={status.orderSent ? 'done' : 'clear'} className="md-12" />
          </Badge>
          <span className="ms-2 me-1">
            {order.isDummyOrder && resource.SimulatedOrderFinal}
            {order.isRequestForPI && resource.InvoiceRequestSent}
            {!order.isDummyOrder && !order.isRequestForPI && resource.OrderSent}
          </span>
          {order.pdfFileId && (
            <PdfLink fileId={order.pdfFileId} fileName={order.documentNumber} title={resource.DownloadRfqPdf} />
          )}
        </div>
        {status.orderSent && (
          <small className="text-muted">
            <em>{formatDateTime(status.dateOrderSent, 'DD MMMM YYYY H:mm')}</em>
          </small>
        )}
      </TableListStatusItem>
      {isCancelled && (
        <TableListStatusItem className="flex-column">
          <div className="d-flex w-100">
            <div className="d-flex align-items-center">
              <Badge color="danger">
                <Icon icon="block" className="md-12" />
              </Badge>
              <span className="ms-2 me-1">{resource.OrderCancelled}</span>
              {order.cancelledOrderPdfFileId && (
                <PdfLink
                  fileId={order.cancelledOrderPdfFileId}
                  fileName={order.documentNumber}
                  title={resource.DownloadRfqPdf}
                />
              )}
            </div>
            {order.dateOrderCancelled && (
              <small className="text-muted ms-auto">
                <em>{formatDateTime(order.dateOrderCancelled, 'DD MMMM YYYY H:mm')}</em>
              </small>
            )}
          </div>
          <div className="text-danger align-self-start mt-3">{order.cancelReason || ''}</div>
        </TableListStatusItem>
      )}
      {!status.orderSent && status.orderSendScheduled && (
        <TableListStatusItem>
          <div className="d-flex align-items-center">
            <Badge color="success">
              <Icon icon="done" className="md-12" />
            </Badge>
            <span className="ms-2 me-3">{resource.Scheduled}</span>
          </div>
          <small className="text-muted">
            <em>{formatDateTime(status.dateOrderSendScheduled, 'DD MMMM YYYY H:mm')}</em>
          </small>
        </TableListStatusItem>
      )}
    </TableListStatus>
  )
}
