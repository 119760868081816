import React from 'react'

import { useFormContext } from 'react-hook-form'

import { PRA_APPROVER_STATUS, PRA_GET_APPROVERS_TYPE, PRA_STATUS } from 'definitions'
import Button from 'common/Button'
import Input from 'common/Forms/Input'
import Popup from 'common/Popup'
import ReactSelect from 'common/Forms/ReactSelect'

export default ({
  praStore,
  resource,
  session,
  target,
  pra,
  settings,
  approver,
  loggedinEmployeeId,
  action,
  title,
  creatorOrAdmin,
  onClose,
  onSubmit,
}) => {
  const buttonDoneRef = React.useRef()
  const [loading, setLoading] = React.useState()
  const [options, setOptions] = React.useState([])
  const [selectedApprover, setSelectedApprover] = React.useState()
  const { clearErrors, getValues, setValue, trigger } = useFormContext()

  const namePrefix = `currentApproverEdit.${action}`
  const isReassign = action === 'reassign'
  const isQuestion = action === 'question'
  const isApprove = action === 'approve'

  React.useEffect(() => {
    // Get approvers for re-assign
    if (isReassign) {
      const getApprovers = async () => {
        setLoading(true)
        setOptions([])

        let requestorId
        if (creatorOrAdmin && settings.approvalReassignMode === 2) {
          requestorId = approver.id
        } else if (settings.approvalReassignMode === 2) {
          requestorId = loggedinEmployeeId
        } else if (pra.requester) {
          requestorId = pra.requester.id
        } else {
          requestorId = loggedinEmployeeId
        }

        const { data, error } = await praStore.getApproversLookup({
          clientId: pra.clientId,
          creatorId: pra.creatorId,
          currentApproverId: pra.currentApproverId?.id,
          praId: pra.id,
          requestorId,
          type: PRA_GET_APPROVERS_TYPE.FOR_REASSIGN_APPROVERS,
        })
        setLoading(false)

        const success = !error && data
        if (success) {
          const newApporvers = data.approvers.filter(item => item.id !== approver.id)
          setOptions(newApporvers)
        }
      }
      getApprovers()
    }
  }, [])

  React.useEffect(() => {
    // Get approvers for asking question
    if (isQuestion) {
      setOptions([])
      setLoading(false)
      const getApprovers = async () => {
        const result = []
        const approvers = pra.approvers
        let availableApprovers = approvers

        // check if pra has been relaunch
        if (approvers.some(approver => approver.status === PRA_APPROVER_STATUS.RELAUNCHED)) {
          // if yes, get only the approvers after the last relaunch
          availableApprovers = approvers.slice()
          availableApprovers.reverse() // the reverse is to get the last possible relaunch status

          // get the approvers after last relaunch
          const lastRelaunchIndex = availableApprovers.indexOf(
            availableApprovers.find(approver => approver.status === PRA_APPROVER_STATUS.RELAUNCHED)
          )
          if (lastRelaunchIndex > 0) {
            availableApprovers = availableApprovers.slice(0, lastRelaunchIndex)
          }
          availableApprovers.reverse()
        }

        availableApprovers.forEach(approver => {
          if (approver.id !== pra.currentApproverId.id) {
            const exists = result.some(item => item.id === approver.id)

            if (!exists) {
              // past approvers must be either the creator or person who approves previously
              if (approver.id === pra.creator.id || approver.status === 1) {
                result.push({
                  id: approver.id,
                  name: approver.name,
                })
              }
            }
          }
        })

        setOptions(result)
      }
      getApprovers()
    }
  }, [])

  React.useEffect(() => {
    isApprove && handleSubmit(true)
  }, [isApprove])

  const handleSubmit = async skipClose => {
    const fields = [`${namePrefix}.comment`]
    if (isReassign || isQuestion) {
      fields.push('reassignTo')
    }

    const result = await trigger(fields)

    if (result) {
      const comment = getValues(`${namePrefix}.comment`)
      setValue('currentApproverEdit.lineIndex', approver.lineIndex)
      setValue('currentApproverEdit.comment', comment)
      setValue('action', action)
      setValue('nextState', PRA_STATUS.PENDING.id)

      // eslint-disable-next-line default-case
      switch (action) {
        case 'approve':
          setValue('currentApproverEdit.status', PRA_APPROVER_STATUS.APPROVED)
          setValue('reassignTo', null)
          break
        case 'reject':
          setValue('currentApproverEdit.status', PRA_APPROVER_STATUS.REJECTED)
          setValue('reassignTo', null)
          break
        // case 'Relaunch':
        //   return resource.Relaunch
        case 'reassign': {
          setValue('currentApproverEdit.status', PRA_APPROVER_STATUS.REASSIGNED)
          setValue('reassignTo', {
            by: approver.id,
            reason: comment,
            to: selectedApprover.id,
            lineIndex: approver.lineIndex,
          })

          const approversCopy = pra.approvers.slice().reverse()
          const currentApproverWithQuestion = approversCopy.find(
            item => item.id === pra.currentApproverId.id && item.question && item.question.pendingQuestion
          )
          const question =
            currentApproverWithQuestion != null && currentApproverWithQuestion.question
              ? {
                  to: currentApproverWithQuestion.question.to,
                  by: currentApproverWithQuestion.id,
                  pendingQuestion: true,
                }
              : null
          setValue('question', question)
          setValue('isAdministratorProxy', session.isAdministratorProxy)
          break
        }
        case 'question':
          setValue('currentApproverEdit.status', PRA_APPROVER_STATUS.QUESTIONED)
          setValue('reassignTo', null)
          setValue('question', {
            by: approver.id,
            to: selectedApprover.id,
            pendingQuestion: true,
          })
          break
        case 'answer': {
          setValue('currentApproverEdit.status', PRA_APPROVER_STATUS.ANSWERED)
          setValue('reassignTo', null)

          const answers = getValues('answers') || []
          answers.push({
            lineIndex: approver.lineIndex,
            comment,
          })
          setValue('answers', answers)
          break
        }
      }

      !skipClose && onClose()
      onSubmit()
    }
  }

  const handleCancel = () => {
    clearErrors([`${namePrefix}.comment`, 'reassignTo'])
    setValue(`${namePrefix}.comment`, null)
    onClose()
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      buttonDoneRef.current.click()
    }
  }

  return (
    <Popup
      isOpen
      target={target}
      title={title}
      className="approval-flow-popup"
      placement="auto-end"
      trigger="legacy"
      toggle={handleCancel}
    >
      {(isReassign || isQuestion) && (
        <ReactSelect
          name="reassignTo"
          rules={{ required: resource.Required }}
          placeholder={`${resource.Select}...`}
          formGroup
          autoFocus
          options={options}
          optionValueKey="id"
          optionLabelKey="name"
          isLoading={loading}
          noOptionsMessage={!loading ? resource.NoMoreApprover : '  '}
          onChange={selection => setSelectedApprover(selection)}
        />
      )}
      <Input
        name={`${namePrefix}.comment`}
        formGroup
        {...(isReassign || isQuestion
          ? {
              rules: { required: !!selectedApprover && resource.Required },
              disabled: !selectedApprover,
            }
          : {
              rules: { required: !isApprove && resource.Required },
              autoFocus: true,
            })}
        onKeyPress={handleKeyPress}
      />
      <div className="text-end">
        <Button innerRef={buttonDoneRef} type="button" onClick={() => handleSubmit()}>
          {resource.Done}
        </Button>
      </div>
    </Popup>
  )
}
