import React from 'react'

import { useFieldArray, useFormContext } from 'react-hook-form'

import cx from 'classnames'

import { FlexTable, FlexTableCell, FlexTableRow } from 'common/FlexTable'
import { formatNumber } from 'utils/formatters'
import { PRA_STATUS, PRA_TYPE } from 'definitions'
import Button from 'common/Button'
import Card from 'common/Card'
import Input from 'common/Forms/Input'
import ReadOnlyField from 'common/ReadOnlyField'

import PraFormProductItem from './PraFormProductItem'

export default ({
  resource,
  isEdit,
  isCopy,
  data,
  settings,
  disabled,
  fetchApprovers,
  isReadOnly,
  onShowGoodsReceived,
}) => {
  const { control, getValues, trigger } = useFormContext()
  const { append, fields, remove, replace, update } = useFieldArray({
    control,
    name: 'lines',
    shouldUnregister: true,
  })

  const showDelete = !isReadOnly && fields.length > 1 && ![PRA_TYPE.WEBFORM, PRA_TYPE.WEBSHOP, PRA_TYPE.CONNECTION].includes(data.type)
  const showCopy = !isReadOnly && fields.length && ![PRA_TYPE.WEBFORM, PRA_TYPE.WEBSHOP, PRA_TYPE.CONNECTION].includes(data.type)

  React.useEffect(() => {
    if (isEdit || isCopy) {
      replace(data.lines)
    } else {
      replace([{ description: '' }])
    }
  }, [isEdit, isCopy, data, replace])

  const handleAddLine = () => {
    const firstLine = getValues('lines.0')

    append({
      description: '',
      currency: firstLine.currency,
    })
  }

  const handleDeleteLine = lineIndex => remove(lineIndex)

  const handleCopyLine = index => {
    const theLine = getValues(`lines.${index}`)
    append({ ...theLine })
  }

  const handleOnChange = (index, key, value) => {
    if (index === 0) {
      const lines = getValues('lines')
      lines &&
        lines.forEach((line, index) => {
          if (index > 0) {
            switch (key) {
              case 'deliveryInfo':
                if (line.deliveryInfo && !line.deliveryInfo[value.key]) {
                  line.deliveryInfo[value.key] = value.value
                  update(index, line)
                  trigger(`lines.${index}.deliveryInfo.${value.key}`)
                }
                break
              default:
                if (!line[key]) {
                  line[key] = value
                  update(index, line)
                  trigger(`lines.${index}.${key}`)
                }
                break
            }
          }
        })
    }
  }

  return (
    <Card
      color="primary"
      outline
      title={data.status === PRA_STATUS.APPROVED.id ? resource.PurchaseOrder : resource.PurchaseRequest}
      actions={
        settings.enableGoodsReceived && (
          <Button type="button" icon="local_shipping" color="primary" size="sm" onClick={onShowGoodsReceived}>
            {resource.ManageGoodsReceived}
          </Button>
        )
      }
      footer={
        !isReadOnly &&
        ![PRA_TYPE.WEBFORM, PRA_TYPE.WEBSHOP, PRA_TYPE.CONNECTION].includes(data.type) && (
          <Button color="primary" icon="add" onClick={handleAddLine} disabled={disabled}>
            {resource.AddPRLine}
          </Button>
        )
      }
      className="mb-4"
    >
      <FlexTable
        columns={[
          { className: 'col-auto', Header: '#' },
          { className: 'col', Header: resource.ProductDescription },
          { className: data.enableGoodsReceived ? 'col-1-half' : 'col-2', Header: resource.Quantity },
          { className: data.enableGoodsReceived ? 'col-1-half' : 'col-2', Header: resource.Price },
          { className: data.enableGoodsReceived ? 'col-1-half' : 'col-2', Header: resource.Subtotal },
          { className: settings.enableOtherCurrency ? 'col-2' : 'col-1', Header: resource.Currency },
          ...(data.enableGoodsReceived
            ? [
                { className: 'col-1-half border-start', Header: resource.QtyReceived },
                { className: 'col-1-half', Header: resource.TotalReceived },
              ]
            : []),
          {
            className: cx('col-action', {
              'col-action-3': showDelete && showCopy,
              'read-only': isReadOnly || !showCopy,
            }),
          },
        ]}
        footer={
          <FlexTableRow className="d-flex flex-wrap">
            <FlexTableCell className={cx('justify-content-lg-end', data.enableGoodsReceived ? 'col' : 'col-12')}>
              <Input
                name="totalAmount"
                defaultValue={formatNumber(data.totalAmount || 0, 2)}
                label={resource.TotalVat}
                formGroup
                horizontal
                labelCol={data.enableGoodsReceived ? { xs: 9 } : { xs: 7, lg: 6 }}
                inputCol={data.enableGoodsReceived ? { xs: 3 } : { xs: 5, lg: 6 }}
                className={cx('fw-bold text-end', !data.enableGoodsReceived && 'text-lg-start')}
                formClassName={cx('mb-0 justify-content-end', data.enableGoodsReceived ? 'col-12' : 'col-lg-6 col-12')}
                labelClassName="fw-bold text-end"
                textOnlyInput
              />
            </FlexTableCell>
            {data.enableGoodsReceived && (
              <React.Fragment>
                <FlexTableCell className="col-1-half border-start" />
                <FlexTableCell className="col-1-half">
                  <ReadOnlyField
                    value={formatNumber(data.totalGoodsReceivedPrice || 0, 2)}
                    formGroup
                    className="fw-bold"
                    formClassName="mb-0"
                  />
                </FlexTableCell>
                <FlexTableCell className="col-action read-only" />
              </React.Fragment>
            )}
          </FlexTableRow>
        }
      >
        {fields.map((item, index) => {
          const lineItem = getValues(`lines.${index}`) || {}
          return (
            <PraFormProductItem
              key={item.id}
              index={index}
              lineItem={lineItem}
              isReadOnly={isReadOnly}
              showDelete={showDelete}
              fetchApprovers={fetchApprovers}
              showCopy={showCopy}
              onDeleteLine={handleDeleteLine}
              onCopyLine={handleCopyLine}
              onChange={handleOnChange}
              clientId={data.clientId}
              isEdit={isEdit}
            />
          )
        })}
      </FlexTable>
    </Card>
  )
}
