import React from 'react'

import { TableList } from 'components/Case/CaseDetail/TableList'
import BundleItem from './BundleItem'
import BundleStatus from './BundleStatus'

export default ({ resource, propose, onViewDetail }) => {
  const { proposalBundles = [] } = propose

  return (
    <TableList>
      {!proposalBundles.length && <div className="text-muted text-center">{resource.NoProposalsFound}</div>}
      {proposalBundles.map((item, index) => {
        return (
          <div key={index} className="d-flex flex-wrap mb-6">
            <BundleItem resource={resource} proposeItem={item} onViewDetail={onViewDetail} />
            <BundleStatus resource={resource} proposeItem={item} />
          </div>
        )
      })}
    </TableList>
  )
}
