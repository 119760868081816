import React from 'react'

import Card from 'common/Card'
import Icon from 'common/Icon'

export default ({ resource, supplier }) => {
  const { address = {} } = supplier

  return (
    <Card title={resource.Supplier} outline className="col-lg-6 col-12 mb-4">
      <address className="mb-0">
        <div>
          <strong>{supplier.name}</strong>
        </div>
        <div>
          {address.street} {address.number} {address.box}
        </div>
        <div>
          {address.postalCode} {address.city}
        </div>
        <div>{address.country ? address.country.name : address.country.code}</div>
        {address.phone && (
          <div>
            <abbr title={resource.Phone} className="me-1">
              <Icon icon="phone" className="md-16" />
            </abbr>
            {address.phone}
          </div>
        )}
        {address.email && (
          <div>
            <abbr title={resource.Email} className="me-1">
              <Icon icon="email" className="md-16" />
            </abbr>
            {address.email}
          </div>
        )}
      </address>
    </Card>
  )
}
