import React from 'react'

import { UncontrolledCollapse } from 'reactstrap'

import { formatDateTime } from 'utils/formatters'
import Button from 'common/Button'
import ButtonLink from 'common/ButtonLink'
import Paper from 'common/Paper'

import ErrorLayout from './ErrorLayout'

class GlobelErrorCatcher extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch = (error, info) => {
    this.props.onError(error, info)
  }

  handleReloadPage = () => {
    window.location.reload()
  }

  handleReportError = () => {
    const { error } = this.props
    const session = this.props.session || {}

    const email = 'support@spotbuycenter.com'
    const subject = 'Error Reporting'

    const divider = '------------------------------------------------'
    const br = '%0D%0A'

    const message = `
    ${br}${br}${br}(Please don't change any text below this line)${br}${divider}
    ${session?.email ? `${br}User Email: ${session.email}` : ''}
    ${br}Date: ${formatDateTime(new Date())}
    ${br}URL: ${encodeURI(window.location.href)}
    ${br}User Agent: ${navigator.userAgent}
    ${br}Error: ${error.message}
  `
    window.location.href = `mailto:${email}?subject=${subject}&body=${message}`
  }

  render() {
    const { isApp, error, info, children, onReset } = this.props

    if (error) {
      const Wrapper = isApp ? ErrorLayout : React.Fragment

      return (
        <Wrapper>
          <Paper id="error-handler">
            <h2 className="text-danger">Oh snap! That's an error!</h2>
            <p>Something went wrong</p>
            <p>You may be able to recover from this error by:</p>
            <ul>
              {!isApp && (
                <li>
                  Click
                  <ButtonLink className="text-primary" onClick={onReset}>
                    here
                  </ButtonLink>
                  to reset and try again
                </li>
              )}
              <li>
                Click
                <ButtonLink className="text-primary" onClick={this.handleReloadPage}>
                  here
                </ButtonLink>
                to reload the page
              </li>
              <li>
                <div>
                  <ButtonLink className="text-primary ps-0" onClick={this.handleReportError}>
                    Report the error
                  </ButtonLink>
                  to Spot Buy Center and <strong>copy the error details</strong> below if problem persist.
                </div>
                <div>Please provide a screenshot of this page</div>
              </li>
            </ul>
            <Button type="button" id="buttonErrorToggler" className="mb-3">
              Show error
            </Button>
            <UncontrolledCollapse toggler="#buttonErrorToggler">
              <Paper className="border">
                {!navigator.onLine && <h5>Your are currently offline. Please connect to the internet.</h5>}
                {navigator.onLine && (
                  <React.Fragment>
                    <h5>{error.message}</h5>
                    <p className="word-break-break-word white-space-normal">{info.componentStack}</p>
                  </React.Fragment>
                )}
              </Paper>
            </UncontrolledCollapse>
          </Paper>
        </Wrapper>
      )
    }
    return children
  }
}

export default GlobelErrorCatcher
