import React from 'react'

import { Alert } from 'reactstrap'
import { observer } from 'mobx-react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { FlexTable, FlexTableCell, FlexTableRow } from 'common/FlexTable'
import { formatDate, praLongDateTimeFormat } from 'utils/formatters'
import { PRA_APPROVER_STATUS, PRA_STATUS } from 'definitions'
import Card from 'common/Card'
import ContentLoader from 'common/ContentLoader'
import Icon from 'common/Icon'
import Input from 'common/Forms/Input'

import ApprovalFlowComment from './ApprovalFlowComment'
import ApprovalFlowFreeChoice from './ApprovalFlowFreeChoice'
import ApprovalFlowStatus from './ApprovalFlowStatus'

export default observer(
  ({
    praStore,
    session,
    isEdit,
    isCopy,
    data,
    settings,
    loggedinEmployeeId,
    resource,
    disabled,
    isReadOnly,
    fetchApprovers,
  }) => {
    const skipSetApproversRef = React.useRef(true)
    const { control } = useFormContext()
    const { fields, replace } = useFieldArray({
      control,
      name: 'approvers',
      shouldUnregister: true,
      keyName: 'arrayId',
    })

    const {
      Approvers: { data: approversData, loading },
    } = praStore

    React.useEffect(() => {
      const data = praStore.CRUD.data
      if (isEdit || isCopy) {
        replace(data.approvers)
      } else if (!data.approvers || !data.approvers.length) {
        if (!skipSetApproversRef.current) {
          fetchApprovers()
        }
      }
    }, [praStore, isEdit, isCopy])

    React.useEffect(() => {
      if (!isReadOnly && !skipSetApproversRef.current) {
        replace(approversData)
      }
      skipSetApproversRef.current = false
    }, [isReadOnly, replace, approversData])

    return (
      <Card title={resource.ApprovalFlow} fieldset className="position-relative mb-2">
        <ContentLoader show={loading} />
        <FlexTable
          columns={[
            { className: 'col-3', Header: resource.ApproverName },
            { className: 'col-5', Header: resource.Action },
            { className: 'col-4', Header: resource.Date },
          ]}
        >
          {fields.map((approver, index) => (
            <FlexTableRow key={approver.arrayId} className="approval-table-row">
              <FlexTableCell className="col-lg-3 col-12 pb-0 align-items-lg-start">
                <Input type="hidden" name={`approvers.${index}.lineIndex`} defaultValue={approver.lineIndex} />
                <span className="d-lg-none me-2">#{approver.lineIndex}</span>
                <ApprovalFlowFreeChoice
                  praStore={praStore}
                  resource={resource}
                  index={index}
                  data={data}
                  approversData={approversData}
                  approver={approver}
                  loggedinEmployeeId={loggedinEmployeeId}
                  isReadOnly={isReadOnly}
                />
              </FlexTableCell>
              <FlexTableCell className="col-lg-5 col-12 pb-0 align-items-start flex-column">
                <ApprovalFlowStatus
                  praStore={praStore}
                  index={index}
                  resource={resource}
                  session={session}
                  pra={data}
                  settings={settings}
                  approvers={approversData}
                  approver={approver}
                  loggedinEmployeeId={loggedinEmployeeId}
                  disabled={disabled}
                />
                <ApprovalFlowComment approver={approver} lineIndex={approver.lineIndex} />
              </FlexTableCell>
              <FlexTableCell className="col-lg-4 col-12 pb-lg-0 align-items-start">
                {approver.status !== PRA_APPROVER_STATUS.UNSTARTED && formatDate(approver.date, praLongDateTimeFormat)}
              </FlexTableCell>
            </FlexTableRow>
          ))}
          {data.status === PRA_STATUS.APPROVED.id && (
            <FlexTableRow className="approval-table-row">
              <FlexTableCell className="col-lg-3 d-lg-flex d-none pb-0 align-items-lg-start" />
              <FlexTableCell className="col-lg-5 col-12 pb-0 align-items-start flex-column">
                <Alert color="success" className="py-1 px-3 mb-0 col-12">
                  {resource.OrderSent}
                </Alert>
                {!!data.poPdfAttachment && (
                  <Alert color="light" className="mt-1 py-1 px-1 mb-0 col-12">
                    <Icon icon="attachment" className="me-1 align-middle" />
                    <a href={data.poPdfAttachment.downloadLink}>{data.poPdfAttachment.fileName}</a>
                  </Alert>
                )}
              </FlexTableCell>
              <FlexTableCell className="col-lg-4 col-12 pb-lg-0 align-items-start">
                {!!data.poPdfAttachment && data.poPdfAttachment.description}
              </FlexTableCell>
            </FlexTableRow>
          )}
        </FlexTable>
      </Card>
    )
  }
)
