import React from 'react'

import { SERVICE_CONTRACT, SPOT_BUY_CENTER, WEBSHOP_ATTRIBUTE_TYPE } from 'definitions'
import { useStores } from 'stores'
import { formatString } from 'utils/formatters'
import { validateEmail, validateMaxLength, validateNumber } from 'utils/validators'
import Checkbox from 'common/Checkbox'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'
import ReactTable from 'common/ReactTable'
import ProductTextAreaCustomField from './ProductTextAreaCustomField'

export default ({ resource, data, handleSelectedCustomField }) => {
  const { authStore } = useStores()

  const { customFields } = data
  const {
    AppSession: { session },
  } = authStore

  const getAttributeValue = (attribute, attributeValue) => {
    if (Array.isArray(attributeValue)) {
      return attributeValue
        .map(item => {
          if (typeof attributeValue === 'object') {
            return Object.keys(item)
              .map(key => item[key])
              .join(', ')
          } else {
            return item
          }
        })
        .join('\n')
    } else if (typeof attributeValue === 'object') {
      const newValue = Object.keys(attributeValue)
        .map(key => attributeValue[key])
        .join('\n')

      return newValue
    } else {
      if (attribute.key.toLowerCase() === 'servicecontract') {
        if (attributeValue === SERVICE_CONTRACT.CLIENT_CATALOG) {
          attributeValue = session.clientName
        } else {
          attributeValue = SPOT_BUY_CENTER
        }
      } else if (attributeValue.toLowerCase() === 'true') {
        attributeValue = 'Yes'
      } else if (attributeValue.toLowerCase() === 'false') {
        attributeValue = 'No'
      }

      return attributeValue
    }
  }

  const renderAttributeValueComponent = (row, value, spec) => {
    const theValue = value.includes('{') ? JSON.parse(value) : value
    const lowerCaseTitle = spec.title.toLowerCase()

    if (row.isCustomField) {
      if (row.type.toLowerCase() === WEBSHOP_ATTRIBUTE_TYPE.CHECKBOX) {
        return (
          <React.Fragment>
            <Checkbox
              type="checkbox"
              onChange={e => handleSelectedCustomField(`${lowerCaseTitle}.${row.key}`, e.target.checked)}
            />
            <ProductTextAreaCustomField
              name={`${lowerCaseTitle}.${row.key}`}
              resource={resource}
              isRequiredCustomField={row.isRequiredCustomField}
            />
          </React.Fragment>
        )
      } else if (row.type.toLowerCase() === WEBSHOP_ATTRIBUTE_TYPE.SELECT) {
        const attributes = []

        row.value.split(',').forEach(item => {
          attributes.push({
            name: row.key,
            value: item.trim(),
          })
        })

        return (
          <ReactSelect
            name={`${lowerCaseTitle}.${row.key}`}
            options={attributes}
            optionValueKey="value"
            optionLabelKey="value"
            rules={{ required: row.isRequiredCustomField && resource.Required }}
          />
        )
      } else if (row.type.toLowerCase() === WEBSHOP_ATTRIBUTE_TYPE.EMAIL) {
        return (
          <Input
            name={`${lowerCaseTitle}.${row.key}`}
            type="text"
            rules={{
              required: row.isRequiredCustomField && resource.Required,
              validate: value => validateEmail(value, resource.Email),
            }}
          />
        )
      } else if (row.type.toLowerCase() === WEBSHOP_ATTRIBUTE_TYPE.PHONE) {
        return (
          <Input
            name={`${lowerCaseTitle}.${row.key}`}
            type="text"
            rules={{
              required: row.isRequiredCustomField && resource.Required,
              validate: {
                maxLength: value => {
                  return validateMaxLength(value, 25, formatString(resource.MaxLength, 25))
                },
              },
            }}
          />
        )
      } else if (row.type.toLowerCase() === WEBSHOP_ATTRIBUTE_TYPE.TEXT) {
        return (
          <Input
            name={`${lowerCaseTitle}.${row.key}`}
            type="text"
            rules={{ required: row.isRequiredCustomField && resource.Required }}
          />
        )
      } else if (row.type.toLowerCase() === WEBSHOP_ATTRIBUTE_TYPE.NUMBER) {
        return (
          <Input
            name={`${lowerCaseTitle}.${row.key}`}
            type="number"
            rules={{
              required: row.isRequiredCustomField && resource.Required,
              validate: value => validateNumber(value, resource.Number),
            }}
          />
        )
      }
    }
    return getAttributeValue(row, theValue)
  }

  return (
    <div className="pe-2 mb-8">
      {customFields &&
        customFields.map((spec, index) => (
          <ReactTable
            key={index}
            data={spec.attributes}
            className="fs-12"
            columns={React.useMemo(
              () => [
                {
                  Header: spec.title,
                  accessor: 'label',
                  width: 200,
                },
                {
                  accessor: 'value',
                  className: 'white-space-pre-line',
                  Cell: ({ row, value }) => {
                    return renderAttributeValueComponent(row, value, spec)
                  },
                },
              ],
              [spec.title]
            )}
          />
        ))}
    </div>
  )
}
