import React from 'react'

export default ({ data }) => {
  const formRef = React.useRef()
  const { catalogInfo: { hookUrl, lines = [] } = {} } = data

  React.useEffect(() => {
    formRef.current.submit()
  }, [])

  return (
    <form ref={formRef} method="POST" action={hookUrl} className="collapse">
      {lines.map((item, index) => (
        <React.Fragment key={index}>
          <input name={`NEW_ITEM-DESCRIPTION[${index + 1}]`} value={item.description} />
          <input name={`NEW_ITEM-MATNR[${index + 1}]`} value={item.matnr} />
          <input name={`NEW_ITEM-MATGROUP[${index + 1}]`} value={item.matGroup} />
          <input name={`NEW_ITEM-ING-CODE[${index + 1}]`} value={item.itemCode} />
          <input name={`NEW_ITEM-QUANTITY[${index + 1}]`} value={item.quantity} />
          <input name={`NEW_ITEM-UNIT[${index + 1}]`} value={item.unit} />
          <input name={`NEW_ITEM-PRICE[${index + 1}]`} value={item.price} />
          <input name={`NEW_ITEM-PRICEUNIT[${index + 1}]`} value={item.priceUnit} />
          <input name={`NEW_ITEM-CURRENCY[${index + 1}]`} value={item.currency} />
          <input name={`NEW_ITEM-LEADTIME[${index + 1}]`} value={item.leadTime} />
          <input name={`NEW_ITEM-VENDOR[${index + 1}]`} value={item.vendor} />
          <input name={`NEW_ITEM-VENDORMAT[${index + 1}]`} value={item.vendorMat} />
          <input name={`NEW_ITEM-MANUFACTCODE[${index + 1}]`} value={item.manufactCode} />
          <input name={`NEW_ITEM-MANUFACTMAT[${index + 1}]`} value={item.manufactMat} />
          <input name={`NEW_ITEM-CONTRACT_ITEM[${index + 1}]`} value={item.contractItem} />
          <input name={`NEW_ITEM-LONGTEXT[${index + 1}]`} value={item.longText} />
          <input name={`NEW_ITEM-ZZ_LONGTEXT_${index + 1}:132[]`} value={item.longText} />
          <input name={`NEW_ITEM-SERVICE[${index + 1}]`} value={item.service} />
          <input name={`NEW_ITEM-CONTRACT[${index + 1}]`} value={item.contract} />
          <input name={`NEW_ITEM-MAT_PURCH_GROUP[${index + 1}]`} value={item.matPurchGroup} />
          <input name={`NEW_ITEM-CUST_FIELD1[${index + 1}]`} value={item.custField1} />
          <input name={`NEW_ITEM-CUST_FIELD2[${index + 1}]`} value={item.custField2} />
          <input name={`NEW_ITEM-CUST_FIELD3[${index + 1}]`} value={item.custField3} />
          <input name={`NEW_ITEM-CUST_FIELD4[${index + 1}]`} value={item.custField4} />
          <input name={`NEW_ITEM-CUST_FIELD5[${index + 1}]`} value={item.custField5} />
          <input name={`NEW_ITEM-CUST_FIELD13[${index + 1}]`} value={item.custField13} />
          <input name={`NEW_ITEM-CUST_FIELD15[${index + 1}]`} value={item.custField15} />
          <input name={`NEW_ITEM-CUST_FIELD16[${index + 1}]`} value={item.custField16} />
          <input name={`NEW_ITEM-CUST_FIELD17[${index + 1}]`} value={item.custField17} />
          <input name={`NEW_ITEM-CUST_FIELD18[${index + 1}]`} value={item.custField18} />
          <input name={`NEW_ITEM-ATTACHMENT[${index + 1}]`} value={item.attachment} />
        </React.Fragment>
      ))}
      <button type="submit" />
    </form>
  )
}
