import BaseStore from 'stores/base'

export default class ClientAreaEmployeeStore extends BaseStore {
  constructor(api, options) {
    super(api)
    this.api = api
    this.options = options
  }

  saveClientEmployee(clientId, payload, options) {
    return this.request(this.CRUD, () => this.service.saveClientEmployee(clientId, payload, options))
  }

  async searchClientEmployee(clientId, payload, options) {
    const { data, error } = await this.service.searchClientEmployee(clientId, payload, options)
    return { data, error }
  }
}
