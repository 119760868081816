import React from 'react'

import { formatCurrency, formatString } from 'utils/formatters'

export default ({ transport, resource }) => {
  const { thresholds } = transport

  return (
    !!thresholds?.length && (
      <div className="fw-bold d-flex flex-row flex-md-column flex-xxl-row">
        <div>
          <small className="pe-1">*{resource.Transport}:</small>
        </div>
        <div>
          {thresholds.map(item => (
            <div key={item.index}>
              <small>
                {formatString(
                  resource.TransportThresholdRuleFormat,
                  item.fee > 0 ? formatCurrency(item.fee) : `€ 0.00`,
                  `${formatCurrency(item.orderAmount)}`
                )}
              </small>
            </div>
          ))}
        </div>
      </div>
    )
  )
}
