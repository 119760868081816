import React from 'react'

import { components } from 'react-select'
import cx from 'classnames'

import { getYearRange } from 'utils/date'
import ReactSelect from 'common/ReactSelect'

export default ({ defaultValue, value, years: yearsProp, inputClassName = '', onChange, ...rest }) => {
  const dateToday = new Date()

  const years = React.useMemo(() => {
    return yearsProp || getYearRange()
  }, [yearsProp])

  const handleOnChange = selection => {
    onChange && onChange(selection?.value)
  }

  return (
    <ReactSelect
      {...(value ? { value: { value, label: value } } : {})}
      {...(defaultValue ? { value: { value: defaultValue, label: defaultValue } } : {})}
      options={years.map(item => ({
        value: item,
        label: item,
      }))}
      className={cx('date-select year-select', inputClassName)}
      isClearable
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        // Menu: ({ children, ...props }) => <components.Menu {...props}>{children}</components.Menu>,
        Option: ({ children, className, value, ...props }) => (
          <components.Option
            className={cx(className, {
              today: value === dateToday.getFullYear(),
            })}
            value={value}
            {...props}
          >
            {children}
          </components.Option>
        ),
      }}
      noOptionsMessage={() => 'Year does not exists'}
      onChange={handleOnChange}
      {...rest}
    />
  )
}
