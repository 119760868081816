import BaseStore from 'stores/base'

export default class SupplierStore extends BaseStore {
  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options
  }

  requestNewSupplier(payload) {
    return this.request(this.CRUD, () => this.service.requestNewSupplier(payload))
  }
}
