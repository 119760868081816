import BaseStore from 'stores/base'

export default class ClientCaseStore extends BaseStore {
  constructor(service, options) {
    super(service)
    this.service = service
    this.options = options
  }

  async updateSubject(caseId, payload) {
    const result = await this.request(this.CRUD, () => this.service.updateSubject(caseId, payload))
    if (result) {
      this.CRUD.data.case.subject = payload.subject
    }
  }
}
