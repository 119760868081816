import React from 'react'

import { observer } from 'mobx-react'

import { formatFileUrl } from 'utils/formatters'
import { useStores } from 'stores'
import Card from 'common/Card'
import DatePicker from 'common/Forms/DatePicker'
import Input from 'common/Forms/Input'
import WebformInfoDeliveryInfo from 'components/Webform/WebformInfoDeliveryInfo'
import WebformInfoDeliveryRequester from 'components/Webform/WebformInfoDeliveryRequester'

export default observer(({ resource }) => {
  const { webformStore } = useStores()

  const {
    CRUD: { data, submitting },
  } = webformStore
  const { settings = {} } = data
  const disabled = submitting

  return (
    <div className="d-flex flex-lg-row flex-column-reverse mb-4">
      <div className="col-lg-8">
        <Input
          name="subject"
          defaultValue={data.subject}
          placeholder={resource.phSubject}
          label={<h2 className="mb-0">{resource.Webform}</h2>}
          formGroup
          horizontal
          maxLength="50"
          className="mb-3"
          labelClassName="pt-0"
          labelCol={{ xs: 'col-12', sm: 'auto' }}
          inputCol={{ xs: true }}
          readOnly={submitting}
        />
        <Card title={resource.DeliveryInformation} color="primary" outline>
          <DatePicker
            name="deliveryInfo.deliveryDate"
            rules={{ required: resource.Required }}
            defaultValue={data.deliveryInfo?.deliveryDate}
            label={`${resource.PreferredDate}*`}
            disabled={disabled}
            formGroup
            horizontal
            inputClassName="col-sm-6"
          />
          {settings.shouldSelectRequester && (
            <WebformInfoDeliveryRequester
              resource={resource}
              data={data}
              disabled={disabled}
              deliverTo={data.deliveryInfo?.requesterType}
            />
          )}
          <WebformInfoDeliveryInfo
            resource={resource}
            data={data}
            addressType={data.deliveryInfo?.addressType}
            disabled={disabled}
          />
          {settings.enableProjectCode && (
            <Input
              name="projectCode"
              defaultValue={data.projectCode}
              label={resource.ProjectCode}
              formGroup
              horizontal
              readOnly={submitting}
            />
          )}
        </Card>
      </div>
      <div className="col-lg-4 col text-center ps-lg-4 mb-7">
        {data.clientLogoFileId && (
          <img
            alt={`Client Logo - ${data.clientLogoFileId}`}
            className="img-responsive img-thumbnail fadein"
            style={{ maxHeight: '200px' }}
            src={formatFileUrl(data.clientLogoFileId)}
          />
        )}
      </div>
    </div>
  )
})
