import React from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useSearchParams } from 'react-router-dom'

import { useStores } from 'stores'
import LayoutTitle from 'common/LayoutTitle'
import Loader from 'common/Loader'
import Toaster from 'common/Toaster'

import FeedbackFooter from './FeedbackFooter'
import FeedbackInfoPanel from './FeedbackInfoPanel'

export default observer(() => {
  const { feedbackStore, globalMessageStore, pageResourceStore } = useStores()
  const [submitted, setSubmitted] = React.useState(false)
  const methods = useForm()
  const [searchParams] = useSearchParams()

  const { handleSubmit } = methods
  const {
    PageResource: { feedback: resource = {}, common: commonResource = {}, loading: resourceLoading },
  } = pageResourceStore
  const {
    CRUD: { loading: crudLoading, submitting },
  } = feedbackStore
  const data = feedbackStore.CRUD.data.complaint || {}

  const loading = crudLoading || resourceLoading
  const hasData = !!data && !!data.caseNumber
  const viewOnly = !!data && !!data.id
  const disabled = !hasData || submitting || viewOnly

  const query = React.useMemo(() => {
    return {
      language: searchParams.get('lang') || 'en',
      caseId: searchParams.get('caseId'),
      defaultPage: searchParams.get('defaultPage') || 'webform',
    }
  }, [searchParams])

  React.useEffect(() => {
    pageResourceStore.get('feedback', `clientcomplaint/${query.language}`)
    pageResourceStore.get('common')
  }, [pageResourceStore, query.language])

  React.useEffect(() => {
    query.caseId && feedbackStore.getFeedback(query.caseId)
  }, [feedbackStore, query.caseId])

  const handleOnSubmit = values => {
    Toaster.confirm({
      title: resource['ConfirmSubmit.Header'],
      message: `${resource['ConfirmSubmit.Body']} ${data.caseNumber} - ${data.caseDescription}`,
      submitText: commonResource.Confirm,
      cancelText: commonResource.Cancel,
      closeOnSubmit: false,
      onSubmit: async closeModal => {
        const payload = {
          ...values,
          level: values.level.value,
          caseId: query.caseId,
          language: query.language,
        }

        const result = await feedbackStore.submitFeedback(payload)
        closeModal()
        if (result) {
          setSubmitted(true)
          globalMessageStore.show({
            title: resource['Feedback.Success'],
            message: resource['Feedback.Success.Body'],
            color: 'success',
          })
        }
      },
    })
  }

  return (
    <div id="feedback">
      <Loader show={loading} className="justify-content-center" />
      {!loading && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleOnSubmit)} className="d-flex flex-column w-100">
            <LayoutTitle title={resource.Feedback} />
            <FeedbackInfoPanel
              resource={resource}
              commonResource={commonResource}
              data={data}
              disabled={disabled || submitted}
            />
            <FeedbackFooter
              resource={resource}
              defaultPage={query.defaultPage}
              disabled={disabled}
              hasData={hasData}
              submitted={submitted}
              viewOnly={viewOnly}
            />
          </form>
        </FormProvider>
      )}
    </div>
  )
})
