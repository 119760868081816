import React from 'react'

import { Badge } from 'reactstrap'

import { padLeft } from 'utils'
import Card from 'common/Card'
import FileLink from 'common/FileLink'

import PurchaseOrderTable from './PurchaseOrderTable'

export default ({ resource, purchaseOrder, onViewDetail }) => {
  const attachmentsFromEmail = purchaseOrder.attachmentsFromEmail || []

  const handleViewDetail = poLine => onViewDetail({ purchaseOrder, poLine })

  return (
    <Card
      title={`${resource.PurchaseOrder} #${padLeft(purchaseOrder.documentIndex, 1)}`}
      color="primary"
      outline
      className="mb-6"
      actions={
        <div>
          <span className="me-2">
            {attachmentsFromEmail.map((item, index) => (
              <span key={index} className="text-muted">
                <FileLink data={item} hideLabel />
              </span>
            ))}
          </span>
          <Badge color="primary">
            {resource.POReference}: {purchaseOrder.externalPoReference}
          </Badge>
        </div>
      }
    >
      <PurchaseOrderTable resource={resource} purchaseOrder={purchaseOrder} onViewDetail={handleViewDetail} />
    </Card>
  )
}
