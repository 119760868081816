import React from 'react'

import { formatCurrency } from 'utils/formatters'
import { padLeft } from 'utils'
import Button from 'common/Button'
import Card from 'common/Card'
import Modal from 'common/Modal'
import ReactTable from 'common/ReactTable'
import ReadOnlyAttachment from 'common/ReadOnlyAttachment'
import ReadOnlyField from 'common/ReadOnlyField'

import SupplierDetail from 'components/Case/CaseDetail/Source/RfqFormModal/SupplierDetail'

export default ({ resource, commonResource, data, onClose }) => {
  const { order, supplier } = data
  const { attachments, lines = [] } = order

  const columns = React.useMemo(
    () => [
      {
        Header: 'PO',
        accessor: 'poIndex',
        headerClassName: 'text-muted',
        className: 'text-muted',
        width: 50,
        Cell: ({ value }) => padLeft(value, 1),
      },
      {
        Header: '#',
        accessor: 'poLineIndex',
        headerClassName: 'text-muted',
        className: 'text-muted',
        width: 50,
        Cell: ({ value }) => padLeft(value, 1),
      },
      {
        Header: resource.Description,
        accessor: 'description',
        headerClassName: 'col',
        className: 'col',
        footerClassName: 'col',
        Cell: ({ value, row }) => (
          <div>
            <div className="mb-3 word-break-break-word white-space-normal">{value}</div>
            <div className="mb-3 word-break-break-word white-space-normal">{row.extendedDescription}</div>
          </div>
        ),
      },
      {
        Header: resource.Qty,
        accessor: 'quantity.amount',
        headerClassName: 'col-1 text-end',
        className: 'col-1 text-end',
        footerClassName: 'col-1',
      },
      {
        Header: `${resource.SupplierPrice} (€)`,
        accessor: 'supplierPrice.value',
        headerClassName: 'col-2 text-end',
        className: 'col-2 text-end',
        footerClassName: 'col-2 text-end',
        Cell: ({ value }) => (value ? formatCurrency(value) : '-'),
        Footer: resource.TotalExcludingVat,
      },
      {
        Header: `${resource.Subtotal} (€)`,
        accessor: 'totalPrice',
        headerClassName: 'col-2 text-end',
        className: 'col-2 text-end',
        footerClassName: 'col-2 text-end',
        Cell: ({ row: { supplierPrice, quantity } }) => {
          if (supplierPrice.value && quantity.amount) {
            return formatCurrency(supplierPrice.value * quantity.amount)
          }
          return '-'
        },
        Footer: ({ data: lines = [] }) => {
          if (lines) {
            const value = lines.reduce((total, item) => total + item.supplierPrice.value * item.quantity.amount, 0)
            return formatCurrency(value)
          }
          return '-'
        },
      },
    ],
    [resource]
  )

  return (
    <Modal
      isOpen
      title={`${resource.ViewOrder} #${order.documentIndex}`}
      size="xl"
      footer={
        <Button type="button" text color="primary" onClick={onClose}>
          {commonResource.Cancel}
        </Button>
      }
      footerClassName="justify-content-start"
      onClose={onClose}
    >
      <Card title={resource.Supplier} outline className="mb-4">
        <SupplierDetail resource={resource} supplier={supplier} formGroup={false} />
      </Card>
      <Card title={resource.OrderLines} color="primary" outline className="mb-4">
        <ReactTable columns={columns} data={lines} showFooter responsive />
      </Card>
      <Card title={resource.OrderExtraInformation} outline className="mb-4">
        <ReadOnlyAttachment
          label={resource.Attachments}
          value={attachments}
          noAttachment={resource.NoAttachments}
          formGroup
          horizontal
        />
        <ReadOnlyField
          label={resource.Remarks}
          value={order.remarks}
          className="word-wrap-break-word"
          formGroup
          horizontal
        />
      </Card>
    </Modal>
  )
}
