import React from 'react'

import { ListGroupItem } from 'reactstrap'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import { CASE_STATUS, CASE_TYPE, ROUTES } from 'definitions'
import Bundles from 'components/Case/CaseList/Proposals'
import CaseItemActiveActions from 'components/Case/CaseList/CaseItem/CaseItemActiveActions'
import CaseItemInActiveActions from 'components/Case/CaseList/CaseItem/CaseItemInActiveActions'
import CaseItemInfo from 'components/Case/CaseList/CaseItem/CaseItemInfo'

export default observer(({ session, caseStore, resource, caseItem, onAbortCase, onReload, onPunchout }) => {
  const bundleRef = React.useRef()
  const navigate = useNavigate()

  const {
    CRUD: { submitting },
  } = caseStore

  const isAborted = caseItem.status ? caseItem.status.toLowerCase().includes('aborted') : false

  const toggle = event => {
    event && event.stopPropagation()
    bundleRef.current && bundleRef.current.toggle()
  }

  const handleCopyCase = async event => {
    event.stopPropagation()
    if (caseItem.type === CASE_TYPE.Webform) {
      navigate(`${ROUTES.WEBFORM}?copy-case=${caseItem.id}`)
    } else if ([CASE_TYPE.BeforeTheFacts, CASE_TYPE.AfterTheFacts, CASE_TYPE.ContractPo].includes(caseItem.type)) {
      if (session.hasPra) {
        const result = await caseStore.copyCase(caseItem.id)
        if (result) {
          navigate(`${ROUTES.PRA}?key=${result.praKey}`)
        }
      }
    }
  }

  const handleViewCase = event => {
    event.stopPropagation()
    navigate(`${ROUTES.CASES}/${caseItem.id}`)
  }

  const handleCaseFeedback = event => {
    event.stopPropagation()
    const feedbackUrl = `${caseItem.clientFeedbackUrl}&lang=${session.uiLanguageSetting}&defaultPage=cases`
    if (session.viewCaseInNewTab) {
      window.open(feedbackUrl)
    } else {
      window.open(feedbackUrl, '_self')
    }
  }

  const handleAbortCase = event => {
    event.stopPropagation()
    onAbortCase({
      caseId: caseItem.id,
      caseNumber: caseItem.caseNumber,
    })
  }

  const handleReleaseCase = async event => {
    event.stopPropagation()
    const result = await caseStore.releaseCase(caseItem.id)
    result && onReload()
  }

  const handleWebshopPunchout = async event => {
    event.stopPropagation()
    const result = await caseStore.webshopPunchout(caseItem.id)
    if (result) {
      window.location = result.url
    }
  }

  return (
    <ListGroupItem className="cases-list-item col px-lg-3 px-0 py-2 border-0 border-bottom">
      <div className="d-flex flex-wrap col flex-xl-row flex-column" onClick={toggle}>
        <CaseItemInfo session={session} resource={resource} caseItem={caseItem} />
        {caseItem.status === CASE_STATUS.ACTIVE && (
          <CaseItemActiveActions
            session={session}
            caseStore={caseStore}
            resource={resource}
            caseItem={caseItem}
            onCopyCase={handleCopyCase}
            onViewCase={handleViewCase}
            onCaseFeedback={handleCaseFeedback}
            onAbortCase={handleAbortCase}
            onReleaseCase={handleReleaseCase}
            onWebshopPunchout={handleWebshopPunchout}
            submitting={submitting}
          />
        )}
        {caseItem.status !== CASE_STATUS.ACTIVE && (
          <CaseItemInActiveActions
            session={session}
            caseStore={caseStore}
            resource={resource}
            caseItem={caseItem}
            onCopyCase={handleCopyCase}
            onViewCase={handleViewCase}
            submitting={submitting}
          />
        )}
      </div>
      <Bundles ref={bundleRef} caseId={caseItem.id} isAborted={isAborted} onPunchout={onPunchout} />
    </ListGroupItem>
  )
})
