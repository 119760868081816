import React from 'react'

import { PUNCHOUT_TYPE } from 'definitions'
import Card from 'common/Card'
import FileAttachment from 'common/Forms/FileAttachment'
import Input from 'common/Forms/Input'
import ReactSelect from 'common/Forms/ReactSelect'

import CategorySubCategory from 'components/Sections/CategorySubCategory'
import QuantityUnit from 'components/Sections/QuantityUnit'

export default ({ resource, commonResource, prTabData, prLine, disabled }) => {
  const { glAccounts = [], purchaseRequest } = prTabData
  const { settings } = purchaseRequest

  return (
    <Card title={resource.ProductInformation} fieldset>
      <CategorySubCategory
        {...{
          resource,
          defaultCategory: prLine.category,
          defaultSubCategory: prLine.subCategory,
          categoryRules: { required: commonResource.Required },
          categories: prTabData.categories,
          subCategories: prTabData.subcategories,
          showSubcategory: settings.shouldSelectSubcategory,
          disabled,
        }}
      />

      {settings.enableGLAccount && (
        <ReactSelect
          name="glAccount"
          rules={{ required: !!settings.enableGLAccount && commonResource.Required }}
          defaultValue={prLine.glAccount}
          label={`${resource.GLAccount} *`}
          placeholder={resource.SelectAppropriateGLAccount}
          options={glAccounts}
          optionValueKey="id"
          optionLabelKey="glAccount"
          formGroup
          horizontal
          isDisabled={disabled}
        />
      )}
      <Input
        name="description"
        rules={{ required: commonResource.Required }}
        defaultValue={prLine.description}
        label={`${resource.ProductDescription} *`}
        placeholder={resource.ProductDescriptionPlaceholder}
        maxLength={settings.erp === PUNCHOUT_TYPE.SAP ? 40 : 150}
        formGroup
        horizontal
        disabled={disabled}
      />
      <QuantityUnit
        resource={resource}
        commonResource={commonResource}
        defaultQuantity={prLine.quantity}
        disabled={disabled}
      />
      <Input
        name="comment"
        type="textarea"
        defaultValue={prLine.comment}
        label={resource.ExtendedDescription}
        rows="4"
        formGroup
        horizontal
        disabled={disabled}
      />
      <FileAttachment
        name="attachments"
        defaultValue={{ attachments: prLine.attachments }}
        label={resource.Attachments}
        formGroup
        horizontal
        disabled={disabled}
      />
    </Card>
  )
}
