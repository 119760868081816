import React from 'react'

import Card from 'common/Card'
import Icon from 'common/Icon'

export default ({ exportUrl, resource }) => {
  return (
    <Card title={resource.Export} color="default" outline bodyClassName="min-height-150">
      <a href={exportUrl} rel="external" className="btn btn-primary text-decoration-none">
        <Icon icon="file_download" className="btn-material-icon align-middle" /> {resource.ExportAll}
      </a>
    </Card>
  )
}
